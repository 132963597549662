import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const parts = ['box', 'card', 'cardBody', 'image', 'cardFooter', 'text'];
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts);

const baseStyle = definePartsStyle({
  box: {
    p: 2,
    maxH: '100%',
  },
  card: {
    h: '16rem',
    bg: 'fpd.600',
    boxShadow: 'md',
    borderRadius: 'lg',
    overflow: 'hidden',
    _hover: {
      transform: 'scale(1.05)',
      boxShadow: 'xl',
      bg: 'fpd.500',
      border: '2px solid #ccc',
    },
  },
  cardBody: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    borderRadius: 'lg',
    w: '90%',
  },
  cardFooter: {
    justifyContent: 'center',
    color: 'fpd.100',
    fontSize: 'xs',
  },
  text: {
    maxW: '10rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export const PrintGraphicPreviewCardStyles = defineMultiStyleConfig({
  baseStyle,
});
