/**
 *
 * This function takes an panel element which should be a group of path elemntns
 * as parameters and returns an object that represents
 * the element's bounding box coordinates,
 */

/**
 *
 * Extracting the transformation matrix from SVG element
 * The function retrieves the matrix components and returns them in an array
 */

function svgMatrixToArray(matrix) {
  return [matrix.a, matrix.b, matrix.c, matrix.d, matrix.e, matrix.f];
}

// A function to extract rotation from the matrix
function getRotationAngle(matrix) {
  return Math.atan2(matrix[1], matrix[0]);
}

// Function to calculate the scale factors based on rotation angle
function getScaleFactors(rotationAngle) {
  // Taking the absolute sine and cosine values ensures that we handle all quadrants of rotation
  let sin = Math.abs(Math.sin(rotationAngle));
  let cos = Math.abs(Math.cos(rotationAngle));

  // The scale factors impact how much the bounding box should be expanded along each axis to fully encompass the rotated shape
  let scale = sin + cos;

  return {
    x: scale, // scale factor for width
    y: scale, // scale factor for height
  };
}

/**
 *
 * Transforms a bounding box based on a transformation matrix, stroke width,
 * and rotation angle
 * Stroke width and rotation are considered to adjust the bounding box
 * dimensions to fully encapsulate the path and its stroke
 * The bounding box is transformed according to the matrix
 */
function transformBoundingBox(bbox, matrix, strokeWidth, rotationAngle) {
  const [a, b, c, d, e, f] = matrix;

  let { x, y, width, height } = bbox;

  let corners = [
    [x, y],
    [x + width, y],
    [x + width, y + height],
    [x, y + height],
  ];

  let transformedCorners = corners.map(([x, y]) => [
    a * x + c * y + e,
    b * x + d * y + f,
  ]);

  let xVals = transformedCorners.map((c) => c[0]);
  let yVals = transformedCorners.map((c) => c[1]);

  let minX = Math.min(...xVals);
  let maxX = Math.max(...xVals);
  let minY = Math.min(...yVals);
  let maxY = Math.max(...yVals);

  let scaleFactors = getScaleFactors(rotationAngle);
  let adjustedStrokeWidthX = strokeWidth * scaleFactors.x;
  let adjustedStrokeWidthY = strokeWidth * scaleFactors.y;

  return {
    x: minX - adjustedStrokeWidthX,
    y: minY - adjustedStrokeWidthY,
    width: maxX - minX + 2 * adjustedStrokeWidthX,
    height: maxY - minY + 2 * adjustedStrokeWidthY,
  };
}

/**
 *
 * The function calculates the smallest single bounding box that
 * can contain all provided bounding boxes.
 * A function to combine multiple bounding boxes
 * into a single bounding box.
 */

function combineBoundingBoxes(bboxes) {
  let minX = Math.min(...bboxes.map((bbox) => bbox.x));
  let minY = Math.min(...bboxes.map((bbox) => bbox.y));
  let maxX = Math.max(...bboxes.map((bbox) => bbox.x + bbox.width));
  let maxY = Math.max(...bboxes.map((bbox) => bbox.y + bbox.height));

  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
  };
}

/**
 *
 * Main function to calculate the bounding box of an SVG element
 * The function iterates over all path elements within the provided
 * SVG element,
 * calculates their bounding boxes, transforms those bounding boxes,
 * and then combines them into a single bounding box that is returned
 */
export const bcrToBbox = function (element) {
  const paths = Array.from(element.querySelectorAll('path, image'));
  const pathesStrok = paths.map((path) => +path.getAttribute('stroke-width'));

  const matrix = svgMatrixToArray(
    element.transform.baseVal.consolidate().matrix
  );
  const rotationAngle = getRotationAngle(matrix);
  let pathStrokeWidths = pathesStrok.map((strokeWidth) => {
    return strokeWidth !== 1 ? strokeWidth / 2 : 0;
  });
  let transformedBBoxes = paths.map((path, index) =>
    transformBoundingBox(
      path.getBBox(),
      matrix,
      pathStrokeWidths[index],
      rotationAngle
    )
  );
  let overallBBox = combineBoundingBoxes(transformedBBoxes);

  return overallBBox;
};

export const engravingTextBcrToBbox = function (element) {
  const paths = Array.from(element.querySelectorAll('path, image'));

  let bBoxes = paths.map((path) => path.getBBox());

  let overallBBox = combineBoundingBoxes(bBoxes);

  return overallBBox;
};
