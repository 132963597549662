/**
 * The 'UploadFile' component, manages the upload file process from the local system and prepares the data for server posting.
 */

// Built-in
import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  useCallback,
} from 'react';

// External
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Box,
  Text,
  VStack,
} from '@chakra-ui/react'; // Importing necessary objects from '@chakra-ui' library for rendering the UI.
// Internal

import { ToolbarIcon } from './ToolbarIcon';
import { ApiContext } from '../../context/ApiContext';
import GroupContainer from './modal/dialog/GroupContainer';
import ActionButton from './modal/dialog/elements/ActionButton';
import { useTranslation } from 'react-i18next';
import { FPD_LIMITS } from '../../api/Client';
import FileUploadContainer, { formatFileSize } from './FileUploadContainer';

// Defining constant variable for file upload
const fileContext = {
  fileContent: '',
  fileName: '',
  fileCustomName: '',
  fileSize: '',
};

function DocumentsUpload() {
  const { uploadDocument, uploadPreviewDoc } = useContext(ApiContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [file, setFile] = useState(fileContext);
  const [svgData, setSvgData] = useState(null);
  const initialRef = useRef();
  const finalRef = useRef();
  const { t } = useTranslation(['Upload', 'ActionButton']);
  const toast = useToast();

  // Opens upload file dialog when click on the respective button
  const uploadFileClickHandler = () => {
    onOpen();
  };
  useEffect(() => {
    if (!file.fileContent) return;
    svgdataProvider(file);
  }, [file.fileContent]);
  const svgdataProvider = useCallback(async (file) => {
    const svg = await uploadPreviewDoc(file);
    setSvgData(svg);
    return svg;
  }, []);
  useEffect(() => {
    if (!isOpen) return;
    toast({
      title: 'File Size Limit!',
      description: `The file size must not exceed ${(
        FPD_LIMITS /
        (1024 * 1024)
      ).toFixed(2)} MB.`,
      status: 'info',
      duration: null,
      isClosable: true,
    });
  }, [isOpen]);

  const selectFileHandleChange = (acceptedFile) => {
    const fileName = acceptedFile.name.replace('.fpd', '');
    const fileSize = formatFileSize(acceptedFile.size);
    const reader = new FileReader();

    reader.readAsArrayBuffer(acceptedFile);
    reader.onloadend = (evt) => {
      if (evt.target.readyState === FileReader.DONE) {
        setFile((prevState) => ({
          ...prevState,
          fileContent: evt.target.result,
          fileName: fileName,
          fileCustomName: fileName,
          fileSize: fileSize,
        }));
      }
    };
  };

  // triggers when click on the submit button
  const handleSubmit = () => {
    uploadDocument(file);
    setFile(fileContext);
    modalCloseHandler();
  };

  const modalCloseHandler = () => {
    toast.closeAll();
    setSvgData(null);
    onClose();
  };

  return (
    <>
      <ToolbarIcon
        label="Upload"
        imagePath="/icons/upload_panel.svg"
        onClickHandler={uploadFileClickHandler}
      />
      {isOpen && (
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={modalCloseHandler}
          size="xl"
          autoFocus={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t('Upload:Header')}</ModalHeader>
            <ModalCloseButton onClick={() => setFile(fileContext)} />
            <ModalBody>
              <GroupContainer title={t('Upload:DocProps')}>
                <FileUploadContainer
                  accept={{ 'application/vnd.fpd': ['.fpd'] }}
                  fileHandler={selectFileHandleChange}
                  svgData={svgData}
                  file={file}
                  fileNameLabel={t('Upload:FileName')}
                  fileSizeLabel={t('Upload:FileSize')}
                />
              </GroupContainer>
            </ModalBody>

            <ModalFooter>
              <ActionButton
                isDisabled={!Boolean(file.fileName)}
                onClick={handleSubmit}
                label={t('ActionButton:Upload')}
              />
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

export default DocumentsUpload;
