/**
 * Separates the properties of an object into three categories: Group, Elements, and rest.
 * The function iterates over the given object's properties and categorizes them
 * based on the presence of a "Group/" or "Elements/" prefix in the property key.
 *
 */

const groupElementSeparator = (membersProps) =>
  Object.entries(membersProps).reduce(
    (acc, [key, value]) => {
      const groupRegex = /^Group\//;
      const elementRegex = /^Elements\//;

      if (groupRegex.test(key)) {
        acc.Group[key.replace(groupRegex, '')] = value;
      } else if (elementRegex.test(key)) {
        acc.Elements[key.replace(elementRegex, '')] = value;
      } else {
        acc.rest[key] = value;
      }

      return acc;
    },
    { Group: {}, Elements: {}, rest: {} }
  );

export default groupElementSeparator;
