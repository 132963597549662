/**
 * The 'SelectMode' component manages the action of changing interactionState to 'SelectMode'.
 */

// Built-in
import React, { useContext } from 'react';
// External
import { IconButton, Tooltip, useStyleConfig } from '@chakra-ui/react'; // Importing necessary objects from '@chakra-ui' library for rendering the UI.

// Internal
import { SelectModeType } from '../../context/Types';
import { ApiContext } from '../../context/ApiContext'; // Provides API context (Global shared state) to the component

import { ToolbarIcon } from './ToolbarIcon';

function SelectMode() {
  const { Document, interactionState, setIntractionState } =
    useContext(ApiContext);

  const selectModeButtonClickHandler = () => {
    setIntractionState(SelectModeType.SelectMode);
  };
  const isSelected = interactionState === SelectModeType.SelectMode;

  return (
    <ToolbarIcon
      label="SelectMode"
      imagePath="/icons/select_mode.svg"
      onClickHandler={selectModeButtonClickHandler}
      isDisabled={!Document.id}
      isSelected={isSelected}
    />
  );
}

export default SelectMode;
