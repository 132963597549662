import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'; // Importing necessary objects from '@chakra-ui' library for rendering the UI.
import RenderSidebar from './RenderSidebar';

const GroupAndElementTabs = ({ member_properties, focusHandler }) => {
  //renderTabPanel function to handle rendering of TabPanel components
  const renderTabPanel = (propsTopCategory) => (
    <RenderSidebar
      member_properties={member_properties[propsTopCategory]}
      focusHandler={focusHandler}
      propsTopCategory={propsTopCategory}
    />
  );
  return (
    <Tabs>
      <TabList>
        <Tab>Group</Tab>
        <Tab>Elements</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>{renderTabPanel('Group')}</TabPanel>
        <TabPanel>
          {renderTabPanel('Elements')}
          {renderTabPanel('rest')}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default GroupAndElementTabs;
