/**
 * The 'HpglEngravingCreator' component, manages the action of inserting a 'hpgl engraving'.
 */

// Built-in
import React, { useContext } from 'react';

// Internal
import { ElementType } from '../../context/Types';
import { ApiContext } from '../../context/ApiContext'; // Provides API context (Global shared state) to the component

import { ToolbarIcon } from './ToolbarIcon';

function HpglEngravingCreator() {
  const { Document, interactionState, setIntractionState } =
    useContext(ApiContext);

  const hpglEngravingButtonClickHandler = () => {
    setIntractionState(ElementType.HpglEngraving);
  };

  const isSelected = interactionState === ElementType.HpglEngraving;

  return (
    <ToolbarIcon
      label={ElementType.HpglEngraving}
      imagePath="/icons/hpgl-engraving.svg"
      onClickHandler={hpglEngravingButtonClickHandler}
      isDisabled={!Document.id}
      isSelected={isSelected}
    />
  );
}

export default HpglEngravingCreator;
