/**
 * The 'DrillHoleCreator' component, manages the action of inserting a 'drill hole'.
 */

// Built-in
import React, { useContext, useEffect } from 'react';

// Internal
import { ElementType } from '../../context/Types';
import { ApiContext } from '../../context/ApiContext'; // Provides API context (Global shared state) to the component

import { ToolbarIcon } from './ToolbarIcon';

function DrillHoleCreator() {
  const { Document, interactionState, setIntractionState } =
    useContext(ApiContext);

  const drillholeButtonClickHandler = () => {
    setIntractionState(ElementType.DrillHole);
  };

  const isSelected = interactionState === ElementType.DrillHole;

  return (
    <ToolbarIcon
      label={ElementType.DrillHole}
      imagePath="/icons/drill-hole.svg"
      onClickHandler={drillholeButtonClickHandler}
      isDisabled={!Document.id}
      isSelected={isSelected}
    />
  );
}

export default DrillHoleCreator;
