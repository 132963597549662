import '../../../../../../theme/components/d3styles/hamburgerMenu.css';
import * as d3 from 'd3';
export const hamburgerMenu = (y, x) => {
  const hamButton = d3.create('button');

  hamButton
    .attr('class', 'hamburgerIcon')
    .attr('type', 'button')
    .attr('name', 'ham')
    .attr('value', ' ')
    .style('top', `${y - 15}px`)
    .style('position', 'fixed')
    .style('left', `${x}px`);
  const svg = hamButton
    .append('svg')
    .attr('width', '15px')
    .attr('height', '15px')
    .attr('viewBox', '0 0 3.216805 3.0676647')
    .attr('version', '1.1');

  const svgDefs = svg.append('defs');

  const gradient = svgDefs
    .append('linearGradient')
    .attr('id', 'hoverGradient')
    .attr('x1', 0)
    .attr('x2', 0)
    .attr('y1', 0)
    .attr('y2', 1);

  gradient.append('stop').attr('offset', 0).attr('class', 'stop1');

  gradient.append('stop').attr('offset', 100).attr('class', 'stop2');

  const g = svg
    .append('g')
    .attr('transform', 'translate(-138.89948,-14.547762)');

  const hamburgerLine = g
    .append('g')
    .attr('transform', 'matrix(0.79716271,0,0,0.79716271,98.860504,-22.35927)');

  hamburgerLine
    .append('rect')
    .attr('width', '3.8275819')
    .attr('height', '3.6404932')
    .attr('x', '50.330723')
    .attr('y', '46.401859')
    .attr('class', 'hamburger-border');

  hamburgerLine.append('path').attr('d', 'm 51.098592,47.333433 h 2.280144');

  hamburgerLine.append('path').attr('d', 'm 51.098592,48.187025 h 2.256757');

  hamburgerLine.append('path').attr('d', 'm 51.075207,49.028925 h 2.291835');

  hamburgerLine.selectAll('path').attr('class', 'hamburger-lines');

  return hamButton.node();
};
