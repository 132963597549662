// import '../../../../../../theme/components/d3styles/hamburgerMenu.css';
import * as d3 from 'd3';
import { hamburgerMenu } from './hamburgerMenu';

import { duplicationStackData } from '../../../../interactions/selection/duplicationStackData';
import i18n from '../../../../../../i18n/i18n';
import { hamburgerCoordinate } from './hamburgerCoordinate';
const hamburgerDrawer = (
  box,
  frontPanelGp,
  groupingActionGenerator,
  groupOrUngroupSelectionProvider,
  selectedElementIds,
  duplicateSelection,
  deleteSelection,
  setRotationModalOpen,
  setMoveModalOpen,
  setMirrorModalOpen,
  setResizeModalOpen,
  setAlignDistributeModalOpen
) => {
  const hamClickHandler = (event) => {
    const isGroupDialogOpened = d3.select('#hamburgerList').node();
    const isResizeAble = d3.select('.vertexHandle').node();
    if (isGroupDialogOpened) return;

    const contextMenu = [
      'move',
      'resize',
      'rotate',
      'mirror',
      'duplicate',
      'delete',
      'align/distribute',
    ];

    // It Will Create an array of Actions Object
    // Then we have  {move: 'Bewegen'},...
    const menuItemCreator = contextMenu.reduce(
      (acc, action) => [
        ...acc,
        { [action]: i18n.t(`Panel:ContextMenu.${action}`) },
      ],
      []
    );

    const body = d3.select('body').node();
    const { clientX, clientY } = event;
    body.appendChild(contextBox(clientY, clientX, 'hamburgDialogBox'));
    const groupDialogBody = d3
      .select('#hamburgDialogBox')
      .append('ul')
      .attr('id', 'hamburgerList')
      .attr('class', 'ham-list');
    menuItemCreator
      .filter((action) => {
        const [[key, value]] = Object.entries(action);
        return !(!isResizeAble && key === 'resize');
      })
      .map((action) => {
        const [[key, value]] = Object.entries(action);
        return groupListItem(
          groupDialogBody,
          key,
          value,
          groupOrUngroupSelectionProvider,
          frontPanelGp,
          selectedElementIds,
          duplicateSelection,
          deleteSelection,
          setRotationModalOpen,
          setMoveModalOpen,
          setMirrorModalOpen,
          setResizeModalOpen,
          setAlignDistributeModalOpen
        );
      });

    groupingActionGenerator.map((tag) =>
      groupListItem(
        groupDialogBody,
        tag,
        tag,
        groupOrUngroupSelectionProvider,
        selectedElementIds,
        frontPanelGp,
        duplicateSelection,
        deleteSelection,
        setRotationModalOpen,
        setMoveModalOpen,
        setMirrorModalOpen,
        setResizeModalOpen,
        setAlignDistributeModalOpen
      )
    );
  };

  const { x, y } = hamburgerCoordinate(frontPanelGp);
  d3.select('#svg-container').node().appendChild(hamburgerMenu(y, x));

  d3.select('.hamburgerIcon').on('click', hamClickHandler);
};

export default hamburgerDrawer;

const groupListItem = (
  groupDialog,
  name,
  value,
  groupOrUngroupSelectionProvider,
  frontPanelGp,
  selectedElementIds,
  duplicateSelection,
  deleteSelection,
  setRotationModalOpen,
  setMoveModalOpen,
  setMirrorModalOpen,
  setResizeModalOpen,
  setAlignDistributeModalOpen
) => {
  const groupActions = {
    [i18n.t(`Panel:ContextMenu.Grouping`)]: { group: true },
    [i18n.t(`Panel:ContextMenu.Ungroup`)]: { ungroup: 'flat' },
    [i18n.t(`Panel:ContextMenu.UngroupAll`)]: { ungroup: 'recursive' },
  };

  groupDialog
    .append('li')
    .append('input')
    .attr('type', 'button')
    .attr('class', 'ham-list-item')
    .attr('name', name)
    .attr('value', value)
    .on('click', function () {
      const groupingKeys = ['Grouping', 'Ungroup', 'UngroupAll'];
      const groupValues = groupingKeys.map((key) =>
        i18n.t(`Panel:ContextMenu.${key}`)
      );

      if (groupValues.includes(this.name)) {
        groupOrUngroupSelectionProvider(groupActions[this.name]);
        d3.selectAll('.hamburgerIcon,.ham-context').remove();
      }
      if (this.name === 'duplicate') {
        duplicateSelection({
          dupStackData: duplicationStackData(frontPanelGp, selectedElementIds),
          isPanel: false,
        });
        d3.selectAll('.hamburgerIcon,.ham-context').remove();
      }
      if (this.name === 'delete') {
        deleteSelection(selectedElementIds);
      }
      if (this.name === 'resize') {
        setResizeModalOpen(true);
        d3.selectAll('.ham-context').remove();
      }
      if (this.name === 'rotate') {
        setRotationModalOpen(true);
        d3.selectAll('.ham-context').remove();
      }
      if (this.name === 'move') {
        setMoveModalOpen(true);
        d3.selectAll('.ham-context').remove();
      }
      if (this.name === 'mirror') {
        setMirrorModalOpen(true);
        d3.selectAll('.ham-context').remove();
      }
      if (this.name === 'align/distribute') {
        setAlignDistributeModalOpen(true);
        d3.selectAll('.ham-context').remove();
      }
    });
};

const contextBox = (top, right, id) => {
  const hamContext = d3.create('div');
  hamContext
    .attr('class', 'ham-context')
    .attr('id', id)
    .style('top', `${top}px`)
    .style('left', `${right}px`);

  return hamContext.node();
};

export const hamburgerIconRemover = () => {
  const hamb = document.querySelector('.hamburgerIcon');
  const vertecies = d3.selectAll('.vertexHandle').nodes();
  if (hamb) hamb.remove();
  if (vertecies.length > 1) {
    vertecies.map((node) => node.remove());
  }
};
