import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  Text,
  VStack,
  useToast,
  Flex,
  IconButton,
  Image,
} from '@chakra-ui/react';
import ActionButton from './modal/dialog/elements/ActionButton';
import { FPD_LIMITS } from '../../api/Client';
import { useTranslation } from 'react-i18next';

const FileUploader = ({ accept, selectFileHandleChange, svgData }) => {
  const [isDragActive, setIsDragActive] = useState(false);
  const svgRef = useRef(null);
  const toast = useToast();
  const { t } = useTranslation('Upload');

  useEffect(() => {
    if (!svgData) return;
    svgRef.current.innerHTML = svgData;
    const svgPreview = document.querySelector('#svg-preview-container>svg');
    svgPreview.setAttribute('width', '100%');
    svgPreview.setAttribute('height', '100%');
  }, [svgData]);
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length < 1) {
      setIsDragActive(false);
      toast.closeAll();
      toast({
        title: 'Wrong File!',
        description: `Please upload proper Front Panel File (.fpd)`,
        status: 'warning',
        duration: null,
        isClosable: true,
      });
      return;
    }
    if (acceptedFiles[0].size > FPD_LIMITS) {
      toast.closeAll();
      toast({
        title: 'File Is Too Big!',
        description: `File size should not exceed ${(
          FPD_LIMITS /
          (1024 * 1024)
        ).toFixed(2)} MB.`,
        status: 'error',
        duration: null,
        isClosable: true,
      });
      return;
    }
    selectFileHandleChange(acceptedFiles[0]);

    setIsDragActive(false);
  }, []);

  const handleDragEnter = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragActive(true);
  }, []);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragActive(true);
  }, []);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragActive(false);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDragEnter: handleDragEnter,
    onDragOver: handleDragOver,
    onDragLeave: handleDragLeave,
    accept: accept,
  });

  return (
    <>
      <Box
        {...getRootProps()}
        border="2px dashed #ccc"
        p="2rem"
        borderRadius="md"
        textAlign="center"
        bg={isDragActive ? 'fpd.500' : 'fpd.700'}
        transition="background-color 0.3s ease"
      >
        <input {...getInputProps()} />
        <VStack spacing={4}>
          {!!svgData ? (
            <Flex
              id={'svg-preview-container'}
              justify={'center'}
              ref={svgRef}
              w="10rem"
              h="10rem"
            />
          ) : (
            <Box
              w="10rem"
              h="10rem"
              borderRadius="full"
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginBottom="1rem"
            >
              <IconButton
                variant="unstyled"
                icon={<Image src={'/icons/upload_panel.svg'} />}
                w={'100%'}
                h={'100%'}
                border="none"
                sx={{
                  _focus: {
                    border: 'none',
                  },
                }}
              />
            </Box>
          )}

          <Text fontSize="lg">
            {isDragActive
              ? t('DropFileMsg', { fileType: 'fpd' })
              : t('DragFileMsg', { fileType: 'fpd' })}
          </Text>
          <Text fontSize="sm" color="fpd.500">
            {t('PrivateMsg')}{' '}
          </Text>
          <ActionButton label={t('SelectFiles')} />
        </VStack>
      </Box>
    </>
  );
};

export default FileUploader;
