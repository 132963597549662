import { elementSelector } from '../../../interactions/selection/elementSelector';
import { bcrToBbox } from '../../utils/bcrToBbox';
import { bboxDrawer } from './bboxDrawer';
/**
 * This function calculates the overall bounding box
 * of a single or multiple selected objects
 * and draws a box around inner elements if they exist.
 */
export function bboxOfSelection({
  frontPanelGp,
  selectedElementIds,
  isDragged = false,
} = {}) {
  const bbox = {};
  const selectedIdsNodes = elementSelector(frontPanelGp, selectedElementIds);
  const elementBboxs = selectedIdsNodes
    .filter((element) => element.children.length)
    .map((element) => {
      if (!isDragged && selectedElementIds.length > 1) {
        bboxDrawer({
          bbox: bcrToBbox(element),
          frontPanelGp: frontPanelGp,
          innerRect: true,
          elementId: element.getAttribute('fpd.id'),
        });
      }

      return bcrToBbox(element);
    });
  bbox.x = Math.min(...elementBboxs.map(({ x }) => x));
  bbox.y = Math.min(...elementBboxs.map(({ y }) => y));
  bbox.width = Math.max(
    ...elementBboxs.map(({ x, width }) => x - bbox.x + width)
  );
  bbox.height = Math.max(
    ...elementBboxs.map(({ y, height }) => y - bbox.y + height)
  );
  return bbox;
}
