import { defineStyleConfig } from '@chakra-ui/react'

export const TextAreaStyles = defineStyleConfig({
  baseStyle: {},
  sizes: {},
  variants: {
    fpd: {
      fontSize: 'xs',
      fontWeight: 200,
      color: 'fpd.50',
      bg: 'fpd.700',
      border: '1px solid',
      borderRadius: 'none',
      borderColor: 'fpd.800',
    },
  },
  defaultProps: { variant: 'fpd' },
});
