import { useCallback, useEffect, useContext, useRef } from 'react';
import * as d3 from 'd3';

import { createZoomHandler } from './ZoomHandler';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { ApiContext } from '../../../context/ApiContext';
import { panelClickHandler } from './frontPanelObjects/panel/panelClickHandler';
import { elementDragHandler } from './frontPanelObjects/element/elementDragHandler';
import { elementReleaseHandler } from './frontPanelObjects/element/elementReleaseHandler';
import { elementClickHandler } from './frontPanelObjects/element/elementSelectHandler';
import { canvasClickHandler } from './frontPanelObjects/svg/canvasClickHandler';
import { CanvasDragHandler } from './frontPanelObjects/svg/canvasDragHandler';
import { SelectModeType, keyCode } from '../../../context/Types';
import { onLoadSelection } from './selection/onLoadSelection';
import { ElementToBeSelected } from '../../../context/PropertyListData';
import { ModalContext } from '../../formHeader/modal/ModalContext';
import { duplicationStackData } from './selection/duplicationStackData';
import { updateControlPositionsOnZoomOrResize } from './updateControlPositionsOnZoomOrResize';

const SVGInteraction = ({
  FPSvg,
  frontPanelGp,
  transformationUpdater,
  FPContainerRef,
}) => {
  const {
    MeshData,
    MeshState,
    interactionState,
    setIntractionState,
    addNewElementToDoc,
    patchMesh,
    getSelectionsForGivenElements,
    isLoading,
    groupOrUngroupSelectionProvider,
    engravingMenuRef,
    duplicateSelection,
    deleteSelection,
    Document,
    DocumentVersion,
    updateDocument,
  } = useContext(ApiContext);

  const {
    setTextEngModalOpen,

    setTextEngModalData,

    setRotationModalOpen,

    setMoveModalOpen,

    setMirrorModalOpen,

    setResizeModalOpen,

    setAlignDistributeModalOpen,

    setPrintGraphicModalState,
  } = useContext(ModalContext);

  const [width, height] = useWindowSize();

  // Set up the initial state of the front panel svg states.
  let fpSvgStates = {
    selectedElementIds: [],
    isSelectionChanged: false,
    isDragged: false,
    prevDragPosition: { x: 0, y: 0 },
    elementCreationPoints: {
      startPointX: 0,
      startPointY: 0,
      endPointX: 0,
      endPointY: 0,
    },
    duplicationStack: [],
  };

  // Create a ref to hold the current interaction state and loading state.
  const currentInteractionState = useRef(interactionState);
  const loadingState = useRef(isLoading);

  // Define a callback function to create a D3 drag behavior object.
  const DragHandler = useCallback(
    (startEvent, dragEvent, releaseEvent) =>
      d3
        .drag()
        .filter((event) => !event.button)
        .on('start', startEvent)
        .on('drag', dragEvent)
        .on('end', releaseEvent),
    []
  );

  // Define a function to update the front panel svg states.
  const updateFpSvgStates = (newStates) => {
    fpSvgStates = { ...fpSvgStates, ...newStates };

    return fpSvgStates;
  };

  // Update the dimensions of the front panel SVG graphic based
  // on the window size using the `useEffect` hook.
  // Get the clientWidth and clientHeight of the parent element of the SVG.
  const updateSVGDimensions = () => {
    const { clientWidth, clientHeight } = FPSvg.current.node().parentElement;
    FPSvg.current.attr('width', clientWidth).attr('height', clientHeight);
  };

  // Update the current interaction state.
  const updateInteractionState = () => {
    if (!interactionState) return;

    currentInteractionState.current = interactionState;
    d3.selectAll('.ham-context').remove();
  };

  // Update the loading state.
  const updateLoadingState = () => {
    loadingState.current = isLoading;
  };

  useEffect(updateSVGDimensions, [FPSvg.current, width, height]);
  useEffect(updateInteractionState, [interactionState]);
  useEffect(updateLoadingState, [isLoading]);
  useEffect(() => {
    if (!width || !height) return;
    updateControlPositionsOnZoomOrResize(frontPanelGp.current);
  }, [width, height]);
  useEffect(() => {
    if (!FPSvg || !FPSvg.current) return;
    // Add event listeners  to theSVG to handle user interactions, such as dragging or scaling objects
    FPSvg.current.call(
      DragHandler(
        function (event) {
          engravingMenuRef.current?.openMenu();
          return canvasClickHandler(
            event,
            currentInteractionState.current,
            updateFpSvgStates,
            frontPanelGp.current,
            getSelectionsForGivenElements,
            loadingState.current
          );
        },

        function (event) {
          return CanvasDragHandler(
            event,
            currentInteractionState.current,
            fpSvgStates,
            updateFpSvgStates,
            frontPanelGp.current,
            loadingState.current
          );
        },
        function (event) {
          return elementReleaseHandler(
            event,
            fpSvgStates,
            updateFpSvgStates,
            frontPanelGp.current,
            getSelectionsForGivenElements,
            patchMesh,
            loadingState.current,
            addNewElementToDoc,
            currentInteractionState.current,
            groupOrUngroupSelectionProvider,
            setTextEngModalOpen,
            setTextEngModalData,
            duplicateSelection,
            deleteSelection,
            setRotationModalOpen,
            setMoveModalOpen,
            setMirrorModalOpen,
            setResizeModalOpen,
            setAlignDistributeModalOpen,
            setPrintGraphicModalState
          );
        }
      )
    );
    frontPanelGp.current
      .selectAll('g[fpd\\.panel]')
      .attr('class', 'panel')
      .call(
        DragHandler(
          function (event) {
            engravingMenuRef.current?.openMenu();
            return panelClickHandler(
              event,
              currentInteractionState.current,
              updateFpSvgStates,
              frontPanelGp.current,
              getSelectionsForGivenElements,
              loadingState.current
            );
          },
          function (event) {
            const self = this;
            return elementDragHandler(
              event,
              currentInteractionState.current,
              fpSvgStates,
              updateFpSvgStates,
              frontPanelGp.current,
              loadingState.current,
              self
            );
          },
          function (event) {
            return elementReleaseHandler(
              event,
              fpSvgStates,
              updateFpSvgStates,
              frontPanelGp.current,
              getSelectionsForGivenElements,
              patchMesh,
              loadingState.current,
              addNewElementToDoc,
              currentInteractionState.current,
              groupOrUngroupSelectionProvider,
              setTextEngModalOpen,
              setTextEngModalData,
              duplicateSelection,
              deleteSelection,
              setRotationModalOpen,
              setMoveModalOpen,
              setMirrorModalOpen,
              setResizeModalOpen,
              setAlignDistributeModalOpen,
              setPrintGraphicModalState
            );
          }
        )
      );

    frontPanelGp.current.selectAll('g:not([fpd\\.panel])').call(
      DragHandler(
        function (event) {
          const self = this;
          engravingMenuRef.current?.openMenu();
          return elementClickHandler(
            event,
            currentInteractionState.current,
            fpSvgStates,
            updateFpSvgStates,
            frontPanelGp.current,
            getSelectionsForGivenElements,
            patchMesh,
            loadingState.current,
            self,
            groupOrUngroupSelectionProvider,
            duplicateSelection,
            deleteSelection,
            setRotationModalOpen,
            setMoveModalOpen,
            setMirrorModalOpen,
            setResizeModalOpen,
            setAlignDistributeModalOpen
          );
        },
        function (event) {
          const self = this;
          return elementDragHandler(
            event,
            currentInteractionState.current,
            fpSvgStates,
            updateFpSvgStates,
            frontPanelGp.current,
            loadingState.current,
            self
          );
        },
        function (event) {
          return elementReleaseHandler(
            event,
            fpSvgStates,
            updateFpSvgStates,
            frontPanelGp.current,
            getSelectionsForGivenElements,
            patchMesh,
            loadingState.current,
            addNewElementToDoc,
            currentInteractionState.current,
            groupOrUngroupSelectionProvider,
            setTextEngModalOpen,
            setTextEngModalData,
            duplicateSelection,
            deleteSelection,
            setRotationModalOpen,
            setMoveModalOpen,
            setMirrorModalOpen,
            setResizeModalOpen,
            setAlignDistributeModalOpen,
            setPrintGraphicModalState
          );
        }
      )
    );
    const zoom = createZoomHandler(
      FPSvg.current,
      frontPanelGp.current,
      transformationUpdater
    );
    FPSvg.current.call(zoom);

    onLoadSelection(
      MeshState,
      updateFpSvgStates,
      getSelectionsForGivenElements,
      frontPanelGp.current,
      ElementToBeSelected,
      patchMesh,
      groupOrUngroupSelectionProvider,
      duplicateSelection,
      deleteSelection,
      setRotationModalOpen,
      setMoveModalOpen,
      setMirrorModalOpen,
      setResizeModalOpen,
      setAlignDistributeModalOpen
    ); //initial element selection which is either main panel or an element with updated properties

    const handleKeyDown = (event) => {
      const isPanelSelected = !frontPanelGp.current
        .select('.BboxRectangle')
        .node();

      const keysStrategies = {
        noModifier: {
          [keyCode.SPACE]: (event) => {
            event.preventDefault();
            setIntractionState(SelectModeType.SelectMode);
          },
          [keyCode.DELETE]: () => {
            if (!isPanelSelected)
              deleteSelection(fpSvgStates.selectedElementIds);
          },
        },
        ctrl: {
          [keyCode.COPY]: () => {
            fpSvgStates.duplicationStack = [...fpSvgStates.selectedElementIds];
            fpSvgStates.isPanelSelected = !d3.select('.BboxRectangle').node();
          },
          [keyCode.PASTE]: () => {
            if (!fpSvgStates.duplicationStack.length) return;

            const duplicationData = fpSvgStates.isPanelSelected
              ? { isPanel: true }
              : {
                  dupStackData: duplicationStackData(
                    frontPanelGp.current,
                    fpSvgStates.duplicationStack
                  ),
                };

            duplicateSelection(duplicationData);
          },
          [keyCode.UNDO]: () => {
            if (isLoading || !DocumentVersion.previous_version) return;
            updateDocument({
              ...Document,
              id: DocumentVersion.document_id,
              version: DocumentVersion.previous_version,
            });
          },
          [keyCode.REDO]: () => {
            if (isLoading || !DocumentVersion.next_version) return;
            updateDocument({
              ...Document,
              id: DocumentVersion.document_id,
              version: DocumentVersion.next_version,
            });
          },
        },

        default: (event) => {},
      };

      const strategy = event.ctrlKey
        ? keysStrategies.ctrl[event.keyCode] || keysStrategies['default']
        : keysStrategies.noModifier[event.keyCode] || keysStrategies['default'];

      strategy(event);
    };

    FPContainerRef.current.addEventListener('keydown', handleKeyDown);

    return () => {
      FPContainerRef.current.removeEventListener('keydown', handleKeyDown);
    };
  }, [FPSvg, frontPanelGp, MeshData, FPContainerRef]);

  return null;
};

export default SVGInteraction;
