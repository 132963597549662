import { createMultiStyleConfigHelpers } from "@chakra-ui/system";

const myAnatomy = ['groupContainer', 'groupContainerTitle'];
const helpers = createMultiStyleConfigHelpers(myAnatomy);

export const GroupContainerStyles = helpers.defineMultiStyleConfig({
  parts: myAnatomy,
  baseStyle: {
    groupContainer: {
      border: '1px solid',
      borderColor: 'fpd.900',
      position: 'relative',
      mt: 3,
      mb: 1,
      p: 4,
    },
    groupContainerTitle: {
      fontSize: 'xs',
      position: 'absolute',
      top: '0',
      transform: 'translateY(-50%)',
      bg: 'fpd.600',
      color: 'fpd.50',
      px: 2,
    },
  },
  sizes: {},
  variants: {
    subGroup: {
      groupContainer: {
        border: '1px solid',
        borderColor: 'fpd.900',
        boxShadow: 'inset 0 1px 0 0 var(--chakra-colors-fpd-400)',
        borderLeft: 'none',
        borderRight: 'none',
        borderBottom: 'none',
        mt: 2,
        mb: 0,
        px: 0,
        pb: 0,
      },
      groupContainerTitle: { fontWeight: 'bold' },
    },
    sidebar: {
      groupContainer: {
        border: '1px solid',
        borderColor: 'fpd.900',
        w: '265px',
        py: '20px',
        px: '0',
        position: 'relative',
        mt: 4,
      },
      groupContainerTitle: {
        fontSize: 'xs',
        position: 'absolute',
        top: '0',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        bg: 'fpd.600',
        color: 'fpd.50',
        px: 2,
      },
    },
  },
  defaultProps: {},
});
