const API_BASE_URL = process.env.REACT_APP_FPSERVER_URL; //gets the API url from the environment variable
const FPD_SHOP_URL = process.env.REACT_APP_SHOP_URL; //gets the web-shop url from the environment variable

/**
 *We're utilizing the fpserver storage endpoint to retrieve a svg preview of the panel. in the open file dialog.
 */
const FPD_THUMB_URL = `${API_BASE_URL}/media/fpdsvgs/`;
if (process.env.NODE_ENV === 'development')
    console.log(`The Application is running in development Mode!`);

const FPD_LIMITS = process.env.REACT_APP_LIMITS || 20971520;

export { API_BASE_URL, FPD_THUMB_URL, FPD_LIMITS, FPD_SHOP_URL };
