import React, { useContext, useRef, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
} from '@chakra-ui/react';
import { ApiContext } from '../../../../context/ApiContext';
import { insertNewElement } from '../../../graphic/interactions/frontPanelObjects/element/insertNewElement';
import { ModalContext } from '../ModalContext';
import { ElementType, PlacementType } from '../../../../context/Types';
import ActionButton from './elements/ActionButton';
import { useTranslation } from 'react-i18next';

const TextEngravingDialog = () => {
  const { addNewElementToDoc } = useContext(ApiContext);
  const { isTextEngModalOpen, setTextEngModalOpen, textEngModalData } =
    useContext(ModalContext);
  const [textValue, setTextValue] = useState('');
  const inputRef = useRef();
  const { t } = useTranslation(['TextEngraving', 'ActionButton']);
  const handleClose = () => {
    setTextEngModalOpen(false);
    setTextValue(''); // Reset the state
  };

  const handleSubmit = () => {
    if (!textValue) return;
    // Check that textValue is not empty
    insertNewElement({
      placementName: PlacementType.TRANSFORMATION,
      value: textEngModalData.value,
      addNewElementToDoc,
      interactionState: ElementType.EngravingText,
      text: textValue,
    });
    handleClose();
  };

  return (
    isTextEngModalOpen && (
      <Modal
        initialFocusRef={inputRef}
        isOpen={isTextEngModalOpen}
        onClose={handleClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('TextEngraving:Alert')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              variant="fpd"
              ref={inputRef}
              placeholder={t('TextEngraving:PlaceHolder')}
              value={textValue}
              onChange={(e) => setTextValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleSubmit();
              }}
            />
          </ModalBody>
          <ModalFooter>
            <ActionButton
              onClick={handleSubmit}
              isDisabled={!textValue}
              label={t('ActionButton:Ok')}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  );
};

export default TextEngravingDialog;
