import { defineStyleConfig } from "@chakra-ui/system";

export const FormLabelStyles = defineStyleConfig({
  baseStyle: {
    color: 'fpd.50',
    fontSize: 'sx',
    mb: '2px',
  },
  sizes: {},
  variants: {},
  defaultProps: {},
});