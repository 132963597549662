/**
 * The 'CavityCreator' component, manages the action of inserting a 'cavity'.
 */

// Built-in
import React, { useContext } from 'react';

// Internal
import { ElementType } from '../../context/Types';
import { ApiContext } from '../../context/ApiContext'; // Provides API context (Global shared state) to the component

import { ToolbarIcon } from './ToolbarIcon';

function CavityCreator() {
  const { Document, interactionState, setIntractionState } =
    useContext(ApiContext);

  const cavityButtonClickHandler = () => {
    setIntractionState(ElementType.Cavity);
  };

  const isSelected = interactionState === ElementType.Cavity;

  return (
    <ToolbarIcon
      label={ElementType.Cavity}
      imagePath="/icons/insert-cavity.svg"
      onClickHandler={cavityButtonClickHandler}
      isDisabled={!Document.id}
      isSelected={isSelected}
    />
  );
}

export default CavityCreator;
