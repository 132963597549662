/**
 *  Returns an array of front panel element
 *  nodes that match the given selected element IDs.
 */

export const elementSelector = (frontPanelGp, selectedElementIds) => {
  // Convert the front panel group children NodeList to an array, and filter
  // out non-element nodes and elements that don't match any of the selected IDs.

  return Array.from(frontPanelGp.node().children).filter(
    (node) =>
      (!node.hasAttribute('fpd.panel') &&
        selectedElementIds.some((id) => +node.getAttribute('fpd.id') === id)) ||
      selectedElementIds.some((id) => +node.getAttribute('fpd.group') === id)
  );
};
