import {
  Box,
  VStack,
  Text,
  createStylesContext,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import FileUploader from './FileUploader';

const [StylesProvider, useStyles] = createStylesContext('FileUploadContainer');

const FileUploadContainer = ({
  accept,
  file,
  fileNameLabel,
  fileSizeLabel,
  fileHandler,
  svgData,
  ...props
}) => {
  const styles = useMultiStyleConfig('FileUploadContainer', {});
  return (
    <Box sx={styles.container} {...props}>
      <StylesProvider value={styles}>
        <FileUploader
          accept={accept}
          selectFileHandleChange={fileHandler}
          svgData={svgData}
        />
        {file?.fileContent && (
          <VStack align="stretch" my="12px">
            <Text sx={styles.fileName}>
              {fileNameLabel} {file.fileName}
            </Text>
            <Text sx={styles.fileName}>
              {fileSizeLabel} {file.fileSize}
            </Text>
          </VStack>
        )}
      </StylesProvider>
    </Box>
  );
};
export function formatFileSize(bytes) {
  if (bytes < 0) {
    return 'Invalid size';
  }

  const KB = 1024;
  const MB = KB * 1024;
  const sizeUnits = ['Bytes', 'KB', 'MB'];

  let size = bytes;
  let unitIndex = 0;

  if (bytes >= MB) {
    size = bytes / MB;
    unitIndex = 2;
  } else if (bytes >= KB) {
    size = bytes / KB;
    unitIndex = 1;
  }

  return `${size.toFixed(2)} ${sizeUnits[unitIndex]}`;
}

export default FileUploadContainer;
