/**
 * The 'NewDoc' component, manages the action of creation the new front-panel Document.
 */

// Built-in
import React, { useContext } from 'react';
// External
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  InputGroup,
  Stack,
  InputLeftAddon,
  Textarea,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
// Internal
import { ToolbarIcon } from './ToolbarIcon';
import { ApiContext } from '../../context/ApiContext'; // Provides API context (Global shared state) to the component
import GroupContainer from './modal/dialog/GroupContainer';
import ActionButton from './modal/dialog/elements/ActionButton';

function NewDocumentCreator() {
  const { createNewDocument } = useContext(ApiContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation(['NewDoc', 'ActionButton']);

  const newDocumentClickHandler = () => {
    onOpen();
  };

  const modalCloseHandler = () => {
    onClose();
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required('File name is required')
      .test(
        'not-only-spaces',
        'File name cannot be empty or just spaces',
        (value) => value?.trim().length > 0
      ),
    description: Yup.string(),
  });

  // Initial values
  const initialValues = {
    name: '',
    description: '',
  };

  const onSubmit = (values) => {
    createNewDocument(values);
    modalCloseHandler();
  };

  return (
    <>
      <ToolbarIcon
        label="NewDoc"
        imagePath="/icons/new_doc.svg"
        onClickHandler={newDocumentClickHandler}
      />
      {isOpen && (
        <Modal isOpen={isOpen} onClose={modalCloseHandler} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t('NewDoc:Header')}</ModalHeader>
            <ModalCloseButton />
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isValid, dirty, errors, touched, setFieldValue }) => (
                <Form>
                  <ModalBody>
                    <GroupContainer title={t('NewDoc:DocProps')}>
                      <Stack spacing={3}>
                        <InputGroup id="name">
                          <InputLeftAddon children={t('NewDoc:FileName')} />
                          <Field
                            name="name"
                            as={Input}
                            variant="fpd"
                            placeholder={t('NewDoc:PlaceHolder')}
                            sx={
                              errors.name &&
                              touched.name && { borderColor: 'red' }
                            }
                            autoFocus
                          />
                        </InputGroup>
                        <InputGroup>
                          <InputLeftAddon children={t('NewDoc:Description')} />
                          <Field
                            name="description"
                            as={Textarea}
                            resize="none"
                            onChange={(event) =>
                              setFieldValue('description', event.target.value)
                            }
                          />
                        </InputGroup>
                      </Stack>
                    </GroupContainer>
                  </ModalBody>

                  <ModalFooter>
                    <ActionButton
                      type="submit"
                      label={t('ActionButton:Create')}
                      isDisabled={!(isValid && dirty)}
                    />
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

export default NewDocumentCreator;
