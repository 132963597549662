import React, {
  createContext,
  useMemo,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import axios from 'axios';
import {
  DocumentMsg,
  Document as DocumentInitState,
  MeshData as MeshDataInitState,
  DocumentVersion as DocumentVersionType,
  ElementToBeSelected,
  Selection as SelectionInitState,
} from './PropertyListData';

import Documents from '../api/services/Documents';
import DocumentVersions from '../api/services/DocumentVersions';
import Mesh from '../api/services/Mesh';
import Selections from '../api/services/Selections';
import Elements from '../api/services/Elements';
import { SelectModeType, MeshStateTypes, User } from '../context/Types';

import { useTranslation } from 'react-i18next';

const ApiContext = createContext();

const getLangValueFromCookie = () => {
  const regex = /(?:^|; )lang=([^;]*)/;
  const match = document.cookie.match(regex);
  return match ? decodeURIComponent(match[1]) : 'de_DE';
};
const ApiProvider = (props) => {
  const [docMsgs, setDocMsgs] = useState(DocumentMsg);
  const [Document, setDocument] = useState(DocumentInitState);
  const [MeshState, setMeshState] = useState(MeshStateTypes.initial);
  const [MeshData, setMeshData] = useState(MeshDataInitState.file);
  const [DocumentVersion, setDocumentVersion] = useState(DocumentVersionType);
  const [Selection, setSelection] = useState(SelectionInitState);
  const [isLoading, setIsLoading] = useState(false);
  const [sideHeaderText, setSideHeaderText] = useState('Element');
  const [priceData, setPriceData] = useState('');
  const [loginDialog, setLoginDialog] = useState({
    isOpen: false,
    dialogMode: '',
  });
  const [userFilesStorage, setUserFilesStorage] = useState();
  const [lang, setLang] = useState(getLangValueFromCookie());
  const [isScreenSmall, setIsScreenSmall] = useState(false);

  const engravingMenuRef = useRef();
  const SelectedFrontElementsIds = useRef();

  const [interactionState, setIntractionState] = useState(
    SelectModeType.SelectMode
  );
  const selectionsValue = useRef(Selection);

  const { i18n } = useTranslation();

  // Set withCredentials to true globally
  axios.defaults.withCredentials = true;

  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401) {
        setDocMsgs((prevState) => ({
          ...prevState,
          errorMsg: error.response.data?.error?.description,
        }));
      }
      return Promise.reject(error);
    }
  );

  // Convert codes like 'en_US' to 'en'
  const getShortLangCode = (longLangCode) => {
    const match = longLangCode.match(/^[a-z]{2,3}/i);
    return match ? match[0] : null;
  };

  useEffect(() => {
    if (i18n.language !== lang) {
      i18n.changeLanguage(getShortLangCode(lang));
    }

    const cookieValue = `lang=${lang}; SameSite=Strict; path=/`;
    if (document.cookie.indexOf(cookieValue) === -1) {
      document.cookie =
        process.env.NODE_ENV === 'development'
          ? cookieValue
          : `${cookieValue};domain=.${document.location.host}`;
    }

    const { member_ids } = Selection;
    if (member_ids.length > 0)
      getSelectionsForGivenElements([...member_ids], sideHeaderText);
  }, [lang]);

  const updateStates = useCallback(
    (data, meshState = MeshStateTypes.initial) => {
      setDocument(data);
      setIntractionState(SelectModeType.SelectMode);
      setMeshState(meshState);
    },
    []
  );
  const resetStates = useCallback(() => {
    setDocument(DocumentInitState);
    setDocumentVersion(DocumentVersionType);
    setMeshData(MeshDataInitState.file);
    setIntractionState(SelectModeType.SelectMode);
    setSelection(SelectionInitState);
  }, []);

  const uploadDocument = async (acceptedFiles) => {
    setIsLoading(true);
    setDocMsgs(DocumentMsg);
    try {
      const { fileCustomName, fileContent, fileDesc } = acceptedFiles;
      const { data } = await axios(
        Documents.upload(fileContent, fileCustomName, fileDesc)
      );

      updateStates(data);
      getDocVersions(data.id, data.version);
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 401) return;
      setDocMsgs(() => ({
        errorMsg: 'We cannot process your request!',
      }));
    }
  };
  const uploadPreviewDoc = async (acceptedFiles) => {
    setIsLoading(true);
    setDocMsgs(DocumentMsg);
    try {
      const { fileCustomName, fileContent, fileDesc } = acceptedFiles;
      const { data } = await axios(
        Documents.upload(fileContent, fileCustomName, fileDesc)
      );

      const response = await axios(Mesh.get(data.id, data.version));
      setIsLoading(false);

      return response.data;
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 401) return;
      setDocMsgs(() => ({
        errorMsg: 'We cannot process your request!',
      }));
    }
  };

  /*getDocumentById(documentId))
   *Loads the FPD file which has already been uploaded on the server by means of the corresponding FPD ID number.
   *- Triggers whenever a user clicks on the Open by Id button.
   *- Sets the FPD file`s states by invoking 'updateFpdStateOnGetDoc'.
   *- Provides well-needed parameters for the upcoming request which is getting the Mesh data from API.
   */
  const getDocumentById = async (id) => {
    setIsLoading(true);
    try {
      setDocMsgs(DocumentMsg);
      const { data } = await axios(Documents.get(id));
      getDocVersions(data.id, data.version);
      updateStates(data);
    } catch (error) {
      setIsLoading(false);
      setDocMsgs((prevState) => ({
        ...prevState,
        errorMsg: 'The FPD file not Found',
      }));
    }
  };

  /**
   *Deletes the currently opened FPD file.
   *-Triggered by clicking the Delete button.
   *- Removes the FPD file from the server database and cleans the work stage.
   */
  const deleteDocument = async (id) => {
    try {
      await axios(Documents.delete(id));
      resetStates();
    } catch (error) {
      setDocMsgs((prevState) => ({
        ...prevState,
        errorMsg: 'We cannot process your request!',
      }));
    }
  };

  /**
   *'updateDocument' update the master version of document
   *- Triggers whenever a user clicks on the undo and redo buttons.
   *- Sets the 'updateFpdStateOnGetDoc' parameters.
   *- Provides well-needed parameters for the upcoming request which is getting the Mesh data from API.
   */
  const updateDocument = async (updatedDoc) => {
    setIsLoading(true);

    try {
      const { data } = await axios(Documents.update(updatedDoc));
      getDocVersions(data.id, data.version);
      updateStates(data, MeshStateTypes.modified);
    } catch (error) {
      setIsLoading(false);
      setDocMsgs((prevState) => ({
        ...prevState,
        errorMsg: 'We cannot process your request!',
      }));
    }
  };

  /**
   *Gets Mesh Data from the server API.
   *- Triggered by required parameters from the previous step, which is getDocVersions().
   *- Returns FPD`s related SVG data from the API and sets the corresponding state.
   */

  const getMesh = async (docVersion) => {
    try {
      const { data } = await axios(
        Mesh.get(docVersion.document_id, docVersion.id)
      );

      setMeshData(data);
    } catch (error) {}
  };

  /**
   *Gets the related current document version properties
   *- Triggered when Documents,Elements and Selections updated .
   *- Sets previous and next version states.
   */

  const getDocVersions = useCallback(
    async (doc_id, version_id, isPatchError) => {
      setDocMsgs(DocumentMsg);
      try {
        const { data } = await axios(
          DocumentVersions.getById(doc_id, version_id)
        );
        getMesh(data);
        setDocumentVersion((prevState) => {
          isPatchError
            ? (ElementToBeSelected.element_ids = [
                ...SelectedFrontElementsIds.current,
              ])
            : (ElementToBeSelected.element_ids =
                data['previous_version'] - prevState.previous_version < 0 //This indicates that the undo action has been performed
                  ? [...prevState.changed_ids]
                  : data['changed_ids']);

          return data;
        });
      } catch (error) {
        setDocMsgs((prevState) => ({
          ...prevState,
          errorMsg: 'We cannot process your request!',
        }));
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  /**
   *Sends Selection.get based on selected document version in order to get corresponding element`s properties.
   *- Triggered by an SVG element has been selected.
   *- Update Selections Object valuse
   */

  const getSelectionsForGivenElements = async (member_ids, descText) => {
    setIsLoading(true);
    setDocMsgs(DocumentMsg);
    try {
      const { data } = await axios(
        Selections.get(
          DocumentVersion.document_id,
          DocumentVersion.id,
          member_ids
        )
      );
      setSideHeaderText(descText);
      SelectedFrontElementsIds.current = [...data.member_ids];
      setSelection(data);
    } catch (error) {
      setDocMsgs((prevState) => ({
        ...prevState,
        errorMsg: 'Corresponding element properties not found!',
      }));
    } finally {
      setIsLoading(false);
    }
  };
  /**
   * The selection value should be updated for
   * the 'groupOrUngroupSelectionProvider' function to work properly
   */

  useEffect(() => {
    if (!Selection.member_ids.length) return;
    selectionsValue.current = Selection;
  }, [Selection.member_ids]);
  /**
   * Posts elements' group or ungroup query strings and selections
   * as a payload for changing their group or ungroup states
   */

  const groupOrUngroupSelectionProvider = async (groupVal) => {
    setIsLoading(true);
    try {
      const { data } = await axios(
        Selections.post(
          DocumentVersion.document_id,
          DocumentVersion.id,
          selectionsValue.current,
          groupVal
        )
      );
      getDocVersions(Document.id, data.document_version);
      setMeshState(MeshStateTypes.modified);
    } catch (error) {
      setDocMsgs((prevState) => ({
        ...prevState,
        errorMsg: 'Corresponding element properties not found!',
      }));
    } finally {
      setIsLoading(false);
    }
  };

  /**
   *Removes selected FrontPanel Items.
   *-The 'Del' key,  triggers this event if an element is being selected.
   *-Removes the selected elements.
   */
  const deleteSelection = async (member_ids) => {
    setIsLoading(true);

    try {
      const { data } = await axios(
        Selections.delete(
          DocumentVersion.document_id,
          DocumentVersion.id,
          member_ids
        )
      );
      getDocVersions(DocumentVersion.document_id, data.document_version);
      setMeshState(MeshStateTypes.modified);
    } catch (error) {
      setIsLoading(false);

      setDocMsgs((prevState) => ({
        ...prevState,
        errorMsg: 'We cannot process your request!',
      }));
    }
  };

  /**
   *'addNewElementToDoc' add a new element to the opened front panel mesh data.
   * Triggers whenever a user clicks on an insert element button.
   */

  const addNewElementToDoc = async (elTransformationData, print = false) => {
    setIsLoading(true);
    try {
      const { data } = await axios(
        !print
          ? Elements.add(
              DocumentVersion.document_id,
              DocumentVersion.id,
              elTransformationData
            )
          : DocumentVersions.print(
              DocumentVersion.document_id,
              DocumentVersion.id,
              elTransformationData
            )
      );

      getDocVersions(DocumentVersion.document_id, data.document_version);
      setMeshState(MeshStateTypes.modified);

      if (data.warnings) {
        setDocMsgs((prevState) => ({
          ...prevState,
          warnings: data.warnings,
        }));
      }

      return true;
    } catch (error) {
      setIsLoading(false);

      if (error?.response && error?.response?.status !== 200) {
        setDocMsgs((prevState) => ({
          ...prevState,
          errorMsg: 'We cannot process your request!',
        }));
        console.log(error?.response?.status);
        return false;
      }

      setDocMsgs((prevState) => ({
        ...prevState,
        errorMsg: 'An unexpected error occurred!',
      }));
      return false;
    }
  };

  /**
   *'patchMesh' sends delta of svg data to the API
   * Triggers when the user releases movement or rescaling.
   */
  const patchMesh = async (patchData) => {
    setIsLoading(true);

    try {
      const { data } = await axios(
        Mesh.patch(DocumentVersion.document_id, DocumentVersion.id, patchData)
      );
      getDocVersions(data.document_id, data.id);
      setMeshState(MeshStateTypes.modified);
    } catch (error) {
      setMeshData('');

      getDocVersions(DocumentVersion.document_id, DocumentVersion.id, true);
      setMeshState(MeshStateTypes.modified);
      setIsLoading(false);

      if (error.response.data.error.type === 'fp_error') {
        setDocMsgs((prevState) => ({
          ...prevState,
          errors: error.response.data.error,
        }));
      } else {
        setDocMsgs((prevState) => ({
          ...prevState,
          errorMsg: 'We cannot process your request!',
        }));
      }
    }
  };
  /**
   *'createNewDocument'
   * Triggers when the user click on the 'create new front panel' icon.
   */

  const createNewDocument = async (documentData) => {
    setIsLoading(true);
    try {
      const { data } = await axios(Documents.new(documentData));
      getDocVersions(data.id, data.version);
      updateStates(data);
    } catch (error) {
      setIsLoading(false);

      if (error?.response?.status === 401) return;
      setDocMsgs((prevState) => ({
        ...prevState,
        errorMsg: 'We cannot process your request!',
      }));
    }
  };

  const getPrice = async (id) => {
    try {
      const { data } = await axios(Documents.price(id));
      setPriceData(data);
    } catch (error) {
      setDocMsgs((prevState) => ({
        ...prevState,
        errorMsg: 'We cannot process your request!',
      }));
    }
  };

  /**
   *Download the currently opened FPD file.
   *- Triggered by clicking the 'Download' button.
   */
  const downloadDocument = async (id, name) => {
    try {
      const { data } = await axios(Documents.download(id, DocumentVersion.id));
      //URL.createObjectURL is used to create a URL for the downloaded file
      const href = URL.createObjectURL(data);
      //then creating an a HTML element with that URL as its href.
      // Finally, we're adding the `a` element to the document,
      // triggering a click event on it to initiate the download,
      // and removing the a element and revoking the URL to clean up afterwards.
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `${name}.fpd`);
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      setDocMsgs((prevState) => ({
        ...prevState,
        errorMsg: 'We cannot process your request!',
      }));
    }
  };

  /**
   *Duplicate the selected elements after performing duplication action.
   *- Triggered by holding ctrl + v or calling the 'duplicateSelection'.
   */
  const duplicateSelection = async ({ dupStackData, isPanel = false }) => {
    setIsLoading(true);

    // Determine which endpoint to hit based on fpd-panel selection
    const endpoint = isPanel
      ? Selections.duplicatePanel(
          DocumentVersion.document_id,
          DocumentVersion.id
        )
      : Selections.duplicate(
          DocumentVersion.document_id,
          DocumentVersion.id,
          dupStackData
        );

    try {
      const { data } = await axios(endpoint);
      getDocVersions(Document.id, data.document_version);
      setMeshState(MeshStateTypes.modified);
    } catch (error) {
      console.error('Error duplicating selection:', error);
      setDocMsgs((prevState) => ({
        ...prevState,
        errorMsg:
          'Error occurred while duplicating. Corresponding element properties not found!',
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const rotateSelection = async (rotationData) => {
    setIsLoading(true);
    try {
      const { data } = await axios(
        Selections.rotate(
          DocumentVersion.document_id,
          DocumentVersion.id,
          Selection.member_ids,
          rotationData
        )
      );
      getDocVersions(Document.id, data.document_version);
      setMeshState(MeshStateTypes.modified);
    } catch (error) {
      setDocMsgs((prevState) => ({
        ...prevState,
        errorMsg: 'Corresponding element properties not found!',
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const moveSelection = async (moveData) => {
    setIsLoading(true);
    try {
      const { data } = await axios(
        Selections.move(
          DocumentVersion.document_id,
          DocumentVersion.id,
          Selection.member_ids,
          moveData
        )
      );
      getDocVersions(Document.id, data.document_version);
      setMeshState(MeshStateTypes.modified);
    } catch (error) {
      setDocMsgs((prevState) => ({
        ...prevState,
        errorMsg: 'Corresponding element properties not found!',
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const mirrorSelection = async (mirrorData) => {
    setIsLoading(true);
    try {
      const { data } = await axios(
        Selections.mirror(
          DocumentVersion.document_id,
          DocumentVersion.id,
          Selection.member_ids,
          mirrorData
        )
      );
      getDocVersions(Document.id, data.document_version);
      setMeshState(MeshStateTypes.modified);
    } catch (error) {
      setDocMsgs((prevState) => ({
        ...prevState,
        errorMsg: 'Corresponding element properties not found!',
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const resizeSelection = async (resizeData) => {
    setIsLoading(true);
    try {
      const { data } = await axios(
        Selections.resize(
          DocumentVersion.document_id,
          DocumentVersion.id,
          Selection.member_ids,
          resizeData
        )
      );
      getDocVersions(Document.id, data.document_version);
      setMeshState(MeshStateTypes.modified);
    } catch (error) {
      setDocMsgs((prevState) => ({
        ...prevState,
        errorMsg: 'Corresponding element properties not found!',
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const alignDistributeSelection = async (alignDistributeData) => {
    setIsLoading(true);
    try {
      const { data } = await axios(
        Selections.alignDistribute(
          DocumentVersion.document_id,
          DocumentVersion.id,
          Selection.member_ids,
          alignDistributeData
        )
      );
      getDocVersions(Document.id, data.document_version);
      setMeshState(MeshStateTypes.modified);
    } catch (error) {
      setDocMsgs((prevState) => ({
        ...prevState,
        errorMsg: 'Corresponding element properties not found!',
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const synchronizeDocument = async (id) => {
    setIsLoading(true);
    try {
      const { data } = await axios(Documents.synchronize(id));

      setDocMsgs(() => ({
        title: 'Synchronization',
        info: 'Synchronization successful!',
      }));
    } catch (error) {
      setDocMsgs((prevState) => ({
        ...prevState,
        errorMsg: 'The synchronization failed!',
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const getUserStorage = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await axios(Documents.getAll());
      setUserFilesStorage(data.rootNode);
    } catch (error) {
      if (error.response.status === 401) return;

      setDocMsgs((prevState) => ({
        ...prevState,
        errorMsg: 'We cannot process your request!',
      }));
    } finally {
      setIsLoading(false);
    }
  }, [setDocMsgs]);

  const updateMemberProps = async (
    propsData,
    setSubmitting,
    setStatus,
    resetForm,
    resetValues
  ) => {
    try {
      const { data } = await axios(
        Selections.post(Document.id, DocumentVersion.id, propsData)
      );

      if (data.warnings) {
        setDocMsgs((prevState) => ({
          ...prevState,
          warnings: data.warnings,
        }));
      }
      getDocVersions(Document.id, data.document_version);
      setMeshState(MeshStateTypes.modified);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });

      if (error.response.data.error.type === 'fp_error') {
        setDocMsgs((prevState) => ({
          ...prevState,
          errors: error.response.data.error,
        }));
      } else {
        setDocMsgs((prevState) => ({
          ...prevState,
          errorMsg: error.response.data.error,
        }));
      }
      setSubmitting(false);
      // Reset form to initial values
      resetForm(resetValues);
    }
  };
  /** We use the useMemo hook to memoize the object
   *  and prevent it from being recreated on
   *  every re-render */
  const api = useMemo(
    () => ({
      docMsgs,
      setDocMsgs,
      Document,
      DocumentVersion,
      MeshData,
      MeshState,
      Selection,
      interactionState,
      isLoading,
      setIntractionState,
      resetStates,
      setMeshState,
      uploadDocument,
      getDocumentById,
      deleteDocument,
      updateDocument,
      getMesh,
      getDocVersions,
      getSelectionsForGivenElements,
      addNewElementToDoc,
      patchMesh,
      createNewDocument,
      downloadDocument,
      groupOrUngroupSelectionProvider,
      sideHeaderText,
      engravingMenuRef,
      getPrice,
      priceData,
      duplicateSelection,
      deleteSelection,
      rotateSelection,
      moveSelection,
      mirrorSelection,
      resizeSelection,
      alignDistributeSelection,
      synchronizeDocument,
      getUserStorage,
      userFilesStorage,
      lang,
      setLang,
      setIsLoading,
      updateMemberProps,
      isScreenSmall,
      setIsScreenSmall,
      loginDialog,
      setLoginDialog,
      uploadPreviewDoc,
    }),
    [
      docMsgs,
      setDocMsgs,
      Document,
      DocumentVersion,
      MeshData,
      MeshState,
      Selection,
      interactionState,
      isLoading,
      setIntractionState,
      resetStates,
      setMeshState,
      uploadDocument,
      getDocumentById,
      deleteDocument,
      updateDocument,
      getMesh,
      getDocVersions,
      getSelectionsForGivenElements,
      addNewElementToDoc,
      patchMesh,
      createNewDocument,
      downloadDocument,
      groupOrUngroupSelectionProvider,
      sideHeaderText,
      engravingMenuRef,
      getPrice,
      priceData,
      duplicateSelection,
      deleteSelection,
      rotateSelection,
      moveSelection,
      mirrorSelection,
      resizeSelection,
      alignDistributeSelection,
      synchronizeDocument,
      getUserStorage,
      userFilesStorage,
      lang,
      setLang,
      setIsLoading,
      updateMemberProps,
      isScreenSmall,
      setIsScreenSmall,
      loginDialog,
      setLoginDialog,
      uploadPreviewDoc,
    ]
  );

  return <ApiContext.Provider value={api} {...props} />;
};

export { ApiContext, ApiProvider };
