// -------------------[Mesh]-------------------\
/**
 * Methods related to Mesh Object
 */
import { API_BASE_URL } from '../Client';

const Mesh = {
  /**
   * Get [MeshData] for a [DocumentVersion].
   */
  get: (doc_id, version_id) => ({
    method: 'GET',
    url: `${API_BASE_URL}/docs/${doc_id}/versions/${version_id}/mesh`,
    headers: {
      Accept: 'image/svg+xml',
      'Content-Type': 'image/svg+xml',
    },
  }),
  patch: (doc_id, version_id, data) => ({
    method: 'PATCH',
    url: `${API_BASE_URL}/docs/${doc_id}/versions/${version_id}/mesh`,
    headers: {
      Accept: 'image/svg+xml',
      'Content-Type': 'image/svg+xml',
    },
    data: data,
  }),
};
export default Mesh;
