import React, { useContext } from 'react';
import { ApiContext } from '../../context/ApiContext';
import en_US from '../../data/properties/en_US.json';
import de_DE from '../../data/properties/de_DE.json';
import { renderComponent } from './renderComponent';

const MembersElementsRenderer = ({
  memberPropsObj,
  propsTopCategory,
  focusHandler,
  isGrouped = false,
}) => {
  const { lang } = useContext(ApiContext);
  const properties = { en_US, de_DE };

  return Object.entries(memberPropsObj).map((memberProperty, index) => {
    const [name, ...[res]] = memberProperty;
    let propName = name;
    if (!!name.match(/Pen[0-9]+\/Tool/)) {
      propName = 'Pen[0-9]+/Tool';
    }
    if (!!name.match(/Pen[0-9]+\/Color/)) {
      propName = 'Pen[0-9]+/Color';
    }
    if (!properties[lang][propName]) return;
    const { label, type } = properties[lang][propName];

    const prefixedName =
      propsTopCategory === 'Group' || propsTopCategory === 'Elements'
        ? `${propsTopCategory}/${name}`
        : name;

    const variant = isGrouped ? 'grouped' : 'single';
    
    return (
      <React.Fragment key={index}>
        {renderComponent(type, {
          isGrouped,
          label,
          name: prefixedName,
          res,
          focusHandler,
        })}
      </React.Fragment>
    );
  });
};

export default MembersElementsRenderer;
