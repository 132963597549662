import * as d3 from 'd3';
import { hamburgerCoordinate } from '../presentation/drawing/boundingBox/contextMenu/hamburgerCoordinate';
import { vertexSquareStyleSetter } from '../presentation/drawing/vertices/vertexSquareStyleSetter';

/**
 * The 'updateControlPositionsOnZoomOrResize' function updates the positions
 * of visual control elements, such as the hamburger menu and vertices,
 * in response to zooming actions or changes in window size.
 */
export const updateControlPositionsOnZoomOrResize = (frontPanel) => {
  const vertexObjects = d3.selectAll('.vertexHandle').nodes();
  const hamburgerIcon = document.querySelector('.hamburgerIcon');

  if (vertexObjects.length > 1) verticesScaleHandler(frontPanel, vertexObjects);
  if (hamburgerIcon) {
    hamburgerIconScaleHandler(frontPanel, hamburgerIcon);
    d3.selectAll('.ham-context').remove();
  }
};

const verticesScaleHandler = (frontPanel, vertexObjects) => {
  vertexObjects.map((node) => {
    const { transform, coordinate } =
      vertexSquareStyleSetter(frontPanel)[node.id];
    node.style.top = `${coordinate.top}px`;
    node.style.left = `${coordinate.left}px`;
    node.style.transform = transform;
  });
};

const hamburgerIconScaleHandler = (frontPanel, hamburgerIcon) => {
  const { x, y } = hamburgerCoordinate(frontPanel);
  hamburgerIcon.style.top = `${
    y - hamburgerIcon.getBoundingClientRect().height
  }px`;
  hamburgerIcon.style.left = `${x}px`;
};
