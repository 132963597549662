import { bboxDrawer } from '../../../../presentation/drawing/boundingBox/bboxDrawer';
import { bboxOfSelection } from '../../../../presentation/drawing/boundingBox/bboxOfSelection';
import { overlapDialogBox } from './overlapDialogBox';
import { overlapListItemCreator } from './overlapListItemCreator';
import { overlapModalCreator } from './overlapModalCreator';
import * as d3 from 'd3';
import i18n from '../../../../../../i18n/i18n';

/**
 * Clears the innerBboxRectangle in the given front panel group.

 */
const clearInnerBboxRectangle = (frontPanelGp) => {
  frontPanelGp.selectAll('.innerBboxRectangle').remove();
};
/**
 * Sets the given element as selected.
 */
const handleSetElement = (frontPanelGp, element) => {
  clearInnerBboxRectangle(frontPanelGp);
  const obj = { selectedElementIds: [element] };
  bboxDrawer({
    bbox: bboxOfSelection({
      frontPanelGp: frontPanelGp,
      selectedElementIds: obj.selectedElementIds,
      isDragged: false,
      isOverlaped: true,
    }),
    frontPanelGp: frontPanelGp,
    innerRect: true,
    color: 'red',
  });
  return element;
};

/**
 * Sets the selection on the given element.

 */
const handleSetSelection = (frontPanelGp, elementSelection, element) => {
  clearInnerBboxRectangle(frontPanelGp);
  d3.selectAll('.overlap-modal,.overlap-dialog-box').remove();

  elementSelection(
    frontPanelGp
      .selectAll('g')
      .filter(function () {
        return +this.getAttribute('fpd.id') === +element;
      })
      .node()
  );
};

/**
 * Handles the overlap event, setting selection and modals for the user to handle the overlap.
 */

export const handleOverlap = function (
  event,
  self,
  frontPanelGp,
  overlappingElements,
  elementSelection
) {
  let elementShouldBeSelected = !event.sourceEvent.ctrlKey
    ? +self.getAttribute('fpd.group') || +self.getAttribute('fpd.id')
    : +self.getAttribute('fpd.id');

  const setElement = (element) => {
    elementShouldBeSelected = handleSetElement(frontPanelGp, element);
  };

  setElement(elementShouldBeSelected);

  const body = d3.select('body').node();
  body.appendChild(
    overlapModalCreator(
      overlappingElements,
      () =>
        handleSetSelection(
          frontPanelGp,
          elementSelection,
          elementShouldBeSelected
        ),
      setElement
    )
  );
  body.appendChild(overlapDialogBox(event.sourceEvent));

  overlapListItemCreator({
    ctrlKey: event.sourceEvent.ctrlKey,
    frontPanelGp: frontPanelGp,
    overlappingElements: overlappingElements,
    headerText: i18n.t('Panel:OverlapTitle'),
    setElement: setElement,
    setSelection: () =>
      handleSetSelection(
        frontPanelGp,
        elementSelection,
        elementShouldBeSelected
      ),
  });

  // Prevents the default context menu from showing up when right-clicking on the dialog box
  const modalElement = d3.select('.overlap-modal');
  d3.select('.overlap-dialog-box >header')
    .on('contextmenu', function (event) {
      event.preventDefault();
      // Delegates the right mouse click event from the dialog box to the modal box

      modalElement.dispatch('contextmenu');
    })
    .on('click', () => modalElement.dispatch('click'));
};
