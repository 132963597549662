import { defineStyleConfig } from '@chakra-ui/react'

export const LinkStyles = defineStyleConfig({
  baseStyle: {},
  sizes: {
    xs: {
      fontSize: 'xs',
      h: '1.75rem',
      minWidth: '7rem',
    },
    sm: {
      fontSize: 'sm',
      h: '2rem',
      minWidth: '8rem',
    },
    md: {
      fontSize: 'md',
      h: '2.5rem',
      minWidth: '10rem',
    },
  },
  variants: {
    'fpd-button': {
      'user-select': 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid',
      borderColor: 'fpd.900',
      bgGradient: 'linear(to-t, fpd.700, fpd.500)',
      color: 'fpd.50',
      _hover: {
        borderColor: 'fpd.accentBorder',
        bgGradient: 'linear(to-t, fpd.hover, fpd.hoverGradient)',
        color: 'fpd.50',
        cursor: 'pointer',
        textDecoration: 'none',
      },
      _active: {
        borderColor: 'fpd.accentBorder',
        bgGradient: 'linear(to-t, fpd.hover, fpd.hoverGradient)',
        color: 'fpd.50',
        cursor: 'default',
      },
    },
  },
  defaultProps: {},
});
