import { scalabilityFactorTypes } from '../../../../context/Types';
import { bcrToBbox } from '../../presentation/utils/bcrToBbox';
import { movingScaleBoxRenderer } from './movingScaleBoxRenderer';
import * as d3 from 'd3';

export const vertexDragHandler = function (
  self,
  event,
  scalability,
  frontPanelGp,
  {
    initDashedRectBbox,
    inRangeElements,
    inRangeElementsInitMatrix: elInitSVGMatrix,
  },
  controlKeys,
  getRotationAngle
) {
  const { isShiftKey, isCtrlKey } = controlKeys;

  const dashedRectBBox = frontPanelGp.select('.BboxRectangle').node(); //current dashed rectangle
  const vertexHandlerDirType = self.getAttribute('id');
  const { width: vX, height: vY } =
    document.querySelector('#svg-container>svg').viewBox.baseVal;
  const { a, d } = frontPanelGp.node().transform.baseVal.consolidate().matrix;
  const DX = event.dx / ((10 * a) / (vX / 100));
  const DY = event.dy / ((10 * d) / (vY / 100));
  const scaleResizeFactor =
    DX * (initDashedRectBbox.height / initDashedRectBbox.width);
  d3.selectAll('.hamburgerIcon,.ham-context,.vertexHandle').remove();
  frontPanelGp.select('#crossReferenceElement').remove();
  //'direction', Calculates and resizes the dashed bounding box size based on the selected vertex
  const direction = {
    'sw-resize': function (dashedRect) {
      this.updateX.west(dashedRect);
      this.updateWidth.west(dashedRect);
      if (scalability === scalabilityFactorTypes.PROPORTIONAL || isCtrlKey) {
        dashedRect.y.baseVal.value += scaleResizeFactor;
        dashedRect.height.baseVal.value -= isShiftKey
          ? scaleResizeFactor * 2
          : scaleResizeFactor;
      } else {
        this.updateY.south(dashedRect);
        this.updateHeight.south(dashedRect);
      }
    },
    'se-resize': function (dashedRect) {
      this.updateWidth.east(dashedRect);
      this.updateX.east(dashedRect);

      if (scalability === scalabilityFactorTypes.PROPORTIONAL || isCtrlKey) {
        dashedRect.y.baseVal.value -= scaleResizeFactor;
        dashedRect.height.baseVal.value += isShiftKey
          ? scaleResizeFactor * 2
          : scaleResizeFactor;
      } else {
        this.updateY.south(dashedRect);
        this.updateHeight.south(dashedRect);
      }
    },
    'nw-resize': function (dashedRect) {
      this.updateX.west(dashedRect);
      this.updateWidth.west(dashedRect);
      if (scalability === scalabilityFactorTypes.PROPORTIONAL || isCtrlKey) {
        dashedRect.y.baseVal.value += isShiftKey ? scaleResizeFactor : 0;

        dashedRect.height.baseVal.value -= isShiftKey
          ? scaleResizeFactor * 2
          : scaleResizeFactor;
      } else {
        this.updateY.north(dashedRect);

        this.updateHeight.north(dashedRect);
      }
    },
    'ne-resize': function (dashedRect) {
      this.updateWidth.east(dashedRect);
      this.updateX.east(dashedRect);
      if (scalability === scalabilityFactorTypes.PROPORTIONAL || isCtrlKey) {
        dashedRect.y.baseVal.value -= isShiftKey ? scaleResizeFactor : 0;

        dashedRect.height.baseVal.value += isShiftKey
          ? scaleResizeFactor * 2
          : scaleResizeFactor;
      } else {
        this.updateY.north(dashedRect);

        this.updateHeight.north(dashedRect);
      }
    },
    //--free elements
    'nm-resize': function (dashedRect) {
      if (isCtrlKey) {
        const scaleFactor = 1 + DY / dashedRect.height.baseVal.value;
        const oldWidth = dashedRect.width.baseVal.value;
        const oldHeight = dashedRect.height.baseVal.value;

        // Scale the width and height
        dashedRect.width.baseVal.value *= scaleFactor;
        dashedRect.height.baseVal.value *= scaleFactor;
        if (isShiftKey) {
          // Adjust the x and y to keep the center stationary
          dashedRect.x.baseVal.value +=
            (oldWidth - dashedRect.width.baseVal.value) / 2;
          dashedRect.y.baseVal.value +=
            (oldHeight - dashedRect.height.baseVal.value) / 2;
        }
      } else {
        // Default behavior: only change the height.
        this.updateHeight.north(dashedRect);
        this.updateY.north(dashedRect);
      }
    },
    'sm-resize': function (dashedRect) {
      if (isCtrlKey) {
        const scaleFactor = 1 - DY / dashedRect.height.baseVal.value;
        const oldWidth = dashedRect.width.baseVal.value;
        const oldHeight = dashedRect.height.baseVal.value;

        // Scale the width and height
        dashedRect.width.baseVal.value *= scaleFactor;
        dashedRect.height.baseVal.value *= scaleFactor;
        dashedRect.y.baseVal.value +=
          oldHeight - dashedRect.height.baseVal.value;
        if (isShiftKey) {
          // Adjust the x and y to keep the center stationary
          dashedRect.x.baseVal.value +=
            (oldWidth - dashedRect.width.baseVal.value) / 2;
          dashedRect.y.baseVal.value -=
            (oldHeight - dashedRect.height.baseVal.value) / 2;
        }
      } else {
        // Default behavior: only change the height.
        this.updateHeight.south(dashedRect);
        this.updateY.south(dashedRect);
      }
    },

    'wm-resize': function (dashedRect) {
      if (isCtrlKey) {
        const scaleFactor = 1 - DX / dashedRect.width.baseVal.value;
        const oldWidth = dashedRect.width.baseVal.value;
        const oldHeight = dashedRect.height.baseVal.value;

        // Scale the width and height
        dashedRect.width.baseVal.value *= scaleFactor;
        dashedRect.height.baseVal.value *= scaleFactor;
        dashedRect.x.baseVal.value += oldWidth - dashedRect.width.baseVal.value;
        dashedRect.y.baseVal.value +=
          oldHeight - dashedRect.height.baseVal.value;
        if (isShiftKey) {
          // Adjust the x and y to keep the center stationary
          dashedRect.x.baseVal.value -=
            (oldWidth - dashedRect.width.baseVal.value) / 2;
          dashedRect.y.baseVal.value -=
            (oldHeight - dashedRect.height.baseVal.value) / 2;
        }
      } else {
        this.updateX.west(dashedRect);
        this.updateWidth.west(dashedRect);
      }
    },

    'em-resize': function (dashedRect) {
      if (isCtrlKey) {
        const scaleFactor = 1 + DX / dashedRect.width.baseVal.value;
        const oldWidth = dashedRect.width.baseVal.value;
        const oldHeight = dashedRect.height.baseVal.value;

        // Scale the width and height
        dashedRect.width.baseVal.value *= scaleFactor;
        dashedRect.height.baseVal.value *= scaleFactor;
        dashedRect.y.baseVal.value +=
          oldHeight - dashedRect.height.baseVal.value;
        if (isShiftKey) {
          // Adjust the x and y to keep the center stationary
          dashedRect.x.baseVal.value +=
            (oldWidth - dashedRect.width.baseVal.value) / 2;
          dashedRect.y.baseVal.value -=
            (oldHeight - dashedRect.height.baseVal.value) / 2;
        }
      } else {
        this.updateX.east(dashedRect);
        this.updateWidth.east(dashedRect);
      }
    },

    updateWidth: {
      west: (dashedRect) =>
        (dashedRect.width.baseVal.value -= isShiftKey ? DX * 2 : DX),

      east: (dashedRect) =>
        (dashedRect.width.baseVal.value += isShiftKey ? DX * 2 : DX),
    },

    updateHeight: {
      north: (dashedRect) =>
        (dashedRect.height.baseVal.value += isShiftKey ? DY * 2 : DY),

      south: (dashedRect) =>
        (dashedRect.height.baseVal.value -= isShiftKey ? DY * 2 : DY),
    },
    updateX: {
      west: (dashedRect) => (dashedRect.x.baseVal.value += DX),

      east: (dashedRect) => (dashedRect.x.baseVal.value -= isShiftKey ? DX : 0),
    },
    updateY: {
      north: (dashedRect) =>
        (dashedRect.y.baseVal.value -= isShiftKey ? DY : 0),

      south: (dashedRect) => (dashedRect.y.baseVal.value += DY),
    },
  };
  direction[vertexHandlerDirType](dashedRectBBox);
  movingScaleBoxRenderer(frontPanelGp, dashedRectBBox); //responsible for re-scaling the dashed souronding box to any direction
  //--
  const HorizontalScaleRatio = dashedRectBBox.width.baseVal.value > 0 ? 1 : -1;
  const verticalScaleRatio = dashedRectBBox.height.baseVal.value > 0 ? 1 : -1;
  const scaleW =
    Math.abs(dashedRectBBox.width.baseVal.value) / initDashedRectBbox.width;
  const scaleH =
    Math.abs(dashedRectBBox.height.baseVal.value) / initDashedRectBbox.height;
  // --
  inRangeElements.forEach(function (element) {
    const elBox = frontPanelGp.select(
      `g>#box${element.getAttribute('fpd.id')}`
    );
    const elId = element.getAttribute('fpd.id');
    if (!elBox.empty()) {
      frontPanelGp.select('.innerBboxRectangle').remove();
    }

    const elementCoordinateCalculator = (element) => {
      return !element.getBBox().width || !element.getBBox().height
        ? {
            x: element.transform.baseVal.consolidate().matrix.e,
            y: element.transform.baseVal.consolidate().matrix.f,
            width: 1,
            height: 1,
          }
        : bcrToBbox(element);
    };
    const elementCoordinate = elementCoordinateCalculator(element);
    const elSVGMatrix = element.transform.baseVal.consolidate().matrix;
    //--SCALING
    if (getRotationAngle(elInitSVGMatrix[elId])) {
      // Checks if the element has a rotation attribute
      elSVGMatrix.a = scaleW * elInitSVGMatrix[elId].a * HorizontalScaleRatio;
      elSVGMatrix.b = scaleH * elInitSVGMatrix[elId].b * verticalScaleRatio;
      elSVGMatrix.c = scaleW * elInitSVGMatrix[elId].c * HorizontalScaleRatio;
      elSVGMatrix.d = scaleH * elInitSVGMatrix[elId].d * verticalScaleRatio;
    } else {
      elSVGMatrix.a = scaleW * HorizontalScaleRatio;
      elSVGMatrix.d = scaleH * verticalScaleRatio;
    }
    // --
    const deltaW = elementCoordinate.width * (scaleW - 1); // deltaW=w2-w1
    const deltaH = elementCoordinate.height * (scaleH - 1); // deltaH=h2-h1
    const newX = isShiftKey
      ? elInitSVGMatrix[elId].e
      : elInitSVGMatrix[elId].e - deltaW / 2;
    const newY = isShiftKey
      ? elInitSVGMatrix[elId].f
      : elInitSVGMatrix[elId].f - deltaH / 2;
    const selectedBox = {
      middleHorizontal: initDashedRectBbox.x + initDashedRectBbox.width / 2,
      middleVertical: initDashedRectBbox.y + initDashedRectBbox.height / 2,
      right: initDashedRectBbox.x + initDashedRectBbox.width,
      left: initDashedRectBbox.x,
      top: initDashedRectBbox.y + initDashedRectBbox.height,
      down: initDashedRectBbox.y,
      distanceFromCenterHor: function () {
        return this.middleHorizontal - elInitSVGMatrix[elId].e;
      },
      distanceFromCenterVer: function () {
        return this.middleVertical - elInitSVGMatrix[elId].f;
      },
      distanceFromRight: function () {
        return (
          this.right - (elInitSVGMatrix[elId].e + elementCoordinate.width / 2)
        );
      },
      distanceFromLeft: function () {
        return (
          this.left - (elInitSVGMatrix[elId].e + elementCoordinate.width / 2)
        );
      },
      distanceFromTop: function () {
        return (
          this.top - (elInitSVGMatrix[elId].f + elementCoordinate.height / 2)
        );
      },
      distanceFromDown: function () {
        return (
          this.down - (elInitSVGMatrix[elId].f + elementCoordinate.height / 2)
        );
      },
      deltaFromLeft: function () {
        return this.distanceFromLeft() * (scaleW - 1);
      },
      deltaFromDown: function () {
        return this.distanceFromDown() * (scaleH - 1);
      },
      deltaFromTop: function () {
        return this.distanceFromTop() * (scaleH - 1);
      },
      deltaFromRight: function () {
        return this.distanceFromRight() * (scaleW - 1);
      },
      deltaFromCenterHor: function () {
        return this.distanceFromCenterHor() * (1 - scaleW);
      },
      deltaFromCenterVer: function () {
        return this.distanceFromCenterVer() * (1 - scaleH);
      },
    };
    const movingDir = {
      negativeHorizontal: function () {
        const newX = elementCoordinate.width / 2 + deltaW / 2;
        elSVGMatrix.e =
          newX +
          dashedRectBBox.x.baseVal.value +
          selectedBox.distanceFromLeft() * scaleW;
      },
      negativeVertical: function () {
        const newY = elementCoordinate.height / 2 + deltaH / 2;
        elSVGMatrix.f =
          newY +
          dashedRectBBox.y.baseVal.value +
          selectedBox.distanceFromDown() * scaleH;
      },
    };
    if (
      vertexHandlerDirType === 'nw-resize' ||
      vertexHandlerDirType === 'sw-resize' ||
      vertexHandlerDirType === 'wm-resize' ||
      (vertexHandlerDirType === 'nm-resize' && isCtrlKey)
    ) {
      if (HorizontalScaleRatio < 0) {
        movingDir.negativeHorizontal();
      } else {
        elSVGMatrix.e = isShiftKey
          ? newX + selectedBox.deltaFromCenterHor()
          : newX - selectedBox.deltaFromRight();
      }
    }
    if (
      vertexHandlerDirType === 'sw-resize' ||
      vertexHandlerDirType === 'se-resize' ||
      vertexHandlerDirType === 'sm-resize' ||
      (vertexHandlerDirType === 'wm-resize' && isCtrlKey) ||
      (vertexHandlerDirType === 'em-resize' && isCtrlKey)
    ) {
      if (verticalScaleRatio < 0) {
        movingDir.negativeVertical();
      } else {
        elSVGMatrix.f = isShiftKey
          ? newY + selectedBox.deltaFromCenterVer()
          : newY - selectedBox.deltaFromTop();
      }
    }
    if (
      vertexHandlerDirType === 'se-resize' ||
      vertexHandlerDirType === 'ne-resize' ||
      vertexHandlerDirType === 'em-resize' ||
      (vertexHandlerDirType === 'nm-resize' && isCtrlKey) ||
      (vertexHandlerDirType === 'sm-resize' && isCtrlKey)
    ) {
      if (HorizontalScaleRatio < 0) {
        movingDir.negativeHorizontal();
      } else {
        elSVGMatrix.e = isShiftKey
          ? newX + selectedBox.deltaFromCenterHor()
          : newX - selectedBox.deltaFromLeft();
      }
    }
    if (
      vertexHandlerDirType === 'nw-resize' ||
      vertexHandlerDirType === 'ne-resize' ||
      vertexHandlerDirType === 'nm-resize'
    ) {
      if (verticalScaleRatio < 0) {
        movingDir.negativeVertical();
      } else {
        elSVGMatrix.f = isShiftKey
          ? newY + selectedBox.deltaFromCenterVer()
          : newY - selectedBox.deltaFromDown();
      }
    }
  });
};
