import { popoverAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from "@chakra-ui/system";

const helpers = createMultiStyleConfigHelpers(popoverAnatomy.keys);

export const PopoverStyles = helpers.defineMultiStyleConfig({
    baseStyle: {
        content: {
            // Define base styles for popover content
            borderRadius: 'none',
            borderColor: 'fpd.800',
            bg: 'fpd.700',
            color: 'fpd.50',
            boxShadow: 'md',
            width: 'auto',
            my: '-2',
            _focus: {
              border: '1px solid fpd.800',
            },
          },
    },
    sizes: {},
    variants: {},
    defaultProps: {},
});