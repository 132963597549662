import { scalabilityFactorTypes } from '../../../context/Types';
import { elementSelector } from '../interactions/selection/elementSelector';

export const selectedElementScalabilityFactorType = (
  frontPanelGp,
  ElementIds
) => {
  const scalabilityFilter = (type) =>
    elementSelector(frontPanelGp, ElementIds).some(
      (element) => element.getAttribute('fpd.scalable') === type
    );
  if (scalabilityFilter(scalabilityFactorTypes.NONE))
    return scalabilityFactorTypes.NONE;
  if (scalabilityFilter(scalabilityFactorTypes.PROPORTIONAL))
    return scalabilityFactorTypes.PROPORTIONAL;
  return scalabilityFactorTypes.FREE;
};
