// Built-in
import React, { useState } from 'react';
// External
import {
  IconButton,
  Image,
  Tooltip,
  useMultiStyleConfig,
  MenuButton,
} from '@chakra-ui/react'; // Importing necessary objects from '@chakra-ui' library for rendering the UI.
import { useTranslation } from 'react-i18next';

export const ToolbarMenuIcon = ({
  label,
  imagePath,
  isSelected,
  onClickHandler,
  icon,
}) => {
  const styles = useMultiStyleConfig('ToolbarMenuIcon');
  const { t } = useTranslation('ToolbarIcons');
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <Tooltip
      isOpen={showTooltip}
      gutter={10}
      onClose={() => setShowTooltip(false)}
      label={t(`${label}`)}
    >
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={
          imagePath ? <Image src={imagePath} sx={styles.iconImage} /> : icon
        }
        sx={isSelected ? styles.selectedButton : styles.button}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        onClick={onClickHandler}
      />
    </Tooltip>
  );
};
