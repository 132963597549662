/**
 * This function verifies whether the member properties are prefixed with 'Group/'.
 */
const isGroupedSelection = (membersProps) => {
  return Object.keys(membersProps).some((memberProp) =>
    memberProp.startsWith('Group')
  );
};

export default isGroupedSelection;
