import React, { createContext, useState, useMemo } from 'react';

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [isTextEngModalOpen, setTextEngModalOpen] = useState(false);
  const [textEngModalData, setTextEngModalData] = useState(null);
  const [isRotationModalOpen, setRotationModalOpen] = useState(false);
  const [isMoveModalOpen, setMoveModalOpen] = useState(false);
  const [isMirrorModalOpen, setMirrorModalOpen] = useState(false);
  const [isResizeModalOpen, setResizeModalOpen] = useState(false);
  const [isCoordinatesModalOpen, setCoordinatesModalOpen] = useState(false);
  const [isAlignDistributeModalOpen, setAlignDistributeModalOpen] =
    useState(false);
  const [PrintGraphicModalState, setPrintGraphicModalState] = useState({
    isOpen: false,
    position: { x: 0, y: 0 },
  });

  const modalValues = useMemo(
    () => ({
      isTextEngModalOpen,
      setTextEngModalOpen,
      textEngModalData,
      setTextEngModalData,
      isRotationModalOpen,
      setRotationModalOpen,
      isMoveModalOpen,
      setMoveModalOpen,
      isMirrorModalOpen,
      setMirrorModalOpen,
      isResizeModalOpen,
      setResizeModalOpen,
      isAlignDistributeModalOpen,
      setAlignDistributeModalOpen,
      isCoordinatesModalOpen,
      setCoordinatesModalOpen,
      textEngModalData,
      PrintGraphicModalState,
      setPrintGraphicModalState,
    }),
    [
      isTextEngModalOpen,
      textEngModalData,
      isRotationModalOpen,
      isMoveModalOpen,
      isMirrorModalOpen,
      isResizeModalOpen,
      isAlignDistributeModalOpen,
      isCoordinatesModalOpen,
      PrintGraphicModalState,
    ]
  );

  return (
    <ModalContext.Provider value={modalValues}>
      {children}
    </ModalContext.Provider>
  );
};
