import { fromViewPortToFrontPanelTransformer } from '../utils/coordinateTransformer';

// Processes key presses and returns a modifier object
const processKeys = (event) => ({
  ctrlKey: event.sourceEvent.ctrlKey,
  shiftKey: event.sourceEvent.shiftKey,
});

const coordinatePointsProvider = (event, source, frontPanelGp) => {
  // Early return for non-canvas source
  if (source !== 'canvas')
    return {
      startX: event.subject.x,
      startY: event.subject.y,
      currentX: event.x,
      currentY: event.y,
    };

  // Calculate coordinates for canvas source
  const transform = (x, y) =>
    fromViewPortToFrontPanelTransformer(x, y, frontPanelGp, true);
  const { x: currentX, y: currentY } = transform(event.x, event.y);
  const { x: startX, y: startY } = transform(event.subject.x, event.subject.y);

  return { startX, startY, currentX, currentY };
};
//Draw the range-selection-box in order to select multi-element
export function drawSelectionBox(event, source, frontPanelGp) {
  const { startX, startY, currentX, currentY } = coordinatePointsProvider(
    event,
    source,
    frontPanelGp
  );
  const path = `M${startX},${startY} L${currentX},${startY} L${currentX},${currentY} L${startX},${currentY} z`;

  return [path, startX > currentX];
}

export function rectShapeDrawer(event, source, frontPanelGp) {
  // Extract the starting and current positions
  const { startX, startY, currentX, currentY } = coordinatePointsProvider(
    event,
    source,
    frontPanelGp
  );

  const { ctrlKey: isCtrlKey, shiftKey: isShiftKey } = processKeys(event);

  // Default to drawing a rectangle with corners at the start and current positions
  let x = startX;
  let y = startY;
  let width = currentX - startX;
  let height = currentY - startY;

  // If the Ctrl key is held down, draw a square instead of a rectangle
  if (isCtrlKey) {
    // Determine the side length based on the longer of the width or height
    let sideLength = Math.min(Math.abs(width), Math.abs(height));

    // Adjust x, y, width, and height to form a square
    x = width > 0 ? startX : startX - sideLength;
    y = height > 0 ? startY : startY - sideLength;
    width = height = sideLength;
  }
  // If the Shift key is held down, draw the rectangle from the center
  if (isShiftKey) {
    width *= 2;
    height *= 2;
    x = startX - width / 2;
    y = startY - height / 2;
  }
  // Generate the path for the SVG
  let path = `
    M${x},${y}
    h${width}
    v${height}
    h${-width}
    Z
  `;

  return {
    shapePath: path,
    shapePoints: {
      startPointX: x,
      startPointY: y,
      endPointX: x + width,
      endPointY: y + height,
      isHealthy: true,
    },
  };
}
export function lineCreatorBoxDrawer(event, source, frontPanelGp, free = true) {
  // Extract the starting and current positions
  let { startX, startY, currentX, currentY } = coordinatePointsProvider(
    event,
    source,
    frontPanelGp
  );
  const { ctrlKey: isCtrlKey, shiftKey: isShiftKey } = processKeys(event);

  let endX = currentX;
  let endY = currentY;
  const angleSteps = 15;
  // If the Ctrl key is held down, constrain the line to 15 degree angle steps
  if (isCtrlKey) {
    let angle = Math.atan2(currentY - startY, currentX - startX); // in radians
    let length = Math.sqrt(
      Math.pow(currentX - startX, 2) + Math.pow(currentY - startY, 2)
    );

    // Convert angle to degrees and round to nearest multiple of 'angleSteps'
    let angleDegrees =
      Math.round((angle * (180 / Math.PI)) / angleSteps) * angleSteps;

    // Convert back to radians for the calculations
    angle = angleDegrees * (Math.PI / 180);

    // Calculate the end point
    endX = startX + length * Math.cos(angle);
    endY = startY + length * Math.sin(angle);
  }

  // If the Shift key is held down, draw the line from the center
  if (isShiftKey) {
    let dx = endX - startX;
    let dy = endY - startY;
    startX = startX - dx;
    startY = startY - dy;
    endX = startX + dx * 2;
    endY = startY + dy * 2;
  }

  // Generate the path for the SVG
  let path = `M${startX},${startY} L${endX},${endY}`;
  return {
    shapePath: path,
    shapePoints: {
      startPointX: startX,
      startPointY: startY,
      endPointX: endX,
      endPointY: endY,
      isHealthy: true,
    },
  };
}

export function ellipseDrawer(event, source, frontPanelGp, free = true) {
  // Extract the starting and current positions

  const { startX, startY, currentX, currentY } = coordinatePointsProvider(
    event,
    source,
    frontPanelGp
  );
  const { ctrlKey: isCtrlKey, shiftKey: isShiftKey } = processKeys(event);

  // Default to drawing an ellipse centered between the start and current positions
  let cx = startX + (currentX - startX) / 2;
  let cy = startY + (currentY - startY) / 2;
  let rx = Math.abs(currentX - startX) / 2;
  let ry = Math.abs(currentY - startY) / 2;

  const signX = currentX > startX ? 1 : -1;
  const signY = currentY > startY ? 1 : -1;

  // If the Shift key is held down, draw the ellipse from the start position
  if (isShiftKey) {
    cx = startX;
    cy = startY;
    rx = Math.abs(currentX - startX);
    ry = Math.abs(currentY - startY);
  }

  // If the Ctrl key is held down, draw a circle instead of an ellipse
  if (isCtrlKey || !free) {
    let radius = Math.min(Math.abs(rx), Math.abs(ry));
    rx = ry = radius;

    // If Shift is also held, keep the start position as the center
    // Else, set the center to be in the middle of start and current positions
    cx = isShiftKey ? startX : startX + (radius * signX) / 2;
    cy = isShiftKey ? startY : startY + (radius * signY) / 2;
  }
  // Set the flags for the arc command
  // Flip the flags for the left quadrants
  let largeArcFlag = currentX < startX ? 1 : 0;
  let sweepFlag = currentX < startX ? 0 : 1;

  let path = `
    M${cx - rx},${cy}
    a${rx},${ry} 0 ${largeArcFlag},${sweepFlag} ${2 * rx},0
    a${rx},${ry} 0 ${largeArcFlag},${sweepFlag} ${-2 * rx},0
  `;
  return {
    shapePath: path,
    shapePoints: {
      startPointX: cx - rx,
      startPointY: cy - ry,
      endPointX: cx + rx,
      endPointY: cy + ry,
      isHealthy: true,
    },
  };
}
