//Draws element vertices based on the scalability factor
import { scalabilityFactorTypes } from '../../../../../context/Types';
import { vertexCreator } from './VertexCreator';
import { vertexSquareStyleSetter } from './vertexSquareStyleSetter';
export const vertexDrawer = function ({
  bbox,
  scalability,
  frontPanelGp,
  selectedElementIds,
  patchMesh,
  symbolSize: symbolSize,
}) {
  let vertexData = [
    {
      id: 'sw-resize',
      cursor: 'nesw-resize',
    },
    {
      id: 'se-resize',
      cursor: 'nwse-resize',
    },
    {
      id: 'nw-resize',
      cursor: 'nwse-resize',
    },
    {
      id: 'ne-resize',
      cursor: 'nesw-resize',
    },
  ];
  if (scalability === scalabilityFactorTypes.FREE) {
    vertexData = [
      ...vertexData,
      {
        id: 'nm-resize',
        cursor: 'ns-resize',
      },
      {
        id: 'sm-resize',
        cursor: 'ns-resize',
      },
      {
        id: 'wm-resize',
        cursor: 'ew-resize',
      },
      {
        id: 'em-resize',
        cursor: 'ew-resize',
      },
    ];
  }

  vertexData.forEach(({ id, cursor }) =>
    vertexCreator({
      vertexId: id,
      transformVal: vertexSquareStyleSetter(frontPanelGp)[id]['transform'],
      coordinate: vertexSquareStyleSetter(frontPanelGp)[id]['coordinate'],
      cursorType: cursor,
      symbolSize: symbolSize,
      frontPanelGp: frontPanelGp,
      scalability: scalability,
      selectedElementIds: selectedElementIds,
      patchMesh: patchMesh,
    })
  );
};
