/**
 * 'Option' renders an element with 'bool-option' properties.
 */
// Built-in
import React from 'react';

// External
import { useField } from 'formik';
import {
  Checkbox,
} from '@chakra-ui/react';

const Option = (props) => {
  const {
    name,
    label,
    isGrouped,
    res: { value },
  } = props;

  const [field] = useField(name);

  return (
    <Checkbox {...field} isChecked={value} variant={isGrouped ? 'sideBarGroup' : 'sideBar'}>
      {label}
    </Checkbox>
  );
};

export default React.memo(Option);
