import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const commonContainerStyles = {
  alignItems: 'center',
  position: 'relative',
  display: 'flex',
  '* + &': {
    mt: '0.6rem',
  },
};

const commonLabelStyles = {
  mb: 0,
  fontWeight: 200,
  position: 'absolute',
  maxWidth: '10.625rem',
  textAlign: 'right',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: 'xs',
};

const baseStyle = definePartsStyle({
  control: {
    border: '1px solid',
    borderRadius: 'none',
    borderColor: 'fpd.800',
    bg: 'fpd.700',
    _hover: {
      borderColor: 'fpd.accentBorder',
      bgGradient: 'linear(to-t, fpd.hover, fpd.hoverGradient)',
    },
    _checked: {
      borderColor: 'fpd.800',
      bg: 'fpd.700',
      _hover: {
        borderColor: 'fpd.accentBorder',
        bgGradient: 'linear(to-t, fpd.hover, fpd.hoverGradient)',
      },
      _disabled: {
        bg: 'fpd.700',
        opacity: 0.45,
      },
    },
    _disabled: {
      bg: 'fpd.700',
      opacity: 0.45,
    },
    _focus: {
      boxShadow: '0 0 0 2px var(--chakra-colors-fpd-accentActiv)',
    },
  },
  icon: {
    color: 'white',
    bg: 'fpd.700',
    _hover: {
      borderColor: 'fpd.accentBorder',
      bgGradient: 'linear(to-t, fpd.hover, fpd.hoverGradient)',
    },
    _disabled: {
      opacity: 0.45,
    },
  },
  label: {
    fontSize: 'xs',
  },
});

const variants = {
  fpd: definePartsStyle({
    container: {
      marginTop: '0.6rem',
    },
    label: {
      fontSize: 'xs',
    },
  }),
  sideBar: definePartsStyle({
    container: {
      ...commonContainerStyles,
      paddingLeft: '98px',
    },
    label: {
      ...commonLabelStyles,
      left: '113px',
    },
  }),
  sideBarGroup: definePartsStyle({
    container: {
      ...commonContainerStyles,
      paddingLeft: '60px',
    },
    label: {
      ...commonLabelStyles,
      left: '77px',
    },
  }),
};

export const CheckboxStyles = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: 'fpd',
  },
});
