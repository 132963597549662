// -------------------[DocumentVersions]-------------------\
/**
 * Methods related to DocumentVersions Object
 */
import { API_BASE_URL } from '../Client';

const DocumentVersions = {
  /**
   * Get a [`DocumentVersion`] by id.
   */
  getById: (doc_id, version_id) => ({
    method: 'GET',
    url: `${API_BASE_URL}/docs/${doc_id}/versions/${version_id}`,
    headers: { accept: 'application/json' },
  }),
  /**
   * Post a graphic element
   */
  print: (doc_id, version_id, data) => ({
    method: 'POST',
    url: `${API_BASE_URL}/docs/${doc_id}/versions/${version_id}/print`,
    data: data,
  }),
};
export default DocumentVersions;
