/**
 *responsible for mutating the 'selected Elements' arrays,
 * which is contained the selected element ids.
 * The panel should be selected if selection.length equals 0.
 * If the panel has already been selected and another element is
 * selected, only the element must be added to the selection
 */

export const selection = function (
  element,
  { selectedElementIds },
  isPanelSelected,
  shiftKey,
  ctrlKey
) {
  const fpdId = +element.getAttribute('fpd.id');
  const groupId =
    element.hasAttribute('fpd.group') && +element.getAttribute('fpd.group');
  const elementId = groupId && !ctrlKey ? groupId : fpdId;
  /**
   * Whenever the ctrl-key has been used to select the member
   * of a group, and the group is selected again,
   * the fpd.id should be removed from the array.
   */
  if (selectedElementIds.includes(fpdId) && !!groupId) {
    selectedElementIds = selectedElementIds.filter((id) => id !== fpdId);
  }

  let isSelectionChanged = false;
  if (shiftKey) {
    if (isPanelSelected) {
      // If no group element has been selected(panel should not be take in to the account), select the current one
      selectedElementIds = [elementId];
    } else if (selectedElementIds.includes(elementId)) {
      // If the current element is already selected, deselect it

      selectedElementIds = selectedElementIds.filter((id) => id !== elementId);
    } else {
      // If the current element is not selected, add it to the selection

      selectedElementIds = [...selectedElementIds, elementId];
    }
    isSelectionChanged = true;
  } else if (!selectedElementIds.includes(elementId)) {
    // If shift key is not pressed and If the current element is not already been selected,select only the current element

    selectedElementIds = [elementId];
    isSelectionChanged = true;
  }
  return {
    selectedElementIds: selectedElementIds,
    isSelectionChanged: isSelectionChanged,
  };
};
