/**
 * The 'Language' component, synchronize the language of the App and set an cookie.
 */

// Built-in
import React, { useContext } from 'react';

import {
  Menu,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
} from '@chakra-ui/react';

// Internal
import { ApiContext } from '../../context/ApiContext'; // Provides API context (Global shared state) to the component
import language from '../../data/languages.json';
import { ToolbarMenuIcon } from './engravingObjects/ToolbarMenuIcon';

function LanguageSelector() {
  const { setLang, lang } = useContext(ApiContext);

  const LangButtonClickHandler = (code) => {
    if (code === lang) return;
    setLang(code);
  };

  return (
    <Menu>
      <ToolbarMenuIcon label="Lang" imagePath="/icons/language_select.svg" />
      <MenuList transform="translateY(-4px)!important">
        <MenuOptionGroup defaultValue={lang} type="radio">
          {language.map(({ display, code }, index) => (
            <MenuItemOption
              value={code}
              key={index}
              onClick={() => LangButtonClickHandler(code)}
            >
              {display}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}

export default LanguageSelector;
