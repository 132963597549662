//@ts-check

import * as Types from './Types';
/**
  Declaration of FPD-WEB context (global state for the app) as 'panelStatusData'
  */

/**@type {Types.ElementPlacement} */
export const ElementPlacement = {
  type: null,
  position: null,
  scale: null,
  rotation: null,
  transformation: null,
};

/**
 * Error type returned by fpcli as json A message from the front panel model that refers to certain elements.
This structure is used in FpcliError::ModelError and for warnings in output of fpcli commands related to properties.
 */
/**@type {Types.ModelMessage} */
export const ModelMessage = {
  element_ids: [],
  message: '',
};

/**@type {Types.Property} */
export const Property = {
  value: null,
  pool: null,
};

/**@type {Types.Selection} */
export const Selection = {
  member_ids: [],
  member_properties: null,
  document_version: 0,
  warnings: null,
};
/**@type {Types.DocumentVersion} */
export const DocumentVersion = {
  id: 0,
  document_id: 0,
  previous_version: null,
  next_version: null,
  created_at: '',
  changed_ids: [],
};

/**@type {Types.DocumentUpload} */
export const DocumentUpload = {
  name: '',
  description: '',
  file: null,
};
/**@type {Types.Document} */
export const Document = {
  id: 0,
  name: '',
  description: '',
  created_at: '',
  modified_at: '',
  version: 0,
};

export const MeshData = {
  file: null,
};

export const DocumentMsg = {
  errorMsg: '', //The error message container string
  warnings: '', //The array of warnings of properties.
  errors: '', //The error object contains one or more errors.
};

// Constant Variables that contain the relative names of elements in the API.
export const ElementToBeSelected = {
  element_ids: [],
};
export const d3vars = {
  svgElement: null,
  groupElement: null,
  selectedElementIds: [],
  isSelected: false,
  currElMatrix: null,
  isDragged: false,
  prevDragPosition: { x: 0, y: 0 },
  currentInteractionState: null,
};
