/**
 *
 * This function maps the canvas X and Y coordinates to viewBox coordinates
 * and returns the transformed coordinates.
 */

export function fromViewPortToFrontPanelTransformer(
  x,
  y,
  frontPanelGp,
  useCTM = false,
  reverse = false
) {
  const destinationCoordinate = frontPanelGp.node();
  const point = new DOMPoint(x, y);

  // return right and bottom
  const ctm = destinationCoordinate.getCTM();
  const screenCTM = destinationCoordinate.getScreenCTM();
  const matrix = reverse
    ? screenCTM
    : useCTM
    ? ctm.inverse()
    : screenCTM.inverse();

  return point.matrixTransform(matrix);
}
