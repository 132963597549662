// import { elementSelectionProvider } from '../../selection/elementSelectionProvider';
import { SelectModeType } from '../../../../../context/Types';
import { getElementsThatCoverMouseByPath } from './overlap/getElementsThatCoverMouseByPath';
import { handleOverlap } from './overlap/handleOverlap';
import { selectionProvider } from '../../selection/selectionProvider';
import { isPointerInBBox } from '../../isPointerInBBox';

// Handle element click event

export const elementClickHandler = (
  event,
  interactionState,
  fpSvgStates,
  updateFpSvgStates,
  frontPanelGp,
  getSelectionsForGivenElements,
  patchMesh,
  isLoading,
  self,
  groupOrUngroupSelectionProvider,
  duplicateSelection,
  deleteSelection,
  setRotationModalOpen,
  setMoveModalOpen,
  setMirrorModalOpen,
  setResizeModalOpen,
  setAlignDistributeModalOpen
) => {
  const isPanelSelected = frontPanelGp.select('.BboxRectangle').empty();

  if (isLoading) {
    event.on('drag', null);
    event.on('end', null);
    return;
  }
  if (
    !isPanelSelected &&
    !event.sourceEvent.shiftKey &&
    isPointerInBBox(event, frontPanelGp)
  )
    return;

  if (interactionState !== SelectModeType.SelectMode) return;

  const elementSelection = (element) => {
    selectionProvider(
      element,
      fpSvgStates,
      event,
      updateFpSvgStates,
      frontPanelGp,
      getSelectionsForGivenElements,
      patchMesh,
      groupOrUngroupSelectionProvider,
      duplicateSelection,
      deleteSelection,
      setRotationModalOpen,
      setMoveModalOpen,
      setMirrorModalOpen,
      setResizeModalOpen,
      setAlignDistributeModalOpen
    );
  };

  const overlapedPath = getElementsThatCoverMouseByPath(event.sourceEvent);

  if (overlapedPath.length <= 1) {
    elementSelection(self);
    return;
  }
  const overlapedObj = overlapedObjectProvider(
    overlapedPath,
    event.sourceEvent.ctrlKey
  );
  if (Object.keys(overlapedObj).length > 1) {
    handleOverlap(event, self, frontPanelGp, overlapedObj, elementSelection);
    return;
  }

  elementSelection(self);
};

/**
 * From all overlapped paths,
 * we create an object with desired specifications for later use
 */
function overlapedObjectProvider(overlapedPath, ctrlKey) {
  const allOverlapedPathObjectProvider = overlapedPath.reduce((acc, path) => {
    const elId = path.parentNode.getAttribute('fpd.id');
    const elGID = path.parentNode.getAttribute('fpd.group');
    const elDesc = path.parentNode.getAttribute('fpd.desc');
    return { ...acc, [elId]: { gid: elGID, desc: elDesc } };
  }, {});

  const uniqueObj = removeDuplicateGidValues(allOverlapedPathObjectProvider);

  return !ctrlKey ? uniqueObj : allOverlapedPathObjectProvider;
}

/**
 *
 * The 'removeDuplicateGidValues' removes duplicate values
 * with identical 'gid' values, which are
 * paths belonging to one group.
 */
function removeDuplicateGidValues(obj) {
  let gidSet = new Set();

  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value['gid'] === null || !gidSet.has(value['gid'])) {
      gidSet.add(value['gid']);
      acc[key] = value;
    }
    return acc;
  }, {});
}
