import { createMultiStyleConfigHelpers } from "@chakra-ui/system";

const myAnatomy = ['container', 'label', 'unit'];
const helpers = createMultiStyleConfigHelpers(myAnatomy);

export const DimValueWithUnitStyles = helpers.defineMultiStyleConfig({
  parts: myAnatomy,
  baseStyle: {
    container: {
      display: 'flex',
      minWidth: 'max-content',
      alignItems: 'center',
      p: '4px',
    },
    label: {
      fontSize: 'xs',
      color: 'white',
      textAlign: 'right',
    },
    unit: {
      fontSize: 'xs',
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {},
});
