import { ElementType, SelectModeType } from '../../../../../context/Types';
import { isPointerInBBox } from '../../isPointerInBBox';
import { drawSelectionBox } from '../../../presentation/drawing/drawSelectionBox';
import { moveSelectedElements } from './move/moveSelectedElements';
import { bboxOfSelection } from '../../../presentation/drawing/boundingBox/bboxOfSelection';
import { elementDrawingStrategy } from './handleElementCreation';
// Handle drag event
export const elementDragHandler = (
  event,
  interactionState,
  fpSvgStates,
  updateFpSvgStates,
  frontPanelGp,
  isLoading,
  self
) => {
  if (isLoading) {
    event.on('drag', null);
    event.on('end', null);
    return;
  }
  const NON_DRAWING_ELEMENTS = new Set([
    ElementType.DHole,
    ElementType.CurvedSlot,
    ElementType.Cavity,
    ElementType.Bolt,
    ElementType.EngravingText,
    ElementType.PrintGraphic,
  ]);

  const isNonDrawingElementIsSelected = (interactionState) =>
    NON_DRAWING_ELEMENTS.has(interactionState);

  const isOverlapDialog = document.querySelector('.overlap-dialog-box');

  if (isOverlapDialog || isNonDrawingElementIsSelected(interactionState))
    return;

  const isElementDrawn = elementDrawingStrategy(
    event,
    'path',
    interactionState,
    frontPanelGp,
    updateFpSvgStates
  );
  if (isElementDrawn) {
    return;
  }

  if (interactionState !== SelectModeType.SelectMode) {
    return;
  }
  const isPanelSelected = frontPanelGp.select('.BboxRectangle').empty();
  const isDragOnPanel = self.hasAttribute('fpd.panel');
  const isdrawingBoxDrawn = frontPanelGp.select('#drawingBox').attr('d');

  function isDrawingBox() {
    return isDragOnPanel && isPanelSelected;
  }

  function isShiftBoxSelection() {
    return (
      isDragOnPanel &&
      event.sourceEvent.shiftKey &&
      !isPanelSelected &&
      !isPointerInBBox(event, frontPanelGp)
    );
  }

  function isTouchingSelectedBox() {
    return isPointerInBBox(event, frontPanelGp) && isdrawingBoxDrawn;
  }

  const shouldDrawBox =
    isDrawingBox() || isShiftBoxSelection() || isTouchingSelectedBox();

  if (shouldDrawBox) {
    const [path, isTouched] = drawSelectionBox(event, 'path', frontPanelGp);
    const drawingBox = frontPanelGp.select('#drawingBox');
    const innerDrawingBox = frontPanelGp.select('#InnerDrawingBox');
    drawingBox.attr('d', path).classed('touched', isTouched);
    innerDrawingBox.attr('d', path).classed('touched', isTouched);
    return;
  }

  updateFpSvgStates({ isDragged: true });
  moveSelectedElements({
    dx: event.dx,
    dy: event.dy,
    bbox: bboxOfSelection({
      frontPanelGp: frontPanelGp,
      selectedElementIds: fpSvgStates.selectedElementIds,
      isDragged: true,
    }),
    frontPanelGp,
    selectedElementIds: fpSvgStates.selectedElementIds,
  });
};
