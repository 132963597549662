/**
 * The 'FeatureLog' component, shows the implemented features of the web-fpd.
 */

// Built-in
import React, { useEffect } from 'react';

// External
import {
  useDisclosure,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  ListItem,
  ListIcon,
  List,
  HStack,
  Link,
  SimpleGrid,
  useBreakpointValue,
} from '@chakra-ui/react'; // Importing necessary objects from '@chakra-ui' library for rendering the UI.

import { useTranslation } from 'react-i18next';

// Internal
import ActionButton from '../modal/dialog/elements/ActionButton';

import { CheckIcon, WorldIcon } from '../../../assets/IconsSvg';

import { ToolbarIcon } from '../ToolbarIcon';

import featureSet_EN from '../../../data/featureSet/en_US.json';
import featureSet_DE from '../../../data/featureSet/de_DE.json';
import RenderTable from './RenderTable';
import { ScrollbarStyles } from '../../../theme/components';
const renderFeatureSets = (featureSets) => {
  return Object.entries(featureSets).map(([category, features]) => (
    <Box key={category}>
      <RenderTable category={category} features={features} />
    </Box>
  ));
};

function FeatureLog() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const featureSets = {
    en: featureSet_EN,
    de: featureSet_DE,
  };
  const modalCloseHandler = () => {
    onClose();
  };

  const columns = useBreakpointValue({ base: 1, md: 2, lg: 4 });
  const { t, i18n } = useTranslation(['FeatureLog', 'ActionButton']);

  useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisited');
    if (!hasVisited) {
      onOpen();
      localStorage.setItem('hasVisited', 'true');
    }
  }, []);
  return (
    <>
      <ToolbarIcon
        label="AppInfo"
        imagePath="/icons/info.svg"
        onClickHandler={onOpen}
      />
      {isOpen && (<Modal
        isOpen={isOpen}
        onClose={modalCloseHandler}
        size="5xl"
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="sm">{t(`FeatureLog:Header`)}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mt={1}>
              <Box
                pr={1}
                overflowX="auto"
                overflowY="auto"
                sx={{ ...ScrollbarStyles }}
              >
                <SimpleGrid columns={columns} spacing={4}>
                  {renderFeatureSets(featureSets[i18n.language])}
                </SimpleGrid>
              </Box>
            </Box>
            <Box mt={5}>
              <Text fontWeight="600" pb={2} pt={3}>
                {t(`FeatureLog:NoticeTitle`)}
              </Text>
              <Text>{t(`FeatureLog:NoticeContext`)}</Text>
            </Box>
            <Box my={4} w={1}>
              <Link
                href="https://www.schaeffer-ag.de/frontplatten-designer#download"
                isExternal
                variant="fpd-button"
                size="xs"
              >
                {t(`ActionButton:Download`)}
              </Link>
            </Box>
            <Box>
              <List>
                <ListItem>
                  <ListIcon as={CheckIcon} mr={2} mb="3px" />
                  {t(`FeatureLog:CrossPlatform`)}{' '}
                </ListItem>
                <ListItem>
                  <ListIcon as={WorldIcon} mr={2} mb="3px" />
                  {t(`FeatureLog:MultiLangugae`)}
                </ListItem>
              </List>
            </Box>
          </ModalBody>
          <ModalFooter>
            <HStack spacing={3}>
              <ActionButton
                label={t(`ActionButton:Confirm`)}
                onClick={() => {
                  modalCloseHandler();
                }}
              />
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
      )}
    </>
  );
}

export default FeatureLog;
