import React, { useContext, useRef, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  Flex,
  Stack,
  HStack,
  Divider,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { ModalContext } from '../ModalContext';
import DimValueWithUnit from './elements/NumberInputs';
import GroupContainer from './GroupContainer';
import ActionButton from './elements/ActionButton';
import { unitDisplayMap } from '../../../../context/Types';
import { selectionBoxDataProvider } from './selectionBoxDataProvider';
import DialogOption from './elements/DialogOption';
import { ApiContext } from '../../../../context/ApiContext';
import { useTranslation } from 'react-i18next';

const RotationDialog = () => {
  const [selectedUnit, setSelectedUnit] = useState('millimeters');

  const { isRotationModalOpen, setRotationModalOpen } =
    useContext(ModalContext);

  const { rotateSelection } = useContext(ApiContext);
  const { t } = useTranslation(['ActionDialogs', 'ActionButton']);

  const handleClose = () => {
    setSelectedUnit('millimeters');
    setRotationModalOpen(false);
  };

  const rotationDialogStyles = {
    labelW: '60px',
    geoInputsW: '100px',
  };

  return (
    isRotationModalOpen && (
      <Modal
        isOpen={isRotationModalOpen}
        onClose={handleClose}
        size="3xl"
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('ActionDialogs:Rotation.Header')} </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{
                xValue: selectionBoxDataProvider().X,
                yValue: selectionBoxDataProvider().Y,
                lValue: selectionBoxDataProvider().L,
                aValue: selectionBoxDataProvider().A,
                dxValue: '0.00',
                dyValue: '0.00',
                dlValue: '0.00',
                daValue: '0.00',
                rValue: '0.00',
                cValue: false,
              }}
              onSubmit={(values) => {
                values.xValue = `${values.xValue} ${unitDisplayMap[selectedUnit]}`;
                values.yValue = `${values.yValue} ${unitDisplayMap[selectedUnit]}`;
                console.log(+values.rValue);
                if (+values.rValue || values.cValue) {
                  rotateSelection(
                    rotationDataProvider(values, unitDisplayMap[selectedUnit])
                  );
                }

                handleClose();
              }}
            >
              {(formikProps) => (
                <Form noValidate>
                  <GroupContainer
                    title={t('ActionDialogs:Rotation.RotationPoint')}
                  >
                    <Flex>
                      <Box width="50%" pr={2}>
                        <GroupContainer
                          title={t('ActionDialogs:Absolute')}
                          variant="subGroup"
                        >
                          <Stack spacing={3}>
                            <Flex align="center">
                              <DimValueWithUnit
                                label="X"
                                labelW={rotationDialogStyles.labelW}
                                inputW={rotationDialogStyles.geoInputsW}
                                name="xValue"
                                unitOptions={Object.keys(unitDisplayMap).filter(
                                  (unit) =>
                                    unit === 'millimeters' || unit === 'inches'
                                )}
                                defaultUnit={selectedUnit}
                                dependPattern={/^(?:xV|yV|lV|dxV|dyV|dlV)/}
                                onUnitChange={setSelectedUnit}
                              />
                            </Flex>

                            <Flex align="center">
                              <DimValueWithUnit
                                label="Y"
                                labelW={rotationDialogStyles.labelW}
                                inputW={rotationDialogStyles.geoInputsW}
                                name="yValue"
                                defaultUnit={selectedUnit}
                              />
                            </Flex>

                            <Flex>
                              <Divider />
                            </Flex>

                            <Flex align="center">
                              <DimValueWithUnit
                                label="Length"
                                labelW={rotationDialogStyles.labelW}
                                inputW={rotationDialogStyles.geoInputsW}
                                name="lValue"
                                defaultUnit={selectedUnit}
                              />
                            </Flex>

                            <Flex align="center">
                              <DimValueWithUnit
                                label="Angle"
                                labelW={rotationDialogStyles.labelW}
                                inputW={rotationDialogStyles.geoInputsW}
                                name="aValue"
                                defaultUnit="degrees"
                              />
                            </Flex>
                          </Stack>
                        </GroupContainer>
                      </Box>
                      <Box width="50%" pl={2}>
                        <GroupContainer
                          title={t('ActionDialogs:Relative')}
                          variant="subGroup"
                        >
                          <Stack spacing={3}>
                            <Flex align="center">
                              <DimValueWithUnit
                                label="DX"
                                labelW={rotationDialogStyles.labelW}
                                inputW={rotationDialogStyles.geoInputsW}
                                name="dxValue"
                                defaultUnit={selectedUnit}
                              />
                            </Flex>

                            <Flex align="center">
                              <DimValueWithUnit
                                label="DY"
                                labelW={rotationDialogStyles.labelW}
                                inputW={rotationDialogStyles.geoInputsW}
                                name="dyValue"
                                defaultUnit={selectedUnit}
                              />
                            </Flex>

                            <Flex>
                              <Divider />
                            </Flex>

                            <Flex align="center">
                              <DimValueWithUnit
                                label="Length"
                                labelW={rotationDialogStyles.labelW}
                                inputW={rotationDialogStyles.geoInputsW}
                                name="dlValue"
                                defaultUnit={selectedUnit}
                              />
                            </Flex>

                            <Flex align="center">
                              <DimValueWithUnit
                                label="Angle"
                                labelW={rotationDialogStyles.labelW}
                                inputW={rotationDialogStyles.geoInputsW}
                                name="daValue"
                                defaultUnit="degrees"
                              />
                            </Flex>
                          </Stack>{' '}
                        </GroupContainer>
                      </Box>
                    </Flex>
                    <Box width="50%" py={2}>
                      <GroupContainer
                        title={t('ActionDialogs:Rotation:Title')}
                        variant="subGroup"
                      >
                        <Flex align="center">
                          <DimValueWithUnit
                            label="Rotation"
                            labelW={rotationDialogStyles.labelW}
                            inputW={rotationDialogStyles.geoInputsW}
                            name="rValue"
                            defaultUnit="degrees"
                          />
                        </Flex>
                      </GroupContainer>
                    </Box>
                  </GroupContainer>
                  <DialogOption name="cValue">
                    {t('ActionDialogs:ApplyAction')}
                  </DialogOption>
                  <ModalFooter pr="0" pl="0">
                    <HStack spacing={3}>
                      <ActionButton
                        label={t('ActionButton:Cancel')}
                        onClick={() => {
                          handleClose();
                        }}
                      />
                      <ActionButton
                        label={t('ActionButton:Ok')}
                        type="submit"
                        isDisabled={
                          !(
                            +formikProps.values.rValue ||
                            formikProps.values.cValue
                          )
                        }
                      />
                    </HStack>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  );
};

const rotationDataProvider = ({ xValue, yValue, rValue, cValue }, unit) => ({
  absolute_x: parseFloat(xValue),
  absolute_y: parseFloat(yValue),
  rotation_angle: parseFloat(rValue),
  unit: unit,
  make_copy: cValue,
});

export default RotationDialog;
