import React from 'react';
import ReactDOM from 'react-dom';
import * as d3 from 'd3';
import { elementSelector } from '../../../interactions/selection/elementSelector';
import { vertexDragHandler } from '../../../interactions/vertices/vertexDragHandler';
import { vertexReleaseHandler } from '../../../interactions/vertices/vertexReleaseHandler';
import { vertexSquare } from './vertexSquare';

/**
 * This function is responsible for adding vertices objects to the element and
 * setting the start, drag, and release events for them.
 */

function getRotationAngle(matrix) {
  // Calculate the angle in radians
  var angleRadians = Math.atan2(matrix.b, matrix.a);

  // Convert the angle to degrees
  var angleDegrees = angleRadians * (180 / Math.PI);

  return angleDegrees;
}

export const vertexCreator = ({
  vertexId,
  transformVal,
  coordinate,
  cursorType,
  symbolSize,
  frontPanelGp,
  scalability,
  selectedElementIds,
  patchMesh,
}) => {
  const vertexSymbol = d3.symbol().type(d3.symbolSquare).size(symbolSize);

  const vertexInitData = {
    initDashedRectBbox: null,
    inRangeElements: null,
    inRangeElementsInitMatrix: null,
  };
  const controlKeys = {
    isCtrlKey: false,
    isShiftKey: false,
  };
  const controlKeysProvider = (event) => {
    controlKeys.isCtrlKey = event.sourceEvent.ctrlKey;
    controlKeys.isShiftKey = event.sourceEvent.shiftKey;
  };
  const vertexInitDataUpdater = () => {
    vertexInitData.initDashedRectBbox = frontPanelGp
      .select('.BboxRectangle')
      .node()
      .getBBox();
    vertexInitData.inRangeElements = elementSelector(
      frontPanelGp,
      selectedElementIds
    );
    vertexInitData.inRangeElementsInitMatrix = elementSelector(
      frontPanelGp,
      selectedElementIds
    ).reduce((acc, el) => {
      return {
        ...acc,
        [el.getAttribute('fpd.id')]: el.transform.baseVal.consolidate().matrix,
      };
    }, {});
    return vertexInitData;
  };

  /**
   * The event.button property tells us which mouse button was clicked:
   * left (0), middle (1), or right (2).
   */
  function vertexHandle() {
    d3.select(`#${vertexId}`)
      .call(
        d3
          .drag()
          .filter((event) => !event.button) // only allows the drag behavior to occur when the left mouse button is clicked (where event.button is 0).
          .on('start', (event) => {
            controlKeysProvider(event);
            return vertexInitDataUpdater();
          })
          .on('drag', function (event) {
            const self = this;

            return vertexDragHandler(
              self,
              event,
              scalability,
              frontPanelGp,
              vertexInitData,
              controlKeys,
              getRotationAngle
            );
          })
          .on('end', () => {
            const isVertex = d3.selectAll('.vertexHandle').node();
            return isVertex
              ? null
              : vertexReleaseHandler(
                  patchMesh,
                  frontPanelGp,
                  selectedElementIds
                );
          })
      )
      .on('mouseover', function () {
        d3.select(this).style('cursor', cursorType);
      });
  }
  vertexSquare(coordinate, transformVal, vertexId);
  vertexHandle();
};
