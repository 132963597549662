import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  Flex,
  Stack,
  HStack,
  Divider,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { ModalContext } from '../ModalContext';
import DimValueWithUnit from './elements/NumberInputs';
import GroupContainer from './GroupContainer';
import ActionButton from './elements/ActionButton';
import {
  ElementType,
  PlacementType,
  unitDisplayMap,
} from '../../../../context/Types';
import { ApiContext } from '../../../../context/ApiContext';
import { insertNewElement } from '../../../graphic/interactions/frontPanelObjects/element/insertNewElement';
import { useTranslation } from 'react-i18next';

const ManuallyCoordinatesDialog = () => {
  const [selectedUnit, setSelectedUnit] = useState('millimeters');
  const { t } = useTranslation(['ActionDialogs', 'ActionButton']);

  const { Document, interactionState, addNewElementToDoc } =
    useContext(ApiContext);

  const { isCoordinatesModalOpen, setCoordinatesModalOpen } =
    useContext(ModalContext);

  const { setTextEngModalOpen, setTextEngModalData } = useContext(ModalContext);

  const initialCoordinates = { x: '0.00', y: '0.00', l: '0.00', a: '0.00' };
  const coordinateRef = useRef(initialCoordinates);

  useEffect(() => {
    coordinateRef.current = { ...initialCoordinates };
  }, [Document.id]);

  const handleClose = () => {
    setSelectedUnit('millimeters');
    setCoordinatesModalOpen(false);
  };

  const coordinatesDialogStyles = {
    labelW: '60px',
    geoInputsW: '100px',
  };
  const initialValuesObj = {
    xValue: coordinateRef.current.x,
    yValue: coordinateRef.current.y,
    lValue: coordinateRef.current.l,
    aValue: coordinateRef.current.a,
    dxValue: '0.00',
    dyValue: '0.00',
    dlValue: '0.00',
    daValue: '0.00',
    rValue: '0.00',
  };
  return (
    isCoordinatesModalOpen && (
      <Modal
        isOpen={isCoordinatesModalOpen}
        onClose={handleClose}
        size="3xl"
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('ActionDialogs:ReferencePoint.Header')} </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={initialValuesObj}
              onSubmit={(values, { resetForm }) => {
                coordinateRef.current.x = values.xValue;
                coordinateRef.current.y = values.yValue;
                coordinateRef.current.l = values.lValue;
                coordinateRef.current.a = values.aValue;
                values.xValue = `${values.xValue} ${unitDisplayMap[selectedUnit]}`;
                values.yValue = `${values.yValue} ${unitDisplayMap[selectedUnit]}`;

                if (interactionState !== ElementType.EngravingText) {
                  insertNewElement({
                    placementName: PlacementType.POSITION,
                    value: [
                      parseFloat(coordinateRef.current.x),
                      parseFloat(coordinateRef.current.y),
                    ],
                    addNewElementToDoc: addNewElementToDoc,
                    interactionState: interactionState,
                  });
                } else {
                  setTextEngModalData({
                    value: [
                      1,
                      0,
                      0,
                      1,
                      parseFloat(coordinateRef.current.x),
                      parseFloat(coordinateRef.current.y),
                    ],
                  });
                  setTextEngModalOpen(true);
                }

                resetForm(initialValuesObj);
                handleClose();
              }}
            >
              {() => (
                <Form noValidate>
                  <Box width="100%">
                    <Flex>
                      <Box width="55%" pr={2}>
                        <GroupContainer title={t('ActionDialogs:Absolute')}>
                          <Stack spacing={3}>
                            <Flex align="center">
                              <DimValueWithUnit
                                label="X"
                                labelW={coordinatesDialogStyles.labelW}
                                inputW={coordinatesDialogStyles.geoInputsW}
                                name="xValue"
                                unitOptions={Object.keys(unitDisplayMap).filter(
                                  (unit) =>
                                    unit === 'millimeters' || unit === 'inches'
                                )}
                                defaultUnit={selectedUnit}
                                dependPattern={/^(?:xV|yV|lV|dxV|dyV|dlV)/}
                                onUnitChange={setSelectedUnit}
                              />
                            </Flex>

                            <Flex align="center">
                              <DimValueWithUnit
                                label="Y"
                                labelW={coordinatesDialogStyles.labelW}
                                inputW={coordinatesDialogStyles.geoInputsW}
                                name="yValue"
                                defaultUnit={selectedUnit}
                              />
                            </Flex>

                            <Flex>
                              <Divider />
                            </Flex>

                            <Flex align="center">
                              <DimValueWithUnit
                                label="Length"
                                labelW={coordinatesDialogStyles.labelW}
                                inputW={coordinatesDialogStyles.geoInputsW}
                                name="lValue"
                                defaultUnit={selectedUnit}
                              />
                            </Flex>

                            <Flex align="center">
                              <DimValueWithUnit
                                label="Angle"
                                labelW={coordinatesDialogStyles.labelW}
                                inputW={coordinatesDialogStyles.geoInputsW}
                                name="aValue"
                                defaultUnit="degrees"
                              />
                            </Flex>
                          </Stack>
                        </GroupContainer>
                      </Box>
                      <Box width="45%" pl={2}>
                        <GroupContainer title={t('ActionDialogs:Relative')}>
                          <Stack spacing={3}>
                            <Flex align="center">
                              <DimValueWithUnit
                                label="DX"
                                labelW={coordinatesDialogStyles.labelW}
                                inputW={coordinatesDialogStyles.geoInputsW}
                                name="dxValue"
                                defaultUnit={selectedUnit}
                              />
                            </Flex>

                            <Flex align="center">
                              <DimValueWithUnit
                                label="DY"
                                labelW={coordinatesDialogStyles.labelW}
                                inputW={coordinatesDialogStyles.geoInputsW}
                                name="dyValue"
                                defaultUnit={selectedUnit}
                              />
                            </Flex>

                            <Flex>
                              <Divider />
                            </Flex>

                            <Flex align="center">
                              <DimValueWithUnit
                                label="Length"
                                labelW={coordinatesDialogStyles.labelW}
                                inputW={coordinatesDialogStyles.geoInputsW}
                                name="dlValue"
                                defaultUnit={selectedUnit}
                              />
                            </Flex>

                            <Flex align="center">
                              <DimValueWithUnit
                                label="Angle"
                                labelW={coordinatesDialogStyles.labelW}
                                inputW={coordinatesDialogStyles.geoInputsW}
                                name="daValue"
                                defaultUnit="degrees"
                              />
                            </Flex>
                          </Stack>{' '}
                        </GroupContainer>
                      </Box>
                    </Flex>
                  </Box>
                  <ModalFooter pr="0" pl="0">
                    <HStack spacing={3}>
                      <ActionButton
                        label={t('ActionButton:Cancel')}
                        onClick={() => {
                          handleClose();
                        }}
                      />
                      <ActionButton
                        label={t('ActionButton:Ok')}
                        type="submit"
                      />
                    </HStack>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  );
};

export default ManuallyCoordinatesDialog;
