// Built-in
import React, { useState } from 'react';
// External
import {
  IconButton,
  Image,
  Tooltip,
  useMultiStyleConfig,
} from '@chakra-ui/react'; // Importing necessary objects from '@chakra-ui' library for rendering the UI.
import { useTranslation } from 'react-i18next';

export const ToolbarIcon = ({
  label,
  imagePath,
  isDisabled,
  onClickHandler,
  isSelected,
}) => {
  const styles = useMultiStyleConfig('ToolbarIcon');
  const { t } = useTranslation('ToolbarIcons');
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <Tooltip
      isOpen={showTooltip}
      gutter={10}
      onClose={() => setShowTooltip(false)}
      label={t(`${label}`)}
    >
      <IconButton
        sx={isSelected ? styles.hovered : styles.button}
        isDisabled={isDisabled}
        icon={<Image src={imagePath} sx={styles.iconImage} />}
        onMouseOver={() => setShowTooltip(true)}
        onMouseOut={() => setShowTooltip(false)}
        onClick={onClickHandler}
      />
    </Tooltip>
  );
};
