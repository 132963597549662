/**
 * 'TreeView' component, Displays a list of all panels from the given storage data as a tree view
 */

// Built-in
import React, { useState, useMemo, useEffect } from 'react';

// External
import Tree from 'rc-tree';
import { Box } from '@chakra-ui/react';

// Internal
import { TreeViewStyles } from '../../theme/components/TreeViewStyles';
const FpdIcon = '/icons/icon-fpd.svg';

export const TreeView = ({
  userFilesStorage,
  setSelectedNode,
  selectedListNode,
}) => {
  const [selectedNodeKeys, setSelectedNodeKeys] = useState(null);

  const getAllKeys = (data) => {
    let keys = [];
    const traverse = (nodes) => {
      nodes.forEach((node) => {
        keys.push(node.node_id);
        if (node.children) {
          traverse(node.children);
        }
      });
    };
    traverse([data]);
    return keys;
  };

  const defaultExpandedKeys = useMemo(
    () => getAllKeys(userFilesStorage),
    [userFilesStorage]
  );

  const nodePropFinder = (nodeData) => {
    const findNodeByKey = (data, key) => {
      for (const item of data) {
        if (item.node_id === key) {
          return item.type === 'FOLDER' ? item.children : [item];
        }
        if (item.children) {
          const found = findNodeByKey(item.children, key);
          if (found) return found;
        }
      }
      return undefined;
    };
    return findNodeByKey([userFilesStorage], +nodeData.key);
  };

  const onSelect = (selectedKeys, e) => {
    const nodeData = e.node;
    setSelectedNodeKeys(selectedKeys);
    //if the tree nodes selection is updated, we need to reflect this update to the List
    const children = nodePropFinder(nodeData);
    if (children) setSelectedNode(children);
  };

  //If list folder selection is updated, we update the selected node in tree to reflect the slection
  useEffect(() => {
    if (!selectedListNode) return;
    setSelectedNodeKeys([selectedListNode.toString()]);
  }, [selectedListNode]);

  const formatTreeData = (data) =>
    data.map(({ node_id: key, name: title, children, fpd, type }) => {
      // Determine if the current node is a folder or a file
      const isLeaf = !children || children.length === 0;

      return {
        key,
        title: title || (fpd && fpd['filename']) || 'Unnamed Node',
        isLeaf: type !== 'FOLDER' || isLeaf,
        children: !isLeaf ? formatTreeData(children) : null,
        icon: isLeaf && (
          <img src={FpdIcon} alt="FP Icon" style={{ width: 16, height: 16 }} />
        ),
      };
    });

  const treeData = [
    {
      key: userFilesStorage.node_id,
      title: 'Data',
      isLeaf: !userFilesStorage.children,
      children: formatTreeData(userFilesStorage.children) ?? [],
    },
  ];

  return (
    <Box sx={{ ...TreeViewStyles }}>
      <Tree
        defaultExpandedKeys={defaultExpandedKeys}
        showLine
        dropLine
        onSelect={onSelect}
        selectedKeys={selectedNodeKeys || []}
        treeData={treeData}
      />
    </Box>
  );
};

export default TreeView;
