/**
 * The 'CurvedSlotCreator' component,manages the action of inserting a 'curved slot'.
 */

// Built-in
import React, { useContext } from 'react';

// Internal
import { ElementType } from '../../context/Types';
import { ApiContext } from '../../context/ApiContext'; // Provides API context (Global shared state) to the component

import { ToolbarIcon } from './ToolbarIcon';

function CurvedSlotCreator() {
  const { Document, interactionState, setIntractionState } =
    useContext(ApiContext);

  const curvedSlotButtonClickHandler = () => {
    setIntractionState(ElementType.CurvedSlot);
  };

  const isSelected = interactionState === ElementType.CurvedSlot;

  return (
    <ToolbarIcon
      label={ElementType.CurvedSlot}
      imagePath="/icons/curved-slot.svg"
      onClickHandler={curvedSlotButtonClickHandler}
      isDisabled={!Document.id}
      isSelected={isSelected}
    />
  );
}

export default CurvedSlotCreator;
