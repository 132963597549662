import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  Stack,
  useDisclosure,
  createStylesContext,
  useMultiStyleConfig,
  Flex,
  FormLabel,
  useToast,
  HStack,
  Link,
  VStack,
  RadioGroup,
  Box,
} from '@chakra-ui/react';

import { Formik, Form, Field } from 'formik';

import * as Yup from 'yup';

import { ApiContext } from '../../../../context/ApiContext';
import GroupContainer from './GroupContainer';
import ActionButton from './elements/ActionButton';
import { useTranslation } from 'react-i18next';
import CustomRadio from './elements/CustomRadio';
import { CustomerType } from '../../../../context/Types';
import { useAuth } from '../../../../context/AuthContext';
import ShowPasswordButton from './elements/ShowPasswordButton';

const [StylesProvider, useStyles] = createStylesContext('Login');

const LoginItemField = (props) => {
  const {error, touched, name, type, ...rest} = props;
  const styles = useStyles();

  return (
    <Field 
      sx={
        error && touched
          ? styles.inputError
          : styles.input
      }
      name={name}
      type={type}
      as={Input}
      {...rest}
    />
  );
};

const LoginItem = (props) => {
  const {size, variant, children, ...rest} = props;
  const styles = useMultiStyleConfig('Login', { size, variant });

  return (
    <Flex __css={styles.container} {...rest}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </Flex>
  )
};

//TODO:The messages not display yet, we used for clearance of actions
const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters long'),
});

const registrationValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  confirmEmail: Yup.string()
    .oneOf([Yup.ref('email'), null], 'Emails must match')
    .email('Invalid confirmed email format')
    .required('Confirmed email is required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters long')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

const LoginDialoge = () => {
  const { isLoading, loginDialog, setLoginDialog } = useContext(ApiContext);
  const {
    actions: { login, register },
  } = useAuth();

  const { onClose } = useDisclosure();
  const [isRegistering, setIsRegistering] = useState();
  const [validationSchema, setValidationSchema] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const formikRef = useRef(null);

  const toast = useToast();
  const { t } = useTranslation(['Login', 'Register', 'ActionButton']);

  useEffect(() => {
    setValidationSchema(
      isRegistering ? registrationValidationSchema : loginValidationSchema
    );
  }, [isRegistering]);

  useEffect(() => {
    setIsRegistering(loginDialog.dialogMode === 'register');
  }, [loginDialog.dialogMode]);

  const handleClose = () => {
    setLoginDialog((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
    onClose();
  };

  const toggleRegistering = () => {
    setIsRegistering(!isRegistering);
    setTimeout(() => {
      formikRef.current?.validateForm();
    });
  };

  // Initial values
  const initialValues = {
    email: '',
    confirmEmail: '',
    password: '',
    confirmPassword: '',
    customer: CustomerType.PRIVATE,
  };

  // Handle form submission
  const onSubmit = async (values) => {
    toast.closeAll();

    try {
      if (isRegistering) {
        const { email, password, customer } = values;
        const userData = {
          isBusinessUser: customer === CustomerType.BUSINESS,
          email,
          password,
        };
        await register(userData);
      } else {
        await login(values);
      }
      handleClose();
    } catch (error) {
      console.error('Error during form submission:', error);
    }
  };

  const handleRadioChange = (value, setFieldValue) => {
    setFieldValue('customer', value);
    formikRef.current?.validateForm();
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Modal
        isOpen={loginDialog.isOpen}
        onClose={handleClose}
        size="sm"
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {isRegistering ? t('Register:Header') : t('Login:Header')}{' '}
          </ModalHeader>
          <ModalCloseButton />
          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnMount={true}
            onSubmit={onSubmit}
          >
            {({
              isSubmitting,
              isValid,
              dirty,
              errors,
              touched,
              setFieldValue,
              values,
            }) => (
              <Form>
                <ModalBody>
                  <GroupContainer
                    title={
                      isRegistering
                        ? t('Register:GroupHeader')
                        : t('Login:GroupHeader')
                    }
                  >
                    {isRegistering ? (
                      <VStack spacing={3}>
                        <LoginItem id="email">
                          <FormLabel htmlFor="email">
                            {t('Register:Email')}
                          </FormLabel>
                          <LoginItemField
                            error={errors.email}
                            touched={touched.email}
                            name="email"
                          />
                        </LoginItem>
                        <LoginItem id="confirmEmail">
                          <FormLabel htmlFor="confirmEmail">
                            {t('Register:ConfirmEmail')}
                          </FormLabel>
                          <LoginItemField
                            error={errors.confirmEmail}
                            touched={touched.confirmEmail}
                            name="confirmEmail"
                          />
                        </LoginItem>
                        <LoginItem id="password" >
                          <FormLabel htmlFor="password">
                            {t('Register:Password')}
                          </FormLabel>
                          <LoginItemField
                            error={errors.password}
                            touched={touched.password}
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                          />
                        </LoginItem>
                        <LoginItem id="confirmPassword">
                          <FormLabel
                            htmlFor="confirmPassword"
                          >
                            {t('Register:ConfirmPassword')}
                          </FormLabel>
                          <Box pos={'relative'}>
                            <LoginItemField
                              error={errors.confirmPassword}
                              touched={touched.confirmPassword}
                              name="confirmPassword"
                              type={showPassword ? 'text' : 'password'}
                            />
                            <ShowPasswordButton
                              showPassword={showPassword}
                              onClick={handleShowPassword}
                            />
                          </Box>
                        </LoginItem>
                        <RadioGroup w="15rem">
                          <Stack spacing={2}>
                            <CustomRadio
                              isChecked={
                                values.customer === CustomerType.PRIVATE
                              }
                              onChange={() => {
                                handleRadioChange(
                                  CustomerType.PRIVATE,
                                  setFieldValue
                                );
                              }}
                              name="customer"
                              value={CustomerType.PRIVATE}
                            >
                              {t('Register:PrivateCustomer')}
                            </CustomRadio>

                            <CustomRadio
                              isChecked={
                                values.customer === CustomerType.BUSINESS
                              }
                              onChange={() => {
                                handleRadioChange(
                                  CustomerType.BUSINESS,
                                  setFieldValue
                                );
                              }}
                              name="customer"
                              value={CustomerType.BUSINESS}
                            >
                              {t('Register:BusinessCustomer')}
                            </CustomRadio>
                          </Stack>
                        </RadioGroup>
                      </VStack>
                    ) : (
                      <VStack spacing={3}>
                        <LoginItem id="email">
                          <FormLabel htmlFor="email">
                            {t('Login:Email')}
                          </FormLabel>
                          <LoginItemField
                            name="email"
                            error={errors.email}
                            touched={touched.email}
                          />
                        </LoginItem>
                        <LoginItem id="password">
                          <FormLabel htmlFor="password">
                            {t('Login:Password')}
                          </FormLabel>
                          <Box pos={'relative'}>
                            <LoginItemField
                              error={errors.password}
                              touched={touched.password}
                              name="password"
                              type={showPassword ? 'text' : 'password'}
                            />
                            <ShowPasswordButton
                              showPassword={showPassword}
                              onClick={handleShowPassword}
                            />
                          </Box>
                        </LoginItem>
                      </VStack>
                    )}
                  </GroupContainer>
                </ModalBody>

                <ModalFooter>
                  <HStack justify="space-between" w="100%">
                    <Link textDecor="underline" onClick={toggleRegistering}>
                      {' '}
                      {isRegistering
                        ? `${t('Login:LogLink')} !`
                        : `${t('Login:RegLink')} !`}
                    </Link>
                    <ActionButton
                      type="submit"
                      label={
                        isRegistering
                          ? t('ActionButton:Register')
                          : t('ActionButton:Login')
                      }
                      isLoading={isLoading}
                      isDisabled={isSubmitting || !isValid}
                    />
                  </HStack>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LoginDialoge;
