/**
 * The 'PrintGraphic' component, manages the action of preview and inserting a 'print Graphic and pdf' elements.
 */

// Built-in
import React, { useContext } from 'react';

// Internal
import { ElementType } from '../../context/Types';
import { ApiContext } from '../../context/ApiContext';

import { ToolbarIcon } from './ToolbarIcon';

import { useTranslation } from 'react-i18next';

function PrintGraphic() {
  const { Document, interactionState, setIntractionState } =
    useContext(ApiContext);

  const printGraphicClickHandler = () => {
    setIntractionState(ElementType.PrintGraphic);
  };
  const isSelected = interactionState === ElementType.PrintGraphic;

  const { t } = useTranslation(['Tooltip']);

  return (
    <ToolbarIcon
      label={t('Tooltip:PrintGraphic')}
      imagePath="/icons/print_Graphic.svg"
      onClickHandler={printGraphicClickHandler}
      isDisabled={!Document.id}
      isSelected={isSelected}
    />
  );
}

export default PrintGraphic;
