/**
 *If the pointer position is within the bounding box area,
 *'isPointerInBBox' returns true.
 */
export const isPointerInBBox = function (
  { x: pointerX, y: pointerY, sourceEvent },
  frontPanelGp
) {
  if (frontPanelGp.select('.BboxRectangle').empty()) return false;
  const { x, y, width, height, right, bottom } =
    sourceEvent.target.nodeName === 'svg'
      ? frontPanelGp.select('.BboxRectangle').node().getBoundingClientRect()
      : frontPanelGp.select('.BboxRectangle').node().getBBox();
  return sourceEvent.target.nodeName === 'svg'
    ? pointerX > x &&
        pointerX < right &&
        sourceEvent.y > y &&
        sourceEvent.y < bottom
    : pointerX > x &&
        pointerX < x + width &&
        pointerY > y &&
        pointerY < y + height;
};
