import { stepSequence, CONVERSION_CONSTANTS } from '../../../../context/Types';

const roundToTwoDecimal = (value) => value.toFixed(2);

const getX = (box) => box.x.baseVal.value + box.width.baseVal.value / 2;
const getY = (box) => box.y.baseVal.value + box.height.baseVal.value / 2;
const getWidth = (box) => box.width.baseVal.value;
const getHeight = (box) => box.height.baseVal.value;

const getTL = (box) => {
  return {
    X: roundToTwoDecimal(box.x.baseVal.value),
    Y: roundToTwoDecimal(box.y.baseVal.value + box.height.baseVal.value),
  };
};

const getBR = (box) => {
  return {
    X: roundToTwoDecimal(box.x.baseVal.value + box.width.baseVal.value),
    Y: roundToTwoDecimal(box.y.baseVal.value),
  };
};

export const selectionBoxDataProvider = () => {
  const selectionBox = document.querySelector('.BboxRectangle');
  const isFreeScaled = !!document.querySelector('#sm-resize');
  return {
    X: roundToTwoDecimal(getX(selectionBox)),
    Y: roundToTwoDecimal(getY(selectionBox)),
    W: roundToTwoDecimal(getWidth(selectionBox)),
    H: roundToTwoDecimal(getHeight(selectionBox)),
    L: roundToTwoDecimal(getLength(getX(selectionBox), getY(selectionBox))),
    A: roundToTwoDecimal(getAngle(getX(selectionBox), getY(selectionBox))),
    TL: getTL(selectionBox),
    BR: getBR(selectionBox),
    isFreeScaled: isFreeScaled,
  };
};

export const getLength = (x, y) => {
  const length = Math.sqrt(x * x + y * y);
  return length;
};

export const getAngle = (x, y) => {
  if (y != 0.0) {
    if (x != 0.0) {
      if (x < 0.0) return 180.0 - Math.abs(Math.atan(y / x) * (180 / Math.PI));
      else return Math.atan(y / x) * (180 / Math.PI);
    } else {
      if (y < 0.0) return -90.0;
      else return 90.0;
    }
  } else {
    if (x < 0.0) return 180.0;
    else return 0.0;
  }
};

export const getPoint = (length, angle) => {
  const y = Math.sin((angle * Math.PI) / 180) * length;
  const x = Math.cos((angle * Math.PI) / 180) * length;

  return { x, y };
};

export const getPanelCenter = () => {
  const { width, height } = document.querySelector('.panel').getBBox();
  return { X: width / 2, Y: height / 2 };
};

// Converts between units based on a given factor.

export const unitConvertor = {
  millimeters: (value) => value * CONVERSION_CONSTANTS.INCH_TO_MM,
  inches: (value) => value * CONVERSION_CONSTANTS.MM_TO_INCH,
};

/**
 * In our user experience design, we've implemented a dynamic step-size provider
 * function for numerical inputs. This function adjusts the step size based on
 * the magnitude of the input value and its unit of measurement,
 * be it 'millimeters' or 'inches'. Each unit has its own unique set of rules
 * to determine the appropriate step size.
 * For 'millimeters':
 *
 * if(num>=0 && num<10)  {step=0.5}
 * if(num>=10 && num<50)  {step=1}
 * if(num>=50 && num<100)  {step=5}
 * if(num>=100 && num<500)  {ste=10}
 * ...
 *
 * For 'inches':
 * if(num>=0 && num<0.5)  {step=0.01}
 * if(num>=0.5 && num<1.0)  {step=0.05}
 * if(num>=1 && num<5)  {step=0.10}
 * if(num>=5 && num<10)  {step=0.50}
 *
 * While it's possible to generate the sequence using a formula,
 * we've opted to use a constant array for the step sequence to
 * avoid additional computations.
 *  const stepSequence = {
 * [Units.INCHES]: [0.01, 0.05, 0.1, 0.5, 1, 5, 10],
 * [Units.MILLIMETERS]: [0.5, 1, 5, 10, 50, 100, 500],}
 *
 * and the function for generate above sequence is :
 * function getSequenceValue(n) {
 * if (n <= 0) return null;  // Invalid, as the sequence starts from index 1.
 *
 * let value = 0.5;
 *
 * for (let i = 1; i < n; i++) {
 *   if (i % 2 === 0) {
 *     value *= 5;
 *   } else {
 *     value *= 2;
 *   }
 * }
 *
 * return value;
 *}
 */

export const getStepSize = (num, unit) => {
  if (num < 0) return;
  const stepsRange = stepSequence[unit];
  const val = num / 10;
  for (let i = stepsRange.length - 1; i >= 0; i--) {
    if (stepsRange[i] <= val) {
      return stepsRange[i];
    }
  }
  return stepsRange[0];
};
