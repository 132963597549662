import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Flex,
  useStyleConfig,
} from '@chakra-ui/react';
import { DropDownIcon } from '../assets/IconsSvg';

const CustomSelect = ({
  value,
  options,
  onChange,
  dropDownWidth,
  dropDownHeight,
  textAlign,
}) => {
  const [selectedOption, setSelectedOption] = useState(value);
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef(null);
  const itemRefs = useRef([]);

  const styles = useStyleConfig('CustomSelect');

  useEffect(() => {
    if (isOpen && itemRefs.current[selectedOption]) {
      window.requestAnimationFrame(() => {
        itemRefs.current[selectedOption].focus();
      });
    }
  }, [isOpen, selectedOption]);

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  const handleSelect = (option) => {
    setIsOpen(false); // Close the dropdown

    if (option === value) return;
    setSelectedOption(option);
    if (onChange) {
      onChange(option);
    }
  };

  return (
    <Popover
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      placement="bottom-start"
    >
      <PopoverTrigger>
        <Box
          w={dropDownWidth}
          h={dropDownHeight}
          as={Button}
          __css={styles.dropDown}
          ref={triggerRef}
          iconspacing={9}
          cursor="pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          <Flex
            justify="space-between"
            align="center"
            width="100%"
            height="100%"
          >
            <Box textAlign={textAlign} __css={styles.text}>
              {selectedOption}
            </Box>
            <Box
              width="20px"
              height="110%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <DropDownIcon width="100%" height="100%" />
            </Box>
          </Flex>
        </Box>
      </PopoverTrigger>
      <PopoverContent minW={dropDownWidth}>
        {Array.isArray(options) &&
          options.map((option, idx) => (
            <Box
              key={`${option}-${idx}`}
              tabIndex={0} // Make the Box focusable
              ref={(el) => (itemRefs.current[option] = el)}
              value={option}
              __css={styles.option}
              onClick={() => handleSelect(option)}
              onMouseEnter={(e) => {
                itemRefs.current[option] = e.target.innerText;
                setSelectedOption(e.target.innerText);
              }}
              onMouseLeave={(e) => {
                if (option === value) return;
                itemRefs.current[option] = value;
                setSelectedOption(value);
              }}
            >
              {option}
            </Box>
          ))}
      </PopoverContent>
    </Popover>
  );
};

export default CustomSelect;
