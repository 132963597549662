/**
 * movingScaleBoxRenderer is responsible for rendering moving-selection-box
 */
export const movingScaleBoxRenderer = (frontPanelGp, box) => {
  frontPanelGp
    .select('#dashedBBox')
    .attr(
      'd',
      `M ${box.x.baseVal.value} ${box.y.baseVal.value} h ${
        box.width.baseVal.value
      } v ${box.height.baseVal.value} h ${-box.width.baseVal.value} v ${-box
        .height.baseVal.value} Z`
    );
};
