// -------------------[Documents]-------------------\
/**
 * Methods related to Document Object
 */

import { API_BASE_URL } from '../Client';

const Documents = {
  /**
   * Get [`Document`] by remote id
   */
  get: (doc_id) => ({
    method: 'GET',
    url: `${API_BASE_URL}/docs_remote/${doc_id}`,
    headers: { accept: 'application/json' },
  }),

  /**
   * Update [`Document`]
   */
  update: (data) => ({
    method: 'POST',
    url: `${API_BASE_URL}/docs/${data.id}`,
    headers: { accept: 'application/json' },
    data: data,
  }),
  /**
   * Delete [`Document`]
   */

  delete: (doc_id) => ({
    method: 'DELETE',
    url: `${API_BASE_URL}/docs/${doc_id}`,
  }),

  /**
   * Get all available [`Document`]s
   */
  getAll: () => ({
    method: 'GET',
    url: `${API_BASE_URL}/docs`,
    headers: { accept: 'application/json' },
  }),

  /**
   * Upload [`Document`]
   */
  upload: (fileContent, fileName, fileDescreption) => {
    const formData = new FormData();
    formData.append(
      'file',
      new Blob([fileContent], { type: 'application/octet-stream' })
    );
    formData.append('name', fileName);
    formData.append('description', fileDescreption);
    return {
      method: 'POST',
      url: `${API_BASE_URL}/docs/upload`,
      headers: {
        'Content-Type': `multipart/form-data`,
      },
      data: formData,
    };
  },
  /**
   * Create new [`Document`]
   */
  new: (documentData) => ({
    method: 'POST',
    url: `${API_BASE_URL}/docs/new`,
    headers: { accept: 'application/json' },
    data: documentData,
  }),
  /**
   * Download .fpd file of a [Document]
   */
  download: (doc_id, version_id) => ({
    method: 'GET',
    url: `${API_BASE_URL}/docs/${doc_id}/versions/${version_id}/download`,
    headers: {
      Accept: 'application/octet-stream', // Set the Accept header to indicate binary data
    },
    responseType: 'blob', // Set the responseType to 'blob' to indicate binary data
  }),
  /**
   *get a price calculation
   */
  price: (doc_id) => ({
    method: 'GET',
    url: `${API_BASE_URL}/docs/${doc_id}/price`,
    headers: {
      Accept: 'text/html', // Set the Accept header to raw html
    },
  }),

  synchronize: (doc_id) => ({
    method: 'POST',
    url: `${API_BASE_URL}/docs/${doc_id}/docs_synchronize`,
    headers: {
      accept: 'application/json',
    },
  }),
};
export default Documents;
