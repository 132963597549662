import categories_en_US from '../../data/catergories/en_US.json';
import categories_de_DE from '../../data/catergories/de_DE.json';
import models_en_US from '../../data/model/en_US.json';
import models_de_DE from '../../data/model/de_DE.json';

/**
 * Returns the category that contains properties from the given 'memberProps'.
 */

const catPropFilter = (membersProps, lang) => {
  const categories = {
    en_US: categories_en_US,
    de_DE: categories_de_DE,
  };

  const models = {
    en_US: models_en_US,
    de_DE: models_de_DE,
  };
  return categories[lang]['categories'].filter(({ properties }) =>
    properties.some((catProp) => {
      return models[lang]['models'][catProp]
        ? models[lang]['models'][catProp].properties.some(
            (modelProp) => membersProps[modelProp]
          )
        : Object.keys(membersProps).some((memberProp) =>
            RegExp(`^${catProp}`, 'g').test(memberProp)
          );
    })
  );
};

export default catPropFilter;
