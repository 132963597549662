/**
 * The 'PriceCreator' component, manages the action of calculating the price'.
 */

// Built-in
import React, { useContext, useRef } from 'react';

// External
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  // ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Grid,
} from '@chakra-ui/react'; // Importing necessary objects from '@chakra-ui' library for rendering the UI.

import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';

// Internal
import { ApiContext } from '../../context/ApiContext'; // Provides API context (Global shared state) to the component

import { ScrollbarStyles } from '../../theme/components';
import { ToolbarIcon } from './ToolbarIcon';

function PriceCreator() {
  const { Document, getPrice, priceData } = useContext(ApiContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef();
  const finalRef = useRef();
  const { t } = useTranslation('PricingPanel');

  const priceButtonClickHandler = () => {
    getPrice(Document.id);
    onOpen();
  };

  const modalCloseHandler = () => {
    onClose();
  };

  d3.selectAll('.ham-context').remove();

  return (
    <>
      <ToolbarIcon
        label="Pricing"
        imagePath="/icons/Calculator.svg"
        onClickHandler={priceButtonClickHandler}
        isDisabled={!Document.id}
      />
      {isOpen && (<Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={modalCloseHandler}
        size="5xl"
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader pl="2.25rem">{t('PricingPanel:title')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid h="70vh" w gap={3}>
              <Box
                h="100%"
                px="0.75rem"
                overflowX={'auto'}
                overflowY={'auto'}
                sx={{
                  ...ScrollbarStyles,
                  table: {
                    borderCollapse: 'collapse',
                    width: '100%', // Ensure table takes full width
                  },
                  'table, td, th': {
                    border: '1px solid black',
                  },
                  p: {
                    marginBottom: '1rem',
                  },
                }}
                dangerouslySetInnerHTML={{ __html: priceData }}
              />
            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>
      )}
    </>
  );
}

export default PriceCreator;
