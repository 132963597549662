import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const MainLayoutAnatomy = ['mainGrid', 'svgContainer', 'propContainer'];

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(MainLayoutAnatomy);

const baseStyle = definePartsStyle({
  mainGrid: {
    overflow: 'hidden',
    h: '100vh',
    gap: '2px',
  },
  svgContainer: {
    tabIndex: 0,
    overflow: 'hidden',
    bg: 'fpd.700',
  },

  propContainer: {
    ml: '-2px',
    minWidth: '23.87rem',
    width: '20vw',
    zIndex: 0,
    bg: 'fpd.600',
    borderLeft: '1px solid',
    borderColor: 'fpd.800',
    boxShadow: '0 1px 0 0 var(--chakra-colors-fpd-500)',
  },
});

export const MainLayoutStyles = defineMultiStyleConfig({ baseStyle });
