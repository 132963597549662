import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  Box,
  NumberIncrementStepper,
  NumberInputStepper,
  NumberInputField,
  NumberInput,
  NumberDecrementStepper,
  Text,
  Image as ChakraImage,
  HStack,
  useToast,
  VStack,
  Flex,
} from '@chakra-ui/react';

import { ApiContext } from '../../../../../context/ApiContext';
import { ModalContext } from '../../ModalContext';
import ActionButton from '../elements/ActionButton';
import { useTranslation } from 'react-i18next';
import PDFPageToImage from './PDFPageToImage';
import { debounce } from 'lodash';
import { FPD_LIMITS } from '../../../../../api/Client';
import { useDropzone } from 'react-dropzone';
import { formatFileSize } from '../../../FileUploadContainer';
import { ElementType, SelectModeType } from '../../../../../context/Types';
import ImgCard from './ImageCard';
import { ScrollbarStyles } from '../../../../../theme/components';

const FallbackPreview = '/image-fallback.svg';

const PrintGraphicDialog = () => {
  const fileContext = {
    fileName: '', // Stores the name of the selected file
    fileType: '', // Specifies the type or format of the file (PDF, png, jpg, ...)
    fileRawContent: '', // Holds the complete file data(blob), including all pages if its a PDF.
    fileBase64Content: '', // Contains the Base64-encoded content of a selected PDF page or Image data for fpserver upload, (used for display only for pdf)
    fileSinglePageB64Content: '', // Stores the Base64-encoded data of a selected PDF page, prepared for fpserver upload from session storage
    fileSize: '', // Indicates the size of the file
  };

  const mimeTypeMapping = {
    imageAndPdf: {
      'application/pdf': ['.pdf'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/bmp': ['.bmp'],
    },
    hpgl: {
      'application/vnd.hp-hpgl': ['.plt'],
    },
  };
  const FILE_SOURCE_STORED = 'stored';
  const FILE_SOURCE_UPLOAD = 'upload';
  // Contexts
  const { PrintGraphicModalState, setPrintGraphicModalState } =
    useContext(ModalContext);
  const { addNewElementToDoc, interactionState, setIntractionState } =
    useContext(ApiContext);
  const { t } = useTranslation(['printGraphic', 'ActionButton', 'Upload']);
  const toast = useToast();

  // State management
  const [file, setFile] = useState(fileContext);
  const [pageNumber, setPageNumber] = useState(1);
  const [dimensions, setDimensions] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const [isDragActive, setIsDragActive] = useState(false);
  const [fileSelectionSource, setFileSelectionSource] =
    useState(FILE_SOURCE_STORED);
  const [isStorageDisplayProcessing, setStorageDisplayProcessing] =
    useState(true);

  // Refs
  const numberInputRef = useRef(null);

  const showFileSizeHint = () => {
    const fileSizeLimitMB = (FPD_LIMITS / (1024 * 1024)).toFixed(2);
    toast({
      title: t('printGraphic:FileSizeHint:Title'),
      description: `${t(
        'printGraphic:FileSizeHint:Body'
      )} ${fileSizeLimitMB} MB.`,
      status: 'info',
      duration: 2500,
      isClosable: true,
    });
  };
  const updateFileContext = (file, content, imageData) => {
    const fileName = file.replace(/\([^\)]*\)\.\w+$/, '');
    const fileType = file.split('.')[1];

    fileType === 'pdf'
      ? setFile((prevState) => ({
          ...prevState,
          fileName,
          fileType,
          fileSinglePageB64Content: content,
          fileBase64Content: imageData,
        }))
      : setFile((prevState) => ({
          ...prevState,
          fileName,
          fileType,
          fileBase64Content: content,
          fileSinglePageB64Content: '',
        }));
    setFileSelectionSource(FILE_SOURCE_STORED);
  };
  const updateFileFromSessionStorage = () => {
    const sessionFileContent = sessionStorage[GraphicDataStored?.[0]];
    if (sessionFileContent) {
      updateFileContext(sessionStorage.key(0), sessionFileContent);
    }
  };
  const isFileExistingInSession = (name) =>
    Object.keys(sessionStorage).includes(name);

  useEffect(() => {
    if (!PrintGraphicModalState.isOpen) {
      setFile(() => fileContext);
      return;
    }
    showFileSizeHint();
    /**TODO:
     * Currently, we display a stored graphic image as a preview if
     * it exists. In the future,when we implement in FPserver, we will
     * need to upgrade this functionality
     * to support HPGL format for the preview as well.
     */
    if (interactionState !== ElementType.PrintGraphic) return;

    if (shouldShowSidebar()) updateFileFromSessionStorage();
  }, [PrintGraphicModalState.isOpen]);

  const handleFileChange = (acceptedFile) => {
    const { name, size, type } = acceptedFile;
    const fileName = getFileNameWithoutExtension(name);

    if (isFileExistingInSession(name)) {
      toast({
        title: t('printGraphic:FileExistHint:Title'),
        description: `${t('printGraphic:FileExistHint:Body')} .`,
        status: 'warning',
        duration: 2500,
        isClosable: true,
      });
      return;
    }
    setFileSelectionSource(FILE_SOURCE_UPLOAD);
    if (size > FPD_LIMITS) {
      showFileSizeErrorToast();
      return;
    }
    const fileSize = formatFileSize(size);

    if (type === 'application/pdf') {
      handlePDFFile(acceptedFile, type, fileSize, fileName);
    } else if (isImageFile(type)) {
      handleImageFile(acceptedFile, type, fileSize, fileName);
    } else {
      return;
    }
  };

  const getFileNameWithoutExtension = (fileName) => {
    return fileName.replace(/\.[^/.]+$/, '');
  };

  const showFileSizeErrorToast = () => {
    const fileSizeLimitMB = (FPD_LIMITS / (1024 * 1024)).toFixed(2);
    toast.closeAll();
    toast({
      title: t('printGraphic:FileSizeError:Title'),
      description: `${t(
        'printGraphic:FileSizeError:Body'
      )} ${fileSizeLimitMB} MB.`,
      status: 'error',
      duration: null,
      isClosable: true,
    });
  };

  const isImageFile = (fileType) => {
    return ['image/jpeg', 'image/png', 'image/bmp'].includes(fileType);
  };

  const handlePDFFile = (file, fileType, fileSize, fileName) => {
    setFile((prevState) => ({
      ...prevState,
      fileName,
      fileType: fileType.split('/')[1],
      fileSize,
      fileRawContent: URL.createObjectURL(file),
    }));
  };

  const handleImageFile = (file, fileType, fileSize, fileName) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      setFile((prevState) => ({
        ...prevState,
        fileName,
        fileType: fileType.split('/')[1],
        fileSize,
        fileBase64Content: e.target.result,
      }));
      setImageDimensions(e.target.result);
    };

    reader.readAsDataURL(file);
  };

  const setImageDimensions = (imageSrc) => {
    const img = new Image();
    img.onload = () => {
      setDimensions(`${img.naturalWidth} x ${img.naturalHeight}`);
    };
    img.src = imageSrc;
  };

  const handlePageNumberChange = debounce((value) => {
    setPageNumber(Number(value));
  }, 300);

  useEffect(() => {
    if (file.fileType === 'pdf') {
      setPageNumber(1);
    }
  }, [file.fileType]);

  const uploadDataProvider = async (base64String, x, y, fileType, fileName) => {
    const element = printDataProvider(
      x,
      y,
      fileType,
      base64String.split(',')[1],
      fileIdentifier(fileName, fileType)
    );
    return await addNewElementToDoc(element, true);
  };

  const handleSubmit = async () => {
    const { fileName, fileType, fileBase64Content, fileSinglePageB64Content } =
      file;
    const { x, y } = PrintGraphicModalState.position;

    const contentToUpload =
      fileType !== 'pdf' ? fileBase64Content : fileSinglePageB64Content;
    const isUploaded = await uploadDataProvider(
      contentToUpload,
      x,
      y,
      fileType,
      fileName
    );

    if (!isUploaded) {
      handleClose();
      return;
    }

    if (fileSelectionSource === FILE_SOURCE_UPLOAD) {
      storeFileToSession(fileName, fileType);
    }

    handleClose();
  };

  const fileIdentifier = ({
    fileName = file.fileName,
    fileType = file.fileType,
    seite = pageNumber,
    von = totalPages,
  }) =>
    fileType === 'pdf' ? `${fileName}(Seite${seite}von${von})` : `${fileName}`;
  const storeFileToSession = (fileName, fileType) => {
    storeToSession(
      `${fileIdentifier(fileName, fileType)}.${fileType}`,
      fileType === 'pdf'
        ? file.fileSinglePageB64Content
        : file.fileBase64Content
    );
  };

  const storeToSession = (fileName, imageData) => {
    sessionStorage.setItem(fileName, imageData);
  };
  const handleClose = () => {
    setPrintGraphicModalState((prevState) => ({
      ...prevState,
      isOpen: false,
    }));

    setIntractionState(SelectModeType.SelectMode);
    toast.closeAll();
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length < 1) {
      setIsDragActive(false);
      toast.closeAll();
      toast({
        title: t('printGraphic:FileFormatError:Title'),
        description: `${t(
          'printGraphic:FileFormatError:Body'
        )} ".jpeg", ".png", ".bmp" and ".pdf".`,
        status: 'warning',
        duration: null,
        isClosable: true,
      });
      return;
    }

    handleFileChange(acceptedFiles[0]);

    setIsDragActive(false);
  }, []);

  const handleDragEnter = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragActive(true);
  }, []);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragActive(true);
  }, []);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragActive(false);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDragEnter: handleDragEnter,
    onDragOver: handleDragOver,
    onDragLeave: handleDragLeave,
    accept:
      interactionState === ElementType.PrintGraphic
        ? mimeTypeMapping.imageAndPdf
        : mimeTypeMapping.hpgl,
  });
  const stopPropagation = (event) => {
    event.stopPropagation();
  };
  function ImageContainer({ src }) {
    return (
      <ChakraImage
        src={src}
        alt={t('ActionDialogs:Print.PreviewImage')}
        boxSize="sm"
        objectFit="contain"
        borderRadius="10px"
        mt={file.fileRawContent ? '0' : '1.8rem'}
      />
    );
  }
  const isValidFilename = (filename) => {
    if (typeof filename !== 'string') return false;
    const parts = filename.split('.');
    return (
      parts.length === 2 &&
      /^[\w,\s\-\(\)]+$/.test(parts[0]) &&
      /^[A-Za-z]{2,4}$/.test(parts[1])
    );
  };

  const isBase64 = (data) => {
    return (
      typeof data === 'string' &&
      /^data:(application\/pdf|image\/(png|jpeg|jpg|bmp));base64,/.test(data)
    );
  };

  const sessionStorageValues = Object.keys(sessionStorage);
  const GraphicDataStored = sessionStorageValues
    ?.filter(isValidFilename)
    .filter((graphic) => {
      if (typeof graphic !== 'string') return false;

      const data = sessionStorage.getItem(graphic);

      return data && isBase64(data);
    });
  const isHpglDataStored = sessionStorageValues?.some((graphic) => {
    if (typeof graphic !== 'string') return false;
    const parts = graphic.split('.');
    return parts.length === 2 && parts[1] === 'plt';
  });
  const sidebarVisibilityMap = {
    [ElementType.PrintGraphic]: () => GraphicDataStored.length,
    [ElementType.HpglEngraving]: () => isHpglDataStored,
  };

  const shouldShowSidebar = () => {
    return [interactionState]
      ? sidebarVisibilityMap[interactionState]()
      : false;
  };

  const CardClickHandler = (e) => {
    updateFileContext(
      e.target.alt,
      sessionStorage[`${e.target.alt}`],
      e.target.src
    );
  };
  const handleBlobReady = (blob) => {
    setFile((prevState) => ({
      ...prevState,
      fileSinglePageB64Content: blob,
    }));
  };

  /**
   * TODO:
   * Once we've finalized the styles for the card image and its container and
   * reached an agreement, we will separate the styles
   */
  const cardContainerStyles = {
    bg: 'fpd.800',
    flex: '0 0 30%',
    overflowY: 'auto',
    overflowX: 'hidden',
    borderRadius: 'lg',
    border: '2px solid #ccc',
    display: 'flex',
    height: '30rem',
    ...ScrollbarStyles,
  };
  return (
    PrintGraphicModalState.isOpen && (
      <Modal
        isOpen={PrintGraphicModalState.isOpen}
        onClose={handleClose}
        size={shouldShowSidebar() ? '3xl' : 'lg'}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('printGraphic:Header')}</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <Flex gap={2} alignItems="stretch">
              <Box
                flex={shouldShowSidebar() ? '0 0 70%' : '100%'}
                height="30rem"
                {...getRootProps()}
                border="2px dashed #ccc"
                borderRadius="md"
                textAlign="center"
                bg={isDragActive ? 'fpd.500' : 'fpd.700'}
                transition="background-color 0.3s ease"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <VStack
                  id="pdf-graphic-container"
                  width="100%"
                  height="100%"
                  bg="fpd.800"
                  p={2}
                  borderRadius="lg"
                  overflow="hidden"
                  boxShadow="xl"
                >
                  {file.fileRawContent && file.fileType === 'pdf' ? (
                    <>
                      <Box>
                        <HStack mb={'6px'} justify={'center'}>
                          <Text>{t('ActionDialogs:Print.SelectPage')}</Text>

                          <NumberInput
                            mx={0}
                            value={pageNumber}
                            min={1}
                            max={totalPages}
                            onChange={handlePageNumberChange}
                            onClick={stopPropagation}
                            onKeyDown={stopPropagation}
                            onWheel={stopPropagation}
                            ref={numberInputRef}
                          >
                            <NumberInputField />
                            <NumberInputStepper>
                              <NumberIncrementStepper />
                              <NumberDecrementStepper />
                            </NumberInputStepper>
                          </NumberInput>
                          <Text>
                            {t('ActionDialogs:Print.From')}({totalPages})
                          </Text>
                        </HStack>

                        <PDFPageToImage
                          pdfFile={file.fileRawContent}
                          pageNumber={pageNumber}
                          onPageCount={setTotalPages}
                          onImageReady={(imageData, width, height) => {
                            setFile((prevState) => ({
                              ...prevState,

                              fileBase64Content: imageData,
                            }));
                            setDimensions(
                              `${width.toFixed(2)}mm x ${height.toFixed(2)}mm`
                            );
                          }}
                          onSinglePDFpageB64Ready={handleBlobReady}
                        />

                        <ImageContainer
                          src={
                            file.fileBase64Content
                              ? file.fileBase64Content
                              : FallbackPreview
                          }
                        />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box>
                        <ImageContainer
                          src={
                            file.fileBase64Content
                              ? file.fileBase64Content
                              : FallbackPreview
                          }
                        />
                      </Box>
                    </>
                  )}

                  <Input {...getInputProps()} />
                  <HStack
                    justify={'center'}
                    mt={
                      numberInputRef.current ? 2 : file.fileRawContent ? 5 : 2
                    }
                  >
                    <Text
                      fontSize="0.625rem"
                      color={file.fileRawContent ? 'fpd.500' : 'fpd.100'}
                    >
                      {isDragActive
                        ? t('Upload:DropFileMsg', {
                            fileType:
                              interactionState === ElementType.PrintGraphic
                                ? 'Image / PDF'
                                : 'HPGL',
                          })
                        : t('Upload:DragFileMsg', {
                            fileType:
                              interactionState === ElementType.PrintGraphic
                                ? 'Image / PDF'
                                : 'HPGL',
                          })}
                    </Text>

                    <ActionButton
                      fontSize="0.625rem"
                      label={t('Upload:SelectFiles')}
                    />
                  </HStack>
                </VStack>
              </Box>
              {shouldShowSidebar() ? (
                <Box __css={cardContainerStyles}>
                  <VStack>
                    {GraphicDataStored.length > 0
                      ? GraphicDataStored.map((graphicData, key) => (
                          <ImgCard
                            key={key}
                            length={GraphicDataStored.length}
                            sessionSrc={sessionStorage.getItem(graphicData)}
                            fileName={graphicData}
                            CardClickHandler={CardClickHandler}
                            onSinglePDFpageB64Ready={handleBlobReady}
                            onImageReady={(imageData) => {
                              setFile((prevState) => ({
                                ...prevState,
                                fileBase64Content: imageData,
                              }));
                            }}
                            isStorageDisplayProcessing={
                              isStorageDisplayProcessing
                            }
                            setStorageDisplayProcessing={
                              setStorageDisplayProcessing
                            }
                          />
                        ))
                      : null}
                  </VStack>
                </Box>
              ) : null}
            </Flex>
          </ModalBody>

          <ModalFooter>
            <ActionButton
              label={
                fileSelectionSource === FILE_SOURCE_STORED
                  ? t('ActionButton:Add')
                  : t('ActionButton:Upload')
              }
              type="submit"
              isDisabled={
                !file.fileName ||
                (shouldShowSidebar() && isStorageDisplayProcessing)
              }
              onClick={handleSubmit}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  );
};
const printDataProvider = (posX, posY, filetype, blob, graphicFileName) => ({
  ref_point: 'leftBottom',
  rotation: 0,
  scale: {
    proportional: false,
    x_scale: 100,
    y_scale: 100,
  },
  cropping: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    unit: 'millimeters',
  },
  mirror: false,
  not_produced: false,
  file: {
    filetype: filetype,
    data: blob,
    filename: graphicFileName,
  },
  position: [posX, posY],
});

export default PrintGraphicDialog;
