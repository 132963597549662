/**
 * The 'ManualCoordinate' component, manages the action of setting manual coordinate for element placement.
 */

// Built-in
import React, { useContext } from 'react';

// Internal
import { ApiContext } from '../../context/ApiContext'; // Provides API context (Global shared state) to the component

import { SelectModeType } from '../../context/Types';
import { ModalContext } from './modal/ModalContext';
import { ToolbarIcon } from './ToolbarIcon';

function ManualCoordinate() {
  const { interactionState } = useContext(ApiContext);

  const { setCoordinatesModalOpen, isCoordinatesModalOpen } =
    useContext(ModalContext);

  const CoordinateButtonClickHandler = () => {
    setCoordinatesModalOpen(true);
  };

  return (
    <>
      <ToolbarIcon
        label="ManualCoordinate"
        imagePath="/icons/coordinates.svg"
        onClickHandler={CoordinateButtonClickHandler}
        isDisabled={
          interactionState === SelectModeType.SelectMode ||
          isCoordinatesModalOpen
            ? true
            : false
        }
      />
    </>
  );
}

export default ManualCoordinate;
