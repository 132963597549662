import { defineStyleConfig } from '@chakra-ui/react'

export const ActionButtonStyles = defineStyleConfig({
  baseStyle: {
    fontWeight: 'normal',
    borderRadius: 'none', // <-- border radius is same for all variants and sizes
  },
  // Two sizes: sm and md
  sizes: {
    xs: {
      px: '.75rem',
      fontSize: 'xs',
      h: '1.75rem',
      minWidth: '7rem',
    },
    sm: {
      px: '.875rem',
      fontSize: 'sm',
      h: '2rem',
      minWidth: '8rem',
    },
    md: {
      px: '1rem',
      fontSize: 'md',
      h: '2.5rem',
      minWidth: '10rem',
    },
  },
  // A new defined variant: solid_border
  variants: {
    fpd: {
      border: '1px solid',
      borderRadius: 'none',
      borderColor: 'fpd.800',
      bgGradient: 'linear(to-t, fpd.700, fpd.500)',
      color: 'fpd.50',
      _disabled: {
        border: '1px solid',
        borderColor: 'fpd.800',
        bgGradient: 'linear(to-t, fpd.700, fpd.500)',
        color: 'fpd.200',
        opacity: '0.45',
        _hover: {
          borderColor: 'fpd.800',
          cursor: 'not-allowed',
        },
      },
      _hover: {
        borderColor: 'fpd.accentBorder',
        bgGradient: 'linear(to-t, fpd.hover, fpd.hoverGradient)',
        color: 'fpd.50',
        cursor: 'pointer',
      },
      _active: {
        borderColor: 'fpd.accentBorder',
        bgGradient: 'linear(to-t, fpd.hover, fpd.hoverGradient)',
        color: 'fpd.50',
        cursor: 'default',
      },
      _focus: {
        boxShadow: '0 0 0 2px var(--chakra-colors-fpd-accentActiv)',
      },
    },
    'fpd-link': {
      p: 0,
      h: '2.375rem',
      minWidth: '0',
      textDecoration: 'underline',
      border: 'none',
      color: 'fpd.50',
      _hover: {
        cursor: 'pointer',
      },
      _active: {
        cursor: 'default',
      },
    },
  },
  // The default size and variant values
  defaultProps: {
    size: 'xs',
    variant: 'fpd',
  },
});
