//@ts-check

/**
 * An enum representing available ElementTypes options.
 * @enum {string}
 */

export const ElementType = {
  Bolt: 'Bolt',
  Border: 'Border',
  Cavity: 'Cavity',
  CurvedSlot: 'CurvedSlot',
  DHole: 'DHole',
  DrillHole: 'DrillHole',
  DxfContour: 'DxfContour',
  EngravingObject: 'EngravingObject',
  HpglEngraving: 'HpglEngraving',
  PrintGraphic: 'PrintGraphic',
  RectHole: 'RectHole',
  EngravingText: 'EngravingText',
  EngravingLine: 'EngravingLine',
  EngravingRectangle: 'EngravingRectangle',
  EngravingEllipse: 'EngravingEllipse',
  FreeContour: 'FreeContour',
  SystemHoles: 'SystemHoles',
  ManualCoordinate: 'ManualCoordinate',
};
/**
 * An enum representing available key code options.
 * @enum {string}
 */

export const keyCode = {
  SPACE: '32',
  DELETE: '46',
  COPY: '67',
  PASTE: '86',
  REDO: '89',
  UNDO: '90',
};

/**
 * An enum representing available unit conversion constant.
 * @readonly
 * @enum {number}
 */
export const CONVERSION_CONSTANTS = {
  MM_TO_INCH: 0.03937007874015748,
  INCH_TO_MM: 25.4,
};

/**
 * An enum representing available unit types.
 * @readonly
 * @enum {string}
 */
export const Units = {
  MILLIMETERS: 'millimeters',
  INCHES: 'inches',
  DEGREES: 'degrees',
};
/**
 * An enum representing available Customer types.
 * @readonly
 * @enum {string}
 */
export const CustomerType = {
  PRIVATE: 'PrivateCustomer',
  BUSINESS: 'BusinessCustomer',
};
/**
 * An enum representing available User types.
 * @readonly
 * @enum {string}
 */
export const User = {
  GUEST: 'Guest',
  AUTHENTICATED: 'Authenticated',
  ANONYMOUS: 'Anonymous',
};

/**
 * Map of unit types to their display values.
 * @type {Object<Units, string>}
 */
export const unitDisplayMap = {
  [Units.MILLIMETERS]: 'millimeters',
  [Units.INCHES]: 'inches',
  [Units.DEGREES]: 'degrees',
};

/**
 * Map of unit types to their sequence values.
 * @type {Object<Units, number[]>}
 */
export const stepSequence = {
  [Units.INCHES]: [0.01, 0.05, 0.1, 0.5, 1, 5, 10],
  [Units.MILLIMETERS]: [0.5, 1, 5, 10, 50, 100, 500],
  [Units.DEGREES]: [0.5, 1, 5, 10, 50, 100, 500],
};

/**
 * An enum representing SelectModeType  options.
 * @enum {string}
 */
export const SelectModeType = {
  SelectMode: 'SelectMode',
};
/**
 * An enum representing scalabilityFactorTypes  options.
 * @enum {string}
 */
export const scalabilityFactorTypes = {
  FREE: 'free',
  PROPORTIONAL: 'proportional',
  NONE: 'none',
};

/**
   This enum represents the state of requesting mesh data for initialization or modification.
   * @enum {string}
   */
export const MeshStateTypes = {
  initial: 'initial',
  modified: 'modified',
};

/**
 * An enum representing available Placement types.
 * @readonly
 * @enum {string}
 */
export const PlacementType = {
  POSITION: 'Position',
  TRANSFORMATION: 'Transformation',
  BOX: 'Box',
};

/**
 * Struct repesenting an element when it is added to a front panel. For positioning an instance can contain *either* a `transformation` or a combination of `position`, `scale` and/or `rotation`. Setting none of the four options is the same as providing a `position` at [0, 0]. If a transformation is provided, it will override the other three options.
 * @typedef {Object} ElementPlacement
 * @property {ElementType| null} type
 * @property {number[]|null} [position]
 * @property {number[]|null} [scale]
 * @property {number|null} [rotation]
 * @property {number[]|null} [transformation]
 *
 */

/**
 * Error type returned by fpcli as json A message from the front panel model that refers to certain elements.
 * This structure is used in FpcliError::ModelError and for warnings in output of fpcli commands related to properties.
 */
/**
 * @typedef {Object} ModelMessage
 * @property {number[]} element_ids
 * @property {string} message
 */

/**
 * Property type for element properties returned by fpcli.
 */
/** @typedef {{[key: string]: Property}} SelectionMemberProperties */

/**
 * Property type for element properties returned by fpcli.
 */
/**
 * @typedef {Object} Property
 * @property {unknown} value
 * @property {string[]|null} [pool]
 */

/**
 * Struct defining a selection of `Element`s of a `DocumentVersion`
 */
/**
 * @typedef {Object} Selection
 * @property {number[]} member_ids IDs of the elements that are members of the 'Selection'
 * @property {SelectionMemberProperties | null} member_properties Common properties of the member elements
 * @property {number} document_version 'DocumentVersion' this 'Selection' refers to
 * @property {ModelMessage[]|null} [warnings] Warnings of the front panel model that belong to some elements of the selection
 */

/**
 * Struct defining a `DocumentVersion`'s database representation A `DocumentVersion` is an immutable snapshot of a [`Document`](super::document::Document) The struct does not contain the content of the document itself The content can either be accessed by its mesh data or by accessing properties of selected elements
 */

/**
 * @typedef {Object} DocumentVersion
 * @property {number} id ID of this 'DocumentVersion', this will be unique in the database
 * @property {number} document_id ID of the 'Document' this 'DocumentVersion' refers to
 * @property {number|null} [previous_version] Previous version in the history chain
 * @property {number|null} [next_version] Next version in the history chain
 * @property {string} created_at Creation time stamp
 * @property {number[]} changed_ids IDs of the elements whose properties have changed in this 'DocumentVersion'
 */

/**
 * Struct defining a Document when uploaded as "multipart/form-data"
 */
/**
 * @typedef {Object} DocumentUpload
 * @property {string} name
 * @property {string} description
 * @property {Blob|null} file
 */

/**
 * Struct defining a document's database representation
 */
/**
 * @typedef {Object} Document
 * @property {number} id
 * @property {string} name
 * @property {string} description
 * @property {string} created_at
 * @property {string} modified_at
 * @property {number|string} version
 */

export const Types = {};
