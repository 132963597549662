/**
 * The 'RectHoleCreator' component,manages the action of inserting a 'rectangular hole'.
 */

// Built-in
import React, { useContext } from 'react';

// Internal
import { ElementType } from '../../context/Types';
import { ApiContext } from '../../context/ApiContext'; // Provides API context (Global shared state) to the component

import { ToolbarIcon } from './ToolbarIcon';

function RectHoleCreator() {
  const { Document, interactionState, setIntractionState } =
    useContext(ApiContext);

  const rectholeButtonClickHandler = () => {
    setIntractionState(ElementType.RectHole);
  };

  const isSelected = interactionState === ElementType.RectHole;

  return (
    <ToolbarIcon
      label={ElementType.RectHole}
      imagePath="/icons/rectangular-hole.svg"
      onClickHandler={rectholeButtonClickHandler}
      isDisabled={!Document.id}
      isSelected={isSelected}
    />
  );
}

export default RectHoleCreator;
