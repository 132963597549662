import { bboxDrawer } from '../../presentation/drawing/boundingBox/bboxDrawer';
import { bboxOfSelection } from '../../presentation/drawing/boundingBox/bboxOfSelection';
import { bboxRemover } from '../../presentation/drawing/boundingBox/bboxRemover';
import { crossDrawer } from '../../presentation/drawing/crossDrawer';
import { bcrToBbox } from '../../presentation/utils/bcrToBbox';
import descriptionGenerator from './descriptionGenerator';

/**
 * Determines which element are in the selection range after
 * a drawing-box is drawn
 */
export function getElementsInsideDrawnBox({
  bbox,
  isTouched,
  frontPanelGp,
  fpSvgStates,
  updateFpSvgStates,
  isPanelSelected,
  patchMesh,
  getSelectionsForGivenElements,
  groupOrUngroupSelectionProvider,
  duplicateSelection,
  deleteSelection,
  setRotationModalOpen,
  setMoveModalOpen,
  setMirrorModalOpen,
  setResizeModalOpen,
  setAlignDistributeModalOpen,
}) {
  const { selectedElementIds } = fpSvgStates;
  const { x: BoxX, y: BoxY, width: BoxW, height: BoxH } = bbox;

  const elementsInRangeIds = frontPanelGp
    .selectAll('g:not([fpd\\.panel])')
    .filter(function () {
      const { x, y, width, height } = bcrToBbox(this);
      return isTouched
        ? //the 'isTouched' is true, which means the user has drawn a box from right to left.So any element that is touched by the box is considered inside the box.
          x + width > BoxX &&
            BoxY + BoxH > y &&
            y + height > BoxY &&
            BoxX + BoxW > x
        : // the 'isTouched' is false, which means the user has drawn a box from left to right.So, only elements that are enlcosed by the box are considered inside the box.
          x > BoxX &&
            BoxY + BoxH > y + height &&
            y > BoxY &&
            BoxX + BoxW > x + width;
    })
    .nodes()
    .map((elementInRange) => {
      return elementInRange.hasAttribute('fpd.group')
        ? +elementInRange.getAttribute('fpd.group')
        : +elementInRange.getAttribute('fpd.id');
    })
    .filter((elementInRange) => !selectedElementIds.includes(elementInRange));
  // Checks whether the element id is already in the 'selectedElementIds' which it means the element has already been selected
  const uniqueElementsInRangeIds = Array.from(new Set(elementsInRangeIds));

  if (!uniqueElementsInRangeIds.length) return; //It means no element included in the range selection
  const newSelectedElementIds = isPanelSelected //Checks if the selection box is empty or already drawn
    ? [...uniqueElementsInRangeIds]
    : [...selectedElementIds, ...uniqueElementsInRangeIds];

  const updateSelection = updateFpSvgStates({
    selectedElementIds: newSelectedElementIds,
  });

  if (updateSelection.selectedElementIds.length === 1) {
    crossDrawer(frontPanelGp, updateSelection.selectedElementIds);
  }

  if (!isPanelSelected) bboxRemover(frontPanelGp);

  bboxDrawer({
    bbox: bboxOfSelection({
      frontPanelGp: frontPanelGp,
      selectedElementIds: updateSelection.selectedElementIds,
      isDragged: false,
      isOverlaped: false,
    }),
    frontPanelGp: frontPanelGp,
    selectedElementIds: updateSelection.selectedElementIds,
    patchMesh: patchMesh,
    isOverlaped: false,
    groupOrUngroupSelectionProvider: groupOrUngroupSelectionProvider,
    duplicateSelection: duplicateSelection,
    deleteSelection: deleteSelection,
    setRotationModalOpen: setRotationModalOpen,
    setMoveModalOpen: setMoveModalOpen,
    setMirrorModalOpen: setMirrorModalOpen,
    setResizeModalOpen: setResizeModalOpen,
    setAlignDistributeModalOpen: setAlignDistributeModalOpen,
  });

  const descText = descriptionGenerator({
    frontPanelGp: frontPanelGp,
    selectedElementIds: updateSelection.selectedElementIds,
  });

  getSelectionsForGivenElements(
    [...updateSelection.selectedElementIds],
    descText
  );

  return updateSelection;
}
