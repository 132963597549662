import React, { memo } from 'react';
import {
  Box,
  Text,
  Table,
  Tbody,
  Tr,
  Td,
  useStyleConfig,
} from '@chakra-ui/react';

const RenderTable = memo(({ category, features }) => {
  const styles = useStyleConfig('RenderTable');

  const featureRow = (feature, index) => (
    <Tr key={`${feature.id || feature.name}-${index}`}>
      <Td
        p="0.25rem"
        fontSize="xs"
        fontWeight="400"
        opacity={feature.supported ? '1.0' : '0.3'}
      >
        {feature.name}
      </Td>
    </Tr>
  );

  return (
    <Box __css={styles.outerCard}>
      <Box __css={styles.innerCard}>
        <Text __css={styles.categoryHeader}>{category}</Text>
      </Box>
      <Box __css={styles.contentBox}>
        <Table variant="fpd" size="sm">
          <Tbody>
            {features.map((feature, index) => featureRow(feature, index))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
});

export default RenderTable;
