// Built-in
import React, { useContext } from 'react';

// External
import {
  HStack,
  useMultiStyleConfig,
  createStylesContext,
  Flex,
} from '@chakra-ui/react';

import UploadFile from './UploadFile'; // Importing 'Upload File', which manages the upload file process from the local system and prepares the data for server posting.
// import OpenById from './OpenById'; // Importing 'OpenById' component, which fetches the panel from the server based on its id.
import DeletePanel from './DeletePanel'; // Importing 'DeletePanel' component, which deletes already opened panel from the server and clear painted graphic.
import RedoAction from './Redo'; // Importing 'RedoAction' component, which makes the redo action possible.
import UndoAction from './Undo'; //Importing 'UndoAction' component, which makes the undo action possible.
import ListOfPanels from './ListOfPanels'; //Importing 'ListOfPanel' component, which displays all available panels for selection in a list.
import SelectMode from './SelectMode'; //Importing 'SelectMode' component, which displays 'select mode' icon and manages the action of changing interactionState to 'SelectMode'.
import DrillHoleCreator from './DrillHole'; //Importing 'DrillHoleCreator' component, which displays 'DrillHole' icon and manages the action of inserting a 'DrillHole'.
import RectHoleCreator from './RectHole'; //Importing 'RectHoleCreator' component, which displays 'RectHole' icon and manages the action of inserting a 'RectHole'.
import DholeCreator from './Dhole'; //Importing 'DholeCreator' component, which displays 'Dhole' icon and manages the action of inserting a 'D-hole'.
import CurvedSlotCreator from './CurvedSlot'; //Importing 'CurvedSlotCreator' component, which displays 'CurvedSlot' icon and manages the action of inserting a 'CurvedSlot'.
import CavityCreator from './Cavity'; //Importing 'CavityCreator' component, which displays 'Cavity' icon and manages the action of inserting a 'Cavity'.
import BoltCreator from './Bolt'; //Importing 'BoltCreator' component, which displays 'Bolt' icon and manages the action of inserting a 'stud/standoff'.
import NewDocumentCreator from './NewDoc';
import DownloadPanel from './DownloadPanel';
import TextEngravingCreator from './engravingObjects/TextEngraving';
import EngravingMenu from './engravingObjects/EngravingMenu';
import PriceCreator from './Price';
import ManualCoordinate from './ManualCoordinate';
import FeatureLog from './featureLog/FeatureLog';
import WebShopSync from './WebShopSync';
import { ApiContext } from '../../context/ApiContext';
import LanguageSelector from './LanguageSelector';
import FreeContourCreator from './FreeContour';
import HpglEngravingCreator from './HpglEngraving';
import SystemHolesCreator from './SystemHoles';
import WebShopLink from './WebShopLink';
import LoginMenu from './LoginMenu';
import PrintGraphic from './PrintGraphic';
import Feedback from './Feedback';

const [StylesProvider, useStyles] = createStylesContext('Toolbar');

const ToolbarSection = ({ children }) => {
  const styles = useStyles();

  return <Flex sx={styles.section}>{children}</Flex>;
};

const ToolbarSectionLink = ({ children }) => {
  const styles = useStyles();

  return <Flex sx={styles.link}>{children}</Flex>;
};

const Toolbar = () => {
  const styles = useMultiStyleConfig('Toolbar', {});
  const { engravingMenuRef } = useContext(ApiContext);

  return (
    <StylesProvider value={styles}>
      <HStack sx={styles.container}>
        <ToolbarSection>
          <NewDocumentCreator />
          <UploadFile />
          <DownloadPanel />
        </ToolbarSection>

        <ToolbarSection>
          <ListOfPanels />
          <DeletePanel />
        </ToolbarSection>

        <ToolbarSection>
          <UndoAction />
          <RedoAction />
        </ToolbarSection>

        <ToolbarSection>
          <SelectMode />
          <DrillHoleCreator />
          <RectHoleCreator />
          <DholeCreator />
          <CurvedSlotCreator />
          <CavityCreator />
          <FreeContourCreator />
          <TextEngravingCreator />
          <HpglEngravingCreator />
          <PrintGraphic />
          <EngravingMenu ref={engravingMenuRef} />
          <BoltCreator />
          <SystemHolesCreator />
          <ManualCoordinate />
        </ToolbarSection>

        <ToolbarSection>
          <PriceCreator />
          <WebShopSync />
        </ToolbarSection>

        <ToolbarSectionLink>
          <LoginMenu />
          <Feedback />
          <FeatureLog />
          <LanguageSelector />
          <WebShopLink />
        </ToolbarSectionLink>
      </HStack>
    </StylesProvider>
  );
};

export default Toolbar;
