import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const toolbarMenuIconAnatomy = ['button', 'iconImage', 'selectedButton'];

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(toolbarMenuIconAnatomy);

const commonStyles = {
  w: '2.375rem',
  h: '2.375rem',
  fill: 'fpd.50',
  stroke: 'fpd.50',
  borderRadius: 'none',
  bg: 'none',
  border: '1px solid transparent',
  _hover: {
    bgGradient: 'linear(to-t, fpd.hover, fpd.hoverGradient)',
    borderColor: 'fpd.accentBorder',
  },
  _focus: { bgGradient: 'linear(to-t, fpd.hover, fpd.hoverGradient)' },
  _active: { bgGradient: 'linear(to-t, fpd.hover, fpd.hoverGradient)' },
};

const baseStyle = definePartsStyle({
  button: {
    ...commonStyles,
  },
  iconImage: {
    h: '100%',
    w: '100%',
  },
  selectedButton: {
    ...commonStyles,
    bgGradient: 'linear(to-t, fpd.hover, fpd.hoverGradient)',
    borderColor: 'fpd.accentBorder',
  },
});

export const ToolbarMenuIconStyles = defineMultiStyleConfig({ baseStyle });
