/**
 *
 * This function returns the proportional factor
 * for changing the view while zooming.
 */
export const scaleFactor = function (frontPanel) {
  const currentPanelScale = frontPanel.node().transform.baseVal.consolidate()
    .matrix.a;

  const { width } = frontPanel.node().getBBox();

  return width > 100
    ? width / (100 * currentPanelScale)
    : 1 / currentPanelScale;
};
