import { defineStyleConfig } from '@chakra-ui/react'

export const DropDownIconStyles = defineStyleConfig({
  baseStyle: {
    borderInlineStart: ' 1px solid',
    color: 'fpd.50',
    bgGradient: 'linear(to-t,fpd.700,fpd.500)',
    border: '1px solid',
    borderColor: 'fpd.800',
    userSelect: 'none',
    _hover: {
      bgGradient: 'linear(to-t, fpd.hover, fpd.hoverGradient)',
      border: '1px solid ',
      borderColor: 'fpd.accentBorder',
    },
    transitionProperty: '',
  },
  size: {},
  variant: {},
  defaultProps: {}
});