/**
 * An 'App component' is a parent component that includes other components as its children.
 */

// Built-in
import React, { useContext, useEffect, useRef } from 'react'; // importing React and necessary hooks objects from 'react' module.

// External
import { ChakraBaseProvider, Grid, GridItem } from '@chakra-ui/react'; // Importing necessary objects from '@chakra-ui' library for rendering the UI.
import { Routes, Route } from 'react-router-dom'; //Use React Router to route to pages based on URLs

// Internal
import Header from './formHeader/Header'; // Importing 'Header' component, which renders the application header display text.
import Toolbar from './formHeader/Toolbar';
import SideBar from './sideBar/SideBar'; // Importing 'SideBar' component, which renders and represents relevant panel and element properties taken from the server.
import { SelectModeType } from '../context/Types';
import { ApiContext } from '../context/ApiContext';
import SvgRenderer from './graphic/presentation/SvgRenderer';
import { ModalProvider } from './formHeader/modal/ModalContext';
import TextEngravingDialog from './formHeader/modal/dialog/TextEngravingDialog';
import ResizeDialog from './formHeader/modal/dialog/ResizeDialog';
import RotationDialog from './formHeader/modal/dialog/RotationDialog';
import MoveDialog from './formHeader/modal/dialog/MoveDialog';
import MirrorDialog from './formHeader/modal/dialog/MirrorDialog';
import ManuallyCoordinatesDialog from './formHeader/modal/dialog/ManuallyCoordinatesDialog';
import theme from '../theme';
import '@fontsource/inter/400.css'; // Weight 400
import '@fontsource/inter/200.css'; // Weight 200
import { useWindowSize } from '../hooks/useWindowSize';
import ScreenSizeAlert from './formHeader/modal/dialog/SmallScreen';
import { hamburgerIconRemover } from './graphic/presentation/drawing/boundingBox/contextMenu/hamburgerDrawer';
import LoginDialoge from './formHeader/modal/dialog/LoginDialoge';
import { useAuth } from '../context/AuthContext';
import { useResetApiStatesOnLogout } from '../hooks/useResetApiStatesOnLogout';
import AlignDistributeDialog from './formHeader/modal/dialog/AlignDistributeDialog';
import PrintGraphicDialog from './formHeader/modal/dialog/printGraphic/PrintGraphicDialog';
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="*" element={<Home />} />
        <Route path="ping" element={<Health />} />
      </Routes>
    </div>
  );
}

function Home() {
  const {
    MeshData,
    Selection,
    interactionState,
    setIsScreenSmall,
    loginDialog,
  } = useContext(ApiContext);

  const {
    actions: { fetchSessionToken },
  } = useAuth();

  const FPContainerRef = useRef(null);
  const [width, height] = useWindowSize();
  useResetApiStatesOnLogout();
  // Monitors screen width; sets `isScreenSmall` to true if width is <= 1052 (only in production mode)
  useEffect(() => {
    setIsScreenSmall(!(width > 1120) && process.env.NODE_ENV !== 'development');
  }, [width, height]);

  useEffect(() => {
    if (!FPContainerRef.current) return;
    //When the 'panel delete' action is performed, this section
    //is used to remove the 'SVG' from the 'FPContainerRef' container.
    if (!MeshData) {
      FPContainerRef.current.innerHTML = '';
      hamburgerIconRemover();
    }
  }, [FPContainerRef.current, MeshData]);
  const checkForSessionToken = () => {
    const regex = /(?:^|; )token=([^;]*)/;
    const match = document.cookie.match(regex);
    fetchSessionToken(match);
  };

  /**
   * Checks for a session token once on component mount to ensure user session continuity.
   * (if the token is not exist, it should provide the user with the anonymos token
   * before checke that is also authenticated user or not)
   */
  useEffect(() => {
    checkForSessionToken();
  }, []);
  const { mainGrid, svgContainer, propContainer } =
    theme.components.MainLayout.baseStyle;
  return (
    <ChakraBaseProvider theme={theme}>
      <Grid
        {...mainGrid}
        templateRows="1.75rem 2.65rem calc(100vh - 4.65rem)" // templateRows = navbar toolbar rest
        templateColumns="repeat(5, 1fr)"
      >
        <ModalProvider>
          {/* Navbar */}
          <GridItem colSpan={5} zIndex={1}>
            <Header />
          </GridItem>

          {/* Toolbar */}
          <GridItem colSpan={5} zIndex={1}>
            <Toolbar />
          </GridItem>

          <GridItem
            {...svgContainer}
            id="svg-container"
            ref={FPContainerRef}
            rowSpan={1}
            colSpan={4}
            cursor={
              interactionState !== SelectModeType.SelectMode
                ? 'crosshair'
                : 'default'
            }
          >
            {MeshData && <SvgRenderer FPContainerRef={FPContainerRef} />}
          </GridItem>
          <TextEngravingDialog />
          <PrintGraphicDialog />
          <ResizeDialog />
          <RotationDialog />
          <MoveDialog />
          <MirrorDialog />
          <AlignDistributeDialog />
          <ManuallyCoordinatesDialog />
          <ScreenSizeAlert />
          {loginDialog.isOpen && <LoginDialoge />}
        </ModalProvider>
        <GridItem
          id="properties-container"
          rowSpan={1}
          colSpan={1}
          {...propContainer}
        >
          {/* As the SideBar component is responsible for rendering element properties, it will be rendered after properties has been loaded*/}
          {!!Selection.member_ids.length && <SideBar />}
        </GridItem>
      </Grid>
    </ChakraBaseProvider>
  );
}

function Health() {
  return (
    <>
      <h3>The Fpd-web is healthy!</h3>
    </>
  );
}
export default App;
