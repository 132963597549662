export const colors = {
  fpd: {
    50: '#FFFFFF',
    100: '#C6C6C6',
    200: '#AFAFAF',
    300: '#989898',
    400: '#828282',
    500: '#6D6D6D',
    600: '#545454',
    700: '#4A4A4A',
    800: '#3F3F3F',
    900: '#353535',
    950: '#232323',
    hover: '#555c61',
    hoverGradient: '#6f7c84',
    accentActiv: '#AECCE5',
    accentActivGradient: '#AECCE5',
    accentInactiv: '#AEDBFB',
    accentInactivGradient: '#AEDBFB',
    accentBorder: '#58738a',
  },
};
