// Built-in
import React, { useState } from 'react';
import { Tooltip, Text, useStyleConfig } from '@chakra-ui/react';
import { useCheckOverflow } from '../../../hooks/useCheckOverflow';


const Label = React.memo(({ label, size, variant, ...rest }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [isOverflowed, textRef] = useCheckOverflow(label);

  const handleMouseEnter = () => setShowTooltip(isOverflowed);
  const handleMouseLeave = () => setShowTooltip(false);

  const styles = useStyleConfig('Label', { size, variant });

  return (
    <Tooltip label={label} isOpen={showTooltip}>
      <Text
        sx={styles}
        ref={textRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...rest}
      >
        {label}:
      </Text>
    </Tooltip>
  );
});

export default Label;
