// -------------------[Element]-------------------\
/**
 * Methods related to Element Object
 */
import { API_BASE_URL } from '../Client';

const Element = {
  /**
   * Add new elements to a [Document]
   */
  add: (doc_id, version_id, data) => ({
    method: 'POST',
    url: `${API_BASE_URL}/docs/${doc_id}/versions/${version_id}/elements`,
    headers: { accept: 'application/json' },
    data: data,
  }),
};
export default Element;
