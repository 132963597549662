import React, {
  createContext,
  useContext,
  useReducer,
  useCallback,
  useMemo,
} from 'react';
import axios from 'axios';
import Authorization from '../api/services/Authorization';
import { User } from './Types';
import { ApiContext } from './ApiContext';

const AuthContext = createContext();

const initialState = {
  userState: {
    state: User.ANONYMOUS,
    userData: { userDisplayName: 'Guest' },
  },
};

function authReducer(state, action) {
  switch (action.type) {
    case 'SET_USER_STATE':
      return { ...state, userState: action.payload };
    case 'UPDATE_USER_STATE':
      return {
        ...state,
        userState: { ...state.userState, ...action.payload },
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const { setDocMsgs, setIsLoading, isLoading } = useContext(ApiContext);

  const login = useCallback(async (credentials) => {
    setIsLoading(true);

    try {
      const response = await axios(Authorization.login(credentials));

      dispatch({
        type: 'SET_USER_STATE',
        payload: {
          state: User.AUTHENTICATED,
          userData: { userDisplayName: credentials.email },
        },
      });

      setDocMsgs({
        title: 'Login Success',
        info: 'You are now logged in.',
        errorMsg: '',
      });
    } catch (error) {
      setDocMsgs({
        errorMsg: 'Login failed. Please check your credentials and try again.',
      });
    } finally {
      setIsLoading(false);
    }
  }, []);
  const logOut = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios(Authorization.logout());

      dispatch({
        type: 'SET_USER_STATE',
        payload: {
          state: User.GUEST,
          userData: { userDisplayName: User.GUEST },
        },
      });

      setDocMsgs({
        title: 'LogOut Success',
        info: 'You are now logged Out.',
        errorMsg: '',
      });
    } catch (error) {
      setDocMsgs({
        errorMsg: 'LogOut failed.',
      });
    } finally {
      setIsLoading(false);
    }
  }, []);
  const register = useCallback(async (userData) => {
    setIsLoading(true);
    try {
      const response = await axios(Authorization.register(userData));

      dispatch({
        type: 'SET_USER_STATE',
        payload: {
          state: User.AUTHENTICATED,
          userData: { userDisplayName: userData.email },
        },
      });

      setDocMsgs({
        title: 'Register Success',
        info: 'You are now logged In.',
        errorMsg: '',
      });
    } catch (error) {
      setDocMsgs({
        errorMsg: 'Register failed.',
      });
    } finally {
      setIsLoading(false);
    }
  }, []);
  const fetchSessionToken = useCallback(
    async (authCheck = false) => {
      if (isLoading) return;
      setIsLoading(true);

      try {
        const { data } = await axios(Authorization.session(authCheck));

        dispatch({
          type: 'SET_USER_STATE',
          payload: {
            state: data.is_registered ? User.AUTHENTICATED : User.GUEST,
            userData: {
              userDisplayName: data.is_registered ? data.email : User.GUEST,
            },
          },
        });
      } catch (error) {
        if (error?.response?.status === 401) {
          console.log(error);
        }
        setDocMsgs({
          errorMsg:
            'Login failed. Please check your credentials and try again.',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [isLoading, dispatch]
  );

  const updateUserState = useCallback((updates) => {
    dispatch({ type: 'UPDATE_USER_STATE', payload: updates });
  }, []);

  const value = useMemo(
    () => ({
      ...state,
      actions: {
        login,
        logOut,
        fetchSessionToken,
        updateUserState,
        register,
      },
    }),
    [state, login, logOut, fetchSessionToken, updateUserState, register]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
