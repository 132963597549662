/**
 * 'InputField' renders an element with 'string' nad 'dimValue' properties.
 *  We consider 'ptDec3Value', 'ptDec4Value', 'ptIntValue' and
 *  'ptMicronValue' as 'DimValue type'
 *  based on the mapping of FPD properties to Web-FPD.
 */

// Built-in
import React from 'react';

// External
import { useField, useFormikContext } from 'formik';
import { Input } from '@chakra-ui/react';

import PropertyItem from './PropertyItem';

const InputField = ({ label, name, focusHandler, isGrouped }) => {
  const [field] = useField(name);
  const { submitForm } = useFormikContext();

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') submitForm();
  };

  return (
    <PropertyItem label={label} variant={isGrouped ? 'grouped' : 'single'}>
      <Input
        {...field}
        variant="dimValue"
        value={field.value || ''}
        id={name}
        onFocus={focusHandler}
        onKeyDown={handleKeyDown}
      />
    </PropertyItem>
  );
};

export default React.memo(InputField);
