import {
  ElementType,
  PlacementType,
  SelectModeType,
} from '../../../../../context/Types';
import { fromViewPortToFrontPanelTransformer } from '../../../presentation/utils/coordinateTransformer';
import { getElementsInsideDrawnBox } from '../../selection/getElementsInsideDrawnBox';
import { patchMeshDelta } from '../../selection/patchMeshDelta';
import { insertNewElement } from './insertNewElement';

// Handle element release event

export const elementReleaseHandler = (
  event,
  fpSvgStates,
  updateFpSvgStates,
  frontPanelGp,
  getSelectionsForGivenElements,
  patchMesh,
  isLoading,
  addNewElementToDoc,
  interactionState,
  groupOrUngroupSelectionProvider,
  setTextEngModalOpen,
  setTextEngModalData,
  duplicateSelection,
  deleteSelection,
  setRotationModalOpen,
  setMoveModalOpen,
  setMirrorModalOpen,
  setResizeModalOpen,
  setAlignDistributeModalOpen,
  setPrintGraphicModalState
) => {
  if (isLoading) return;

  // Helper function to select an element and check if attribute 'd' exists
  const selectAndCheck = (frontPanelGp, selector) => {
    const element = frontPanelGp.select(selector);
    return element.node().hasAttribute('d');
  };

  // Mapping of element types to their selectors
  const elementSelectors = {
    rectHole: `#${ElementType.RectHole}`,
    engravingLine: `#${ElementType.EngravingLine}`,
    engravingRectangle: `#${ElementType.EngravingRectangle}`,
    engravingEllipse: `#${ElementType.EngravingEllipse}`,
    drillHole: `#${ElementType.DrillHole}`,
  };

  // Create objects for selected elements and whether they have attribute 'd' using reduce
  const isElementDrawing = Object.values(elementSelectors).some((selector) =>
    selectAndCheck(frontPanelGp, selector)
  );
  const drawingBoxPathRectangle = frontPanelGp.select('#drawingBox'); // we grab the drawing-box selection
  const innerDrawingBoxPathRectangle = frontPanelGp.select('#InnerDrawingBox'); // we grab the drawing-box selection

  const isPanelSelected = frontPanelGp.select('.BboxRectangle').empty();
  const isDrawingBox = selectAndCheck(frontPanelGp, '#drawingBox'); // checks if the drawing box is drawn or not. (If the drawing box is already drawn, there will be a value in the 'd' attribute) )
  if (isDrawingBox) {
    getElementsInsideDrawnBox({
      bbox: drawingBoxPathRectangle.node().getBBox(),
      isTouched: drawingBoxPathRectangle.classed('touched'),
      frontPanelGp,
      fpSvgStates,
      updateFpSvgStates,
      isPanelSelected,
      patchMesh,
      getSelectionsForGivenElements,
      groupOrUngroupSelectionProvider,
      duplicateSelection,
      deleteSelection,
      setRotationModalOpen,
      setMoveModalOpen,
      setMirrorModalOpen,
      setResizeModalOpen,
      setAlignDistributeModalOpen,
    });
    drawingBoxPathRectangle.attr('d', null);
    innerDrawingBoxPathRectangle.attr('d', null);
  }
  if (isElementDrawing) {
    const { startPointX, startPointY, endPointX, endPointY, isHealthy } =
      fpSvgStates.elementCreationPoints;
    const placementVal = [
      [startPointX, startPointY],
      [endPointX, endPointY],
    ];
    if (!isHealthy) return;

    insertNewElement({
      placementName: PlacementType.BOX,
      value: placementVal,
      addNewElementToDoc: addNewElementToDoc,
      interactionState: interactionState,
    });
    return;
  }
  if (interactionState !== SelectModeType.SelectMode) {
    const { clientX, clientY } = event.sourceEvent;
    const { x, y } = fromViewPortToFrontPanelTransformer(
      clientX,
      clientY,
      frontPanelGp
    );
    const placementVal = [1, 0, 0, 1, x, y];
    if (interactionState === ElementType.EngravingText) {
      setTextEngModalData({
        value: placementVal,
      });
      setTextEngModalOpen(true);
      return;
    }
    if (
      interactionState === ElementType.PrintGraphic ||
      interactionState === ElementType.HpglEngraving
    ) {
      setPrintGraphicModalState((preveState) => ({
        ...preveState,
        isOpen: true,
        position: {
          x: x,
          y: y,
        },
      }));
      return;
    }
    insertNewElement({
      placementName: PlacementType.TRANSFORMATION,
      value: placementVal,
      addNewElementToDoc: addNewElementToDoc,
      interactionState: interactionState,
    });
  }

  if (
    !fpSvgStates.isDragged ||
    (fpSvgStates.selectedElementIds.length !== 1 && isPanelSelected)
  )
    return;
  updateFpSvgStates({ isDragged: false });

  if (isLoading) return;
  patchMeshDelta(frontPanelGp, fpSvgStates.selectedElementIds, patchMesh);
};
