import { useRef, useLayoutEffect, useCallback, useContext } from 'react';

import * as d3 from 'd3';

import { ApiContext } from '../../../context/ApiContext';
import SVGInteraction from '../interactions/SVGInteraction';
import { ElementType, MeshStateTypes } from '../../../context/Types';
import { engravingTextBcrToBbox } from '../presentation/utils/bcrToBbox';
import { hamburgerIconRemover } from './drawing/boundingBox/contextMenu/hamburgerDrawer';

function SVGRenderer({ FPContainerRef }) {
  const { MeshData, MeshState } = useContext(ApiContext);

  const FPSvg = useRef();
  const frontPanelGp = useRef();
  const fpTransformation = useRef({ fpTransform: null });

  const transformationUpdater = useCallback((transform) => {
    fpTransformation.current = { ...fpTransformation.current, ...transform };
  }, []);
  function isValidMeshData(MeshData) {
    try {
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(MeshData, 'image/svg+xml');
      return svgDoc.documentElement.nodeName === 'svg';
    } catch (err) {
      return false;
    }
  }

  const FPSvgAttrSetter = useCallback((FPSvg, { width, height }) => {
    FPSvg.attr('width', width)
      .attr('height', height)
      .attr('preserveAspectRatio', 'xMidYMid meet');
  }, []);

  /**
   * we want to manipulate the DOM right after it has
   * been updated, but before the browser has painted the updated screen
   */
  useLayoutEffect(() => {
    if (!FPContainerRef.current) return;
    FPContainerRef.current.innerHTML = MeshData;

    FPSvg.current = d3.select(FPContainerRef.current.querySelector('svg'));
    frontPanelGp.current = FPSvg.current.select('g');
    // Get the size of the  container
    const FPContainerDim = {
      width: FPContainerRef.current.clientWidth,
      height: FPContainerRef.current.clientHeight,
    };
    /**
     * appends group element  to the svg file in order to preparation of adding elements of multiselection
     */
    FPSvgAttrSetter(FPSvg.current, FPContainerDim);

    /**
     * Removes each group that does not have 'fpd.id' attribute.
     */
    frontPanelGp.current.selectAll('g').each(function () {
      if (!this.hasAttribute('fpd.id')) {
        this.remove();
      }
    });

    hamburgerIconRemover();

    /**
     * Resolving an edge case where
     * a reference point placed element (0,0) ,
     * doesn't come with a transform attribute from the API.
     * */
    frontPanelGp.current.selectAll('g').each(function () {
      if (!this.hasAttribute('transform') && !this.hasAttribute('fpd.panel')) {
        d3.select(this).attr('transform', 'translate(0,0)');
      }
    });

    const boxConfig = [
      { id: 'drawingBox', opacity: '0' }, // append empty path in order to be a place for range-selection-box
      { id: 'InnerDrawingBox', opacity: '.2' }, // append empty path in order to drawing a transparent rectangle inside range-selection-box
      { id: 'dashedBBox', opacity: '0' }, // append empty path in order to be a place for moving-selection-box
      { id: 'creatingElementBox', opacity: '0' },
      { id: ElementType.RectHole, opacity: '1' },
      { id: ElementType.DrillHole, opacity: '1' },
      { id: ElementType.EngravingLine, opacity: '1', dash: 'none', stroke: 3 },
      {
        id: ElementType.EngravingRectangle,
        opacity: '0',
        dash: 'none',
        stroke: 3,
      },
      {
        id: ElementType.EngravingEllipse,
        opacity: '0',
        dash: 'none',
        stroke: 3,
      },
    ];

    boxConfig.forEach((config) =>
      appendBox({ frontPanelGp: frontPanelGp.current, ...config })
    );

    /**
     * Sets the 'initial transformation attributes' so the margin is
     *  implemented the first time the panel is loaded.
     */
    if (
      !!fpTransformation.current.fpTransform &&
      MeshState === MeshStateTypes.modified
    ) {
      frontPanelGp.current.attr(
        'transform',
        fpTransformation.current.fpTransform
      );
    } else {
      const { width, height } = frontPanelGp.current.node().getBBox();
      const frontPanelTransform = frontPanelGp.current
        .node()
        .transform.baseVal.consolidate().matrix;

      frontPanelTransform.a = 0.93;
      frontPanelTransform.d = -0.93;
      frontPanelTransform.e = 0.039 * width;
      frontPanelTransform.f -= 0.039 * height;
    }

    if (MeshState === MeshStateTypes.initial) {
      fpTransformation.current = { fpTransform: null };
    }
  }, [MeshData, FPContainerRef]);

  const appendBox = useCallback(
    ({ frontPanelGp, id, opacity, dash = '4 5', stroke = 1 }) => {
      frontPanelGp
        .append('path')
        .style(
          'mix-blend-mode',
          id === ElementType.DrillHole ||
            id === ElementType.RectHole ||
            id === 'InnerDrawingBox'
            ? 'unset'
            : 'difference'
        )
        .attr('id', id)
        .attr('d', null)
        .attr('fill-opacity', opacity)
        .attr(
          'stroke',
          id === ElementType.DrillHole || id === ElementType.RectHole
            ? 'black'
            : id === 'InnerDrawingBox'
            ? 'none'
            : 'white'
        )
        .attr('stroke-width', stroke)
        .attr('stroke-dasharray', dash)
        .attr('vector-effect', 'non-scaling-stroke');
    },
    []
  );

  if (!isValidMeshData(MeshData)) {
    return null;
  }

  //add a hidden rect in size of text engraving for selection
  const test = d3.selectAll('g[fpd\\.text_engraving]').each(function () {
    if (this.lastElementChild.nodeName === 'path') {
      const box = engravingTextBcrToBbox(this);
      let rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
      rect.setAttribute('x', box.x);
      rect.setAttribute('y', box.y);
      rect.setAttribute('width', box.width);
      rect.setAttribute('height', box.height);
      rect.setAttribute('visibility', 'hidden');
      rect.setAttribute('style', 'pointer-events: all');

      this.appendChild(rect);
    }
  });

  return (
    <>
      <SVGInteraction
        FPSvg={FPSvg}
        frontPanelGp={frontPanelGp}
        transformationUpdater={transformationUpdater}
        FPContainerRef={FPContainerRef}
      />
    </>
  );
}

export default SVGRenderer;
