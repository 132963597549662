import { extendTheme } from '@chakra-ui/react';

import { colors, fonts, styles } from './foundations';
import {
  AccordionStyles,
  ActionButtonStyles,
  CheckboxStyles,
  CustomSelectStyles,
  DimValueWithUnitStyles,
  DividerStyles,
  DropDownIconStyles,
  FeedbackStyles,
  FileUploadContainerStyles,
  FormLabelStyles,
  GroupContainerStyles,
  HeaderStyles,
  HeadingStyles,
  InputStyles,
  LabelStyles,
  LinkStyles,
  LoginStyles,
  MenuStyles,
  ModalStyles,
  NumberInputStyles,
  CardStyles,
  PopoverStyles,
  PropertyItemStyles,
  RenderTableStyles,
  ResizeSquareStyles,
  ShowPasswordButtonStyles,
  ScrollbarStyles,
  SideBarStyles,
  TabsStyles,
  TextAreaStyles,
  ToolbarStyles,
  ToolbarIconStyles,
  ToolbarMenuIconStyles,
  TooltipStyles,
  EngravingMenuStyles,
  PrintGraphicPreviewCardStyles,
  MainLayoutStyles,
} from './components';

const overrides = {
  colors,
  fonts,
  styles,
  components: {
    Accordion: AccordionStyles,
    ActionButton: ActionButtonStyles,
    Checkbox: CheckboxStyles,
    CustomSelect: CustomSelectStyles,
    DimValueWithUnit: DimValueWithUnitStyles,
    Divider: DividerStyles,
    DropDownIcon: DropDownIconStyles,
    GroupContainer: GroupContainerStyles,
    Feedback: FeedbackStyles,
    FileUploadContainer: FileUploadContainerStyles,
    FormLabel: FormLabelStyles,
    Header: HeaderStyles,
    Heading: HeadingStyles,
    Input: InputStyles,
    Label: LabelStyles,
    Link: LinkStyles,
    Login: LoginStyles,
    Menu: MenuStyles,
    Modal: ModalStyles,
    NumberInput: NumberInputStyles,
    Card: CardStyles,
    Popover: PopoverStyles,
    PropertyItem: PropertyItemStyles,
    RenderTable: RenderTableStyles,
    ResizeSquare: ResizeSquareStyles,
    ShowPasswordButton: ShowPasswordButtonStyles,
    Scrollbar: ScrollbarStyles,
    SideBar: SideBarStyles,
    Tabs: TabsStyles,
    Textarea: TextAreaStyles,
    Toolbar: ToolbarStyles,
    ToolbarIcon: ToolbarIconStyles,
    ToolbarMenuIcon: ToolbarMenuIconStyles,
    Tooltip: TooltipStyles,
    EngravingMenu: EngravingMenuStyles,
    PrintGraphicPreviewCard: PrintGraphicPreviewCardStyles,
    MainLayout: MainLayoutStyles,
  },
};
export default extendTheme(overrides);
