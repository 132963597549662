import { numberInputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from "@chakra-ui/system";

const helpers = createMultiStyleConfigHelpers(numberInputAnatomy.keys);

export const NumberInputStyles = helpers.defineMultiStyleConfig({
  baseStyle: {},
  variants: {
    fpd: {
      root: {
        border: 'none',
        borderRadius: 'none',
        m: '0 5px',
      },
      field: {
        fontSize: 'xs',
        color: 'fpd.50',
        bg: 'fpd.700',
        borderRadius: 'none',
        border: '1px solid',
        borderColor: 'fpd.800',
        height: '1.75rem',
        // - 1.2rem stepper
        // - .5rem paddingStart
        // - .5rem paddingEnd
        // - 3x 1px borders (input and left border stepper)
        width: 'calc(100% - 1.3rem - 3px - 2px)',
        paddingStart: '.5rem',
        paddingEnd: '.5rem',
        _disabled: {
          opacity: '0.45',
        },
      },
      stepper: {
        color: 'fpd.50',
        bgGradient: 'linear(to-t, fpd.700, fpd.500)',
        border: '1px solid',
        borderColor: 'fpd.800',
        fontSize: '.5rem',
        width: '1.2rem',
        _hover: {
          border: '1px solid',
          borderColor: 'fpd.accentBorder',
          bgGradient: 'linear(to-t, fpd.hover, fpd.hoverGradient)',
        },
        ':first-of-type': {
          borderRadius: 0,
        },
        ':last-of-type': {
          borderRadius: 0,
        },
      },
      stepperGroup: {
        margin: 0,
        height: '100%',
        borderRadius: 0,
      },
    },
  },
  defaultProps: {
    variant: 'fpd',
  },
});
