import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const parts = ['container', 'fileName'];

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts);

const baseStyle = definePartsStyle({
  container: {
    p: '1.25rem',
    maxW: '37.5rem',
  },
  fileName: {
    maxW: '20rem',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

export const FileUploadContainerStyles = defineMultiStyleConfig({
  baseStyle,
});
