import { createMultiStyleConfigHelpers } from '@chakra-ui/system';

const parts = ['cardContainer', 'cardText', 'headerText', 'contentText'];
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts);

const baseStyle = definePartsStyle({
  cardContainer: {
    p: '.4rem',
    pr: '0',
    my: '.4rem',
    bg: 'fpd.700',
    border: '1px solid',
    borderColor: 'fpd.800',
  },
  cardText: {
    w: '35%',
  },
  headerText: {
    fontWeight: 600,
    fontSize: 'lg',
    letterSpacing: 'wide',
    color: 'fpd.50',
  },
  contentText: {
    my: '2px',
    fontSize: 'sm',
    lineHeight: 'normal',
    fontWeight: 400,
    color: 'fpd.50',
  },
});

export const CardStyles = defineMultiStyleConfig({ baseStyle });
