import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const toolbarAnatomy = ['container', 'section', 'link'];

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(toolbarAnatomy);

const baseStyle = definePartsStyle({
  container: {
    p: '2px 0',
    bg: 'fpd.600',
    borderBottom: '1px solid',
    borderColor: 'fpd.800',
    boxShadow: '0 1px 0 0 var(--chakra-colors-fpd-500)',
    columnGap: '0!important',
  },
  section: {
    borderRight: '1px solid',
    boxShadow: '1px 0 var(--chakra-colors-fpd-400)',
    p: '0 .3rem',
    'span + span': {
      marginLeft: '2px',
    },
  },

  link: {
    position: 'absolute',
    right: '0.5rem',
  },
});

export const ToolbarStyles = defineMultiStyleConfig({ baseStyle });
