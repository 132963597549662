import { createMultiStyleConfigHelpers } from "@chakra-ui/system";

const myAnatomy = ['outerCard', 'innerCard', 'contentBox', 'categoryHeader'];
const helpers = createMultiStyleConfigHelpers(myAnatomy);

export const RenderTableStyles = helpers.defineMultiStyleConfig({
  parts: myAnatomy,
  baseStyle: {
    outerCard: {
      overflow: 'hidden',
      bg: 'fpd.700',
      border: '1px solid',
      borderColor: 'fpd.800',
    },
    innerCard: {
      background: 'fpd.500',
      borderBottom: '1px solid',
      borderColor: 'fpd.800',
      p: '0.5rem',
    },
    contentBox: {
      p: '.2rem 1rem',
      fontSize: 'sm'
    },
    categoryHeader: {
      fontSize: 'sm',
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {},
});
