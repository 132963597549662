import { zoom } from 'd3-zoom';
import { scaleFactor } from '../presentation/utils/scaleFactor';
import { updateControlPositionsOnZoomOrResize } from './updateControlPositionsOnZoomOrResize';
/**
 * Handles the zoom event and updates the scale size
 * of the front panel and cross reference element.
 */
function zoomHandler(svgElement, frontPanel, event) {
  // Extract the zoom scale and translation from the event transform object.
  const { k, x, y } = event.transform;

  // Select the cross reference element and vertex handles from the front panel.
  const crossObject = frontPanel.select('#crossReferenceElement');

  // Extract the cross reference element matrix and view box from the SVG element.
  const CrossEl =
    !crossObject.empty() &&
    crossObject.node().transform.baseVal.consolidate().matrix;
  const viewBox = svgElement.node().viewBox.baseVal;

  // Extract the current front panel transformation matrix.
  // Sets Transformation Matrix based on : [scale x, b, c, scale y, translate x, translate y];
  // it's been understood that in order to prevent misbehavior of zooming while we are using the firefox, we have to get the value of
  // transformation in the middle of the zooming action
  const frontPanelTransform = frontPanel
    .node()
    .transform.baseVal.consolidate().matrix;

  // As parameters 'a' and 'd' are responsible for scaling,
  // they should be updated correspondingly during zooming

  frontPanelTransform.a = k;
  frontPanelTransform.d = -k;

  //parameter 'e' is also responsible for the translation factor of 'x'
  frontPanelTransform.e = x;

  // We already have mesh data with reversed 'y' parameter,
  // so we need to calculate the proper y factor based on
  // the height of the panel during zooming action.
  frontPanelTransform.f = y + k * viewBox.height;
  const crossScale = scaleFactor(frontPanel) < 2 ? scaleFactor(frontPanel) : 2;

  // Scale the cross reference element if it exists.
  if (CrossEl) {
    CrossEl.a = crossScale;
    CrossEl.d = crossScale;
  }

  updateControlPositionsOnZoomOrResize(frontPanel);
}

/**
 * Creates a zoom handler function that updates
 * the front panel transformation.
 */
export function createZoomHandler(
  svgElement,
  frontPanelRef,
  transformationUpdater
) {
  // Create a D3 zoom behavior object for the SVG element.
  return (
    zoom()
      // Set the zoom behavior extent to the size of the SVG element.
      .extent([
        [0, 0],
        [svgElement.node().clientWidth, svgElement.node().clientHeight],
      ])

      // Set the zoom behavior scale extent to the range [0.5, 10].
      .scaleExtent([0.5, 10])

      // Register a 'zoom' event listener that calls the zoomHandler function.
      .on('zoom', (event) => zoomHandler(svgElement, frontPanelRef, event))

      // Register an 'end' event listener that updates the front panel transformation.
      .on('end', () => {
        transformationUpdater({
          fpTransform: frontPanelRef.attr('transform'),
        });
      })

      // Filter events to allow panning.
      .filter(function (event) {
        return (
          (event.button === 0 || event.button === 1) &&
          // ignore double-click events for zooming:
          event.type !== 'dblclick'
        );
      })
  );
}
