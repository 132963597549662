/**
 * ModalDisplayForSmallScreens Component
 *
 *
 * This component is designed to manage the display of a modal specifically
 * in situations where the screen size falls below 1053 pixels (opened by 'isScreenSmall' state). It is intended to
 * inform users that the current web application does not fully support small screen
 * devices.
 *
 * **/

// Built-in
import React, { useContext } from 'react';

// External
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Text,
} from '@chakra-ui/react';

// Internal
import { ApiContext } from '../../../../context/ApiContext';

const ScreenSizeAlert = () => {
  const { isScreenSmall } = useContext(ApiContext);
  const { onClose } = useDisclosure();
  const { t } = useTranslation('SmallScr');

  return (
    <>
      <Modal
        isOpen={isScreenSmall}
        onClose={onClose}
        size="lg"
        autoFocus={false}
      >
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(90deg)"
        />
        <ModalContent>
          <ModalHeader fontSize="large">{t('SmallScr:Header')}</ModalHeader>
          <ModalBody>
            <Text whiteSpace="pre-line" fontSize="md">
              {t('SmallScr:Message')}
            </Text>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ScreenSizeAlert;
