import { tabsAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const helpers = createMultiStyleConfigHelpers(tabsAnatomy.keys);

export const TabsStyles = helpers.defineMultiStyleConfig({
  baseStyle: {},
  variants: {
    fpd: {
      root: {
        pl: 0,
        pr: 0,
        mt: 0,
      },
      tablist: {
        pl: '16px',
        border: 0,
        position: 'sticky',
        zIndex: 4,
        boxShadow: 'none',
      },
      tab: {
        fontSize: 'xs',
        my: 1,
        color: 'fpd.400',
        _selected: { bg: 'fpd.600', color: 'fpd.50', boxShadow: 'none' },
        borderColor: 'fpd.800',
        border: 'solid 1px',
        _hover: {
          borderColor: 'fpd.accentBorder',
          bgGradient: 'linear(to-t, fpd.hover, fpd.hoverGradient)',
        },
      },
      tabpanel: {
        p: 0,
        pt: '8px',
      },
    },
  },
  defaultProps: {
    variant: 'fpd',
  },
});
