import { createMultiStyleConfigHelpers } from "@chakra-ui/system";

const myAnatomy = ['container', 'input', 'inputError'];
const helpers = createMultiStyleConfigHelpers(myAnatomy);

export const LoginStyles = helpers.defineMultiStyleConfig({
  parts: myAnatomy,
  baseStyle: {
    container: {
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'start',
    },
    input: {
      w: '15rem',
    },
    inputError: {
      w: '15rem',
      borderColor: 'red',
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {},
});
