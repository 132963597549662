/**
 * 'Choice' renders an element with 'choice' properties.
 * We consider 'ptSelection' and 'ptMapSelection' as 'Choice type'
 * based on the mapping of FPD properties to Web-FPD.
 */

// Built-in
import React from 'react';

// External
import { useField, useFormikContext } from 'formik';

import CustomSelect from '../../CustomSelect';
import PropertyItem from './PropertyItem';

const Choice = (props) => {
  const { label, focusHandler, res, isGrouped, ...rest } = props;

  const { is_shared } = res;
  const pool = is_shared ? res.pool : ['', 'true', 'false'];

  const [fieldProps] = useField(rest);
  const field = { ...fieldProps, value: is_shared ? fieldProps.value : '' };

  const { setFieldValue, submitForm } = useFormikContext();

  const handleChange = (event) => {
    setFieldValue(props.name, event.key);
    submitForm();
  };
  const hasPoolData = pool?.length > 0;
  const selectOptions = hasPoolData ? (
    pool.map((item) => (
      <option key={item} value={item}>
        {item}
      </option>
    ))
  ) : (
    <option>{label}</option>
  );
  
  return (
    <PropertyItem
      label={label}
      variant={isGrouped ? 'grouped' : 'single'}
    >
      <CustomSelect
        dropDownWidth="12.112rem"
        dropDownHeight="1.33rem"
        value={field.value}
        options={selectOptions}
        onChange={handleChange}
      />
    </PropertyItem>
  );
};

export default React.memo(Choice);
