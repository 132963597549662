/**
 * The 'ToastManager' component listens for changes in the document messages state
 * and displays appropriate toast notifications for information, errors, and other messages,
 * ensuring centralized and consistent message handling across the fpd-web app.
 */
// Built-in
import React, { useEffect, useContext } from 'react';

// External
import { createStandaloneToast } from '@chakra-ui/toast';

// Internal
import { ApiContext } from '../context/ApiContext';

const { ToastContainer, toast } = createStandaloneToast();

const ToastManager = () => {
  const { docMsgs, setDocMsgs } = useContext(ApiContext);

  useEffect(() => {
    if (docMsgs.info) {
      toast({
        title: docMsgs.title,
        description: docMsgs.info,
        status: 'success',
        duration: 2000,
        isClosable: false,
      });
      setDocMsgs((prevState) => ({ ...prevState, info: '' }));
    }
  }, [docMsgs.info, setDocMsgs]);

  useEffect(() => {
    if (docMsgs.errorMsg) {
      toast({
        title: 'Something went wrong',
        description: docMsgs.errorMsg,
        status: 'error',
        duration: null,
        isClosable: true,
      });
      setDocMsgs((prevState) => ({ ...prevState, errorMsg: '' }));
    }
  }, [docMsgs.errorMsg, setDocMsgs]);

  useEffect(() => {
    if (docMsgs.errors) {
      const errorType = docMsgs.errors?.source?.source?.type;
      const messages = docMsgs.errors?.source?.source?.messages || [];
      const message = docMsgs.errors?.source?.message || docMsgs.errors;

      if (errorType === 'model_error' && Array.isArray(messages)) {
        messages.forEach((msg) => {
          toast({
            title: 'Front Panel Designer Error',
            description: msg.message,
            status: 'error',
            duration: null,
            isClosable: true,
          });
        });
      } else if (typeof message === 'string') {
        toast({
          title: 'Front Panel Designer Error',
          description: message,
          status: 'error',
          duration: null,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Something went wrong',
          description: JSON.stringify(docMsgs.errors),
          status: 'error',
          duration: null,
          isClosable: true,
        });
      }

      setDocMsgs((prevState) => ({ ...prevState, errors: '' }));
    }
  }, [docMsgs.errors, setDocMsgs]);

  useEffect(() => {
    if (docMsgs.warnings) {
      docMsgs.warnings.forEach((warning) => {
        toast({
          title: 'Front Panel Designer Warning',
          description: warning.message,
          status: 'warning',
          duration: null,
          isClosable: true,
        });
      });
      setDocMsgs((prevState) => ({ ...prevState, warnings: '' }));
    }
  }, [docMsgs.warnings]);

  return <ToastContainer />;
};

export default ToastManager;
