/**
 * The 'DownloadPanel' component, manages the action of downloading the front panel
 */

// Built-in
import React, { useContext } from 'react';

// Internal
import { ToolbarIcon } from './ToolbarIcon';
import { ApiContext } from '../../context/ApiContext'; // Provides API context (Global shared state) to the component

function DownloadPanel() {
  const { Document, downloadDocument } = useContext(ApiContext);
  const downloadFpClickHandler = () => {
    downloadDocument(Document.id, Document.name);
  };
  return (
    <ToolbarIcon
      label="Downlaod"
      imagePath="/icons/download_panel.svg"
      isDisabled={!Document.id}
      onClickHandler={downloadFpClickHandler}
    />
  );
}

export default DownloadPanel;
