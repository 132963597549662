const memberPropRenderer = ({ key, properties }, members) => {
  if (key === 'pen-assign-model' || key === 'tool-selection-cat') {
    return properties
      .map((property) =>
        Object.keys(members)
          .filter((member) => RegExp(property, 'g').test(member))
          .reduce((acc, key) => ({ ...acc, [key]: members[key] }), {})
      )
      .reduce((acc, items) => ({ ...acc, ...items }), {});
  }

  return properties
    .filter((property) => members[property])
    .reduce((acc, property) => {
      return { ...acc, [property]: members[property] };
    }, {});
};
export const groupMemberPropRenderer = ({ key, properties }, members) => {
  let memberProps = properties
    .filter((property) => members[property])
    .reduce((acc, property) => {
      return { ...acc, [property]: members[property] };
    }, {});

  return memberProps;
};

export default memberPropRenderer;
