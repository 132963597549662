/**
 *The  'SideBar' component, renders and represents relevant panel and element properties taken from the server.
 */
// Built-in
import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';

// External
import { Formik } from 'formik'; // importing the Formk object for formik library which is form library for react
import { isEqual } from 'lodash'; // Importing 'lodash Javascript library' ('lodash') is a JS helper library for arrays, strings and objects.
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Text,
  useMultiStyleConfig,
  createStylesContext,
  Divider,
} from '@chakra-ui/react'; // Importing necessary objects from '@chakra-ui' library for rendering the UI.

// Internal
import { ApiContext } from '../../context/ApiContext'; // Provides API context (Global shared state) to the component
import groupElementSeparator from './groupElementSeparator';
import membersPropsInitializer from './membersPropsInitializer';
import isGroupedSelection from './isGroupSelected';
import GroupAndElementTabs from './GroupAndElementTabs';
import RenderSidebar from './RenderSidebar';

const [StylesProvider, useStyles] = createStylesContext('SideBarStyles');

const SideBarHeader = (props) => {
  const { title, ...rest } = props;
  const styles = useStyles();

  return (
    <Box __css={styles.sideBarHeader} {...rest}>
      <Text>Properties : {title}</Text>
    </Box>
  );
};

const SideBar = (props) => {
  const {
    Document,
    Selection,
    DocumentVersion,
    sideHeaderText,
    updateMemberProps,
  } = useContext(ApiContext);
  const [panelStatus, setPanelStatus] = useState(''); //the 'panelStatus' need to be persisted between component re-rendering.
  const formikRef = useRef(null);
  const inputRef = useRef(null);
  const sideBarRef = useRef(null);

  const { ...rest } = props;

  const styles = useMultiStyleConfig('SideBar', {});

  /**
   * In modifiedPropsDataProvider, raw data is processed and well-needed data is returned for API consumption
   */
  const modifiedPropsDataProvider = (dataToModify) => {
    const obj = {
      member_ids: Selection.member_ids,
      member_properties: dataToModify,
      properties: {},
      document_version: Document.version,
    };
    return obj;
  };

  /**
   *  changedPropsValueProvider,returns just modified data for API consumption
   */
  const changedPropsValueProvider = (objSource, objModif) => {
    return Object.keys(objModif).reduce((diff, key) => {
      if (objSource[key] === objModif[key]) return diff;
      return {
        ...diff,
        [key]: objModif[key],
      };
    }, {});
  };

  /**
   * Displays panel properties
   * Triggs once getPropertiesOfSelection has been performed by selecting element.
   * Displays corresponding properties for panel and selected elements(id) in the 'status' section of the sidebar.
   */

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') return;
    const panelStatusData = {
      documentId: Document.id,
      masterVersion: DocumentVersion.id,
      panelName: Document.name,
      elementIds: Selection.member_ids,
    };
    setPanelStatus(JSON.stringify(panelStatusData, null, 2));
  }, [Selection.member_ids, DocumentVersion.id, Document.id, Document.name]);

  /**
   *Sends postPropertiesOfSelection with 'POST' request based on updated element properties submitted as payload.
   *Triggered when the user hits the Enter key while changing the Input control values(dimValues). Alternatively, just update the input's value that has pool properties(like choice or option).
   *Sets panel version, collection version, and ID states which they Subsequently cause the updated SVG data has be loaded
   */

  const submitDataProvider = (
    data,
    { setSubmitting, setStatus, resetForm }
  ) => {
    const initialProps = membersPropsInitializer(Selection.member_properties);
    const anyValueNotUpdated = isEqual(formikRef.current.values, initialProps);
    if (anyValueNotUpdated) return;
    updateMemberProps(
      modifiedPropsDataProvider(
        memberPropsModifier(data, Selection.member_properties)
      ),
      setSubmitting,
      setStatus,
      resetForm,
      { values: initialProps }
    );
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }, 50);

    return () => clearTimeout(timer);
  }, [Selection.member_properties]);

  const handleReset = () => {
    const timer = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }, 0);

    return () => clearTimeout(timer);
  };

  const renderHandler = (data) => {
    inputRef.current = null;
  };
  const focusHandler = (e) => {
    e.target.select();
    inputRef.current = e.target;
  };
  const memberPropsModifier = (data, membersProps) => {
    const diffObj = changedPropsValueProvider(
      membersPropsInitializer(membersProps),
      data
    );
    Object.entries(diffObj).forEach(
      ([key, value]) => (membersProps[key].value = value)
    );
    return membersProps;
  };

  const handleOnChange = (event) => {
    event.target.type !== 'text' && formikRef.current.handleSubmit();
  };
  const initialValues = useMemo(
    () => membersPropsInitializer(Selection.member_properties),
    [Selection.member_properties]
  );

  return (
    <>
      <Box id="side-props" ref={sideBarRef} __css={styles.sideBar} {...rest}>
        <StylesProvider value={styles}>
          <SideBarHeader title={sideHeaderText} />

          <Formik
            innerRef={formikRef}
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={submitDataProvider}
            onReset={handleReset}
          >
            {(props) => (
              <form
                onSubmit={props.handleSubmit}
                onBlur={renderHandler}
                onChange={handleOnChange}
              >
                {isGroupedSelection(Selection.member_properties) ? (
                  <GroupAndElementTabs
                    member_properties={groupElementSeparator(
                      Selection.member_properties
                    )}
                    focusHandler={focusHandler}
                  />
                ) : (
                  <RenderSidebar
                    member_properties={Selection.member_properties}
                    focusHandler={focusHandler}
                    propsTopCategory={'normal'}
                  />
                )}
              </form>
            )}
          </Formik>
        </StylesProvider>
      </Box>
      <Accordion allowMultiple>
        <Divider />
        {process.env.NODE_ENV === 'development' ? (
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                {!!sideBarRef.current &&
                  sideBarRef.current.setAttribute(
                    'style',
                    isExpanded ? `height:68% ` : 'height:94%'
                  )}
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      Status
                    </Box>
                  </AccordionButton>
                </h2>
                {isExpanded && (
                  <AccordionPanel pb={4}>
                    <Box id="svg-props">
                      {' '}
                      <pre
                        style={{
                          maxHeight: '175px',
                          maxWidth: '340px',
                          whiteSpace: 'pre-line',
                          overflowX: 'hidden',
                          overflowY: 'auto',
                        }}
                      >
                        {panelStatus}
                      </pre>
                    </Box>
                  </AccordionPanel>
                )}
              </>
            )}
          </AccordionItem>
        ) : (
          !!sideBarRef.current &&
          sideBarRef.current.setAttribute('style', 'height:94%')
        )}
      </Accordion>
    </>
  );
};
export default React.memo(SideBar);
