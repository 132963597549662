//
import { useEffect, useContext } from 'react';
import { useAuth } from '../context/AuthContext';
import { ApiContext } from '../context/ApiContext';
import { User } from '../context/Types';

export const useResetApiStatesOnLogout = () => {
  const { userState } = useAuth();
  const { resetStates } = useContext(ApiContext);

  useEffect(() => {
    if (userState.state === User.AUTHENTICATED) return;
    resetStates();
  }, [userState.state, resetStates]);
};
