import { fromViewPortToFrontPanelTransformer } from '../../../utils/coordinateTransformer';

export const hamburgerCoordinate = (frontPanelGp) => {
  // Extract the bounding box of the specified element
  const boundingBox = frontPanelGp.select('.BboxRectangle').node().getBBox();

  // Attempt to find the vertex element in the document
  const vertexElement = document.querySelector('#ne-resize');

  if (vertexElement) {
    // If the vertex element exists, use its coordinates directly
    const { right: vertexX, top: vertexY } =
      vertexElement.getBoundingClientRect();
    return { x: vertexX, y: vertexY };
  }

  // Transform viewport coordinates to front panel coordinates if vertex element is not found
  const { x, y } = fromViewPortToFrontPanelTransformer(
    boundingBox.x + boundingBox.width,
    boundingBox.y + boundingBox.height,
    frontPanelGp,
    false,
    true
  );

  return { x, y };
};
