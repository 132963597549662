import { defineStyleConfig } from '@chakra-ui/react'

const commonStyles = {
    fontWeight: 200,
    fontSize: 'small',
    position: 'absolute',
    right: '196px',
    textAlign: 'right',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    pr: '.3rem',
  };

export const LabelStyles = defineStyleConfig({
    baseStyle: {},
    sizes: {},
    variants: {
        single: {
            ...commonStyles,
            maxW: '80px',
        },
        grouped: {
            ...commonStyles,
            maxW: '50px',
        },
    },
    defaultProps: {},
});
