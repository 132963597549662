import { defineStyleConfig } from '@chakra-ui/react'

export const TooltipStyles = defineStyleConfig({
  baseStyle: {
    bg: 'fpd.50',
    borderRadius: 'none',
    color: 'fpd.950',
  },
  sizes: {},
  variants: {},
  defaultProps: {},
});
