import { MeshStateTypes } from '../../../../context/Types';
import { bboxDrawer } from '../../presentation/drawing/boundingBox/bboxDrawer';
import { bboxOfSelection } from '../../presentation/drawing/boundingBox/bboxOfSelection';
import { crossDrawer } from '../../presentation/drawing/crossDrawer';
import descriptionGenerator from './descriptionGenerator';
import { elementSelector } from './elementSelector';
import { panelSelector } from './panelSelector';
import * as d3 from 'd3';

/**
 * This function runs every time the mesh is updated and
 * is responsible for selecting panels or other elements
 * in the selection based on whether the mesh is updated or initiated.
 */
export const onLoadSelection = function (
  MeshState,
  updateFpSvgStates,
  getSelectionsForGivenElements,
  frontPanelGp,
  ElementToBeSelected,
  patchMesh,
  groupOrUngroupSelectionProvider,
  duplicateSelection,
  deleteSelection,
  setRotationModalOpen,
  setMoveModalOpen,
  setMirrorModalOpen,
  setResizeModalOpen,
  setAlignDistributeModalOpen
) {
  if (MeshState === MeshStateTypes.initial) {
    panelSelector(
      updateFpSvgStates,
      getSelectionsForGivenElements,
      frontPanelGp
    );
  } else {
    const updateSelection = updateFpSvgStates({
      selectedElementIds: [...ElementToBeSelected.element_ids],
      duplicationStack: [...ElementToBeSelected.element_ids],
      isSelectionChanged: true,
    });

    const selectedElements = elementSelector(
      frontPanelGp,
      updateSelection.selectedElementIds
    );
    if (!selectedElements.length) {
      panelSelector(
        updateFpSvgStates,
        getSelectionsForGivenElements,
        frontPanelGp
      );
      return;
    }
    d3.select('.hamburgerIcon').remove();

    bboxDrawer({
      bbox: bboxOfSelection({
        frontPanelGp: frontPanelGp,
        selectedElementIds: updateSelection.selectedElementIds,
        isDragged: false,
      }),
      frontPanelGp: frontPanelGp,
      selectedElementIds: updateSelection.selectedElementIds,
      patchMesh: patchMesh,
      isOverlaped: false,
      groupOrUngroupSelectionProvider: groupOrUngroupSelectionProvider,
      duplicateSelection: duplicateSelection,
      deleteSelection: deleteSelection,
      setRotationModalOpen: setRotationModalOpen,
      setMoveModalOpen: setMoveModalOpen,
      setMirrorModalOpen: setMirrorModalOpen,
      setResizeModalOpen: setResizeModalOpen,
      setAlignDistributeModalOpen: setAlignDistributeModalOpen,
    });

    //responsible for drawing the reference cross for a single selected element
    if (updateSelection.selectedElementIds.length === 1) {
      crossDrawer(frontPanelGp, updateSelection.selectedElementIds);
    }
    const descText = descriptionGenerator({
      frontPanelGp: frontPanelGp,
      selectedElementIds: updateSelection.selectedElementIds,
    });

    getSelectionsForGivenElements(
      [...updateSelection.selectedElementIds],
      descText
    );
  }
};
