import { bboxDrawer } from '../../presentation/drawing/boundingBox/bboxDrawer';
import { bboxOfSelection } from '../../presentation/drawing/boundingBox/bboxOfSelection';
import { bboxRemover } from '../../presentation/drawing/boundingBox/bboxRemover';
import { crossDrawer } from '../../presentation/drawing/crossDrawer';
import descriptionGenerator from './descriptionGenerator';
import { panelSelector } from './panelSelector';
import { selection } from './selection';
/*
 * Updates the selection and front panel graphics based on the selected element
 **/

export const selectionProvider = function (
  element,
  fpSvgStates,
  event,
  updateFpSvgStates,
  frontPanelGp,
  getSelectionsForGivenElements,
  patchMesh,
  groupOrUngroupSelectionProvider,
  duplicateSelection,
  deleteSelection,
  setRotationModalOpen,
  setMoveModalOpen,
  setMirrorModalOpen,
  setResizeModalOpen,
  setAlignDistributeModalOpen
) {
  const shiftKey = event.sourceEvent.shiftKey;
  const ctrlKey = event.sourceEvent.ctrlKey;
  // Check if the front panel is selected.
  const isPanelSelected = frontPanelGp.select('.BboxRectangle').empty();
  // Update the selection and front panel graphics based on the selected element and keyboard modifiers.
  const updateSelection = updateFpSvgStates(
    selection(element, fpSvgStates, isPanelSelected, shiftKey, ctrlKey)
  );
  // If the selection hasn't changed, return.
  if (!updateSelection.isSelectionChanged) return;

  // Remove the previous bounding box and cross reference element from the front panel graphics.
  bboxRemover(frontPanelGp);

  // If no element is selected, select the front panel.
  if (updateSelection.selectedElementIds.length === 0) {
    return panelSelector(
      updateFpSvgStates,
      getSelectionsForGivenElements,
      frontPanelGp
    );
  }
  // Draw the new bounding box around the selected elements.
  bboxDrawer({
    bbox: bboxOfSelection({
      frontPanelGp: frontPanelGp,
      selectedElementIds: updateSelection.selectedElementIds,
      isDragged: false,
    }),
    frontPanelGp: frontPanelGp,
    selectedElementIds: updateSelection.selectedElementIds,
    patchMesh: patchMesh,
    isOverlaped: false,
    groupOrUngroupSelectionProvider: groupOrUngroupSelectionProvider,
    duplicateSelection: duplicateSelection,
    deleteSelection: deleteSelection,
    setRotationModalOpen: setRotationModalOpen,
    setMoveModalOpen: setMoveModalOpen,
    setMirrorModalOpen: setMirrorModalOpen,
    setResizeModalOpen: setResizeModalOpen,
    setAlignDistributeModalOpen: setAlignDistributeModalOpen,
  });

  // If a single element is selected, draw the cross reference element.
  if (updateSelection.selectedElementIds.length === 1) {
    crossDrawer(frontPanelGp, updateSelection.selectedElementIds);
  }

  // Get the selections for the updated elements.
  const descText = descriptionGenerator({
    frontPanelGp: frontPanelGp,
    selectedElementIds: updateSelection.selectedElementIds,
  });
  getSelectionsForGivenElements(
    [...updateSelection.selectedElementIds],
    descText
  );
};
