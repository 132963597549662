import { IconButton, useStyleConfig } from '@chakra-ui/react';

import { IconEye, IconEyeInvisible } from '../../../../../assets/IconsSvg';

const ShowPasswordButton = (props) => {
  const { showPassword, ...rest } = props;

  const styles = useStyleConfig('ShowPasswordButton', {});

  return (
    <IconButton
      sx={styles}
      icon={showPassword ? <IconEyeInvisible /> : <IconEye />}
      {...rest}
    />
  );
};

export default ShowPasswordButton;
