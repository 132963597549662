import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const headerAnatomy = ['headerContainer', 'headerIcon'];
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(headerAnatomy);

const baseStyle = definePartsStyle({
  headerContainer: {
    bg: 'black',
    borderTop: '1px solid',
    borderColor: 'fpd.800',
    boxShadow: '0 1px 0 0 var(--chakra-colors-fpd-500)',
    columnGap: '0px!important',
  },
  headerIcon: {
    m: '0.125rem',
    ml: '0.75rem',
    boxSize: '1.5rem',
  },
});

export const HeaderStyles = defineMultiStyleConfig({ baseStyle });
