import { useEffect, useRef, useState } from 'react';

/**
 * The useCheckOverflow is a custom React Hook that is used to
 * determine whether an HTML element's content overflows its container.
 * The hook takes a label prop as a parameter,
 * sets up a reference to an HTML element, and checks for overflow whenever the
 * label prop or the window size changes.
 */
export const useCheckOverflow = (label) => {
  const textRef = useRef(null);
  const [isOverflowed, setIsOverflowed] = useState(false);

  useEffect(() => {
    let timerId;
    const checkOverflow = () => {
      timerId = setTimeout(() => {
        if (textRef.current) {
          const isOverflow =
            textRef.current.offsetWidth < textRef.current.scrollWidth ||
            textRef.current.offsetHeight < textRef.current.scrollHeight;

          setIsOverflowed(isOverflow);
        }
      }, 0); // we don't need a long delay, just push the execution to the end of the call stack
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      clearTimeout(timerId);
      window.removeEventListener('resize', checkOverflow);
    };
  }, [label]);

  return [isOverflowed, textRef];
};
