import {
    Button,
    useStyleConfig,
    Spinner,
  } from '@chakra-ui/react';

const ActionButton = (props) => {
  const { variant, size, label, isLoading = false, ...rest } = props;

  const styles = useStyleConfig('ActionButton', { variant, size });

  return (
    <Button __css={styles} {...rest}>
      {isLoading ? <Spinner /> : label}
    </Button>
  );
};

export default ActionButton;