// Built-in
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ApiProvider } from './context/ApiContext';

// External
import { BrowserRouter } from 'react-router-dom';
import './i18n/i18n';

// Internal
import App from './components/App';
import { AuthProvider } from './context/AuthContext';
import ToastManager from './components/ToastManager';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <StrictMode>
    <ApiProvider>
      <AuthProvider>
        <BrowserRouter>
          <App />
          <ToastManager />
        </BrowserRouter>
      </AuthProvider>
    </ApiProvider>
  </StrictMode>
);
