/**
 * The 'Feedback' component, give the user the posibility of feedback.
 */

// Built-in
import React, { useState, useEffect, useContext } from 'react';

// External

import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Textarea,
  HStack,
  Flex,
  FormLabel,
  createStylesContext,
  useMultiStyleConfig,
  Input,
  useToast,
} from '@chakra-ui/react'; // Importing necessary objects from '@chakra-ui' library for rendering the UI.

import axios from 'axios';

import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from 'react-simple-captcha';

import { Formik, Form, Field } from 'formik';

import * as Yup from 'yup';

// Internal
import { ApiContext } from '../../context/ApiContext';

import { useTranslation } from 'react-i18next';

import GroupContainer from './modal/dialog/GroupContainer';
import ActionButton from './modal/dialog/elements/ActionButton';

import { ToolbarIcon } from './ToolbarIcon';

import FeedbackService from '../../api/services/Feedback';
import { FPD_LIMITS } from '../../api/Client';

const [StylesProvider, useStyles] = createStylesContext('Feedback');

const FeedbackAdvice = () => {
  const styles = useStyles();
  const { t } = useTranslation(['Feedback']);

  return (
    <>
      <FormLabel htmlFor="advice">{t('Feedback:Advice')}</FormLabel>
      <Field sx={styles.input} name="advice" as={Textarea} />
    </>
  );
};

const FeedbackCaptcha = () => {
  const styles = useStyles();
  const { t } = useTranslation(['Feedback']);

  return (
    <>
      <Flex __css={styles.captcha}>
        <LoadCanvasTemplateNoReload />
      </Flex>
      <FormLabel id="captcha" htmlFor="captcha">
        {t('Feedback:Captcha')}
      </FormLabel>
      <Field name="captcha" as={Input} />
    </>
  );
};

const FeedbackContainer = (props) => {
  const { size, variant, children, ...rest } = props;
  const styles = useMultiStyleConfig('Feedback', { size, variant });

  return (
    <Flex id="advice" __css={styles.container} {...rest}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </Flex>
  );
};

function Feedback(props) {
  const { isLoading, setDocMsgs } = useContext(ApiContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [shouldInitializeCaptcha, setShouldInitializeCaptcha] = useState(false);
  const toast = useToast();
  useEffect(() => {
    if (isOpen) {
      // Trigger CAPTCHA initialization when modal opens
      setShouldInitializeCaptcha(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (shouldInitializeCaptcha) {
      loadCaptchaEnginge(6);
      setShouldInitializeCaptcha(false);
    }
  }, [shouldInitializeCaptcha]);

  const { t } = useTranslation(['Tooltip', 'Feedback', 'ActionButton']);

  const modalCloseHandler = () => {
    onClose();
    toast.closeAll();
  };

  const feedbackValidationSchema = Yup.object().shape({
    advice: Yup.string().required('Advice is required'),
    captcha: Yup.string()
      .min(6, 'Six characters are required')
      .test(
        'six-characters',
        (d) => 'It must be six characters',
        (value) => value?.trim().length < 7
      )
      .test(
        'is-valid',
        (d) => `${d.path} is not valid`,
        (value) => validateCaptcha(value, false)
      ),
  });

  // Initial values
  const initialValues = {
    advice: '',
    captcha: '',
  };

  function StringSizeInBytes(string) {
    return new Blob([string]).size;
  }

  // Handle form submission
  const onSubmit = async (values) => {
    try {
      if (StringSizeInBytes(values.advice) > FPD_LIMITS) {
        setDocMsgs({
          errorMsg: `Form Data Size should not exceed ${(
            FPD_LIMITS /
            (1024 * 1024)
          ).toFixed(2)} MB.`,
        });
        return;
      }
      await axios(FeedbackService.feedback({ feedback: values.advice }));

      setDocMsgs({
        title: 'Feedback Success',
        info: 'You advices are sending.',
        errorMsg: '',
      });

      modalCloseHandler();
    } catch (error) {
      setDocMsgs((prevState) => ({
        ...prevState,
        errorMsg: 'We cannot process your request!',
      }));
    } finally {
      isLoading(false);
    }
  };

  return (
    <>
      <ToolbarIcon
        label={t('Tooltip:Feedback')}
        imagePath="/icons/feedback.svg"
        onClickHandler={onOpen}
      />
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={modalCloseHandler}
          size="xl"
          autoFocus={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t(`Feedback:Header`)}</ModalHeader>
            <ModalCloseButton />
            <Formik
              initialValues={initialValues}
              validationSchema={feedbackValidationSchema}
              validateOnMount={true}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, isValid }) => (
                <Form>
                  <ModalBody>
                    <GroupContainer title={t('Feedback:GroupHeader')}>
                      <FeedbackContainer>
                        <FeedbackAdvice />
                        <FeedbackCaptcha />
                      </FeedbackContainer>
                    </GroupContainer>
                  </ModalBody>
                  <ModalFooter>
                    <HStack>
                      <ActionButton
                        type="submit"
                        label={t(`ActionButton:Submit`)}
                        isLoading={isLoading}
                        isDisabled={!isValid}
                      />
                    </HStack>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

export default Feedback;
