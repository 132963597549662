export const ScrollbarStyles = {
  '&::-webkit-scrollbar': {
    width: '18px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'fpd.600',
    border: '1px solid',
    borderColor: 'fpd.800',
    borderRight: 'none',
    borderBottom: 'none',
    borderTop: 'none',
    boxShadow: 'inset 0 0 0 1px  var(--chakra-colors-fpd-400)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'fpd.400',
    border: '1px solid',
    borderColor: 'fpd.950',
    borderRight: 'none',
    borderBottom: 'none',
    borderTop: 'none',
    boxShadow: 'inset 0 0 0 1px  var(--chakra-colors-fpd-400)',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: 'fpd.700',
  },
  // Standard scrollbar properties for Firefox
  '&': {
    scrollbarWidth: 'auto',
    scrollbarColor: 'var(--chakra-colors-fpd-400) var(--chakra-colors-fpd-600)',
  },
};
