import { elementSelector } from './elementSelector';

// Function that checks if there's a group of selected nodes in the front panel
const isGroup = (frontPanelGp, selectedIdsNodes) =>
  selectedIdsNodes.length > 1 &&
  frontPanelGp.select('#crossReferenceElement').node()
    ? 'Group'
    : null;

// Function that checks if there's more than one element selected
const isMultiElements = (selectedIdsNodes, selectedElementIds) =>
  selectedIdsNodes.length > 1 ? generateElementsDescription(selectedIdsNodes, selectedElementIds) : null;

const generateElementsDescription = (selectedIdsNodes, selectedElementIds) => {
  const firstElementDesc = selectedIdsNodes[0].getAttribute('fpd.short_desc');

  const isDifferent = selectedIdsNodes.some(
    (node) => node.getAttribute('fpd.short_desc') !== firstElementDesc
  );
  
  if(isDifferent)
    return `Different Objects [${selectedElementIds.length}]`;
  else
    return `${firstElementDesc} [${selectedElementIds.length}]`;
};

// Function that retrieves the attribute 'fpd.short_desc' from the first selected node
const isSingleElement = (selectedIdsNodes) =>
  selectedIdsNodes[0]?.getAttribute('fpd.short_desc');

// Main function that generates a description based on the selected elements
const descriptionGenerator = ({
  frontPanelGp,
  selectedElementIds,
  isPanelSelected = false,
}) => {
  // If the panel is selected, return 'panel'
  if (isPanelSelected) return 'panel';

  // If the panel is not selected, get the selected nodes
  const selectedIdsNodes = elementSelector(frontPanelGp, selectedElementIds);

  // Return the first applicable description: 'Group', 'Elements', or the 'fpd.short_desc' attribute of the first node
  return (
    isGroup(frontPanelGp, selectedIdsNodes) ||
    isMultiElements(selectedIdsNodes, selectedElementIds) ||
    isSingleElement(selectedIdsNodes)
  );
};

export default descriptionGenerator;
