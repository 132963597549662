import { modalAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from "@chakra-ui/system";

const helpers = createMultiStyleConfigHelpers(modalAnatomy.keys);

export const ModalStyles = helpers.defineMultiStyleConfig({
  baseStyle: {},
  sizes: {},
  variants: {
    fpd: {
      header: {
        fontSize: 'xs',
        fontWeight: 'bold',
        paddingRight: '3rem',
        paddingBottom: '0',
      },
      dialog: {
        borderRadius: 'none',
        bg: 'fpd.600',
        color: 'fpd.50',
        fontSize: 'xs',
      },
      closeButton: {
        borderRadius: 'none',
        _hover: {
          transform: 'scale(1.2)',
        },
        _focus: {
          boxShadow: '0 0 0 2px var(--chakra-colors-fpd-accentActiv)',
        },
      },
    },
  },
  defaultProps: {
    variant: 'fpd',
  },
});
