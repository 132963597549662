import { elementSelector } from './elementSelector';
/**
 * patchMeshDelta sends the the id and transformation of selected elements as
 * a data parameter for 'patchMeshData'.
 */

/**
* 'hasGroupIdentifierInSelection' checks whether a group identifier element 
*  is present in the selections.
* This element determines a group's coordination and name.
* It does not have an 'fpd.group' attribute and contains no child paths.

 */
export function hasGroupIdentifierInSelection(elements, groupId) {
  return elements.some((el) => el.getAttribute('fpd.id') === groupId);
}

export const patchMeshDelta = (frontPanelGp, selectedElementIds, patchMesh) => {
  const selectedElements = elementSelector(frontPanelGp, selectedElementIds);
  const deltaMesh = selectedElements.reduce((acc, el) => {
    const groupId = el.getAttribute('fpd.group');
    /**
     * When selecting a group, one element within that group carries
     * an ID (fpd.id)which defines the group's coordinates.
     * This element has no associated path.
     * The remaining elements in the group are tagged with
     * this ID as their 'group ID'(fpd.group).
     * Thus, only the container element with the ID needs patching,
     * not the other elements which reference this ID as their group ID.
     * if the function 'hasItsGroupIdentifierInSelection' evaluates to true
     * for a specific groupId, the corresponding element should not be selected.
     * This indicates that the element is part of a group whose identifier
     * is already included in the selection. Therefore,
     * the group identifier should be selected on behalf of the element,
     * rather than the element itself.
     */
    if (!groupId || !hasGroupIdentifierInSelection(selectedElements, groupId)) {
      const elementFpdId = el.getAttribute('fpd.id');
      const elementTransform = el.getAttribute('transform');
      acc += `<g fpd.id= '${elementFpdId}' transform='${elementTransform}'> </g>`;
    }

    return acc;
  }, '');
  patchMesh(deltaMesh);
};
