import React, { useContext, useRef, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  Flex,
  Stack,
  HStack,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { ModalContext } from '../ModalContext';
import DimValueWithUnit from './elements/NumberInputs';
import GroupContainer from './GroupContainer';
import ActionButton from './elements/ActionButton';
import { unitDisplayMap } from '../../../../context/Types';
import { selectionBoxDataProvider } from './selectionBoxDataProvider';
import DialogOption from './elements/DialogOption';
import ResizeSquare from './Anchor';
import { ApiContext } from '../../../../context/ApiContext';
import { useTranslation } from 'react-i18next';
const resizeDialogStyles = {
  labelW: '30px',
  geoInputsW: '110px',
  percentageInputsW: '100px',
};

const ResizeDialog = () => {
  const [selectedUnit, setSelectedUnit] = useState('millimeters');
  const anchorPoint = useRef('center');
  const { isResizeModalOpen, setResizeModalOpen } = useContext(ModalContext);
  const { resizeSelection } = useContext(ApiContext);
  const { t } = useTranslation(['ActionDialogs', 'ActionButton']);

  const handleClose = () => {
    setSelectedUnit('millimeters');
    setResizeModalOpen(false);
  };
  const handleAnchorClick = (anchor) => {
    anchorPoint.current = anchor;
  };

  const selectionBox = isResizeModalOpen && selectionBoxDataProvider();

  return (
    isResizeModalOpen && (
      <Modal
        isOpen={isResizeModalOpen}
        onClose={handleClose}
        size="xl"
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('ActionDialogs:Resize.Header')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{
                wValue: selectionBox.W,
                hValue: selectionBox.H,
                dwValue: '0.00',
                dhValue: '0.00',
                pwValue: '100.0',
                phValue: '100.0',
                arValue: !selectionBox.isFreeScaled,
              }}
              onSubmit={(values) => {
                values.wValue = `${values.wValue} ${unitDisplayMap[selectedUnit]}`;
                values.hValue = `${values.hValue} ${unitDisplayMap[selectedUnit]}`;
                const isInvalid =
                  parseFloat(values.wValue) * parseFloat(values.hValue);
                if (!isInvalid) {
                  handleClose();
                } else {
                  resizeSelection(
                    resizeDataProvider(
                      values,
                      unitDisplayMap[selectedUnit],
                      anchorPoint.current
                    )
                  );
                  handleClose();
                }
              }}
            >
              {(formikProps) => (
                <Form noValidate>
                  <Box width="100%">
                    <Box width="100%" mb={4}>
                      <GroupContainer title={t('ActionDialogs:Resize.Anchor')}>
                        <Flex justify="center" align="center" height="100%">
                          <ResizeSquare onAnchorClick={handleAnchorClick} />
                        </Flex>
                        <GroupContainer
                          title={t('ActionDialogs:Absolute')}
                          variant="subGroup"
                        >
                          <Stack spacing={3}>
                            <Flex align="center">
                              <DimValueWithUnit
                                label="W"
                                labelW={resizeDialogStyles.labelW}
                                inputW={resizeDialogStyles.geoInputsW}
                                name="wValue"
                                unitOptions={Object.keys(unitDisplayMap).filter(
                                  (unit) =>
                                    unit === 'millimeters' || unit === 'inches'
                                )}
                                defaultUnit={selectedUnit}
                                dependPattern={/^(?:wV|hV|dwV|dhV)/}
                                onUnitChange={setSelectedUnit}
                              />
                            </Flex>

                            <Flex align="center">
                              <DimValueWithUnit
                                label="H"
                                labelW={resizeDialogStyles.labelW}
                                inputW={resizeDialogStyles.geoInputsW}
                                name="hValue"
                                defaultUnit={selectedUnit}
                              />
                            </Flex>
                          </Stack>

                          <Box mb=".6rem" ml={10}>
                            <DialogOption
                              name="arValue"
                              defaultUnit={selectedUnit}
                              isDisabled={
                                !selectionBoxDataProvider().isFreeScaled
                              }
                            >
                              {t('ActionDialogs:Resize.AspectRatio')}
                            </DialogOption>
                          </Box>
                        </GroupContainer>
                        <Flex mb={4}>
                          <Box width="50%" pr={2}>
                            <GroupContainer
                              title={t('ActionDialogs:Relative')}
                              variant="subGroup"
                            >
                              <Stack spacing={3}>
                                <Flex align="center">
                                  <DimValueWithUnit
                                    defaultUnit={selectedUnit}
                                    label="DW"
                                    labelW={resizeDialogStyles.labelW}
                                    inputW={resizeDialogStyles.geoInputsW}
                                    name="dwValue"
                                  />
                                </Flex>

                                <Flex align="center">
                                  <DimValueWithUnit
                                    defaultUnit={selectedUnit}
                                    label="DH"
                                    labelW={resizeDialogStyles.labelW}
                                    inputW={resizeDialogStyles.geoInputsW}
                                    name="dhValue"
                                  />
                                </Flex>
                              </Stack>
                            </GroupContainer>
                          </Box>
                          <Box width="50%" pl={2}>
                            <GroupContainer
                              title={t('ActionDialogs:Percentage')}
                              variant="subGroup"
                            >
                              <Stack spacing={3}>
                                <Flex align="center">
                                  <DimValueWithUnit
                                    defaultUnit={selectedUnit}
                                    label="W"
                                    labelW={resizeDialogStyles.labelW}
                                    inputW={
                                      resizeDialogStyles.percentageInputsW
                                    }
                                    name="pwValue"
                                    displayUnit="%"
                                  />
                                </Flex>

                                <Flex align="center">
                                  <DimValueWithUnit
                                    defaultUnit={selectedUnit}
                                    label="H"
                                    labelW={resizeDialogStyles.labelW}
                                    inputW={
                                      resizeDialogStyles.percentageInputsW
                                    }
                                    name="phValue"
                                    displayUnit="%"
                                  />
                                </Flex>
                              </Stack>
                            </GroupContainer>
                          </Box>
                        </Flex>
                      </GroupContainer>
                    </Box>
                  </Box>
                  <ModalFooter pr="0" pl="0">
                    <HStack spacing={3}>
                      <ActionButton
                        label={t('ActionButton:Cancel')}
                        onClick={() => handleClose()}
                      />
                      <ActionButton
                        label={t('ActionButton:Ok')}
                        type="submit"
                        isDisabled={
                          !(
                            formikProps.values.wValue !== selectionBox.W ||
                            formikProps.values.hValue !== selectionBox.H
                          )
                        }
                      />
                    </HStack>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  );
};

const resizeDataProvider = ({ wValue, hValue }, unit, anchor) => ({
  absolute_w: parseFloat(wValue),
  absolute_h: parseFloat(hValue),
  anchor_point: anchor,
  unit: unit,
});

export default ResizeDialog;
