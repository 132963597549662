import React, { useCallback, useMemo } from 'react';
import { useField, useFormikContext } from 'formik';
import { Checkbox } from '@chakra-ui/react';
import { Units, unitDisplayMap } from '../../../../../context/Types';
import { initValuesProvider } from './NumberInputs';

const DialogOption = ({ children, name, defaultUnit, isDisabled }) => {
  const [field, , helpers] = useField(name);
  const { setFieldValue, initialValues, values } = useFormikContext();
  const isInches = unitDisplayMap[defaultUnit] === unitDisplayMap[Units.INCHES];

  // Prefer using useMemo for derived state to avoid recalculating on every render
  const convertedInitialValues = useMemo(() => {
    return isInches
      ? initValuesProvider(initialValues, defaultUnit)
      : initialValues;
  }, [isInches, initialValues, defaultUnit]);

  const customOnChange = (e) => {
    const { checked } = e.target;
    helpers.setValue(checked);

    if (!checked || name !== 'arValue') return;

    const updatedValueNum = parseFloat(values['wValue']);
    const initialWValueNum = parseFloat(convertedInitialValues['wValue']);
    const initialHValueNum = parseFloat(convertedInitialValues['hValue']);

    if (
      !isNaN(updatedValueNum) &&
      !isNaN(initialWValueNum) &&
      !isNaN(initialHValueNum)
    ) {
      const ratio = updatedValueNum / initialWValueNum;
      const updatedHvalue = initialHValueNum * ratio;

      setFieldValue('hValue', updatedHvalue.toFixed(isInches ? 4 : 2));
      setFieldValue(
        'dhValue',
        (updatedHvalue - initialHValueNum).toFixed(isInches ? 4 : 2),
      );
      setFieldValue('phValue', values['pwValue']);
    }
  };

  return (
    <Checkbox
      {...field}
      onChange={customOnChange}
      isDisabled={isDisabled}
      isChecked={field.value}
    >
      {children}
    </Checkbox>
  );
};

export default DialogOption;
