/**
 Making SVG icon buttons based on the mockup draft SVG file that is used on the header sections of the application.
*/

import { Icon, useStyleConfig } from '@chakra-ui/react';

export const OpenByIdIcon = (props) => (
  <Icon viewBox="0 0 30 30" {...props}>
    <g transform="translate(-107.74 -188.79)" fill="none">
      <g
        transform="matrix(1.7926 0 0 1.7926 50.591 339.5)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".465"
      >
        <path d="m39.825-77.506-1.0049 4.617" />
        <path d="m41.399-77.506-1.0049 4.617" />
        <path d="m42.485-75.955h-4.5963" />
        <path d="m42.234-74.441h-4.5963" />
      </g>
      <g transform="matrix(1.7926 0 0 1.7926 69.496 164.09)">
        <path
          d="m21.032 14.062 15.132-0.03007c1.2251-0.0024 2.2113 0.98626 2.2113 2.2113v11.567c0 1.2251-0.98626 2.2113-2.2113 2.2113h-12.928c-1.2251 0-2.2113-0.98626-2.2113-2.2113-0.0037-2.6576 0.0073-13.748 0.0073-13.748z"
          strokeOpacity=".0025974"
          strokeWidth=".5"
        />
        <rect
          x="23.827"
          y="17.905"
          width="11.635"
          height="8.4836"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth=".465"
        />
      </g>
      <path d="m112.09 199.52h5.4079v-1.09h15.511" strokeWidth=".83355" />
    </g>
  </Icon>
);

export const SelectModeIcon = (props) => (
  <Icon viewBox="0 0 30 30" {...props}>
    <g fill="none" transform="translate(5 5)">
      <polygon points="4.38,0 4.38,15 8.89,13 11.88,20 14.38,18.75 11.44,11.87 15.62,10 " />
    </g>
  </Icon>
);

export const PriceIcon = (props) => (
  <Icon viewBox="0 0 30 30" {...props}>
    <g transform="translate(5,5) ">
      <rect width="20" height="20" fill="none" />
      <g transform="translate(2,2) scale(.8)">
        <g fill="none" stroke="#6B6B6B" strokeWidth="0.25">
          <path
            fill="#FFFFFF"
            d="M6.49,13.51 c3.49,0,6.32-2.83,6.32-6.32S9.98,0.88,6.49,0.88S0.18,3.71,0.18,7.19S3,13.51,6.49,13.51"
          />
          <circle cx="6.49" cy="7.19" r="6.32" />
          <path
            fill="#6B6B6B"
            d="M7.7,3.68 C6.75,3.68,5.98,4,5.4,4.62C5.02,5.03,4.76,5.55,4.63,6.16H4.08L3.93,6.88h0.61C4.53,6.99,4.53,7.11,4.53,7.25 c0,0.08,0,0.16,0.01,0.24H4.08L3.93,8.21h0.68C4.74,8.83,5,9.36,5.4,9.78c0.58,0.62,1.32,0.93,2.22,0.93c0.53,0,1-0.1,1.4-0.3 V8.98C8.67,9.35,8.2,9.53,7.6,9.53c-0.49,0-0.9-0.18-1.2-0.55C6.24,8.8,6.12,8.54,6.04,8.21h2.11L8.3,7.49H5.95 C5.94,7.37,5.94,7.26,5.94,7.15c0-0.11,0-0.2,0.01-0.27h2.49l0.15-0.72H6.04C6.11,5.86,6.23,5.6,6.41,5.4 c0.31-0.36,0.72-0.54,1.22-0.54c0.5,0,0.89,0.13,1.15,0.39l0.27-1.28C8.67,3.78,8.22,3.68,7.7,3.68"
          />
        </g>
        <polygon
          fill="#BFBFBF"
          points="9.76,9.76 9.26,9.92 9.69,10.22 9.68,10.74 10.1,10.42 10.59,10.59 10.43,10.1 10.74,9.68 10.22,9.69 9.92,9.26"
        />
        <g fill="none" stroke="#6B6B6B" strokeWidth="0.25">
          <path
            fill="#FFFFFF"
            d="M13.51,19.12 c3.49,0,6.32-2.83,6.32-6.32S17,6.49,13.51,6.49s-6.32,2.83-6.32,6.32S10.02,19.12,13.51,19.12"
          />
          <circle cx="13.51" cy="12.81" r="6.32" />
          <path
            fill="#6B6B6B"
            d="M14.54,9.28 c-0.95,0-1.72,0.31-2.3,0.93c-0.39,0.41-0.64,0.93-0.77,1.54h-0.55l-0.15,0.72h0.61c-0.01,0.11-0.01,0.23-0.01,0.37 c0,0.08,0,0.16,0.01,0.24h-0.45l-0.15,0.72h0.68c0.13,0.63,0.39,1.15,0.78,1.57c0.58,0.62,1.32,0.93,2.22,0.93 c0.53,0,1-0.1,1.4-0.3v-1.42c-0.35,0.36-0.82,0.55-1.42,0.55c-0.49,0-0.9-0.18-1.2-0.55c-0.16-0.19-0.28-0.45-0.36-0.78H15 l0.15-0.72h-2.36c-0.01-0.12-0.01-0.23-0.01-0.34c0-0.11,0-0.2,0.01-0.27h2.49l0.15-0.72h-2.55c0.07-0.31,0.2-0.56,0.37-0.76 c0.31-0.36,0.72-0.54,1.22-0.54c0.5,0,0.89,0.13,1.15,0.39l0.27-1.28C15.51,9.37,15.06,9.28,14.54,9.28"
          />
        </g>
      </g>
    </g>
  </Icon>
);

export const CoordinateIcon = (props) => (
  <Icon viewBox="0 0 30 30" {...props}>
    <g transform="translate(5,5) ">
      <rect width="20" height="20" fill="none" />
      <g transform="translate(2,2) scale(.8)">
        <g fill="none" stroke="#6B6B6B" strokeWidth="0.25">
          <path
            fill="#fff"
            d="M20,9.33h-3.11c-0.32-3.29-2.92-5.89-6.21-6.21V0H9.32v3.11C6.03,3.44,3.43,6.04,3.11,9.33H0v1.35h3.11  c0.32,3.29,2.92,5.89,6.21,6.21V20h1.35v-3.11c3.29-0.32,5.89-2.92,6.21-6.21h3.11L20,9.33L20,9.33z M15.54,9.33h-4.86V4.46  C13.22,4.77,15.23,6.78,15.54,9.33z M9.32,4.46v4.86H4.46C4.77,6.78,6.78,4.77,9.32,4.46z M4.46,10.67h4.86v4.86  C6.78,15.23,4.77,13.22,4.46,10.67z M10.67,15.54v-4.86h4.86C15.23,13.22,13.22,15.23,10.67,15.54z"
          />
        </g>
      </g>
    </g>
  </Icon>
);

export const ResizeDotIcon = ({
  color = 'currentColor',
  size = '1rem',
  ...props
}) => (
  <Icon
    viewBox="0 0 597.5 597.5"
    width={size}
    height={size}
    aria-label="arrow up"
    {...props}
  >
    <g id="Ebene_1-2" data-name="Ebene 1">
      <g>
        <rect
          id="Rechteck_542"
          data-name="Rechteck 542"
          width="597.5"
          height="597.5"
          fill="none"
          strokeWidth="0"
        />
        <g id="Ellipse_1-2" data-name="Ellipse 1-2">
          <circle
            cx="298.75"
            cy="298.75"
            r="167.36"
            fill={color}
            strokeWidth="0"
          />
        </g>
      </g>
    </g>
  </Icon>
);

export const ArrowUpIcon = ({
  color = 'currentColor',
  size = '1rem',
  ...props
}) => (
  <Icon
    viewBox="0 0 597.5 597.5"
    width={size}
    height={size}
    aria-label="arrow up"
    {...props}
  >
    <g id="Ebene_1-2" data-name="Ebene 1">
      <g>
        <rect
          id="Rechteck_538"
          data-name="Rechteck 538"
          width="597.5"
          height="597.5"
          fill="none"
          strokeWidth="0"
        />
        <g id="Vereinigungsmenge_5-5" data-name="Vereinigungsmenge 5-5">
          <polygon
            points="508.84 341.35 368.08 341.35 368.08 490.26 229.43 490.26 229.43 341.35 88.66 341.35 298.75 107.24 508.84 341.35"
            fill={color}
            strokeWidth="0"
          />
        </g>
      </g>
    </g>
  </Icon>
);

export const CheckIcon = ({
  color = 'currentColor',
  size = '1em',
  ...props
}) => (
  <Icon
    viewBox="0 0 1024 1024"
    width={size}
    height={size}
    aria-label="check"
    {...props}
  >
    <path
      fill={color}
      d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"
    />
  </Icon>
);

export const WorldIcon = ({
  color = 'currentColor',
  size = '1em',
  ...props
}) => (
  <Icon
    viewBox="0 0 24 24"
    width={size}
    height={size}
    aria-label="world"
    {...props}
  >
    <g
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <path d="M21 12 A9 9 0 0 1 12 21 A9 9 0 0 1 3 12 A9 9 0 0 1 21 12 z" />
      <path d="M3.6 9h16.8M3.6 15h16.8M11.5 3a17 17 0 000 18M12.5 3a17 17 0 010 18" />
    </g>
  </Icon>
);

export const ArrowsCcwIcon = ({
  color = 'currentColor',
  size = '1em',
  ...props
}) => (
  <Icon
    viewBox="0 0 820 1000"
    width={size}
    height={size}
    aria-label="arrows counter-clockwise"
    {...props}
  >
    <path
      fill={color}
      d="M186 710l116-116v292L26 870l88-86C36.667 702.667-1.333 606 0 494c1.333-112 41.333-208 120-288 66.667-66.667 146.667-105.333 240-116l4 102c-66.667 10.667-124 40-172 88-58.667 58.667-88.667 129.667-90 213-1.333 83.333 26.667 155.667 84 217m332-598l276 16-88 86c77.333 81.333 115.333 178 114 290-1.333 112-41.333 208-120 288-64 65.333-144 104.667-240 118l-2-104c65.333-10.667 122-40 170-88 58.667-58.667 88.667-129.667 90-213 1.333-83.333-26.667-155.667-84-217L520 404l-2-292"
    />
  </Icon>
);

export const CloseIcon = ({
  color = 'currentColor',
  size = '1em',
  ...props
}) => (
  <Icon
    viewBox="0 0 1024 1024"
    width={size}
    height={size}
    aria-label="close"
    {...props}
  >
    <path
      fill={color}
      d="M810.667,213.333 L597.333,426.667 L810.667,640 L746.667,704 L533.333,490.667 L320,704 L256,640 L469.333,426.667 L256,213.333 L320,149.333 L533.333,362.667 L746.667,149.333z"
    />
  </Icon>
);

export const DropDownIcon = ({ color = 'currentColor', size, variant, ...props }) => {
  const styles = useStyleConfig('DropDownIcon', { size, variant });

  return (
    <Icon
      __css={styles}
      viewBox="0 0 24 24"
      fill="currentColor"
      role="button"
      {...props}
    >
      <path d="M16.939 7.939L12 12.879l-4.939-4.94-2.122 2.122L12 17.121l7.061-7.06z" />
    </Icon>
  );
};

export const ChevronDownIcon = ({
  color = 'currentColor',
  size = '.5rem',
  ...props
}) => (
  <div role="button" tabIndex="-1">
    <svg
      viewBox="0 0 24 24"
      focusable="false"
      className="chakra-icon css-onkibi"
      width={size}
      height={size}
      {...props}
    >
      <path
        fill={color}
        d="M21,5H3C2.621,5,2.275,5.214,2.105,5.553C1.937,5.892,1.973,6.297,2.2,6.6l9,12 c0.188,0.252,0.485,0.4,0.8,0.4s0.611-0.148,0.8-0.4l9-12c0.228-0.303,0.264-0.708,0.095-1.047C21.725,5.214,21.379,5,21,5z"
      />
    </svg>
  </div>
);

export const IconEye = (props) => {
  return (
    <svg
      viewBox="0 0 1024 1024"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M396 512a112 112 0 10224 0 112 112 0 10-224 0zm546.2-25.8C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM508 688c-97.2 0-176-78.8-176-176s78.8-176 176-176 176 78.8 176 176-78.8 176-176 176z" />
    </svg>
  );
};

export const IconEyeInvisible = (props) => {
  return (
    <svg
      viewBox="0 0 1024 1024"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M942.2 486.2Q889.47 375.11 816.7 305l-50.88 50.88C807.31 395.53 843.45 447.4 874.7 512 791.5 684.2 673.4 766 512 766q-72.67 0-133.87-22.38L323 798.75Q408 838 512 838q288.3 0 430.2-300.3a60.29 60.29 0 000-51.5zm-63.57-320.64L836 122.88a8 8 0 00-11.32 0L715.31 232.2Q624.86 186 512 186q-288.3 0-430.2 300.3a60.3 60.3 0 000 51.5q56.69 119.4 136.5 191.41L112.48 835a8 8 0 000 11.31L155.17 889a8 8 0 0011.31 0l712.15-712.12a8 8 0 000-11.32zM149.3 512C232.6 339.8 350.7 258 512 258c54.54 0 104.13 9.36 149.12 28.39l-70.3 70.3a176 176 0 00-238.13 238.13l-83.42 83.42C223.1 637.49 183.3 582.28 149.3 512zm246.7 0a112.11 112.11 0 01146.2-106.69L401.31 546.2A112 112 0 01396 512z" />
      <path d="M508 624c-3.46 0-6.87-.16-10.25-.47l-52.82 52.82a176.09 176.09 0 00227.42-227.42l-52.82 52.82c.31 3.38.47 6.79.47 10.25a111.94 111.94 0 01-112 112z" />
    </svg>
  );
};

export const MinusIcon = ({
  color = 'currentColor',
  size = '1em',

  ...props
}) => (
  <div role="button" tabIndex="-1">
    <svg viewBox="0 0 800 1000" width={size} height={size} {...props}>
      <path
        fill={color}
        d="M700 100c28 0 51.667 9.667 71 29s29 43 29 71v600c0 26.667-9.667 50-29 70s-43 30-71 30H100c-26.667 0-50-10-70-30S0 826.667 0 800V200c0-28 10-51.667 30-71s43.333-29 70-29h600m-50 450V450H150v100h500"
      />
    </svg>
  </div>
);

export const PlusIcon = ({
  color = 'currentColor',
  size = '1em',
  ...props
}) => (
  <div role="button" tabIndex="-1">
    <svg viewBox="0 0 1024 1024" width={size} height={size} {...props}>
      <path
        fill={color}
        d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM704 536c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z"
      />
    </svg>
  </div>
);

export const CrossHairIcon = (props) => {
  return (
    <svg
      viewBox="0 0 1024 1024"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <defs>
        <style />
      </defs>
      <path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z" />
      <path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z" />
    </svg>
  );
};

export default Icon;
