// -------------------[Feedbacks]-------------------\
/**
 * Methods related to Feedback process
 */
import { API_BASE_URL } from '../Client';

const Feedback = {
  /**
   *Sends a POST request to the feedback endpoint.
   */
  feedback: (data) => ({
    method: 'POST',
    url: `${API_BASE_URL}/feedback`,
    headers: { accept: 'application/json' },
    data: data,
  }),
};

export default Feedback;
