//Provides the necessary data for creating new elements
const newElementDataProvider = ({ elementType, placement, text }) => {
  return [
    {
      element: {
        type: elementType,
        text: text,
      },
      placement,
    },
  ];
};

//Provides triggers and necessary data for adding new elements.
export const insertNewElement = ({
  placementName,
  value,
  addNewElementToDoc,
  interactionState,
  text = '',
}) => {
  const placement = {};
  placement[placementName] = value;
  addNewElementToDoc(
    newElementDataProvider({
      elementType: interactionState,
      placement: placement,
      text: text,
    })
  );
};
