import '../../../../../../theme/components/d3styles/overlapMenu.css';
import * as d3 from 'd3'; // import d3

// Provides a dialog box containing a list of overlapped elements
export const overlapDialogBox = function ({ clientX, clientY }) {
  const dialogBox = d3.create('div');
  dialogBox
    .attr('class', 'overlap-dialog-box')
    .style('top', `${clientY}px`)
    .style('left', `${clientX}px`);

  return dialogBox.node();
};
