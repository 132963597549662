/**
 * Checks whether an element is in a selectable group.
 */

const isElementInSelectableGroup = (element) => {
  return (
    element.parentNode.nodeName === 'g' &&
    !element.parentNode.hasAttribute('fpd.panel')
  );
};

/**
 * This function returns elements where the paths intersect
 * at the position of the mouse click and is used for overlapping detection.
 */

export const getElementsThatCoverMouseByPath = ({ clientX, clientY }) => {
  // Get the elements that cover the mouse coordinates.

  const elements = document.elementsFromPoint(clientX, clientY);
  // Filter out the elements that are not in a selectable group.

  return elements.filter((element) => isElementInSelectableGroup(element));
};
