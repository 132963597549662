import * as d3 from 'd3';
export const vertexSquare = (coordinate, transformVal, vertexId) => {
  const { left, top } = coordinate;
  const style = {
    width: '10px',
    height: '10px',
    background: 'white',
    position: 'fixed',
    left: `${left}px`,
    top: `${top}px`,
    'mix-blend-mode': 'difference',
    transform: transformVal,
  };

  const square = d3.create('div');
  square.attr('id', vertexId).attr('class', 'vertexHandle');
  Object.entries(style).forEach(([key, value]) => {
    square.style(key, value);
  });
  return d3.select('#svg-container').node().appendChild(square.node());
};
