import React, { useContext, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  Flex,
  Stack,
  HStack,
  RadioGroup,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { ModalContext } from '../ModalContext';
import DimValueWithUnit from './elements/NumberInputs';
import GroupContainer from './GroupContainer';
import ActionButton from './elements/ActionButton';
import { unitDisplayMap } from '../../../../context/Types';
import {
  getPanelCenter,
  selectionBoxDataProvider,
  unitConvertor,
} from './selectionBoxDataProvider';
import DialogOption from './elements/DialogOption';
import CustomRadio from './elements/CustomRadio';
import { ApiContext } from '../../../../context/ApiContext';
import { useTranslation } from 'react-i18next';

const MirrorDialog = () => {
  const [selectedUnit, setSelectedUnit] = useState('millimeters');
  const { t } = useTranslation(['ActionDialogs', 'ActionButton']);

  const { isMirrorModalOpen, setMirrorModalOpen } = useContext(ModalContext);

  const { mirrorSelection } = useContext(ApiContext);

  const [isInputDisabled, setInputDisabled] = useState(true);

  const handleClose = () => {
    setSelectedUnit('millimeters');
    setMirrorModalOpen(false);
  };

  const mirrorDialogStyle = {
    labelW: '60px',
    geoInputsW: '100px',
  };

  const handleRadioChange = (value, name, formik) => {
    formik.setFieldValue(name, value);

    let ordinate = '';
    let position = '';
    if (name === 'axisOrdinate') {
      ordinate = value;
      position = formik.values.axisPosition;
    } else {
      ordinate = formik.values.axisOrdinate;
      position = value;
    }

    let newValue = 0.0;
    switch (position) {
      case 'lUpValue':
        setInputDisabled(true);
        newValue =
          ordinate === 'horizontal'
            ? selectionBoxDataProvider().TL.X
            : selectionBoxDataProvider().TL.Y;
        break;
      case 'csValue':
        setInputDisabled(true);
        newValue =
          ordinate === 'horizontal'
            ? selectionBoxDataProvider().X
            : selectionBoxDataProvider().Y;
        break;
      case 'rLowValue':
        setInputDisabled(true);
        newValue =
          ordinate === 'horizontal'
            ? selectionBoxDataProvider().BR.X
            : selectionBoxDataProvider().BR.Y;
        break;
      case 'cpValue':
        setInputDisabled(true);
        newValue =
          ordinate === 'horizontal' ? getPanelCenter().X : getPanelCenter().Y;
        break;
      case 'pValue':
        setInputDisabled(false);
        break;
    }

    if (position !== 'pValue') {
      if (selectedUnit === 'inches') newValue = unitConvertor.inches(newValue);

      formik.setFieldValue('mValue', newValue);
    }
  };

  return (
    isMirrorModalOpen && (
      <Modal
        isOpen={isMirrorModalOpen}
        onClose={handleClose}
        size="xl"
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('ActionDialogs:Mirror.Header')} </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{
                mValue: selectionBoxDataProvider().X,
                axisOrdinate: 'horizontal',
                axisPosition: 'csValue',
                cValue: false,
              }}
              onSubmit={(values) => {
                values.mValue = `${values.mValue} ${unitDisplayMap[selectedUnit]}`;
                mirrorSelection(
                  mirrorDataProvider(values, unitDisplayMap[selectedUnit])
                );
                handleClose();
              }}
            >
              {(formikProps) => (
                <Form noValidate>
                  <Box width="100%">
                    <GroupContainer
                      title={t('ActionDialogs:Mirror.MirrorDirection.Title')}
                    >
                      <RadioGroup name="axisOrdinate">
                        <Stack spacing={1}>
                          <CustomRadio
                            isChecked={
                              formikProps.values.axisOrdinate === 'horizontal'
                            }
                            onChange={() => {
                              formikProps.setFieldValue(
                                'axisOrdinate',
                                'horizontal'
                              );
                              handleRadioChange(
                                'horizontal',
                                'axisOrdinate',
                                formikProps
                              );
                            }}
                            name="axisOrdinate"
                            value="horizontal"
                          >
                            {t(
                              'ActionDialogs:Mirror.MirrorDirection.Horizontally'
                            )}
                          </CustomRadio>

                          <CustomRadio
                            isChecked={
                              formikProps.values.axisOrdinate === 'vertical'
                            }
                            onChange={() => {
                              formikProps.setFieldValue(
                                'axisOrdinate',
                                'vertical'
                              );
                              handleRadioChange(
                                'vertical',
                                'axisOrdinate',
                                formikProps
                              );
                            }}
                            name="axisOrdinate"
                            value="vertical"
                          >
                            {t(
                              'ActionDialogs:Mirror.MirrorDirection.Vertically'
                            )}
                          </CustomRadio>
                        </Stack>
                      </RadioGroup>
                    </GroupContainer>

                    <GroupContainer
                      title={t('ActionDialogs:Mirror.MirrorAxisPosition.Title')}
                    >
                      <RadioGroup name="axisPosition">
                        <Stack spacing={1}>
                          <CustomRadio
                            isChecked={
                              formikProps.values.axisPosition === 'lUpValue'
                            }
                            onChange={() => {
                              formikProps.setFieldValue(
                                'axisPosition',
                                'lUpValue'
                              );
                              handleRadioChange(
                                'lUpValue',
                                'axisPosition',
                                formikProps
                              );
                            }}
                            name="axisPosition"
                            value="lUpValue"
                          >
                            {t(
                              'ActionDialogs:Mirror.MirrorAxisPosition.LeftUpper'
                            )}
                          </CustomRadio>
                          <CustomRadio
                            isChecked={
                              formikProps.values.axisPosition === 'csValue'
                            }
                            onChange={() => {
                              formikProps.setFieldValue(
                                'axisPosition',
                                'csValue'
                              );
                              handleRadioChange(
                                'csValue',
                                'axisPosition',
                                formikProps
                              );
                            }}
                            name="axisPosition"
                            value="csValue"
                          >
                            {t(
                              'ActionDialogs:Mirror.MirrorAxisPosition.Center'
                            )}
                          </CustomRadio>
                          <CustomRadio
                            isChecked={
                              formikProps.values.axisPosition === 'rLowValue'
                            }
                            onChange={() => {
                              formikProps.setFieldValue(
                                'axisPosition',
                                'rLowValue'
                              );
                              handleRadioChange(
                                'rLowValue',
                                'axisPosition',
                                formikProps
                              );
                            }}
                            name="axisPosition"
                            value="rLowValue"
                          >
                            {t(
                              'ActionDialogs:Mirror.MirrorAxisPosition.RightLower'
                            )}
                          </CustomRadio>
                          <CustomRadio
                            isChecked={
                              formikProps.values.axisPosition === 'cpValue'
                            }
                            onChange={() => {
                              formikProps.setFieldValue(
                                'axisPosition',
                                'cpValue'
                              );
                              handleRadioChange(
                                'cpValue',
                                'axisPosition',
                                formikProps
                              );
                            }}
                            name="axisPosition"
                            value="cpValue"
                          >
                            {t(
                              'ActionDialogs:Mirror.MirrorAxisPosition.FPCenter'
                            )}
                          </CustomRadio>
                          <CustomRadio
                            isChecked={
                              formikProps.values.axisPosition === 'pValue'
                            }
                            onChange={() => {
                              formikProps.setFieldValue(
                                'axisPosition',
                                'pValue'
                              );
                              handleRadioChange(
                                'pValue',
                                'axisPosition',
                                formikProps
                              );
                            }}
                            name="axisPosition"
                            value="pValue"
                          >
                            {t(
                              'ActionDialogs:Mirror.MirrorAxisPosition.PositionOfMAxis'
                            )}{' '}
                          </CustomRadio>
                        </Stack>
                      </RadioGroup>

                      <Flex align="center" pt={3}>
                        <DimValueWithUnit
                          label=""
                          labelW={mirrorDialogStyle.labelW}
                          inputW={mirrorDialogStyle.geoInputsW}
                          name="mValue"
                          unitOptions={Object.keys(unitDisplayMap).filter(
                            (unit) =>
                              unit === 'millimeters' || unit === 'inches'
                          )}
                          defaultUnit={selectedUnit}
                          dependPattern={/mValue/}
                          onUnitChange={setSelectedUnit}
                          isDisabled={isInputDisabled}
                        />
                      </Flex>
                    </GroupContainer>
                    <DialogOption name="cValue">
                      {t('ActionDialogs:ApplyAction')}
                    </DialogOption>
                  </Box>
                  <ModalFooter pr="0" pl="0">
                    <HStack spacing={3}>
                      <ActionButton
                        label={t('ActionButton:Cancel')}
                        onClick={() => {
                          handleClose();
                        }}
                      />
                      <ActionButton
                        label={t('ActionButton:Ok')}
                        type="submit"
                      />
                    </HStack>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  );
};

const mirrorDataProvider = ({ mValue, axisOrdinate, cValue }, unit) => ({
  position: parseFloat(mValue),
  unit: unit,
  direction: axisOrdinate,
  make_copy: cValue,
});

export default MirrorDialog;
