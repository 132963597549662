import { createMultiStyleConfigHelpers } from "@chakra-ui/system";

const myAnatomy = ['dropDown', 'text', 'option'];
const helpers = createMultiStyleConfigHelpers(myAnatomy);

const commonStyles = {
  borderRadius: 'none',
  borderColor: 'fpd.800',
};

export const CustomSelectStyles = helpers.defineMultiStyleConfig({
  parts: myAnatomy,
  baseStyle: {
    dropDown: {
      // Define base styles for button
      ...commonStyles,
      borderWidth: '1px',
      borderRightWidth: 0,
      pr: 0,
      pl: '0px!important',
      bg: 'fpd.700',
      fontSize: 'xs',
      _hover: { bg: 'fpd.700', color: 'fpd.50' },
      _active: { bg: 'fpd.800', color: 'fpd.50' },
      _focus: { boxShadow: 'none!important' },
    },
    text: {
      color: 'fpd.50',
      fontWeight: 200,
      flex: '1  ',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'noWrap',
      pl: '5px',
    },
    // Define base styles for 'Dropdown lists item' or Option
    option: {
      fontSize: 'xs',
      pl: 3,
      pt: 1,
      pb: 1,
      bg: 'fpd.700',
      cursor: 'pointer',

      _focus: {
        pl: '11px',
        pt: '3px',
        pb: '3px',
        bgGradient: 'linear(to-t, fpd.hover, fpd.hoverGradient)',
        border: '1px solid ',
        borderColor: 'fpd.accentBorder',
      },
      _hover: {
        pl: '11px',
        pt: '3px',
        pb: '3px',
        bgGradient: 'linear(to-t, fpd.hover, fpd.hoverGradient)',
        border: '1px solid',
        borderColor: 'fpd.accentBorder',
      },
    },
  },
  variants: {},
  defaultProps: {},
});
