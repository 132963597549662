import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    border: 'none',
    pt: '2px',
    position: 'relative', // Needed for the pseudo-element positioning
    '> .chakra-collapse': {
      overflow: 'unset !important',
    },
    '::before': {
      // Pseudo-element to simulate the inset shadow effect at the top of the panel (the separation line)
      content: '""',
      position: 'absolute',
      height: '1px',
      margin: 'auto',
      width: '81%',
      top: '-1px',
      left: 0,
      right: 0,
      bg: 'fpd.400',
      borderTop: '1px solid',
      borderColor: 'fpd.800',
    },
  },
  panel: {
    borderColor: 'fpd.800',
    m: '0.25rem 2rem 0 2.25rem',
    px: 0,
    py: 3,
  },
  button: {
    fontSize: 'xs',
    fontWeight: 'bold',
    mb: '0',
    bg: 'fpd.600',
    p: '0 15px 0',
    _hover: {
      borderColor: 'fpd.accentBorder',
      bgGradient: 'linear(to-t, fpd.hover, fpd.hoverGradient)',
    },
  },
  icon: {
    color: 'fpd.500',
  },
  root: {
    border: 'none',
  },
});

export const AccordionStyles = defineMultiStyleConfig({ baseStyle });
