import { Box, Button } from '@chakra-ui/react';

import React, { useEffect, useState } from 'react';
import { ArrowUpIcon, ResizeDotIcon } from '../../../../assets/IconsSvg';
import { useMultiStyleConfig } from '@chakra-ui/react';

// Directions represented as an array to maintain order
const directions = ['nw', 'n', 'ne', 'w', 'center', 'e', 'sw', 's', 'se'];
const iconSize = '1.5rem';

const ResizeSquare = ({ onAnchorClick }) => {
  const styles = useMultiStyleConfig('ResizeSquare', {});

  /**
   *  Initialize the selectedDirection with the value from localStorage if it exists,
   *  otherwise default to 'center'
   *  */
  const [selectedDirection, setSelectedDirection] = useState(
    sessionStorage.getItem('selectedDirection') || 'center'
  );
  useEffect(() => {
    sessionStorage.setItem('selectedDirection', selectedDirection);
  }, [selectedDirection]);

  /**
   * Function to determine and retrieve the appropriate icon for a direction.
   * - If the direction is the selected one, it returns a bullet (•).
   * - If the direction is adjacent to the selected one, it returns an arrow
   *   pointing from the selected direction to it.
   *
   * Diagram to illustrate the direction mapping:
   *   NW  N  NE
   *   W  •(C)  E
   *   SW  S  SE
   */
  const getIcon = (direction) => {
    if (direction === selectedDirection) {
      return <ResizeDotIcon size={iconSize} />;
    }

    /**
     *
     * Calculating the horizontal (dx) and vertical (dy)
     * distance between the selected direction and the input direction.
     */
    const dx =
      (directions.indexOf(direction) % 3) -
      (directions.indexOf(selectedDirection) % 3);
    const dy =
      Math.floor(directions.indexOf(direction) / 3) -
      Math.floor(directions.indexOf(selectedDirection) / 3);

    /**
     *  Returning the appropriate arrow icon based on the relative
     *  positions (dx, dy) of the input direction and the selected direction.
     */
    if (dx === 1 && dy === 0) return <ArrowForwardIcon />;
    if (dx === -1 && dy === 0) return <ArrowBackIcon />;
    if (dx === 0 && dy === 1) return <ArrowDownIcon />;
    if (dx === 0 && dy === -1) return <ArrowUpIcon size={iconSize} />;
    if (dx === 1 && dy === -1)
      return (
        <Box transform="rotate(45deg)">
          <ArrowUpIcon size={iconSize} />
        </Box>
      );
    if (dx === 1 && dy === 1)
      return (
        <Box transform="rotate(135deg)">
          <ArrowUpIcon size={iconSize} />
        </Box>
      );
    if (dx === -1 && dy === 1)
      return (
        <Box transform="rotate(-135deg)">
          <ArrowUpIcon size={iconSize} />
        </Box>
      );
    if (dx === -1 && dy === -1)
      return (
        <Box transform="rotate(-45deg)">
          <ArrowUpIcon size={iconSize} />
        </Box>
      );
  };

  // Rendering a grid of buttons where each button represents a direction.
  return (
    <Box __css={styles.container}>
      {directions.map((direction, idx) => (
        <Button
          key={direction}
          onClick={() => {
            // On button click, update the selected direction and invoke the callback function with the new direction.
            setSelectedDirection(direction);
            onAnchorClick(direction);
          }}
          gridColumn={(idx % 3) + 1}
          gridRow={Math.floor(idx / 3) + 1}
          __css={styles.button}
        >
          {/* Invoking getIcon to get the appropriate icon for each button. */}
          {getIcon(direction)}
        </Button>
      ))}
    </Box>
  );
};

export default ResizeSquare;

const ArrowForwardIcon = () => (
  <Box transform="rotate(90deg)">
    <ArrowUpIcon size={iconSize} />
  </Box>
);
const ArrowBackIcon = () => (
  <Box transform="rotate(-90deg)">
    <ArrowUpIcon size={iconSize} />
  </Box>
);
const ArrowDownIcon = () => (
  <Box transform="rotate(180deg)">
    <ArrowUpIcon size={iconSize} />
  </Box>
);
