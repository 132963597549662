import { SelectModeType } from '../../../../../context/Types';
import { bboxOfSelection } from '../../../presentation/drawing/boundingBox/bboxOfSelection';
import { drawSelectionBox } from '../../../presentation/drawing/drawSelectionBox';
import { fromViewPortToFrontPanelTransformer } from '../../../presentation/utils/coordinateTransformer';
import { isPointerInBBox } from '../../isPointerInBBox';
import { elementDrawingStrategy } from '../element/handleElementCreation';
import { moveSelectedElements } from '../element/move/moveSelectedElements';

// Handle svg drag event
export const CanvasDragHandler = (
  event,
  interactionState,
  fpSvgStates,
  updateFpSvgStates,
  frontPanelGp,
  isLoading
) => {
  if (isLoading) {
    event.on('drag', null);
    event.on('end', null);
    return;
  }
  const isElementDrawn = elementDrawingStrategy(
    event,
    'canvas',
    interactionState,
    frontPanelGp,
    updateFpSvgStates
  );
  if (isElementDrawn) {
    return;
  }
  if (interactionState !== SelectModeType.SelectMode) {
    return;
  }
  const isPanelSelected = frontPanelGp.select('.BboxRectangle').empty();
  const isdrawingBoxDrawn = frontPanelGp.select('#drawingBox').attr('d');

  function isShiftBoxSelection() {
    return (
      event.sourceEvent.shiftKey &&
      !isPanelSelected &&
      !isPointerInBBox(event, frontPanelGp)
    );
  }
  function isTouchingSelectedBox() {
    return isPointerInBBox(event, frontPanelGp) && isdrawingBoxDrawn;
  }
  if (
    isPanelSelected || // Adding an element to a selection by drawing a box while holding the 'shift key' is the second part of the condition
    isShiftBoxSelection() ||
    isTouchingSelectedBox() // When the user is about to draw a selection and touches an already selected box, the drawing should continue)
  ) {
    const [path, isTouched] = drawSelectionBox(event, 'canvas', frontPanelGp);
    const drawingBox = frontPanelGp.select('#drawingBox');
    const innerDrawingBox = frontPanelGp.select('#InnerDrawingBox');

    drawingBox.attr('d', path).classed('touched', isTouched);
    innerDrawingBox.attr('d', path).classed('touched', isTouched);

    return;
  }

  const coordinateTransform = fromViewPortToFrontPanelTransformer(
    event.x,
    event.y,
    frontPanelGp
  );
  const { x: userCoordinateX, y: userCoordinateY } = coordinateTransform;
  const dx = userCoordinateX - fpSvgStates.prevDragPosition.x;
  const dy = userCoordinateY - fpSvgStates.prevDragPosition.y;
  moveSelectedElements({
    dx: dx,
    dy: dy,
    bbox: bboxOfSelection({
      frontPanelGp: frontPanelGp,
      selectedElementIds: fpSvgStates.selectedElementIds,
      isDragged: true,
    }),
    frontPanelGp,
    selectedElementIds: fpSvgStates.selectedElementIds,
  });
  updateFpSvgStates({
    isDragged: true,
    prevDragPosition: coordinateTransform,
  });
};
