import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

//DE
import ActionButtonDE from './locales/de/ActionButton.json';
import ActionDialogsDE from './locales/de/ActionDialogs.json';
import DeletePanelDE from './locales/de/DeletePanel.json';
import FeatureLogDE from './locales/de/FeatureLog.json';
import FeedbackDE from './locales/de/Feedback.json';
import HeaderDE from './locales/de/Header.json';
import ListOfPanelDE from './locales/de/ListOfPanel.json';
import RegisterDE from './locales/de/Register.json';
import LoginDE from './locales/de/Login.json';
import PanelDE from './locales/de/Panel.json';
import TextEngravingDE from './locales/de/TextEngraving.json';
import ToolbarIconsDE from './locales/de/ToolbarIcons.json';
import UploadDE from './locales/de/Upload.json';
import SmallScrDE from './locales/de/SmallScreen.json';
import EngravingObjDE from './locales/de/EngravingObjects.json';
import PricingPanelDE from './locales/de/PricingPanel.json';
import NewDocDE from './locales/de/NewDoc.json';
import printGraphicDE from './locales/de/PrintGraphic.json';

//EN
import ActionButtonEN from './locales/en/ActionButton.json';
import ActionDialogsEN from './locales/en/ActionDialogs.json';
import DeletePanelEN from './locales/en/DeletePanel.json';
import FeatureLogEN from './locales/en/FeatureLog.json';
import FeedbackEN from './locales/en/Feedback.json';
import HeaderEN from './locales/en/Header.json';
import ListOfPanelEN from './locales/en/ListOfPanel.json';
import RegisterEN from './locales/en/Register.json';
import LoginEN from './locales/en/Login.json';
import PanelEN from './locales/en/Panel.json';
import TextEngravingEN from './locales/en/TextEngraving.json';
import ToolbarIconsEN from './locales/en/ToolbarIcons.json';
import UploadEN from './locales/en/Upload.json';
import SmallScrEN from './locales/en/SmallScreen.json';
import EngravingObjEN from './locales/en/EngravingObjects.json';
import PricingPanelEN from './locales/en/PricingPanel.json';
import NewDocEN from './locales/en/NewDoc.json';
import printGraphicEN from './locales/en/PrintGraphic.json';

const resources = {
  en: {
    ActionButton: ActionButtonEN,
    ActionDialogs: ActionDialogsEN,
    DeletePanel: DeletePanelEN,
    FeatureLog: FeatureLogEN,
    Feedback: FeedbackEN,
    Header: HeaderEN,
    ListOfPanel: ListOfPanelEN,
    Register: RegisterEN,
    Login: LoginEN,
    Panel: PanelEN,
    TextEngraving: TextEngravingEN,
    ToolbarIcons: ToolbarIconsEN,
    Upload: UploadEN,
    SmallScr: SmallScrEN,
    EngravingObj: EngravingObjEN,
    PricingPanel: PricingPanelEN,
    NewDoc: NewDocEN,
    printGraphic: printGraphicEN,
  },
  de: {
    ActionButton: ActionButtonDE,
    ActionDialogs: ActionDialogsDE,
    DeletePanel: DeletePanelDE,
    FeatureLog: FeatureLogDE,
    Feedback: FeedbackDE,
    Header: HeaderDE,
    ListOfPanel: ListOfPanelDE,
    Register: RegisterDE,
    Login: LoginDE,
    Panel: PanelDE,
    TextEngraving: TextEngravingDE,
    ToolbarIcons: ToolbarIconsDE,
    Upload: UploadDE,
    SmallScr: SmallScrDE,
    EngravingObj: EngravingObjDE,
    PricingPanel: PricingPanelDE,
    NewDoc: NewDocDE,
    printGraphic: printGraphicDE,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'de', // default language
  fallbackLng: 'de',
  ns: [
    'ActionButton',
    'ActionDialogs',
    'DeletePanel',
    'FeatureLog',
    'Feedback',
    'Header',
    'ListOfPanel',
    'Register',
    'Login',
    'Panel',
    'TextEngraving',
    'ToolbarIcons',
    'Upload',
    'SmallScr',
    'EngravingObj',
    'PricingPanel',
    'NewDoc',
    'printGraphic',
  ],
  defaultNS: 'Header',
  interpolation: {
    escapeValue: false,
  },
  debug: false,
});

export default i18n;
