/**
 * Selects the panel element in the front panel group
 * and updates the front panel graphic states.
 */

import descriptionGenerator from './descriptionGenerator';

export const panelSelector = function (
  updateFpSvgStates,
  getSelectionsForGivenElements,
  frontPanelGp
) {
  // Update the front panel SVG states with the ID of the panel element
  // and set the selection state to changed.
  const fpSvgStates = updateFpSvgStates({
    selectedElementIds: [+frontPanelGp.select('.panel').attr('fpd.id')],
    isSelectionChanged: true,
  });
  const descText = descriptionGenerator({
    frontPanelGp: frontPanelGp,
    isPanelSelected: true,
  });
  getSelectionsForGivenElements([...fpSvgStates.selectedElementIds], descText);
};
