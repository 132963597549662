import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const commonFieldStyles = {
  fontSize: 'xs',
  color: 'fpd.50',
  borderRadius: 'none',
  fontWeight: 200,
  bg: 'fpd.700',
  border: '1px solid',
  borderColor: 'fpd.800',

  _active: {
    color: 'white',
  },
  _placeholder: {
    color: 'fpd.500',
  },
};

const commonAddonStyles = {
  fontSize: 'xs',
  padding: '0',
  minWidth: '6rem',
};

const commonComponentStyles = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  paddingLeft: '0.3125rem',
};

const baseStyle = definePartsStyle({
  field: {},
  addon: {},
});

const variants = {
  'fpd-sidebar': definePartsStyle({
    field: {
      ...commonFieldStyles,
      textAlign: 'center',
      width: '12.0625rem', // 193px (based on layout design)
    },
    addon: {
      ...commonAddonStyles,
      justifyContent: 'flex-end',
      paddingRight: '0.5rem!important',
    },
  }),
  fpd: definePartsStyle({
    field: {
      ...commonFieldStyles,
    },
    addon: {
      ...commonAddonStyles,
    },
  }),
  dimValue: definePartsStyle({
    field: {
      ...commonFieldStyles,
      textAlign: 'center',
      width: '12.0625rem', // 193px (based on layout design)
      height: '1.33rem',
    },
    addon: {
      ...commonComponentStyles,
    },
  }),
};

export const InputStyles = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    size: 'sm',
    variant: 'fpd-sidebar',
  },
});
