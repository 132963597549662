import * as d3 from 'd3';

const OVERLAPPED_LIST_SELECTOR = '#overLapeedList input';

// Function to find the current element
function findCurrentElement(allListItems, listItem, overlappingElements) {
  return allListItems
    .nodes()
    .find(
      (input) =>
        input.name === listItem ||
        input.name === overlappingElements[listItem]?.gid
    );
}

export const overlapModalCreator = function (
  overlappingElements,
  setSelection,
  setElement
) {
  const modal = d3.create('div');
  const listItems = Object.keys(overlappingElements);
  let rightClickIndex = 0;

  modal
    .attr('class', 'overlap-modal')
    .on('contextmenu', (event) => {
      event.preventDefault();

      rightClickIndex = (rightClickIndex + 1) % listItems.length;

      const allListItems = d3.selectAll(OVERLAPPED_LIST_SELECTOR);
      const currentElement = findCurrentElement(
        allListItems,
        listItems[rightClickIndex],
        overlappingElements
      );

      currentElement.focus();
      setElement(+currentElement.name);
    })
    .on('click', () => {
      d3.selectAll('.overlap-modal,.overlap-dialog-box').remove();
      setSelection();
    });

  return modal.node();
};
