export const TreeViewStyles = {
  '.rc-tree ': {
    fontSize: 'xs',
    color: 'fpd.100',
  },

  '.rc-tree-node-selected': {
    bg: 'none',
    color: 'fpd.50',
    cursor: 'default',
    boxShadow: 'none',
    m: '0',
  },

  '.rc-tree .rc-tree-treenode:hover': {
    bgGradient: 'linear(to-t, fpd.hover, fpd.hoverGradient)',
    border: '1px solid',
    borderColor: 'fpd.accentBorder',
    m: '0',
    p: '0',
  },

  //Selected node wrapper
  '.rc-tree-treenode-selected': {
    bgGradient: 'linear(to-t, fpd.hover, fpd.hoverGradient)',
    border: '1px solid',
    borderColor: 'fpd.accentBorder',
    m: '0px!important',
    p: '0',
  },

  '.rc-tree-title': {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '17vw',
    verticalAlign: 'middle',
  },
  '.rc-tree .rc-tree-iconEle.rc-tree-icon__customize ': {
    w: '1.25rem',
    h: '1.25rem',
    verticalAlign: 'middle',
  },
  '.rc-tree .rc-tree-treenode .rc-tree-node-content-wrapper': {
    p: '1px',
    h: '25px',
  },
  '.rc-tree-treenode ': {
    m: '1px',
  },
  '.rc-tree .rc-tree-treenode ': {
    width: '100%',
  },

  '.rc-tree .rc-tree-node-content-wrapper': {
    width: ' 100%',
  },
};
