import React, { useContext } from 'react';
import { ApiContext } from '../../context/ApiContext';

import models_en_US from '../../data/model/en_US.json';
import models_de_DE from '../../data/model/de_DE.json';
import { Box, Flex } from '@chakra-ui/react'; // Importing necessary objects from '@chakra-ui' library for rendering the UI.
import MembersElementsRenderer from './MembersElementsRenderer';
import GroupContainer from '../formHeader/modal/dialog/GroupContainer';

const FilterModelRelatedMemberProps = ({
  category,
  member_properties,
  focusHandler,
  propsTopCategory,
}) => {
  const { lang } = useContext(ApiContext);
  const models = {
    en_US: models_en_US,
    de_DE: models_de_DE,
  };

  if (!category.properties.some((prop) => models[lang]['models'][prop]))
    return <></>;

  // With 'model Related Member Props', we are able to have member properties and their corresponding model object properties in the same object.
  const modelRelatedMemberProps = category.properties
    .filter(
      (prop) =>
        models[lang]['models'][prop] &&
        models[lang]['models'][prop].properties.some((modelProp) =>
          Object.keys(member_properties).some((memebr) =>
            RegExp(`^${modelProp}`, 'g').test(memebr)
          )
        )
    ) //The 'model' object value must have some member properties in its properties array, as well as exist in the current category properties.
    .reduce((acc, prop) => {
      return {
        ...acc,
        [prop]: {
          label: models[lang]['models'][prop].label,
          properties: models[lang]['models'][prop].properties.filter((prop) =>
            Object.keys(member_properties).some((memebr) =>
              RegExp(`^${prop}`, 'g').test(memebr)
            )
          ),
        },
      };
    }, {});

  if (modelRelatedMemberProps) {
    return Object.values(modelRelatedMemberProps).map(
      ({ properties: e, label }, index) => {
        const modelRespectiveObject = e.reduce(
          (acc, property) => ({
            ...acc,
            [property]: member_properties[property],
          }),
          {}
        );

        return (
          <Box key={index} ml="38px" my="2px">
            <GroupContainer title={label} variant="sidebar">
              <MembersElementsRenderer
                memberPropsObj={modelRespectiveObject}
                propsTopCategory={propsTopCategory}
                focusHandler={focusHandler}
                isGrouped={true}
              />
            </GroupContainer>
          </Box>
        );
      }
    );
  }
  return <></>;
};

export default React.memo(FilterModelRelatedMemberProps);
