/**
 * The 'Login' component, synchronize the login or register of the user.
 */

// Built-in
import React, { useContext, useState } from 'react';

import {
  Menu,
  MenuList,
  MenuItemOption,
  Text,
  Divider,
} from '@chakra-ui/react';

// Internal
import { ApiContext } from '../../context/ApiContext'; // Provides API context (Global shared state) to the component
import { ToolbarMenuIcon } from './engravingObjects/ToolbarMenuIcon';
import { User } from '../../context/Types';
import { useAuth } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';

function LoginMenu() {
  const { setLoginDialog } = useContext(ApiContext);
  const {
    userState,
    actions: { logOut, updateUserState },
  } = useAuth();
  const { userDisplayName } = userState.userData;

  const { t } = useTranslation(['Login']);

  const menuLogin = [
    { code: 'login', display: t('Login:Menu:Login') },
    { code: 'register', display: t('Login:Menu:Register') },
  ];
  const menuLogout = [
    { code: 'status', display: `` },
    { code: 'logout', display: t('Login:Menu:Logout') },
  ];
  const [menuItems, setMenuItems] = useState(
    userState.state === User.GUEST ? menuLogin : menuLogout
  );

  const actionHandlers = (code) => {
    const loginType = {
      login: () =>
        setLoginDialog((prevState) => ({
          ...prevState,
          isOpen: true,
          dialogMode: 'login',
        })),
      logout: () => {
        logOut();
        return updateUserState({ state: User.GUEST });
      },

      register: () =>
        setLoginDialog((prevState) => ({
          ...prevState,
          isOpen: true,
          dialogMode: 'register',
        })),
      default: () => console.log('Unhandled menu option'),
    };
    return (loginType[code] ?? loginType.default)();
  };

  const handleMenuItemClick = (code) => {
    actionHandlers(code);
  };

  const menuClickHandler = () => {
    setMenuItems(userState.state === User.GUEST ? menuLogin : menuLogout);
  };

  return (
    <Menu variant="noIcon">
      <ToolbarMenuIcon
        label="Login"
        onClickHandler={menuClickHandler}
        imagePath={
          userState.state === User.AUTHENTICATED
            ? '/icons/icon-user-check.svg'
            : '/icons/icon-user.svg'
        }
      />
      <MenuList transform="translateY(-4px)!important">
        {menuItems.map(({ code, display }, index) => (
          <MenuItemOption
            value={code}
            key={index}
            onClick={() => handleMenuItemClick(code)}
            sx={code === 'status' && { _hover: { bg: 'none' } }} // If user is authenticated, remove hover effect from the email display in the menu to prevent visual clutter and improve UX.
          >
            {code === 'status' ? (
              <>
                <Text>{t('Login:Menu:LoggedInAs')}</Text>
                <Text
                  fontSize="xs"
                  color="fpd.100"
                  isTruncated
                  maxW="7.75rem"
                  mb="5px"
                >
                  {userDisplayName}
                </Text>
                <Divider mt="5px" position="absolute" left="3px" w="95%" />
              </>
            ) : (
              display
            )}
          </MenuItemOption>
        ))}
      </MenuList>
    </Menu>
  );
}

export default LoginMenu;
