// Built-in
import React from 'react';

import { Flex, createStylesContext, useMultiStyleConfig, Box } from '@chakra-ui/react';

import Label from './Label';

const [StylesProvider, useStyles] = createStylesContext('PropertyItem');

const PropertyItemSelect = (props) => {
  const { variant, label, children, ...rest } = (props);
  const styles = useStyles();

  return (
    <Box __css={styles.customSelect} {...rest}>
      <Label
        label={label}
        variant={variant}
      />
      {children}
    </Box>
  );
}

const PropertyItem = (props) => {
  const {size, variant, label, children, ...rest} = props;

  const styles = useMultiStyleConfig('PropertyItem', { size, variant });

  return (
    <Flex __css={styles.container} {...rest}>
      <StylesProvider value={styles}>
        <PropertyItemSelect
          label={label}
          variant={variant}
        >
          {children}
        </PropertyItemSelect>
      </StylesProvider>
    </Flex>
  );
};

export default PropertyItem;