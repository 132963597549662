/**
 * Transforms an element array into an array
 * of objects containing element id and position.
 */
const transformElementsToObjects = (elements) => {
  return elements.reduce((acc, element) => {
    const elId = +element.getAttribute('fpd.id');
    const { e: X, f: Y } = element.transform.baseVal.consolidate().matrix;
    const elPosition = [X, Y];
    return [...acc, { id: elId, position: elPosition }];
  }, []);
};

/**
 * convert the group elements inside the panel to array of elements
 */
const groupsInPanelArray = (frontPanelGp) => {
  return Array.from(frontPanelGp.node().children);
};

export const duplicationStackData = (frontPanelGp, selectedElementIds) => {
  // Filter out elements nodes that belong to the selected elements
  const elementsToBeDuplicated = groupsInPanelArray(frontPanelGp).filter(
    (el) =>
      !el.hasAttribute('fpd.panel') &&
      selectedElementIds.includes(+el.getAttribute('fpd.id'))
  );

  // Transform elements to a duplication data structure
  const duplicatedElements = transformElementsToObjects(elementsToBeDuplicated);

  // Filter out any potential falsy values

  return duplicatedElements.filter(Boolean);
};
