import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const toolbarIconAnatomy = ['button', 'hovered', 'iconImage'];

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(toolbarIconAnatomy);

const commonStyles = {
  w: '2.375rem',
  h: '2.375rem',
  fill: 'fpd.50',
  minWidth: 'unset',
  stroke: 'fpd.50',
  borderRadius: 'none',
  bg: 'none',
  fontSize: '2.375rem',
  border: '1px solid transparent',
  _hover: {
    bgGradient: 'linear(to-t, fpd.hover, fpd.hoverGradient)',
    borderColor: 'fpd.accentBorder',
  },
  _focus: { bgGradient: 'linear(to-t, fpd.hover, fpd.hoverGradient)' },
  _active: { bgGradient: 'linear(to-t, fpd.hover, fpd.hoverGradient)' },
};

const baseStyle = definePartsStyle({
  button: {
    ...commonStyles,
  },
  hovered: {
    ...commonStyles,
    bgGradient: 'linear(to-t, fpd.hover, fpd.hoverGradient)',
    borderColor: 'fpd.accentBorder',
  },
  iconImage: {
    h: '100%',
    w: '100%',
  },
});

export const ToolbarIconStyles = defineMultiStyleConfig({ baseStyle });
