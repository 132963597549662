import { SelectModeType } from '../../../../../context/Types';
import { bboxRemover } from '../../../presentation/drawing/boundingBox/bboxRemover';
import { fromViewPortToFrontPanelTransformer } from '../../../presentation/utils/coordinateTransformer';
import { isPointerInBBox } from '../../isPointerInBBox';
import { panelSelector } from '../../selection/panelSelector';

// Handle svg click event

export const canvasClickHandler = (
  event,
  interactionState,
  updateFpSvgStates,
  frontPanelGp,
  getSelectionsForGivenElements,
  isLoading
) => {
  if (isLoading || event.sourceEvent.srcElement.tagName !== 'svg') {
    event.on('drag', null);
    event.on('end', null);
    return;
  }

  if (interactionState !== SelectModeType.SelectMode) return;

  const isPanelSelected = frontPanelGp.select('.BboxRectangle').empty();
  const prevDragPosition = fromViewPortToFrontPanelTransformer(
    event.x,
    event.y,
    frontPanelGp
  );
  updateFpSvgStates({ prevDragPosition: prevDragPosition });

  if (
    event.sourceEvent.shiftKey ||
    isPointerInBBox(event, frontPanelGp) ||
    isPanelSelected
  )
    return;

  bboxRemover(frontPanelGp);
  panelSelector(updateFpSvgStates, getSelectionsForGivenElements, frontPanelGp);
};
