/**
 * The 'Redo' component, makes the undo action possible.
 */

// Built-in
import React, { useContext, useEffect, useState } from 'react';

// Internal
import { ToolbarIcon } from './ToolbarIcon';
import { ApiContext } from '../../context/ApiContext'; // Provides API context (Global shared state) to the component

function RedoAction() {
  const { Document, DocumentVersion, isLoading, updateDocument } =
    useContext(ApiContext);

  const [visibility, setVisibility] = useState();

  useEffect(() => {
    setVisibility(!DocumentVersion.next_version);
  }, [DocumentVersion.next_version]);

  const redoButtonClickHandler = () => {
    if (isLoading) return;
    updateDocument({
      ...Document,
      id: DocumentVersion.document_id,
      version: DocumentVersion.next_version,
    });
  };
  return (
    <ToolbarIcon
        label="Redo"
        imagePath="/icons/redo.svg"
        isDisabled={visibility}
        onClickHandler={redoButtonClickHandler}
    />
  );
}

export default RedoAction;
