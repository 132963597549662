// -------------------[Authorization]-------------------\
/**
 * Methods related to Authorization process
 */
import { API_BASE_URL } from '../Client';

const Authorization = {
  /**
   *Sends a POST request to the login endpoint to authenticate a user.
   */
  login: (data) => ({
    method: 'POST',
    url: `${API_BASE_URL}/login`,
    headers: { accept: 'application/json' },
    data: data,
  }),

  /**
   *Sends a POST request to the logout endpoint to logout The user.
   */
  logout: () => ({
    method: 'POST',
    url: `${API_BASE_URL}/logout`,
    headers: { accept: 'application/json' },
  }),
  /**
   *Sends a POST request to the register endpoint to register a user.
   */
  register: (data) => ({
    method: 'POST',
    url: `${API_BASE_URL}/register`,
    headers: { accept: 'application/json' },
    data: data,
  }),
  /**
   *Sends a GET request to the session endpoint to get an anonymous session .
   */
  session: (authCheck) => ({
    method: 'GET',
    url: authCheck
      ? `${API_BASE_URL}/session/registered`
      : `${API_BASE_URL}/session`,
    headers: { accept: 'application/json' },
  }),
};
export default Authorization;
