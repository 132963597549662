/**
 * The 'SystemHolesCreator' component, manages the action of inserting a 'system holes'.
 */

// Built-in
import React, { useContext, useEffect } from 'react';

// Internal
import { ElementType } from '../../context/Types';
import { ApiContext } from '../../context/ApiContext'; // Provides API context (Global shared state) to the component

import { ToolbarIcon } from './ToolbarIcon';

function SystemHolesCreator() {
  const { Document, interactionState, setIntractionState } =
    useContext(ApiContext);

  const systemHolesButtonClickHandler = () => {};

  // const isSelected = interactionState === ElementType.DrillHole;

  return (
    <ToolbarIcon
      label={ElementType.SystemHoles}
      imagePath="/icons/system-holes.svg"
      onClickHandler={systemHolesButtonClickHandler}
      isDisabled={true}
      isSelected={false}
    />
  );
}

export default SystemHolesCreator;
