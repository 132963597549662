import {
  Box,
  useMultiStyleConfig,
  createStylesContext,
} from '@chakra-ui/react';

const [StylesProvider, useStyles] = createStylesContext('GroupContainer');

const GroupContainerTitle = (props) => {
  const { title, ...rest } = props;
  const styles = useStyles();

  return (
    <Box __css={styles.groupContainerTitle} {...rest}>
      {title}
    </Box>
  );
};

const GroupContainer = (props) => {
  const { variant, children, title, ...rest } = props;
  const styles = useMultiStyleConfig('GroupContainer', { variant });
  
  return (
    <Box __css={styles.groupContainer} {...rest}>
      <StylesProvider value={styles}>
        <GroupContainerTitle title={title} />
        <Box>{children}</Box>
      </StylesProvider>
    </Box>
  );
};

export default GroupContainer;
