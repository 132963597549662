/**
 * The 'WebShopLink' component, link to the webshop.
 */

// Built-in
import React, { useEffect } from 'react';

import { FPD_SHOP_URL } from '../../api/Client';

// Internal
import { ToolbarIcon } from './ToolbarIcon';

const isValidwebSopURL = (webSopUrl) => {
  try {
    new URL(webSopUrl);
    return true;
  } catch (error) {
    return false;
  }
};

const WebShopLink = () => {
  const LoginButtonClickHandler = (webSopUrl) => window.open(webSopUrl);

  return (
    <ToolbarIcon
      label="WebShopLink"
      imagePath="/icons/shop.svg"
      isDisabled={!isValidwebSopURL(FPD_SHOP_URL)}
      onClickHandler={() => LoginButtonClickHandler(FPD_SHOP_URL)}
    />
  );
};

export default WebShopLink;
