// -------------------[Selections]-------------------\
/**
 * Methods related to Selections Object
 */
import { API_BASE_URL } from '../Client';

const Selections = {
  /**
   * Get [Selection] of given [ElementId]s.
   */
  get: (doc_id, version_id, member_ids) => {
    return {
      method: 'POST',
      url: `${API_BASE_URL}/docs/${doc_id}/versions/${version_id}/getSelections`,
      headers: {
        accept: 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
      },
      data: { member_ids: member_ids },
    };
  },
  /**
   * Remove selected elements
   */
  delete: (doc_id, version_id, member_ids) => ({
    method: 'DELETE',
    url: `${API_BASE_URL}/docs/${doc_id}/versions/${version_id}/selections`,
    data: { member_ids: member_ids },
  }),
  /**
   * Post a [`Selection`]. This is the main route for making changes to a [`Document`]s content by changing the properties of a `Selection`. This will result in a new [`DocumentVersion`] and the [`Document`] being updated to the new version.
   */
  post: (doc_id, version_id, data, groupVal) => ({
    method: 'POST',
    url: `${API_BASE_URL}/docs/${doc_id}/versions/${version_id}/selections`,
    data: data,
    //set QUERY-STRING PARAMETERS:
    params: groupVal,
  }),
  /**
   * Post a [`Duplication`]. This is the main route for duplicat selection
   */
  duplicate: (doc_id, version_id, data) => ({
    method: 'POST',
    url: `${API_BASE_URL}/docs/${doc_id}/versions/${version_id}/selections/duplicate`,
    data: data,
  }),
  /**
   * Duplicate Whole panel elements
   */
  duplicatePanel: (doc_id, version_id) => ({
    method: 'GET',
    url: `${API_BASE_URL}/docs/${doc_id}/versions/${version_id}/selections/duplicate`,
  }),
  /**
   * Rotate panel elements
   */
  rotate: (doc_id, version_id, member_ids, data) => ({
    method: 'POST',
    url: `${API_BASE_URL}/docs/${doc_id}/versions/${version_id}/selections/rotate`,
    data: { member_ids: member_ids, ...data },
  }),
  /**
   * move panel elements
   */
  move: (doc_id, version_id, member_ids, data) => ({
    method: 'POST',
    url: `${API_BASE_URL}/docs/${doc_id}/versions/${version_id}/selections/move`,
    data: { member_ids: member_ids, ...data },
  }),
  /**
   * Mirror panel elements
   */
  mirror: (doc_id, version_id, member_ids, data) => ({
    method: 'POST',
    url: `${API_BASE_URL}/docs/${doc_id}/versions/${version_id}/selections/mirror`,
    data: { member_ids: member_ids, ...data },
  }),
  /**
   * resize panel elements
   */
  resize: (doc_id, version_id, member_ids, data) => ({
    method: 'POST',
    url: `${API_BASE_URL}/docs/${doc_id}/versions/${version_id}/selections/resize`,
    data: { member_ids: member_ids, ...data },
  }),
  /**
   * align and distribute panel elements
   */
  alignDistribute: (doc_id, version_id, member_ids, data) => ({
    method: 'POST',
    url: `${API_BASE_URL}/docs/${doc_id}/versions/${version_id}/selections/alignDistribute`,
    data: { member_ids: member_ids, ...data },
  }),
};

export default Selections;
