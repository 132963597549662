/**
 * 'ListOfPanel' displays all available panels for selection in a list.
 */

// Built-in
import React, { useRef, useEffect, useState, useContext } from 'react';
// External
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Grid,
  Spinner,
  GridItem,
} from '@chakra-ui/react'; // Importing necessary objects from '@chakra-ui' library for rendering the UI.
import 'rc-tree/assets/index.css';

// Internal
import { ToolbarIcon } from './ToolbarIcon';
import { ApiContext } from '../../context/ApiContext';
import GroupContainer from './modal/dialog/GroupContainer';
import { ScrollbarStyles } from '../../theme/components';

import Tree from '../listOfPanels/TreeView';
import { useTranslation } from 'react-i18next';
import { User } from '../../context/Types';
import { useAuth } from '../../context/AuthContext';
const LazyList = React.lazy(() => import('../listOfPanels/List'));

function ListOfPanels() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef();
  const finalRef = useRef();
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedListNode, setSelectedListNode] = useState(null);
  const { t } = useTranslation('ListOfPanel');

  const { getDocumentById, getUserStorage, isLoading, userFilesStorage } =
    useContext(ApiContext);
  const { userState } = useAuth();

  useEffect(() => {
    if (userState.state === User.AUTHENTICATED) onClose();
  }, [userState.state, onClose]);

  const ListOfPanelButtonClickHandler = () => {
    onOpen();
    //TODO: currently it takes while to get file data from the web shop
    // !userFilesStorage && getUserStorage();

    getUserStorage();
  };
  const modalCloseHandler = () => {
    onClose();
  };
  return (
    <>
      <ToolbarIcon
        label="ListPanels"
        imagePath="/icons/list_panels.svg"
        onClickHandler={ListOfPanelButtonClickHandler}
      />
      {isOpen && (<Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={modalCloseHandler}
        size="4xl"
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('Header')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid
              templateRows="repeat(1, 1fr)"
              templateColumns="repeat(3, 1fr)"
              gap={1}
            >
              <GridItem rowSpan={1} colSpan={1}>
                <GroupContainer title={t('Archive')}>
                  <Box
                    h="34rem"
                    sx={{ ...ScrollbarStyles }}
                    overflowX={'hidden'}
                    overflowY={'auto'}
                  >
                    {isLoading ? (
                      <Spinner />
                    ) : (
                      userFilesStorage && (
                        <Tree
                          userFilesStorage={userFilesStorage}
                          setSelectedNode={setSelectedNode}
                          selectedListNode={selectedListNode}
                        />
                      )
                    )}
                  </Box>
                </GroupContainer>
              </GridItem>
              <GridItem rowSpan={1} colSpan={2}>
                <GroupContainer>
                  <Box
                    h="34rem"
                    sx={{ ...ScrollbarStyles }}
                    overflowX={'hidden'}
                    overflowY={'auto'}
                  >
                    <React.Suspense fallback={<Spinner />}>
                      {isLoading ? (
                        <></>
                      ) : (
                        userFilesStorage && (
                          <LazyList
                            selectedNodeData={selectedNode}
                            fpdFilesData={userFilesStorage}
                            getDocumentById={getDocumentById}
                            modalCloser={modalCloseHandler}
                            setSelectedListNode={setSelectedListNode}
                            setSelectedNode={setSelectedNode}
                          />
                        )
                      )}
                    </React.Suspense>
                  </Box>
                </GroupContainer>
              </GridItem>
            </Grid>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      )}
    </>
  );
}

export default ListOfPanels;
