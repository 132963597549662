import { elementSelector } from '../../../selection/elementSelector';
import * as d3 from 'd3';

/**
 * Moves the selected elements by the given delta and updates the front panel graphics.
 */
export const moveSelectedElements = function ({
  dx,
  dy,
  bbox,
  frontPanelGp,
  selectedElementIds,
}) {
  // Remove the inner bounding box rectangle and vertex handles from the front panel graphics.
  frontPanelGp.select('.innerBboxRectangle').remove();
  d3.selectAll('.vertexHandle,.hamburgerIcon').remove();
  d3.select('.hamburgerIcon').remove();
  // Get the selected elements and move each element by the delta.
  const selectedElements = elementSelector(frontPanelGp, selectedElementIds);
  selectedElements.map((element) => {
    element.transform.baseVal.consolidate().matrix.e += dx;
    element.transform.baseVal.consolidate().matrix.f += dy;
  });

  // Move the outer bounding box and cross reference element by the delta.
  frontPanelGp
    .select('.BboxRectangle')
    .attr('x', bbox.x + dx)
    .attr('y', bbox.y + dy);
  frontPanelGp.selectAll('#crossReferenceElement').each(function () {
    this.transform.baseVal.consolidate().matrix.e += dx;
    this.transform.baseVal.consolidate().matrix.f += dy;
  });
};
