import React, { useContext } from 'react';
import { ApiContext } from '../../context/ApiContext';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';

import memberPropRenderer from './memberPropRenderer';
import FilterModelRelatedMemberProps from './FilterModelRelatedMemberProps';
import catPropFilter from './catPropFilter';
import MembersElementsRenderer from './MembersElementsRenderer';
import { MinusIcon, PlusIcon } from '../../assets/IconsSvg';

function PropertiesRenderer({
  category,
  focusHandler,
  index,
  member_properties,
  propsTopCategory,
}) {
  return (
    <AccordionItem borderBottomWidth={'0!important'} key={index}>
      {({ isExpanded }) => (
        <>
          <h2>
            <AccordionButton>
              {isExpanded ? <MinusIcon /> : <PlusIcon />}

              <Box ml="9px">{category.label}</Box>
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <MembersElementsRenderer
              memberPropsObj={memberPropRenderer(category, member_properties)}
              propsTopCategory={propsTopCategory}
              focusHandler={focusHandler}
            />
            <FilterModelRelatedMemberProps
              category={category}
              member_properties={member_properties}
              focusHandler={focusHandler}
              propsTopCategory={propsTopCategory}
            />
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
}

function RenderSidebar({ member_properties, focusHandler, propsTopCategory }) {
  const { lang } = useContext(ApiContext);

  // Set up 'defaultIndexes' as an array containing indexes for each item to ensure the accordion is expanded from the start.
  const defaultIndexes = catPropFilter(member_properties, lang).map(
    (_, index) => index
  );

  return (
    <Accordion defaultIndex={defaultIndexes} allowMultiple>
      {/* The default index is utilized to establish the initial expansion state. */}

      {catPropFilter(member_properties, lang).map((category, index) => (
        <PropertiesRenderer
          key={index}
          category={category}
          index={index}
          focusHandler={focusHandler}
          member_properties={member_properties}
          propsTopCategory={propsTopCategory}
        />
      ))}
    </Accordion>
  );
}

export default React.memo(RenderSidebar);
