/**
 * Removes the bounding box elements and vertex
 * handles from the front panel.
 */
import * as d3 from 'd3';

export function bboxRemover(frontPanelGp) {
  // Select all bounding box elements and vertex handles using CSS selectors,
  // and remove them from the front panel group.
  frontPanelGp
    .selectAll('rect.BboxRectangle,.innerBboxRectangle,#crossReferenceElement')

    .remove();
  d3.selectAll('.hamburgerIcon,.ham-context,.vertexHandle').remove();
}
