import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const parts = ['container', 'button'];

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts);

const baseStyle = definePartsStyle({
  container: {
    display: 'grid',
    gridTemplateRows: 'repeat(3, 1fr)',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '0',
    placeItems: 'center',
    width: '7.5rem',
    height: '7.5rem',
  },
  button: {
    width: '2.3rem',
    height: '2.3rem',
    border: '1px solid',
    borderColor: 'fpd.800',
    bg: 'fpd.700',
    transition: 'all ease-in-out 100ms',
    _hover: {
      borderColor: 'fpd.50',
    },
    _active: {
      borderColor: 'fpd.50',
    },
  },
});

export const ResizeSquareStyles = defineMultiStyleConfig({
  baseStyle,
});
