import { ScrollbarStyles } from './ScrollbarStyles';

import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const parts = ['sideBar', 'sideBarHeader'];
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts);

const baseStyle = definePartsStyle({
  sideBar: {
    color: 'white',
    bg: 'fpd.600',
    overflowY: 'auto',
    overflowX: 'hidden',
    ...ScrollbarStyles,
  },
  sideBarHeader: {
    fontSize: 'xs',
    fontWeight: 700,
    position: 'sticky',
    pl: '30px',
    pt: '20px',
    pb: '20px',
  },
});

export const SideBarStyles = defineMultiStyleConfig({ baseStyle });
