import {
  Box,
  IconButton,
  Image,
  Menu,
  MenuList,
  MenuButton,
  HStack,
  Tooltip,
  useMultiStyleConfig,
  MenuItem,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  useCallback,
  useContext,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';

import { ChevronDownIcon } from '../../../assets/IconsSvg';
import { ApiContext } from '../../../context/ApiContext';
import { ElementType } from '../../../context/Types';

let engravingOptions = {
  line: {
    icon: '/icons/line-engraving.svg',
    elementType: ElementType.EngravingLine,
  },
  rect: {
    icon: '/icons/rectangle.svg',
    elementType: ElementType.EngravingRectangle,
  },
  elip: {
    icon: '/icons/elipse.svg',
    elementType: ElementType.EngravingEllipse,
  },
};

const EngravingMenu = forwardRef((_, ref) => {
  const menuButtonRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [selectedIconType, setSelectedIconType] = useState('line');

  const ToolbarIconStyles = useMultiStyleConfig('ToolbarIcon', {});
  const engravingMenuStyles = useMultiStyleConfig('EngravingMenu', {});
  const { t } = useTranslation(['ToolbarIcons', 'EngravingObj']);

  engravingOptions.line.label = t('EngravingObj:Line');
  engravingOptions.rect.label = t('EngravingObj:Rectangle');
  engravingOptions.elip.label = t('EngravingObj:Ellipse');

  const { Document, interactionState, setIntractionState } =
    useContext(ApiContext);

  const engObjClickHandler = useCallback(
    (engravingType) => {
      setSelectedIconType(engravingType);
      setIntractionState(engravingOptions[engravingType].elementType);
    },
    [setIntractionState, setSelectedIconType]
  );

  const isSelected = Object.values(engravingOptions).some(
    (option) => option.elementType === interactionState
  );

  const selectedIcon = engravingOptions[selectedIconType].icon;
  const isDisabled = !Document.id;

  useImperativeHandle(ref, () => ({
    openMenu: () => {
      if (menuButtonRef.current && isOpen) {
        menuButtonRef.current.click();
      }
    },
  }));

  const hoverEffect =
    isHovered || isSelected
      ? { ...ToolbarIconStyles.hovered }
      : { ...ToolbarIconStyles.button };
  const iconButtonProps = {
    ...hoverEffect,
    borderRadius: '0',
    'aria-label': 'Options',
    isDisabled: isDisabled,
    onClick: () => engObjClickHandler(selectedIconType),
  };

  const menuButtonProps = {
    ...hoverEffect,

    ref: menuButtonRef,
    borderRadius: '0',
    as: IconButton,
    'aria-label': 'Options',
    isDisabled: isDisabled,
    fontSize: '10px',
    minWidth: 5,
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Box display="inline-flex">
      <HStack spacing={0.999} mr="1px">
        <Tooltip gutter={10} label={t('ToolbarIcons:EngravingObject')}>
          <IconButton
            {...iconButtonProps}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            icon={<Image src={selectedIcon} sx={ToolbarIconStyles.iconImage} />}
          />
        </Tooltip>
        <Menu
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          variant="fpd"
        >
          <MenuButton
            {...menuButtonProps}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            _focus={{ outline: 'none' }}
            w={5}
            icon={
              <Box>
                <ChevronDownIcon color="white" />
              </Box>
            }
          />

          {!isDisabled && (
            <MenuList
              onMouseLeave={handleMouseLeave}
              zIndex="999"
              transform="translate(-38px,-4px)!important"
            >
              {Object.keys(engravingOptions).map((engravingItem, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={engravingOptions[engravingItem].elementType}
                    onClick={() => engObjClickHandler(engravingItem)}
                    onMouseEnter={handleMouseEnter}
                    sx={engravingMenuStyles.listItem}
                  >
                    <Image
                      src={engravingOptions[engravingItem].icon}
                      alt={engravingOptions[engravingItem].label}
                      sx={engravingMenuStyles.iconImageList}
                    />
                    {engravingOptions[engravingItem].label}
                  </MenuItem>
                );
              })}
            </MenuList>
          )}
        </Menu>
      </HStack>
    </Box>
  );
});

export default EngravingMenu;
