import React, { useEffect, useRef } from 'react';
import { useField, useFormikContext } from 'formik';
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Flex,
  Text,
  Box,
  createStylesContext,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import { Units, unitDisplayMap } from '../../../../../context/Types';
import {
  getLength,
  getStepSize,
  unitConvertor,
  getAngle,
  getPoint,
} from '../selectionBoxDataProvider';
import CustomSelect from '../../../../CustomSelect';
import { useTranslation } from 'react-i18next';

const StyledNumberInput = ({
  field,
  name,
  handleKeyDown,
  setFieldValue,
  initialValues,
  inputW,
  values,
  defaultUnit,
  precision,
  isDisabled,
}) => {
  const step = useRef(0.1);
  const handleNumberChange = (valueAsString) => {
    const isInches =
      unitDisplayMap[defaultUnit] === unitDisplayMap[Units.INCHES];
    field.onChange({ target: { name: field.name, value: valueAsString } });
    precision = isInches ? 4 : 2;
    if (isInches) {
      initialValues = { ...initValuesProvider(initialValues, defaultUnit) };
    }

    // if value is not a valid number, reset associated fields to initial value
    if (isNaN(valueAsString) || valueAsString === '') {
      if (name === 'wValue') {
        setFieldValue('dwValue', initialValues.dwValue);
        setFieldValue('pwValue', initialValues.pwValue);
      }
      if (name === 'hValue') {
        setFieldValue('dhValue', initialValues.dhValue);
        setFieldValue('phValue', initialValues.phValue);
      }
      return;
    }
    // Convert updatedValue to a number immediately
    const updatedValueNum = parseFloat(valueAsString);

    // Convert initialValues.wValue and initialValues.hValue to numbers
    const initialWValueNum = parseFloat(initialValues.wValue);
    const initialHValueNum = parseFloat(initialValues.hValue);
    const initialXValueNum = parseFloat(initialValues.xValue);
    const initialYValueNum = parseFloat(initialValues.yValue);
    const initialLValueNum = parseFloat(initialValues.lValue);
    const initialAValueNum = parseFloat(initialValues.aValue);
    step.current = getStepSize(updatedValueNum, defaultUnit);
    let updatedHvalue;
    let updatedDWvalue;

    switch (name) {
      case 'wValue':
        const deltaW = updatedValueNum - initialWValueNum;
        const percentW = ((updatedValueNum / initialWValueNum) * 100).toFixed(
          precision
        );
        setFieldValue('dwValue', deltaW.toFixed(precision));
        setFieldValue('pwValue', percentW);
        if (!values.arValue) break;

        updatedHvalue =
          initialHValueNum * aspectRatio(updatedValueNum, initialWValueNum);
        setFieldValue('hValue', updatedHvalue.toFixed(precision));
        setFieldValue(
          'dhValue',
          (updatedHvalue - initialHValueNum).toFixed(precision)
        );
        setFieldValue('phValue', percentW);
        break;

      case 'dwValue':
        const newWValueNum = initialWValueNum + updatedValueNum;
        const newPercentW = ((newWValueNum / initialWValueNum) * 100).toFixed(
          precision
        );

        setFieldValue('wValue', newWValueNum.toFixed(precision));
        setFieldValue('pwValue', newPercentW);
        if (!values.arValue) break;
        updatedHvalue =
          initialHValueNum * aspectRatio(newWValueNum, initialWValueNum);
        setFieldValue('hValue', updatedHvalue.toFixed(precision));
        setFieldValue(
          'dhValue',
          (updatedHvalue - initialHValueNum).toFixed(precision)
        );
        setFieldValue('phValue', newPercentW);

        break;

      case 'pwValue':
        const adjustedWValue = (initialWValueNum * updatedValueNum) / 100;
        const deltaPW = adjustedWValue - initialWValueNum;

        setFieldValue('wValue', adjustedWValue.toFixed(precision));
        setFieldValue('dwValue', deltaPW.toFixed(precision));
        if (!values.arValue) break;
        updatedHvalue =
          initialHValueNum * aspectRatio(adjustedWValue, initialWValueNum);
        setFieldValue('hValue', updatedHvalue.toFixed(precision));
        setFieldValue(
          'dhValue',
          (updatedHvalue - initialHValueNum).toFixed(precision)
        );
        setFieldValue('phValue', updatedValueNum.toFixed(precision));
        break;

      case 'hValue':
        const deltaH = updatedValueNum - initialHValueNum;
        const percentH = ((updatedValueNum / initialHValueNum) * 100).toFixed(
          precision
        );

        setFieldValue('dhValue', deltaH.toFixed(precision));
        setFieldValue('phValue', percentH);
        if (!values.arValue) break;
        updatedDWvalue =
          deltaH * aspectRatio(initialWValueNum, initialHValueNum);
        setFieldValue(
          'wValue',
          (initialWValueNum + updatedDWvalue).toFixed(precision)
        );
        setFieldValue('dwValue', updatedDWvalue.toFixed(precision));
        setFieldValue('pwValue', percentH);
        break;

      case 'dhValue':
        const newHValue = initialHValueNum + updatedValueNum;
        const newPercentH = ((newHValue / initialHValueNum) * 100).toFixed(
          precision
        );

        setFieldValue('hValue', newHValue.toFixed(precision));
        setFieldValue('phValue', newPercentH);
        if (!values.arValue) break;
        updatedDWvalue =
          updatedValueNum * aspectRatio(initialWValueNum, initialHValueNum);
        setFieldValue(
          'wValue',
          (initialWValueNum + updatedDWvalue).toFixed(precision)
        );
        setFieldValue('dwValue', updatedDWvalue.toFixed(precision));
        setFieldValue('pwValue', newPercentH);
        break;

      case 'phValue':
        const adjustedHValue = (initialHValueNum * updatedValueNum) / 100;
        const deltaPH = adjustedHValue - initialHValueNum;

        setFieldValue('hValue', adjustedHValue.toFixed(precision));
        setFieldValue('dhValue', deltaPH.toFixed(precision));

        if (!values.arValue) break;

        updatedDWvalue =
          deltaPH * aspectRatio(initialWValueNum, initialHValueNum);
        setFieldValue(
          'wValue',
          (initialWValueNum + updatedDWvalue).toFixed(precision)
        );
        setFieldValue('dwValue', updatedDWvalue.toFixed(precision));
        setFieldValue('pwValue', updatedValueNum.toFixed(precision));
        break;

      case 'xValue': {
        const deltaX = updatedValueNum - initialXValueNum;
        const deltaL = getLength(deltaX, values.dyValue);
        const deltaA = getAngle(deltaX, values.dyValue);
        const length = getLength(updatedValueNum, values.yValue);
        const angle = getAngle(updatedValueNum, values.yValue);

        setFieldValue('dxValue', deltaX.toFixed(precision));
        setFieldValue('dlValue', deltaL.toFixed(precision));
        setFieldValue('daValue', deltaA.toFixed(precision));
        setFieldValue('lValue', length.toFixed(precision));
        setFieldValue('aValue', angle.toFixed(precision));
        break;
      }

      case 'dxValue': {
        const deltaL = getLength(updatedValueNum, values.dyValue);
        const deltaA = getAngle(updatedValueNum, values.dyValue);
        const newXValue = initialXValueNum + updatedValueNum;
        const length = getLength(newXValue, values.yValue);
        const angle = getAngle(newXValue, values.yValue);

        setFieldValue('dlValue', deltaL.toFixed(precision));
        setFieldValue('daValue', deltaA.toFixed(precision));
        setFieldValue('xValue', newXValue.toFixed(precision));
        setFieldValue('lValue', length.toFixed(precision));
        setFieldValue('aValue', angle.toFixed(precision));
        break;
      }

      case 'yValue': {
        const deltaY = updatedValueNum - initialYValueNum;
        const deltaL = getLength(values.dxValue, deltaY);
        const deltaA = getAngle(values.dxValue, deltaY);
        const length = getLength(values.xValue, updatedValueNum);
        const angle = getAngle(values.xValue, updatedValueNum);

        setFieldValue('dyValue', deltaY.toFixed(precision));
        setFieldValue('dlValue', deltaL.toFixed(precision));
        setFieldValue('daValue', deltaA.toFixed(precision));
        setFieldValue('lValue', length.toFixed(precision));
        setFieldValue('aValue', angle.toFixed(precision));
        break;
      }

      case 'dyValue': {
        const deltaL = getLength(values.dxValue, updatedValueNum);
        const deltaA = getAngle(values.dxValue, updatedValueNum);
        const newYValue = initialYValueNum + updatedValueNum;
        const length = getLength(values.xValue, newYValue);
        const angle = getAngle(values.xValue, newYValue);

        setFieldValue('dlValue', deltaL.toFixed(precision));
        setFieldValue('daValue', deltaA.toFixed(precision));
        setFieldValue('yValue', newYValue.toFixed(precision));
        setFieldValue('lValue', length.toFixed(precision));
        setFieldValue('aValue', angle.toFixed(precision));
        break;
      }

      case 'lValue': {
        const point = getPoint(updatedValueNum, values.aValue);
        const point2 = getPoint(initialLValueNum, initialAValueNum);
        const deltaX = point.x - point2.x;
        const deltaY = point.y - point2.y;
        const deltaL = getLength(deltaX, deltaY);
        const deltaA = getAngle(deltaX, deltaY);

        setFieldValue('xValue', point.x.toFixed(precision));
        setFieldValue('yValue', point.y.toFixed(precision));
        setFieldValue('dxValue', deltaX.toFixed(precision));
        setFieldValue('dyValue', deltaY.toFixed(precision));
        setFieldValue('dlValue', deltaL.toFixed(precision));
        setFieldValue('daValue', deltaA.toFixed(precision));
        break;
      }

      case 'dlValue': {
        const point = getPoint(updatedValueNum, values.daValue);
        const x = initialXValueNum + point.x;
        const y = initialYValueNum + point.y;
        const length = getLength(x, y);
        const angle = getAngle(x, y);

        setFieldValue('xValue', x.toFixed(precision));
        setFieldValue('yValue', y.toFixed(precision));
        setFieldValue('dxValue', point.x.toFixed(precision));
        setFieldValue('dyValue', point.y.toFixed(precision));
        setFieldValue('lValue', length.toFixed(precision));
        setFieldValue('aValue', angle.toFixed(precision));
        break;
      }

      case 'aValue': {
        const point = getPoint(values.lValue, updatedValueNum);
        const point2 = getPoint(initialLValueNum, initialAValueNum);
        const deltaX = point.x - point2.x;
        const deltaY = point.y - point2.y;
        const deltaL = getLength(deltaX, deltaY);
        const deltaA = getAngle(deltaX, deltaY);

        setFieldValue('xValue', point.x.toFixed(precision));
        setFieldValue('yValue', point.y.toFixed(precision));
        setFieldValue('dxValue', deltaX.toFixed(precision));
        setFieldValue('dyValue', deltaY.toFixed(precision));
        setFieldValue('dlValue', deltaL.toFixed(precision));
        setFieldValue('daValue', deltaA.toFixed(precision));
        break;
      }

      case 'daValue': {
        const point = getPoint(values.dlValue, updatedValueNum);
        const x = initialXValueNum + point.x;
        const y = initialYValueNum + point.y;
        const length = getLength(x, y);
        const angle = getAngle(x, y);

        setFieldValue('xValue', x.toFixed(precision));
        setFieldValue('yValue', y.toFixed(precision));
        setFieldValue('lValue', length.toFixed(precision));
        setFieldValue('aValue', angle.toFixed(precision));
        setFieldValue('dxValue', point.x.toFixed(precision));
        setFieldValue('dyValue', point.y.toFixed(precision));
        break;
      }
      case 'scaleXvalue': {
        if (values.scalePvalue) {
          setFieldValue('scaleYvalue', valueAsString);
        }
        break;
      }
      case 'scaleYvalue': {
        if (values.scalePvalue) {
          setFieldValue('scaleXvalue', valueAsString);
        }
        break;
      }
      default: 
        break;
    }
  };

  return (
    <NumberInput
      width={inputW}
      id={name}
      step={step.current}
      onKeyDown={handleKeyDown}
      onChange={handleNumberChange}
      value={field.value}
      precision={precision}
      isDisabled={isDisabled}
    >
      <NumberInputField {...field} />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  );
};

const [StylesProvider, useStyles] = createStylesContext('DimValueWithUnitStyles');

const UnitSelector = ({
  unitOptions,
  defaultUnit,
  handleUnitChange,
  displayUnit,
}) => {
  const styles = useStyles();

  const { t } = useTranslation('ActionDialogs');

  const unitOptionsTranslate =
    Array.isArray(unitOptions) &&
    unitOptions.map((option) => {
      return t(`UnitSelector.${option}`);
    });
  // Function to render the unit label
  const renderUnitDisplay = () => (
    <Box __css={styles.unit}>
      {!displayUnit
        ? t(`UnitSelector.${unitDisplayMap[defaultUnit]}`)
        : t(`UnitSelector.${displayUnit}`)}
    </Box>
  );

  // Function to render the CustomSelect
  const renderUnitSelector = () => (
    <CustomSelect
      textAlign="left"
      dropDownWidth="8.125rem"
      dropDownHeight="1.6rem"
      value={t(`UnitSelector.${defaultUnit}`)}
      options={unitOptionsTranslate}
      onChange={handleUnitChange}
    />
  );

  return unitOptions ? renderUnitSelector() : renderUnitDisplay();
};

const DimValueWithUnitLabel = ({ label, ...rest }) => {
  const styles = useStyles();
  return (
    <Box __css={styles.label} {...rest}>
      <Text>{label}</Text>
    </Box>
  );
};

const DimValueWithUnit = ({
  label,
  labelW,
  inputW,
  name,
  unitOptions,
  defaultUnit,
  onUnitChange,
  isDisabled,
  displayUnit,
  dependPattern,
}) => {
  const styles = useMultiStyleConfig('DimValueWithUnit', {});

  const [field] = useField(name);
  const { t } = useTranslation('ActionDialogs');

  const { submitForm, setFieldValue, initialValues, values } =
    useFormikContext();
  const precision = useRef(2);
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      submitForm();
    }
  };

  const handleUnitChange = (option) => {
    const optionRetranslate =
      option === 'Millimeter'
        ? 'millimeters'
        : option === 'Zoll'
        ? 'inches'
        : option;

    if (onUnitChange) onUnitChange(optionRetranslate);
    precision.current =
      unitDisplayMap[optionRetranslate] === unitDisplayMap[Units.INCHES]
        ? 4
        : 2;
    const valKeys = Object.keys(values).filter((key) =>
      dependPattern?.test(key)
    );
    valKeys.forEach((value) => {
      setFieldValue(
        value,
        unitConvertor[unitDisplayMap[optionRetranslate]](
          parseFloat(values[value])
        ).toFixed(precision.current)
      );
    });
  };

  useEffect(() => {
    precision.current =
      unitDisplayMap[defaultUnit] === unitDisplayMap[Units.INCHES] ? 3 : 2;
  }, [defaultUnit]);

  return (
    <Flex __css={styles.container}>
      <StylesProvider value={styles}>
        <DimValueWithUnitLabel
          label={label ? t(`DimValue.${label}`) : ''}
          minW={labelW}
        />
        <StyledNumberInput
          field={field}
          name={name}
          handleKeyDown={handleKeyDown}
          setFieldValue={setFieldValue} // passing setFieldValue
          initialValues={initialValues}
          inputW={inputW}
          defaultUnit={defaultUnit}
          values={values}
          precision={precision.current}
          isDisabled={isDisabled}
        />

        <UnitSelector
          unitOptions={unitOptions}
          defaultUnit={defaultUnit}
          handleUnitChange={handleUnitChange}
          displayUnit={displayUnit}
        />
      </StylesProvider>
    </Flex>
  );
};

export default DimValueWithUnit;

//We modify the initial values because we want to use them differently for various units (mm, inches)
export const initValuesProvider = (initialValues, defaultUnit) => {
  const filteredInitValues = Object.entries(initialValues).filter(
    ([key, value]) => /^[a-zA-Z]Value$/.test(key)
  );
  return filteredInitValues.reduce(
    (acc, [k, v]) => ({
      ...acc,
      [k]: unitConvertor[unitDisplayMap[defaultUnit]](parseFloat(v)),
    }),
    {}
  );
};

const aspectRatio = (updatedValue, initialValue) => updatedValue / initialValue;
