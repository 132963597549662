/**
 * The 'Header' component renders the application header display text
 */

// Built-in
import React, { useContext } from 'react';

// External
import {
  Heading,
  Image,
  useMultiStyleConfig,
  createStylesContext,
  HStack,
} from '@chakra-ui/react'; // Importing necessary objects from '@chakra-ui' library for rendering the UI.
import { useTranslation } from 'react-i18next';

// Internal
import { ApiContext } from '../../context/ApiContext'; // Provides API context (Global shared state) to the component

const [StylesProvider, useStyles] = createStylesContext('HeaderStyles');

const Icon = (props) => {
  const { ...rest } = props;
  const styles = useStyles();

  return (
    <Image src="/favicon.ico" alt="FPD Icon" __css={styles.icon} {...rest} />
  );
};

const Header = () => {
  const styles = useMultiStyleConfig('Header', {});

  const { Document } = useContext(ApiContext); // Provides API context (Global shared state) to the component
  const { t } = useTranslation('Header');

  return (
    <StylesProvider value={styles}>
      <HStack __css={styles.headerContainer}>
        <Icon __css={styles.headerIcon} />
        <Heading>
          {t('FrontplattenDesignerWeb')}{' '}
          <span style={{ color: '#0DBD8B', textTransform: 'uppercase' }}>
            {t('Version')}{' '}
          </span>
          {Document.name ? ` - ${Document.name}` : ''}
        </Heading>
      </HStack>
    </StylesProvider>
  );
};

export default Header;
