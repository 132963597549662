/**
 * The 'DeletePanel' component deletes already opened panel from the server and clear painted graphic.
 */

// Built-in
import React, { useState, useRef, useContext } from 'react';

// External
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Text,
  HStack,
} from '@chakra-ui/react'; // AlertDialog component is used to interrupt the user with a mandatory confirmation or action.

// Internal
import { ToolbarIcon } from './ToolbarIcon';
import { ApiContext } from '../../context/ApiContext'; // Provides API context (Global shared state) to the component
import ActionButton from './modal/dialog/elements/ActionButton';
import { useTranslation } from 'react-i18next';

function DeletePanel() {
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef(false);
  const { Document, deleteDocument } = useContext(ApiContext);
  const { t } = useTranslation(['DeletePanel', 'ActionButton']);
  const onClose = () => {
    setIsOpen(false);
  };

  const cancelhandler = () => {
    onClose();
  };

  const deleteHandler = () => {
    deleteDocument(Document.id);
    onClose();
  };

  return (
    <>
      <ToolbarIcon
        label="Delete"
        imagePath="/icons/delete.svg"
        isDisabled={!Document.id}
        onClickHandler={() => setIsOpen(Document.id)}
      />
      {isOpen && (<AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        autoFocus={false}
        variant="fpd"
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>{t('DeletePanel:Header')}</AlertDialogHeader>
            <AlertDialogBody>
              <Text>
                {t('DeletePanel:Alert', { panel: Document.name })}
              </Text>
              <Box
                bg="fpd.700"
                border="1px solid"
                borderColor="fpd.800"
                p=".5rem"
                mt="1rem"
              >
                <Text mb=".5rem" fontWeight="600">
                  {t('DeletePanel:warningTitle')}
                </Text>
                <Text>{t('DeletePanel:warningMsg')}</Text>
              </Box>{' '}
            </AlertDialogBody>

            <AlertDialogFooter>
              <HStack spacing={3}>
                <ActionButton
                  ref={cancelRef}
                  onClick={cancelhandler}
                  label={t('ActionButton:Cancel')}
                />
                <ActionButton
                  onClick={deleteHandler}
                  label={t('ActionButton:Delete')}
                />
              </HStack>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      )}
    </>
  );
}

export default DeletePanel;
