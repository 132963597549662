import * as d3 from 'd3';

// creates the list of over-lapped elements as a button list for providing the overlap dialog box with items
export const overlapListItemCreator = function ({
  ctrlKey,
  frontPanelGp,
  overlappingElements,
  headerText,
  setElement,
  setSelection,
}) {
  const overlapListItem = d3
    .select('.overlap-dialog-box')
    .html(`<header>${headerText}</header>`)
    .append('ul')
    .attr('id', 'overLapeedList')
    .attr('class', 'overlap-list');

  Object.entries(overlappingElements).forEach(([id, { gid, desc }]) => {
    overlapListItem
      .append('li')
      .append('input')
      .attr('type', 'button')
      .attr('class', 'overlap-list-item')
      .attr('name', gid && !ctrlKey ? gid : id)
      .attr(
        'value',
        gid && !ctrlKey ? groupDescFinder(frontPanelGp, gid) : desc
      )
      .on('mouseover', function (event) {
        return setElement(+this.name);
      })
      .on('click', () => setSelection());
  });

  const dialogBbox = d3
    .select('.overlap-dialog-box')
    .node()
    .getBoundingClientRect();
  if (dialogBbox.bottom > window.innerHeight) {
    d3.select('.overlap-dialog-box').style(
      'top',
      `${dialogBbox.top - (dialogBbox.bottom - window.innerHeight)}px`
    );
  }
};
/**
 * When there are overlapped group elements,
 * groupDescFinder is used to set the group element description
 */
function groupDescFinder(frontPanelGp, id) {
  return frontPanelGp
    .selectAll('g')
    .filter(function () {
      return +this.getAttribute('fpd.id') === +id;
    })
    .node()
    .getAttribute('fpd.desc');
}
