import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const parts = ['container', 'customSelect'];
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts);

const baseStyle = definePartsStyle({
  container: {
    display: 'flex',
  },
  customSelect: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
});

const singleVariant = definePartsStyle({
  container: {
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '98px',
    paddingRight: '39px',
    '* + &': {
      marginTop: '0.3rem',
    },
  },
});

const groupedVariant = definePartsStyle({
  container: {
    alignItems: 'start',
    justifyContent: 'space-between',
    paddingLeft: '60px',
    '* + &': {
      marginTop: '0.3rem',
    },
  },
});

export const PropertyItemStyles = defineMultiStyleConfig({
  baseStyle,
  variants: {
    single: singleVariant,
    grouped: groupedVariant,
  },
});
