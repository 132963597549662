/**
 * CustomRadio Component
 *
 * Custom Radio Button component that uses Chakra UI for styling.
 * This component visually represents a radio button with a custom appearance
 * that displays a filled circle inside when checked (isChecked is true).
 *
 * Props:
 * - isChecked: Boolean determining if the radio is currently checked.
 * - children: Contents of the label to be displayed next to the radio button.
 * - ...rest: Spread syntax to pass on any additional props to the radio input.
 */

import { Box, Text, Input } from '@chakra-ui/react';

function CustomRadio({ isChecked, children, ...rest }) {
  return (
    <Box as="label" display="flex" alignItems="center">
      <Input type="radio" {...rest} hidden />

      <Box
        boxSize="1em"
        mr="2"
        borderRadius="50%"
        borderWidth="2px"
        borderColor="currentColor"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bg={isChecked ? 'currentColor' : 'transparent'}
      >
        {isChecked && <Box size="0.5em" bg="white" borderRadius="50%" />}
      </Box>
      <Text>{children}</Text>
    </Box>
  );
}

export default CustomRadio;
