import React, { useContext, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  Flex,
  Stack,
  HStack,
  RadioGroup,
} from '@chakra-ui/react';

import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';

import { ModalContext } from '../ModalContext';
import { ApiContext } from '../../../../context/ApiContext';
import GroupContainer from './GroupContainer';
import ActionButton from './elements/ActionButton';
import CustomRadio from './elements/CustomRadio';

const AlignDistributeDialog = () => {
  const { isAlignDistributeModalOpen, setAlignDistributeModalOpen } =
    useContext(ModalContext);

  const { Selection, alignDistributeSelection } = useContext(ApiContext);

  const { t } = useTranslation(['ActionDialogs', 'ActionButton']);

  const handleClose = () => {
    setAlignDistributeModalOpen(false);
  };

  return (
    isAlignDistributeModalOpen && (
      <Modal
        isOpen={isAlignDistributeModalOpen}
        onClose={handleClose}
        size="2xl"
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('ActionDialogs:AlignDistribute.Header')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{
                alignHorizontal: 'notAlign',
                alignVertical: 'notAlign',
                alignOn: 'first',
                distributeHorizontal: 'notDistribute',
                distributeVertical: 'notDistribute',
                distributeAcross: 'areaSelected',
              }}
              onSubmit={(values) => {
                const hasChanged =
                  values.alignHorizontal !== 'notAlign' ||
                  values.alignVertical !== 'notAlign' ||
                  (values.distributeHorizontal &&
                    values.distributeHorizontal !== 'notDistribute') ||
                  (values.distributeVertical &&
                    values.distributeVertical !== 'notDistribute');
                if (hasChanged)
                  alignDistributeSelection(alignDistributeDataProvider(values));

                handleClose();
              }}
            >
              {(formikProps) => (
                <Form>
                  <GroupContainer
                    title={t('ActionDialogs:AlignDistribute.Align.Title')}
                  >
                    <Flex>
                      <Box width="25%" pr={2}>
                        <GroupContainer
                          title={t(
                            'ActionDialogs:AlignDistribute.Align.Horizontal.Title'
                          )}
                          variant="subGroup"
                        >
                          <RadioGroup name="alignHorizontal">
                            <Stack spacing={2}>
                              <CustomRadio
                                isChecked={
                                  formikProps.values.alignHorizontal ===
                                  'notAlign'
                                }
                                onChange={() => {
                                  formikProps.setFieldValue(
                                    'alignHorizontal',
                                    'notAlign'
                                  );
                                }}
                                name="alignHorizontal"
                                value="notAlign"
                              >
                                {t(
                                  'ActionDialogs:AlignDistribute.Align.Horizontal.NotAlign'
                                )}
                              </CustomRadio>
                              <CustomRadio
                                isChecked={
                                  formikProps.values.alignHorizontal === 'left'
                                }
                                onChange={() => {
                                  formikProps.setFieldValue(
                                    'distributeHorizontal',
                                    'notDistribute'
                                  );
                                  formikProps.setFieldValue(
                                    'alignHorizontal',
                                    'left'
                                  );
                                }}
                                name="alignHorizontal"
                                value="left"
                              >
                                {t(
                                  'ActionDialogs:AlignDistribute.Align.Horizontal.Left'
                                )}
                              </CustomRadio>
                              <CustomRadio
                                isChecked={
                                  formikProps.values.alignHorizontal ===
                                  'center'
                                }
                                onChange={() => {
                                  formikProps.setFieldValue(
                                    'distributeHorizontal',
                                    'notDistribute'
                                  );
                                  formikProps.setFieldValue(
                                    'alignHorizontal',
                                    'center'
                                  );
                                }}
                                name="alignHorizontal"
                                value="center"
                              >
                                {t(
                                  'ActionDialogs:AlignDistribute.Align.Horizontal.Center'
                                )}
                              </CustomRadio>
                              <CustomRadio
                                isChecked={
                                  formikProps.values.alignHorizontal === 'right'
                                }
                                onChange={() => {
                                  formikProps.setFieldValue(
                                    'distributeHorizontal',
                                    'notDistribute'
                                  );
                                  formikProps.setFieldValue(
                                    'alignHorizontal',
                                    'right'
                                  );
                                }}
                                name="alignHorizontal"
                                value="right"
                              >
                                {t(
                                  'ActionDialogs:AlignDistribute.Align.Horizontal.Right'
                                )}
                              </CustomRadio>
                              <CustomRadio
                                isChecked={
                                  formikProps.values.alignHorizontal ===
                                  'referencePoints'
                                }
                                onChange={() => {
                                  formikProps.setFieldValue(
                                    'distributeHorizontal',
                                    'notDistribute'
                                  );
                                  formikProps.setFieldValue(
                                    'alignHorizontal',
                                    'referencePoints'
                                  );
                                }}
                                name="alignHorizontal"
                                value="referencePoints"
                              >
                                {t(
                                  'ActionDialogs:AlignDistribute.Align.Horizontal.ReferencePoints'
                                )}
                              </CustomRadio>
                            </Stack>
                          </RadioGroup>
                        </GroupContainer>
                      </Box>
                      <Box width="25%" px={2}>
                        <GroupContainer
                          title={t(
                            'ActionDialogs:AlignDistribute.Align.Vertical.Title'
                          )}
                          variant="subGroup"
                        >
                          <RadioGroup name="alignVertical">
                            <Stack spacing={2}>
                              <CustomRadio
                                isChecked={
                                  formikProps.values.alignVertical ===
                                  'notAlign'
                                }
                                onChange={() => {
                                  formikProps.setFieldValue(
                                    'alignVertical',
                                    'notAlign'
                                  );
                                }}
                                name="alignVertical"
                                value="notAlign"
                              >
                                {t(
                                  'ActionDialogs:AlignDistribute.Align.Vertical.NotAlign'
                                )}
                              </CustomRadio>
                              <CustomRadio
                                isChecked={
                                  formikProps.values.alignVertical === 'top'
                                }
                                onChange={() => {
                                  formikProps.setFieldValue(
                                    'distributeVertical',
                                    'notDistribute'
                                  );
                                  formikProps.setFieldValue(
                                    'alignVertical',
                                    'top'
                                  );
                                }}
                                name="alignVertical"
                                value="top"
                              >
                                {t(
                                  'ActionDialogs:AlignDistribute.Align.Vertical.Top'
                                )}
                              </CustomRadio>
                              <CustomRadio
                                isChecked={
                                  formikProps.values.alignVertical === 'center'
                                }
                                onChange={() => {
                                  formikProps.setFieldValue(
                                    'distributeVertical',
                                    'notDistribute'
                                  );
                                  formikProps.setFieldValue(
                                    'alignVertical',
                                    'center'
                                  );
                                }}
                                name="alignVertical"
                                value="center"
                              >
                                {t(
                                  'ActionDialogs:AlignDistribute.Align.Vertical.Center'
                                )}
                              </CustomRadio>
                              <CustomRadio
                                isChecked={
                                  formikProps.values.alignVertical === 'bottom'
                                }
                                onChange={() => {
                                  formikProps.setFieldValue(
                                    'distributeVertical',
                                    'notDistribute'
                                  );
                                  formikProps.setFieldValue(
                                    'alignVertical',
                                    'bottom'
                                  );
                                }}
                                name="alignVertical"
                                value="bottom"
                              >
                                {t(
                                  'ActionDialogs:AlignDistribute.Align.Vertical.Bottom'
                                )}
                              </CustomRadio>
                              <CustomRadio
                                isChecked={
                                  formikProps.values.alignVertical ===
                                  'referencePoints'
                                }
                                onChange={() => {
                                  formikProps.setFieldValue(
                                    'distributeVertical',
                                    'notDistribute'
                                  );
                                  formikProps.setFieldValue(
                                    'alignVertical',
                                    'referencePoints'
                                  );
                                }}
                                name="alignVertical"
                                value="referencePoints"
                              >
                                {t(
                                  'ActionDialogs:AlignDistribute.Align.Vertical.ReferencePoints'
                                )}
                              </CustomRadio>
                            </Stack>
                          </RadioGroup>
                        </GroupContainer>
                      </Box>
                      <Box width="50%" pl={2}>
                        <GroupContainer
                          title={t(
                            'ActionDialogs:AlignDistribute.Align.AlignOn.Title'
                          )}
                          variant="subGroup"
                        >
                          <RadioGroup name="alignOn">
                            <Stack spacing={2}>
                              <CustomRadio
                                isChecked={
                                  formikProps.values.alignOn === 'first'
                                }
                                onChange={() => {
                                  formikProps.setFieldValue('alignOn', 'first');
                                }}
                                name="alignOn"
                                value="first"
                              >
                                {t(
                                  'ActionDialogs:AlignDistribute.Align.AlignOn.First'
                                )}
                              </CustomRadio>
                              <CustomRadio
                                isChecked={
                                  formikProps.values.alignOn === 'last'
                                }
                                onChange={() => {
                                  formikProps.setFieldValue('alignOn', 'last');
                                }}
                                name="alignOn"
                                value="last"
                              >
                                {t(
                                  'ActionDialogs:AlignDistribute.Align.AlignOn.Last'
                                )}
                              </CustomRadio>
                              <CustomRadio
                                isChecked={
                                  formikProps.values.alignOn === 'frontPanel'
                                }
                                onChange={() => {
                                  formikProps.setFieldValue(
                                    'alignOn',
                                    'frontPanel'
                                  );
                                }}
                                name="alignOn"
                                value="frontPanel"
                              >
                                {t(
                                  'ActionDialogs:AlignDistribute.Align.AlignOn.FrontPanel'
                                )}
                              </CustomRadio>
                            </Stack>
                          </RadioGroup>
                        </GroupContainer>
                      </Box>
                    </Flex>
                  </GroupContainer>

                  {canBeDistribute(Selection.member_ids) && (
                    <GroupContainer
                      title={t(
                        'ActionDialogs:AlignDistribute.Distribute.Title'
                      )}
                    >
                      <Flex>
                        <Box width="25%" pr={2}>
                          <GroupContainer
                            title={t(
                              'ActionDialogs:AlignDistribute.Distribute.Horizontal.Title'
                            )}
                            variant="subGroup"
                          >
                            <RadioGroup name="distributeHorizontal">
                              <Stack spacing={2}>
                                <CustomRadio
                                  isChecked={
                                    formikProps.values.distributeHorizontal ===
                                    'notDistribute'
                                  }
                                  onChange={() => {
                                    formikProps.setFieldValue(
                                      'distributeHorizontal',
                                      'notDistribute'
                                    );
                                  }}
                                  name="distributeHorizontal"
                                  value="notDistribute"
                                >
                                  {t(
                                    'ActionDialogs:AlignDistribute.Distribute.Horizontal.NotDistribute'
                                  )}
                                </CustomRadio>
                                <CustomRadio
                                  isChecked={
                                    formikProps.values.distributeHorizontal ===
                                    'leftEdge'
                                  }
                                  onChange={() => {
                                    formikProps.setFieldValue(
                                      'alignHorizontal',
                                      'notAlign'
                                    );
                                    formikProps.setFieldValue(
                                      'distributeHorizontal',
                                      'leftEdge'
                                    );
                                  }}
                                  name="distributeHorizontal"
                                  value="leftEdge"
                                >
                                  {t(
                                    'ActionDialogs:AlignDistribute.Distribute.Horizontal.LeftEdge'
                                  )}
                                </CustomRadio>
                                <CustomRadio
                                  isChecked={
                                    formikProps.values.distributeHorizontal ===
                                    'center'
                                  }
                                  onChange={() => {
                                    formikProps.setFieldValue(
                                      'alignHorizontal',
                                      'notAlign'
                                    );
                                    formikProps.setFieldValue(
                                      'distributeHorizontal',
                                      'center'
                                    );
                                  }}
                                  name="distributeHorizontal"
                                  value="center"
                                >
                                  {t(
                                    'ActionDialogs:AlignDistribute.Distribute.Horizontal.Center'
                                  )}
                                </CustomRadio>
                                <CustomRadio
                                  isChecked={
                                    formikProps.values.distributeHorizontal ===
                                    'rightEdge'
                                  }
                                  onChange={() => {
                                    formikProps.setFieldValue(
                                      'alignHorizontal',
                                      'notAlign'
                                    );
                                    formikProps.setFieldValue(
                                      'distributeHorizontal',
                                      'rightEdge'
                                    );
                                  }}
                                  name="distributeHorizontal"
                                  value="rightEdge"
                                >
                                  {t(
                                    'ActionDialogs:AlignDistribute.Distribute.Horizontal.RightEdge'
                                  )}
                                </CustomRadio>
                                <CustomRadio
                                  isChecked={
                                    formikProps.values.distributeHorizontal ===
                                    'objectWidth'
                                  }
                                  onChange={() => {
                                    formikProps.setFieldValue(
                                      'alignHorizontal',
                                      'notAlign'
                                    );
                                    formikProps.setFieldValue(
                                      'distributeHorizontal',
                                      'objectWidth'
                                    );
                                  }}
                                  name="distributeHorizontal"
                                  value="objectWidth"
                                >
                                  {t(
                                    'ActionDialogs:AlignDistribute.Distribute.Horizontal.ObjectWidth'
                                  )}
                                </CustomRadio>
                                <CustomRadio
                                  isChecked={
                                    formikProps.values.distributeHorizontal ===
                                    'referencePoints'
                                  }
                                  onChange={() => {
                                    formikProps.setFieldValue(
                                      'alignHorizontal',
                                      'notAlign'
                                    );
                                    formikProps.setFieldValue(
                                      'distributeHorizontal',
                                      'referencePoints'
                                    );
                                  }}
                                  name="distributeHorizontal"
                                  value="referencePoints"
                                >
                                  {t(
                                    'ActionDialogs:AlignDistribute.Distribute.Horizontal.ReferencePoints'
                                  )}
                                </CustomRadio>
                              </Stack>
                            </RadioGroup>
                          </GroupContainer>
                        </Box>
                        <Box width="25%" px={2}>
                          <GroupContainer
                            title={t(
                              'ActionDialogs:AlignDistribute.Distribute.Vertical.Title'
                            )}
                            variant="subGroup"
                          >
                            <RadioGroup name="distributeVertical">
                              <Stack spacing={2}>
                                <CustomRadio
                                  isChecked={
                                    formikProps.values.distributeVertical ===
                                    'notDistribute'
                                  }
                                  onChange={() => {
                                    formikProps.setFieldValue(
                                      'distributeVertical',
                                      'notDistribute'
                                    );
                                  }}
                                  name="distributeVertical"
                                  value="notDistribute"
                                >
                                  {t(
                                    'ActionDialogs:AlignDistribute.Distribute.Vertical.NotDistribute'
                                  )}
                                </CustomRadio>
                                <CustomRadio
                                  isChecked={
                                    formikProps.values.distributeVertical ===
                                    'topEdge'
                                  }
                                  onChange={() => {
                                    formikProps.setFieldValue(
                                      'alignVertical',
                                      'notAlign'
                                    );
                                    formikProps.setFieldValue(
                                      'distributeVertical',
                                      'topEdge'
                                    );
                                  }}
                                  name="distributeVertical"
                                  value="topEdge"
                                >
                                  {t(
                                    'ActionDialogs:AlignDistribute.Distribute.Vertical.TopEdge'
                                  )}
                                </CustomRadio>
                                <CustomRadio
                                  isChecked={
                                    formikProps.values.distributeVertical ===
                                    'center'
                                  }
                                  onChange={() => {
                                    formikProps.setFieldValue(
                                      'alignVertical',
                                      'notAlign'
                                    );
                                    formikProps.setFieldValue(
                                      'distributeVertical',
                                      'center'
                                    );
                                  }}
                                  name="distributeVertical"
                                  value="center"
                                >
                                  {t(
                                    'ActionDialogs:AlignDistribute.Distribute.Vertical.Center'
                                  )}
                                </CustomRadio>
                                <CustomRadio
                                  isChecked={
                                    formikProps.values.distributeVertical ===
                                    'bottomEdge'
                                  }
                                  onChange={() => {
                                    formikProps.setFieldValue(
                                      'alignVertical',
                                      'notAlign'
                                    );
                                    formikProps.setFieldValue(
                                      'distributeVertical',
                                      'bottomEdge'
                                    );
                                  }}
                                  name="distributeVertical"
                                  value="bottomEdge"
                                >
                                  {t(
                                    'ActionDialogs:AlignDistribute.Distribute.Vertical.BottomEdge'
                                  )}
                                </CustomRadio>
                                <CustomRadio
                                  isChecked={
                                    formikProps.values.distributeVertical ===
                                    'objectHeight'
                                  }
                                  onChange={() => {
                                    formikProps.setFieldValue(
                                      'alignVertical',
                                      'notAlign'
                                    );
                                    formikProps.setFieldValue(
                                      'distributeVertical',
                                      'objectHeight'
                                    );
                                  }}
                                  name="distributeVertical"
                                  value="objectHeight"
                                >
                                  {t(
                                    'ActionDialogs:AlignDistribute.Distribute.Vertical.ObjectHeight'
                                  )}
                                </CustomRadio>
                                <CustomRadio
                                  isChecked={
                                    formikProps.values.distributeVertical ===
                                    'referencePoints'
                                  }
                                  onChange={() => {
                                    formikProps.setFieldValue(
                                      'alignVertical',
                                      'notAlign'
                                    );
                                    formikProps.setFieldValue(
                                      'distributeVertical',
                                      'referencePoints'
                                    );
                                  }}
                                  name="distributeVertical"
                                  value="referencePoints"
                                >
                                  {t(
                                    'ActionDialogs:AlignDistribute.Distribute.Vertical.ReferencePoints'
                                  )}
                                </CustomRadio>
                              </Stack>
                            </RadioGroup>
                          </GroupContainer>
                        </Box>
                        <Box width="50%" pl={2}>
                          <GroupContainer
                            title={t(
                              'ActionDialogs:AlignDistribute.Distribute.DistributeAcross.Title'
                            )}
                            variant="subGroup"
                          >
                            <RadioGroup name="distributeAcross">
                              <Stack spacing={2}>
                                <CustomRadio
                                  isChecked={
                                    formikProps.values.distributeAcross ===
                                    'areaSelected'
                                  }
                                  onChange={() => {
                                    formikProps.setFieldValue(
                                      'distributeAcross',
                                      'areaSelected'
                                    );
                                  }}
                                  name="distributeAcross"
                                  value="areaSelected"
                                >
                                  {t(
                                    'ActionDialogs:AlignDistribute.Distribute.DistributeAcross.AreaSelected'
                                  )}
                                </CustomRadio>
                                <CustomRadio
                                  isChecked={
                                    formikProps.values.distributeAcross ===
                                    'entireFP'
                                  }
                                  onChange={() => {
                                    formikProps.setFieldValue(
                                      'distributeAcross',
                                      'entireFP'
                                    );
                                  }}
                                  name="distributeAcross"
                                  value="entireFP"
                                >
                                  {t(
                                    'ActionDialogs:AlignDistribute.Distribute.DistributeAcross.EntireFP'
                                  )}
                                </CustomRadio>
                                <CustomRadio
                                  isChecked={
                                    formikProps.values.distributeAcross ===
                                    'entireFPBorder'
                                  }
                                  onChange={() => {
                                    formikProps.setFieldValue(
                                      'distributeAcross',
                                      'entireFPBorder'
                                    );
                                  }}
                                  name="distributeAcross"
                                  value="entireFPBorder"
                                >
                                  {t(
                                    'ActionDialogs:AlignDistribute.Distribute.DistributeAcross.EntireFPBorder'
                                  )}
                                </CustomRadio>
                              </Stack>
                            </RadioGroup>
                          </GroupContainer>
                        </Box>
                      </Flex>
                    </GroupContainer>
                  )}
                  <ModalFooter pr="0" pl="0">
                    <HStack spacing={3}>
                      <ActionButton
                        label={t('ActionButton:Cancel')}
                        onClick={() => {
                          handleClose();
                        }}
                      />
                      <ActionButton
                        label={t('ActionButton:Ok')}
                        type="submit"
                        isDisabled={
                          !(
                            formikProps.values.alignHorizontal !== 'notAlign' ||
                            formikProps.values.alignVertical !== 'notAlign'
                          )
                        }
                      />
                    </HStack>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  );
};

const alignDistributeDataProvider = ({
  alignHorizontal,
  alignVertical,
  alignOn,
  distributeHorizontal,
  distributeVertical,
  distributeAcross,
}) => ({
  align_horizontal: alignHorizontal,
  align_vertical: alignVertical,
  align_on: alignOn,
  distribute_horizontal: distributeHorizontal,
  distribute_vertical: distributeVertical,
  distribute_across: distributeAcross,
});

const canBeDistribute = (ids) => {
  return ids.length > 1;
};

export default AlignDistributeDialog;
