// EngravingMenuStyles.js
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const engravingMenuAnatomy = ['iconImageList', 'listItem'];

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(engravingMenuAnatomy);

const baseStyle = definePartsStyle({
  iconImageList: {
    h: '2.3rem',
    w: '2.3rem',
    mr: '.5rem',
  },
  listItem: {
    pl: 0,
    py: 0,
    w: '8.125rem',
    border: '1px solid transparent',
    bgGradient: 'linear(to-t, fpd.base, fpd.gradient)',
    _hover: {
      bgGradient: 'linear(to-t, fpd.hover, fpd.hoverGradient)',
      borderColor: 'fpd.accentBorder',
    },
  },
});

export const EngravingMenuStyles = defineMultiStyleConfig({ baseStyle });
