import Option from './elements/Option'; //Importing the 'Option' component. The 'Opetion' renders an element with 'bool-option' properties.
import Choice from './elements/Choice'; //Importing the 'Choice' component.  We consider 'ptSelection' and 'ptMapSelection' as 'Choice type'.
import InputField from './elements/InputField';

const componentsMapping = {
  string: InputField,
  'dim-value': InputField,
  'bool-option': Option,
  choice: Choice,
};

export const renderComponent = (item, props) => {
  const isInvalidBoolOption =
    !props?.res['is_shared'] && item === 'bool-option';
  const Component = isInvalidBoolOption
    ? componentsMapping['choice']
    : componentsMapping[item] ?? null;

  return Component ? <Component {...props} /> : null;
};
