import { ElementType, SelectModeType } from '../../../../../context/Types';
import {
  rectShapeDrawer,
  ellipseDrawer,
  lineCreatorBoxDrawer,
} from '../../../presentation/drawing/drawSelectionBox';
export const elementDrawingStrategy = (
  event,
  source,
  interactionState,
  frontPanelGp,
  updateFpSvgStates
) => {
  const strategy = strategies[interactionState];
  if (!strategy) {
    console.error('Invalid strategy: ', interactionState);
    return;
  }
  return strategy(event, source, frontPanelGp, updateFpSvgStates);
};
const strategies = {
  [ElementType.RectHole]: (event, source, frontPanelGp, updateFpSvgStates) => {
    shapeDrawingProvider(
      event,
      source,
      frontPanelGp,
      updateFpSvgStates,
      ElementType.RectHole,
      rectShapeDrawer
    );
    return true;
  },
  [ElementType.DrillHole]: (event, source, frontPanelGp, updateFpSvgStates) => {
    shapeDrawingProvider(
      event,
      source,
      frontPanelGp,
      updateFpSvgStates,
      ElementType.DrillHole,
      ellipseDrawer,
      false
    );
    return true;
  },
  [ElementType.EngravingEllipse]: (
    event,
    source,
    frontPanelGp,
    updateFpSvgStates
  ) => {
    shapeDrawingProvider(
      event,
      source,
      frontPanelGp,
      updateFpSvgStates,
      ElementType.EngravingEllipse,
      ellipseDrawer,
      event.sourceEvent.ctrlKey ? false : true
    );
    return true;
  },
  [ElementType.EngravingLine]: (
    event,
    source,
    frontPanelGp,
    updateFpSvgStates
  ) => {
    shapeDrawingProvider(
      event,
      source,
      frontPanelGp,
      updateFpSvgStates,
      ElementType.EngravingLine,
      lineCreatorBoxDrawer
    );
    return true;
  },
  [ElementType.EngravingRectangle]: (
    event,
    source,
    frontPanelGp,
    updateFpSvgStates
  ) => {
    shapeDrawingProvider(
      event,
      source,
      frontPanelGp,
      updateFpSvgStates,
      ElementType.EngravingRectangle,
      rectShapeDrawer
    );
    return true;
  },
  [SelectModeType.SelectMode]: () => {},
};

export const shapeDrawingProvider = (
  event,
  source,
  frontPanelGp,
  updateFpSvgStates,
  elementType,
  shapeDrawerType,
  freeScale
) => {
  const { shapePath, shapePoints } = shapeDrawerType(
    event,
    source,
    frontPanelGp,
    freeScale
  );
  const drawingElement = frontPanelGp.select(`#${elementType}`);
  updateFpSvgStates({
    elementCreationPoints: shapePoints,
  });
  drawingElement
    .attr('fill', 'white')
    .attr('stroke-dasharray', 'none')
    .attr('d', shapePath);
};
