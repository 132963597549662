import { defineStyleConfig } from '@chakra-ui/react';

export const ShowPasswordButtonStyles = defineStyleConfig({
  baseStyle: {
    position: 'absolute',
    right: 0,
    height: '100%',
    opacity: 0.3,
    background: 'none',
    color: 'fpd.50',
    _hover: {
      color: 'fpd.200',
    },
    _active: {
      background: 'none',
    },
  },
});
