import {
  Box,
  Text,
  Image,
  Card,
  CardBody,
  Divider,
  CardFooter,
  Tooltip,
  useMultiStyleConfig,
  Spinner,
} from '@chakra-ui/react';

import { useEffect, useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist/webpack.mjs';

const ImgCard = ({
  sessionSrc,
  fileName,
  CardClickHandler,
  onSinglePDFpageB64Ready,
  onImageReady,
  length,
  isStorageDisplayProcessing,
  setStorageDisplayProcessing,
}) => {
  const styles = useMultiStyleConfig('PrintGraphicPreviewCard', {});

  const fileType = fileName.split('.')[1];

  return (
    <>
      <Box sx={styles.box}>
        <Card sx={styles.card}>
          <CardBody sx={styles.cardBody}>
            {fileType !== 'pdf' ? (
              <Image
                src={sessionSrc}
                alt={fileName}
                sx={styles.image}
                onClick={CardClickHandler}
              />
            ) : (
              <PdfToImageCard
                pdfData={sessionSrc}
                fileName={fileName}
                CardClickHandler={CardClickHandler}
                onSinglePDFpageB64Ready={onSinglePDFpageB64Ready}
                onImageReady={onImageReady}
                length={length}
                isStorageDisplayProcessing={isStorageDisplayProcessing}
                setStorageDisplayProcessing={setStorageDisplayProcessing}
              />
            )}
          </CardBody>
          <Divider />
          <CardFooter sx={styles.cardFooter}>
            <Tooltip label={fileName}>
              <Text sx={styles.text}>{fileName}</Text>
            </Tooltip>
          </CardFooter>
        </Card>
      </Box>
    </>
  );
};

const PdfToImageCard = ({
  pdfData,
  fileName,
  CardClickHandler,
  onSinglePDFpageB64Ready,
  onImageReady,
  length,
  isStorageDisplayProcessing,
  setStorageDisplayProcessing,
}) => {
  const [imageSrc, setImageSrc] = useState(null);
  const styles = useMultiStyleConfig('PrintGraphicPreviewCard', {});

  const renderPdfAsImage = async () => {
    setStorageDisplayProcessing(true);
    try {
      const loadingTask = pdfjsLib.getDocument(pdfData);
      const pdf = await loadingTask.promise;
      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 5 });

      const canvas = document.createElement('canvas');
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      const context = canvas.getContext('2d');

      await page.render({ canvasContext: context, viewport }).promise;

      const imageDataUrl = canvas.toDataURL('image/jpeg');
      setImageSrc(imageDataUrl);
      onImageReady(imageDataUrl);
      onSinglePDFpageB64Ready(pdfData);
    } catch (error) {
      console.error('Error rendering PDF as image:', error);
    } finally {
      setStorageDisplayProcessing(false);
    }
  };

  useEffect(() => {
    if (pdfData) renderPdfAsImage();
  }, [pdfData]);

  return (
    <>
      {isStorageDisplayProcessing ? (
        <Spinner m={length > 1 ? '4.9rem' : '5.4rem'} />
      ) : (
        <Image
          src={imageSrc}
          alt={fileName}
          sx={styles.image}
          onClick={CardClickHandler}
        />
      )}
    </>
  );
};

export default ImgCard;
