import { defineStyleConfig } from '@chakra-ui/react'

export const HeadingStyles = defineStyleConfig({
  baseStyle: {
    ml: '.5rem',
    color: 'fpd.50',
  },
  sizes: {
    xxs: {
      fontSize: ".83rem"
    }
  },
  variants: {},
  defaultProps: {
    size: 'xxs',
  },
});
