/**
 * The 'Undo' component, makes the undo action possible.
 */

// Built-in
import React, { useContext, useEffect, useState } from 'react';

// Internal
import { ToolbarIcon } from './ToolbarIcon';
import { ApiContext } from '../../context/ApiContext'; // Provides API context (Global shared state) to the component

function UndoAction() {
  const { Document, DocumentVersion, isLoading, updateDocument } =
    useContext(ApiContext);
  const [visibility, setVisibility] = useState();
  // 'payload' returns an object containing the information that needs to be sent to the API.
  useEffect(() => {
    setVisibility(!DocumentVersion.previous_version);
  }, [DocumentVersion.previous_version]);
  const undoButtonClickHandler = () => {
    if (isLoading) return;
    updateDocument({
      ...Document,
      id: DocumentVersion.document_id,
      version: DocumentVersion.previous_version,
    });
  };
  return (
    <ToolbarIcon
        label="Undo"
        imagePath="/icons/undo.svg"
        isDisabled={visibility}
        onClickHandler={undoButtonClickHandler}
    />
  );
}

export default UndoAction;
