import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const fpdVariant = definePartsStyle({
  list: {
    borderRadius: 'none',
    border: '1px solid',
    borderColor: 'fpd.800',
    bg: 'fpd.600',
    p: '0',
    fontSize: 'xs',
    minWidth: 'none',
    color: 'fpd.50',
  },
  item: {
    bg: 'fpd.600',
    _hover: { bgGradient: 'linear(to-t, fpd.hover, fpd.hoverGradient)' },
    _focus: {
      bgGradient: 'linear(to-t, fpd.hover, fpd.hoverGradient)',
      bg: 'none',
    },
    _active: {
      bgGradient: 'linear(to-t, fpd.hover, fpd.hoverGradient)',
      bg: 'none',
    },
  },
});

const noIconVariant = definePartsStyle({
  ...fpdVariant,
  item: {
    ...fpdVariant.item,
    '.chakra-menu__icon-wrapper': {
      display: 'none',
    },
  },
});

export const MenuStyles = defineMultiStyleConfig({
  variants: {
    fpd: fpdVariant,
    noIcon: noIconVariant,
  },
  defaultProps: {
    variant: 'fpd',
  },
});
