import { scalabilityFactorTypes } from '../../../../../context/Types';
import i18n from '../../../../../i18n/i18n';
import { elementSelector } from '../../../interactions/selection/elementSelector';
import { selectedElementScalabilityFactorType } from '../../selectedElementScalabilityFactorType';
import { scaleFactor } from '../../utils/scaleFactor';
import { vertexDrawer } from '../vertices/vertexDrawer';
import hamburgerDrawer from './contextMenu/hamburgerDrawer';
/**
 * This function is responsible for draw
 * bounding box around selected element
 */
export const bboxDrawer = ({
  bbox,
  frontPanelGp,
  elementId = null,
  innerRect = false,
  color = 'white',
  selectedElementIds,
  patchMesh,
  isOverlaped,
  groupOrUngroupSelectionProvider,
  duplicateSelection,
  deleteSelection,
  setRotationModalOpen,
  setMoveModalOpen,
  setMirrorModalOpen,
  setResizeModalOpen,
  setAlignDistributeModalOpen,
  isDragged = false,
}) => {
  const box = frontPanelGp
    .append('rect')
    .style('mix-blend-mode', 'difference')
    .attr('class', innerRect ? 'innerBboxRectangle' : 'BboxRectangle')
    .attr('id', innerRect ? `box${elementId}` : null)
    .attr('x', bbox.x)
    .attr('y', bbox.y)
    .attr('width', bbox.width)
    .attr('height', bbox.height)
    .attr('fill', 'none')
    .attr('stroke', color)
    .attr('stroke-width', 1)
    .attr('stroke-dasharray', '4 5')
    .attr('vector-effect', 'non-scaling-stroke');

  if (innerRect) {
    return box;
  }

  const scalability = selectedElementScalabilityFactorType(
    frontPanelGp,
    selectedElementIds
  );

  if (scalability !== scalabilityFactorTypes.NONE) {
    vertexDrawer({
      bbox: bbox,
      scalability: scalability,
      frontPanelGp: frontPanelGp,
      selectedElementIds: selectedElementIds,
      patchMesh: patchMesh,
      symbolSize: scaleFactor(frontPanelGp) < 2 ? scaleFactor(frontPanelGp) : 2,
    });
  }
  const selectedIdsNodes = elementSelector(frontPanelGp, selectedElementIds);

  if (!isOverlaped & !isDragged) {
    hamburgerDrawer(
      bbox,
      frontPanelGp,
      groupingActionGenerator(selectedIdsNodes),
      groupOrUngroupSelectionProvider,
      selectedElementIds,
      duplicateSelection,
      deleteSelection,
      setRotationModalOpen,
      setMoveModalOpen,
      setMirrorModalOpen,
      setResizeModalOpen,
      setAlignDistributeModalOpen
    );
  }

  return box;
};

/**
 *  This function checks if there is more than one entity (frontPanelGp or element) selected
 */
const isSelectedMoreThanOneEntity = (selectedIdsNodes) =>
  selectedIdsNodes.filter(
    (el) => el.hasAttribute('fpd.id') && !el.hasAttribute('fpd.group')
  ).length > 1;

const numberOfSelectedGroup = (selectedIdsNodes) =>
  selectedIdsNodes.filter(
    (el) => el.hasAttribute('fpd.id') && !el.childElementCount
  ).length;
const canBeGrouped = (selectedIdsNodes) => {
  return isSelectedMoreThanOneEntity(selectedIdsNodes)
    ? i18n.t(`Panel:ContextMenu.Grouping`)
    : null;
};

const canBeUnGrouped = (selectedIdsNodes) => {
  return numberOfSelectedGroup(selectedIdsNodes) >= 1
    ? i18n.t(`Panel:ContextMenu.Ungroup`)
    : null;
};
const canBeUnGroupedAll = (selectedIdsNodes) => {
  return numberOfSelectedGroup(selectedIdsNodes) > 1
    ? i18n.t(`Panel:ContextMenu.UngroupAll`)
    : null;
};

const groupingActionGenerator = (selectedIdsNodes) => {
  return [
    canBeGrouped(selectedIdsNodes),
    canBeUnGrouped(selectedIdsNodes),
    canBeUnGroupedAll(selectedIdsNodes),
  ].filter(Boolean);
};
