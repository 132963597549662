export const vertexSquareStyleSetter = (frontPanelGp) => {
  const { x, y, bottom, right, width, height } = frontPanelGp
    .select('.BboxRectangle')
    .node()
    .getBoundingClientRect();

  return {
    'sw-resize': {
      coordinate: { left: x, top: bottom },
      transform: 'translate(-10px,0px)',
    },
    'se-resize': {
      coordinate: { left: right, top: bottom },
      transform: 'translate(0px,0px)',
    },
    'nw-resize': {
      coordinate: { left: x, top: y },
      transform: 'translate(-10px,-10px)',
    },
    'ne-resize': {
      coordinate: { left: right, top: y },
      transform: 'translate(0px,-10px)',
    },

    'nm-resize': {
      coordinate: { left: x + width / 2, top: y },
      transform: 'translate(-5px,-10px)',
    },
    'sm-resize': {
      coordinate: { left: x + width / 2, top: bottom },
      transform: 'translate(-5px,0px)',
    },
    'wm-resize': {
      coordinate: { left: x, top: y + height / 2 },
      transform: 'translate(-10px,-5px)',
    },
    'em-resize': {
      coordinate: { left: right, top: y + height / 2 },
      transform: 'translate(0px,-5px)',
    },
  };
};
