/**
 * The 'WebShopSync' component, synchronize the panel with the webshop.
 */

// Built-in
import React, { useContext } from 'react';

// Internal
import { ToolbarIcon } from './ToolbarIcon';
import { ApiContext } from '../../context/ApiContext'; // Provides API context (Global shared state) to the component

function WebShopSync() {
  const { Document, synchronizeDocument } = useContext(ApiContext);

  const SyncButtonClickHandler = () => {
    synchronizeDocument(Document.id);
  };

  return (
    <ToolbarIcon
      label="WebShopSync"
      imagePath="/icons/disk.svg"
      isDisabled={!Document.id}
      onClickHandler={SyncButtonClickHandler}
    />
  );
}

export default WebShopSync;
