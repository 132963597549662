import { theme as base } from '@chakra-ui/react';

export const styles = {
  global: {
    body: {
      fontFamily: `Inter, ${base.fonts?.heading}`,
    },
    heading: {
      fontFamily: `Inter, ${base.fonts?.body}`,
    },
  },
};
