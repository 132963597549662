import { useEffect, useRef } from 'react';
import { PDFDocument } from 'pdf-lib';
import * as pdfjsLib from 'pdfjs-dist/webpack.mjs';

function PDFPageToImage({
  pdfFile,
  pageNumber = 1,
  onImageReady,
  onPageCount,
  onSinglePDFpageB64Ready,
}) {
  const canvasRef = useRef();
  const renderTaskRef = useRef(null);
  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const loadingTask = pdfjsLib.getDocument(pdfFile);
        const pdf = await loadingTask.promise;
        const page = await pdf.getPage(pageNumber);

        // Set a default scale for rendering the page
        const viewport = page.getViewport({ scale: 1 });
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        canvas.width = viewport.width;
        canvas.height = viewport.height;

        onPageCount(pdf.numPages);

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        // Cancel the previous render task if it's still running(for case that user rapidly changed the page number)
        renderTaskRef.current?.cancel();

        // Start a new render task
        renderTaskRef.current = page.render(renderContext);
        await renderTaskRef.current.promise;

        const imageData = canvas.toDataURL('image/png');
        const DPI = 72;
        const widthInMillimeters = (canvas.width / DPI) * 25.4;
        const heightInMillimeters = (canvas.height / DPI) * 25.4;
        onImageReady(imageData, widthInMillimeters, heightInMillimeters);

        pdfSinglePageBlobProvider(pdfFile, pageNumber).then((blob) =>
          onSinglePDFpageB64Ready(blob)
        );
      } catch (error) {
        if (error.name === 'RenderingCancelledException') {
          console.log('Rendering cancelled, retrying...');
        } else {
          console.error('Error while rendering the page:', error);
        }
      }
    };

    fetchPdf();
    return () => {
      renderTaskRef.current?.cancel();
    };
  }, [pdfFile, pageNumber]);

  return (
    <div style={{ display: 'none' }}>
      <canvas ref={canvasRef}></canvas>
    </div>
  );
}

const pdfSinglePageBlobProvider = async (file, pageNumber) => {
  try {
    const pdfBuffer = await fetch(file).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(pdfBuffer);
    const totalPages = pdfDoc.getPageCount();
    const extractedDoc = await PDFDocument.create();

    const validPageNum = Math.max(1, Math.min(pageNumber, totalPages));
    const [extractedPage] = await extractedDoc.copyPages(pdfDoc, [
      validPageNum - 1,
    ]);
    extractedDoc.addPage(extractedPage);

    const singlePagePdfBytes = await extractedDoc.save();
    const blob = new Blob([singlePagePdfBytes], { type: 'application/pdf' });

    // Convert the blob to base64
    const base64Data = await convertBlobToBase64(blob);

    return base64Data;
  } catch (error) {
    console.error('Error while processing PDF:', error);
  }
};

const convertBlobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result); // reader.result contains the base64 data
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(blob); // Convert the blob to base64 (Data URL format)
  });
};

export default PDFPageToImage;
