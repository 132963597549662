import { elementSelector } from '../../interactions/selection/elementSelector';
import { scaleFactor } from '../utils/scaleFactor';

/**
 *  draws the crosscrossReferenceDrawer at the selected element center.
 */
export const crossDrawer = (frontPanelGp, elementIds) => {
  const element = elementSelector(frontPanelGp, elementIds);

  const [crossEl] =
    element.length > 1
      ? element.filter((el) => !el.childElementCount)
      : element;

  const { e, f } = crossEl.transform.baseVal // we take e(as x) and f (as y) of the element as the current position of the element after applying the initial transformation
    .consolidate().matrix;
  const crossScale =
    scaleFactor(frontPanelGp) < 2 ? scaleFactor(frontPanelGp) : 2;
  frontPanelGp
    .append('path')
    .attr('id', 'crossReferenceElement')
    .attr('d', `M-1,0L1,0M0,-1L0,1Z`)
    .attr('stroke', 'red')
    .attr('stroke-width', 1)
    .attr('vector-effect', 'non-scaling-stroke')
    .attr('transform', `matrix(${[crossScale, 0, 0, crossScale, e, f]})`);
};
